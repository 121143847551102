import { WikidataClaim, WikidataEntity, WikidataTimeValue } from "./wikidata-api"

export const timeClaimsToYears = (timeClaims: WikidataClaim<WikidataTimeValue>[]): string =>
  timeClaims
    ?.map(timeClaim => timeClaim?.mainsnak?.datavalue?.value.time)
    .map(timeString => /\d{4}/.exec(timeString)[0])
    .reduce((uniqueYears, year) => uniqueYears.includes(year) ? uniqueYears : [...uniqueYears, year], [] as string[])
    .join("/")

export const getTimeframeString = (claims: WikidataEntity['claims']): string => {
  const yearOfBirth = claims["P569"] && timeClaimsToYears(claims["P569"])
  const yearOfDeath = claims["P570"] && timeClaimsToYears(claims["P570"])

  if (yearOfBirth && yearOfDeath) {
    return `${yearOfBirth}-${yearOfDeath}`
  } else if (yearOfBirth) {
    return `geb. ${yearOfBirth}`
  } else if (yearOfDeath) {
    return `gest. ${yearOfDeath}`
  }
}

export const getName = (entity: WikidataEntity): string => {
  return entity.labels?.["de"]?.value
  ?? entity.labels?.["en"]?.value
  ?? Object.keys(entity.labels)
    ?.map(langKey => entity.labels[langKey].value)
    ?.find(labelValue => labelValue?.length > 0)
}
