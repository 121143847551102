import { Component, Input } from "@angular/core"
import { RouterLink } from "@angular/router"

@Component({
  standalone:  true,
  selector:    "insc-activity-link",
  templateUrl: "./activity-link.component.html",
  imports:     [
    RouterLink
  ],
  styleUrls:   ["./activity-link.component.scss"]
})
export class ActivityLinkComponent {
  @Input() type: string
  @Input() id: string
}
