import { Component } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { License } from "../../../../shared/models/license.model"
import { SubformComponent } from "../../../../shared/subform.component"

@Component({
  selector:    'insc-license-form',
  templateUrl: './license-form.component.html',
  styleUrls:   ['shared-styles.scss', './license-form.component.scss']
})
export class LicenseFormComponent extends SubformComponent {

  static buildFormGroup(license: Partial<License>) {
    return new FormGroup({
      id:   new FormControl(license?.id),
      name: new FormControl(license?.name),
      url:  new FormControl(license?.url),
      text: new FormControl(license?.text),
      summary: new FormControl(license?.summary)
    })
  }
}
