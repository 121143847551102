import { NgTemplateOutlet } from "@angular/common";
import { Component, Input, ViewChild } from "@angular/core"
import { MatMenu, MatMenuModule } from "@angular/material/menu"
import { ImageDownloadService } from "../../../../services/image-download.service"
import { ImageParent, InscImage } from "../../../../shared/models/image.model"
import { FormatBytesPipe } from "../../../../shared/pipes/format-bytes.pipe"

/**
 * Provides a menu with download options for a one or several images.
 *
 * Usage: Create a component instance in a template and access the menu using the [menu]{@link ImageDownloadMenuComponent#menu} property.
 * ```
 * <insc-image-download-menu #imageDownloadMenu
 *   [images]="images" [parent]="{type: 'insc_object', id: object.id}"
 * ></insc-image-download-menu>
 *  <button [matMenuTriggerFor]="imageDownloadMenu.menu">Download</button>
 *```
 */
@Component({
  selector: 'insc-image-download-menu',
  standalone: true,
  imports: [MatMenuModule, NgTemplateOutlet, FormatBytesPipe],
  templateUrl: './image-download-menu.component.html',
  styles: [
  ]
})
export class ImageDownloadMenuComponent {
  /** Array of InscImages to download */
  @Input() images: Partial<InscImage>[] = []
  /** Reference to a parent Inscription or InscObject for the images. Sent to the API for generating zip file names. */
  @Input() parent: ImageParent
  /** Provide access to the MatMenu */
  @ViewChild(MatMenu) public readonly menu: MatMenu

  constructor(public readonly imageDownloadService: ImageDownloadService) {}

  /**
   * Generates a download link for the specified version of the images.
   *
   * @param version - The version of the images to download. Defaults to "original".
   * @returns Download link for the specified version of the images.
   */
  protected getDownloadLink(version = "original") {
    return this.imageDownloadService.getDownloadLink(this.getImageIds(), version, this.parent)
  }

  private getImageIds() {
    return this.images.map(image => image.id)
  }
}
