import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from "@angular/material/button"
import { MatIconModule } from "@angular/material/icon"
import { MatSnackBarModule } from "@angular/material/snack-bar"
import { RouterLink } from "@angular/router"
import { ErrorSnackbarComponent } from "./error-snackbar/error-snackbar.component"
import { ErrorViewHostDirective } from "./error-snackbar/error-view-host.directive"
import { DatabaseErrorViewComponent } from "./error-snackbar/error-views/database-error-view.component"
import {
  DeletePreventedByAssociationErrorViewComponent
} from "./error-snackbar/error-views/delete-prevented-by-association-error-view.component"
import { ErrorViewBasicComponent } from "./error-snackbar/error-views/error-view-basic.component"
import { ValidationErrorViewComponent } from "./error-snackbar/error-views/validation-error-view.component"
import { SnackbarService } from "./snackbar.service"
import { SuccessSnackbarComponent } from "./success-snackbar/success-snackbar.component"


@NgModule({
  declarations: [
    SuccessSnackbarComponent,
    ErrorSnackbarComponent,
    ErrorViewHostDirective,
    ErrorViewBasicComponent,
    DatabaseErrorViewComponent,
    DeletePreventedByAssociationErrorViewComponent,
    ErrorViewBasicComponent,
    ValidationErrorViewComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    RouterLink,
    MatSnackBarModule
  ],
  providers: [
    SnackbarService
  ]
})
export class SnackbarsModule { }
