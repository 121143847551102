import { Component } from "@angular/core"
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms"
import { MatInputModule } from "@angular/material/input"
import { InscFormFieldErrorMessageComponent } from "../../../../shared/components/form-components/insc-form-field-error-message.component"
import { Address } from "../../../../shared/models/address.model"
import { SubformComponent } from "../../../../shared/subform.component"

@Component({
  standalone:  true,
  selector:    "insc-address-form",
  templateUrl: "address-form.component.html",
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    InscFormFieldErrorMessageComponent
  ],
  styles:      [`
                  :host {
                    display: flex;
                    flex-direction: column;
                  }

                  .row {
                    display: flex;
                    max-width: 400px;
                  }

                  .flex {
                    flex: 1;
                  }

                  .number-input {
                    width: 5rem;
                  }
                `]
})
export class AddressFormComponent extends SubformComponent {

  static buildFormGroup(address: Address, destroyable = true) {
    return new FormGroup({
      id:            new FormControl(address?.id || ""),
      city:          new FormControl(address?.city || ""),
      district:      new FormControl(address?.district || ""),
      street:        new FormControl(address?.street || ""),
      street_number: new FormControl(address?.street_number || ""),
      post_code:     new FormControl(address?.post_code || ""),
      ...this.destroyableControl(address?.id, destroyable)
    })
  }
}
