<ng-container [formGroup]="formGroup">
 <div class="row">
  <mat-form-field class="flex">
     <input matInput  type="text" formControlName="street">
     <mat-label>Straße / Platz</mat-label>
     <mat-error inscError errorControlName="street"></mat-error>
   </mat-form-field>
   <mat-form-field class="number-input">
     <input matInput  type="text" formControlName="street_number">
     <mat-label>Hausnr.</mat-label>
     <mat-error inscError errorControlName="street_number"></mat-error>
   </mat-form-field>
 </div>

<div class="row">
  <mat-form-field class="flex">
    <input matInput  type="text" formControlName="district">
    <mat-label>Ortsteil</mat-label>
    <mat-error inscError errorControlName="district"></mat-error>
  </mat-form-field>
</div>

<div class="row">
  <mat-form-field class="number-input">
    <input matInput  type="text" formControlName="post_code">
    <mat-label>Postleitzahl</mat-label>
    <mat-error inscError errorControlName="post_code"></mat-error>
  </mat-form-field>
  <mat-form-field class="flex">
    <input matInput  type="text" formControlName="city">
    <mat-label>Ort</mat-label>
    <mat-error inscError errorControlName="city"></mat-error>
  </mat-form-field>
</div>



<!--   <div formGroupName="geolocation">
    <div>
      <mat-form-field>
        <input matInput  type="text" formControlName="latitude">
        <mat-label>Lat</mat-label>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput  type="text" formControlName="longitude">
        <mat-label>Long</mat-label>
      </mat-form-field>
    </div>
  </div> -->
</ng-container>
