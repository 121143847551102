@if (config | async; as config) {
  @if (config.title) {
    <h2 mat-dialog-title>
      {{config.title}}
    </h2>
  }
  <div matDialogContent class="content-container">
    <insc-record-picker mat-dialog-content
      #picker
      [disabled]="loading"
      [multiple]="true"
      (selectedRecordsChange)="onSelectionChange($event, picker)"
      [selectedRecords]="data?.select"
      [extraQueryParams]="config.queryParams"
      [subtitleTemplate]="config.subtitleTemplate"
      [getSearchResults]="recordPickerGetSearchResults"
      [quickFilter]="config.quickFilter"
    ></insc-record-picker>
    <mat-divider></mat-divider>
    @if (config.validationMessage) {
      <div class="mat-caption validation message mat-warn">
        <mat-icon fontSet="material-icons-outlined">warning</mat-icon>
        <span class="text">{{config.validationMessage}}</span>
      </div>
    }
    @if (config.infoMessage) {
      <div class="mat-caption info message">
        <mat-icon fontSet="material-icons-outlined">info</mat-icon>
        <span class="text">{{config.infoMessage}}</span>
      </div>
    }
  </div>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null" color="warn">Abbrechen</button>
    <button mat-button
      cdkFocusInitial
      color="primary"
      (click)="save()"
      [disabled]="!(config.canSave ?? true)"
    >Speichern</button>
  </mat-dialog-actions>
}
