<insc-data-group>
  <div inscDataField>
    <span *inscFieldLabel>Betreff</span>
    <span *inscFieldValue>{{ literatureReference.ref_type }}</span>
  </div>
  <div inscDataField>
    <span *inscFieldLabel>Kurztitel</span>
    <span *inscFieldValue>
      @if (literatureReference.literature_entry?.url; as url) {
        <a target="_blank" [href]="url">{{ literatureReference.literature_entry?.name }}</a>
      } @else {
        {{ literatureReference.literature_entry?.name }}
      }
    </span>
  </div>

  @if (literatureReference.literature_entry?.urn) {
    <div inscDataField>
      <span *inscFieldLabel>URN</span>
      <span *inscFieldValue>
        @if (literatureReference.literature_entry?.urn; as urn) {
          <a href="{{urnResolver}}/{{urn}}">{{urn}}</a>
        }
      </span>
    </div>
  }


  <div inscDataField>
    <span *inscFieldLabel>Stelle</span>
    <span *inscFieldValue>
      @if (literatureReference.url; as url) {
        <a target="_blank" [href]="url">{{ literatureReference.reference }}</a>
      } @else {
        {{ literatureReference.reference }}
      }
    </span>
  </div>

  @if (literatureReference.urn) {
    <div inscDataField>
      <span *inscFieldLabel>URN (Stelle)</span>
      <span *inscFieldValue>
        @if (literatureReference.urn; as urn) {
          <a href="{{urnResolver}}/{{urn}}">{{urn}}</a>
        }
      </span>
    </div>
  }

  <div inscDataField layout="vertical">
    <span *inscFieldLabel>Kommentar</span>
    <span *inscFieldValue>{{ literatureReference.literature_entry?.comment }}</span>
  </div>
</insc-data-group>
