import { Injectable } from "@angular/core"
import { ObjectLocation } from "../shared/models"

@Injectable({
  providedIn: 'root'
})
export class LocationDisplayHelperService {

  getLocationTypeString(type: string): string {
    switch (type) {
      case "": case null: return ""
      case "Location":
      case "City":
      case "SubLocation":
      case "FreeSite":
      case "PrincipalTown":
        return "Stelle (topographisch)"
      case "Building":
        return "Stelle am Bauwerk"
      case "Manager":
      case "PrivateProperty":
      case "MuseumCollection":
        return "Inv.-Nr."
      default:
        throw new Error("Got invalid location type: " + type)
    }
  }

  getLocationNameWithLostAnnotation(location: Partial<ObjectLocation> | null): string {
    if (!location) {
      return
    }

    return location.lost ? location.name + "†" : location.name
  }

  getOwnAndAncestorNames(location: ObjectLocation): string {
    if (location == null) {
      return null
    }

    // ancestor is none only the city that is displayed anyways
    if (location.ancestor_locations.length < 1) {
      return null
    }

    const locations = [location, ...location.ancestor_locations.slice(1)]
    const locationNames = locations.map(loc => this.getLocationNameWithLostAnnotation(loc))
    return locationNames.join(", ")
  }
}
