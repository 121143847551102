import { Injectable } from "@angular/core"
import { FacetResult } from "../../../../services/data.service"


/**
 * One node in the facet value tree expected by the FacetComponent
 */
export interface FacetValueFlatNode {
  /**
   * The key for this subvalue node, used for display.
   */
  key: string
  /**
   * Remember the whole original keyPath of the facet API result for this node
   * for selection and removal events.
   */
  keyPath: string

  level: number

  /**
   * Whether this facet value is currently selected
   */
  selected: boolean
  /**
   * Number of results for this facet value
   */
  docCount: number
}


/**
 * Helper for converting a facet API response into a tree structure for display by a FacetComponent
 * The backend supports infinitely nesed hierarchical facets, e.g. for locations:
 * ```
 * | Köln
 *   | - Ehrenfeld
 *   | - Sülz
 * | Bonn
 *   | - Schwarzrheindorf
 *        | - St. Maria
 * ```
 *
 * In the API response, those are returned as a flat array with path components separated
 * by '|', for example:
 * - key: Köln, doc_count: 3, selected: false
 * - key: Köln|Ehrenfeld, doc_count: 2, selected: false
 * - key: Köln|Sülz, doc_count, 1 [...]
 * - key: Bonn, ...
 * - key: Bonn|Schwarzrheindorf, ...
 * - key: Bonn|St. Maria
 */
@Injectable({providedIn: "root"})
export class FacetValueFlatTreeBuilder {

  readonly PATH_SEPARATOR = "|"

  /**
   * Convert the flat facet results returned by the API to a hierarchical facet value tree
   *
   * @param {FacetResult[]} facetResultData the facet data part of the API response
   * @returns {FacetValueFlatNode[]} the tree of facet values
   */
  buildFacetValueTree(facetResultData: FacetResult[]): FacetValueFlatNode[] {

    // sort the keys so that shorter keypaths get processed first
    // "a|b" before "a|b|c"
    // TODO: obsolete because sorting is done in the elasticsearch query
    // facetResultData.sort((a, b) => a.key > b.key ? 1 : -1)

    // process each facet value result
    return facetResultData.map(facetResult => {

      // split the character-separated keypaths into an array of segments
      const pathSegments = facetResult.key.split(this.PATH_SEPARATOR)

      return {
        key: pathSegments[pathSegments.length - 1],
        keyPath: facetResult.key,
        level: pathSegments.length,
        selected: facetResult.selected,
        docCount: facetResult.doc_count
      }
    })
  }
}

