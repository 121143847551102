import { Component, forwardRef, Input, OnChanges, SimpleChanges } from "@angular/core"
import { MatFormFieldAppearance } from "@angular/material/form-field"
import { CriteriumDescription, INSC_SEARCH_CONTROL, SearchControlDirective } from "../search-control.directive"
import { SearchControl } from "../search-control.interface"

/**
 * A fulltext term {@link SearchControl} for the overview page.
 */
@Component({
  selector:    'insc-fulltext-search-control',
  templateUrl: './fulltext-search-control.component.html',
  styleUrls:   ['./fulltext-search-control.component.css'],
  providers:   [{
    provide: INSC_SEARCH_CONTROL,
    useExisting: forwardRef(() => FulltextSearchControlComponent)
  }]
})
export class FulltextSearchControlComponent implements OnChanges, SearchControl<string, string> {
  @Input() disabled = false

  /**
   * The current search term as sent by the backend in a search response
   */
  @Input() resultParams: string
  @Input() emitOnType = false

  @Input() apperance: MatFormFieldAppearance = "outline"
  @Input() title = "Volltextsuche"

  /**
   * Reference to the {@link SearchControlDirective} set in the {@link SearchControlDirective}'s constructor
   */
  searchControlDirective: SearchControlDirective<string, string>

  ngOnChanges(changes: SimpleChanges): void {
    /**
     * When a new search response arrives, update the criteria with the new search term.
     * (Notice: this does not emit any param change as this would result in a new search
     * request being made. It only updates the criteria displayed in the chip list)
     */
    if ('resultParams' in changes) {
      this.searchControlDirective.emitCriteriaChange(this.criteriaForValue(this.resultParams))
    }
  }

  /**
   * Called by the template when the user changes the search string
   * @param {string} value
   */
  emitNewParams(value: string): void {
    // if the input is empty, emit null instead of an empty string
    const searchValue = value || null

    this.searchControlDirective.emitParamsChange(searchValue)
    this.searchControlDirective.emitCriteriaChange(this.criteriaForValue(searchValue))
  }

  onKeyUp(event: KeyboardEvent, inputValue: string): void {
    if (this.emitOnType) {
      this.emitNewParams(inputValue)
    } else if (event.key === "Enter") {
      this.emitNewParams(inputValue)
    }
  }

  remove(): void {
    this.emitNewParams(null)
  }

  criteriaForValue(value: string): CriteriumDescription<string>[] {
    if (!value) {
      return []
    }

    return [{
      name: this.searchControlDirective.inscSearchControl,
      displayName: this.searchControlDirective.searchControlDisplayName,
      displayValue: value,
      value: value
    }]
  }
}

