import { Component, EventEmitter, Inject, InjectionToken, Input, OnDestroy, OnInit, Optional, Output, Self } from "@angular/core"
import { ControlValueAccessor, NgControl, UntypedFormControl } from "@angular/forms"
import { asyncScheduler, Observable, of, Subject, Subscription } from "rxjs"
import { catchError, distinctUntilChanged, finalize, map, share, switchMap, takeUntil, tap, throttleTime } from "rxjs/operators"
import { AbstractExternalNormDataProviderService } from "../abstract-external-norm-data-provider.service"
import { ExternalNormDataProviderResolverService } from "../external-norm-data-provider-resolver.service"

export const EXTERNAL_NORM_DATA_LOOKUP_ENABLED = new InjectionToken<boolean>('External NormData lookupClick enabled')

export class ExternalNormDataLookupFormAction {
  constructor(public readonly providerId: string, public readonly lookupOptions: Record<string, string>) {}
}

// start enum with 1 so it does not evaluate as false in *ngIf
export enum RecordLookupState {
  Valid = 1, Invalid, Error, BlankId
}

// https://material.angular.io/guide/creating-a-custom-form-field-control
@Component({
  selector:    'insc-external-norm-data-id-input',
  templateUrl: './external-norm-data-id-input.component.html',
  styleUrls:   ['./external-norm-data-id-input.component.scss']
})
export class ExternalNormDataIdInputComponent implements OnInit, ControlValueAccessor, OnDestroy {

  private unsubscribe$ = new Subject<void>()

  @Input() providerId: string
  @Input() lookupOptions: Record<string, string> = {}
  @Input() label: string

  @Output() lookupClick = new EventEmitter<ExternalNormDataLookupFormAction>()

  provider: AbstractExternalNormDataProviderService<unknown>

  idInputCtrl = new UntypedFormControl("")
  idInputValueSubscription: Subscription

  recordDescription: Observable<string>

  lookupStates = RecordLookupState
  lookupState: Observable<RecordLookupState>

  validityLoading = false
  recordDescriptionLoading = false

  idLookupTrigger = new Subject<string>()

  constructor(
    private providerResolver: ExternalNormDataProviderResolverService,
    @Inject(EXTERNAL_NORM_DATA_LOOKUP_ENABLED) @Optional() public lookupEnabled: boolean,
    @Optional() @Self() private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }
  }

  ngOnInit(): void {

    if (!this.providerId) {
      throw new Error(`Provider ID must be set! Available providers: ${this.providerResolver.availableProviders}.`)
    }

    this.provider = this.providerResolver.get(this.providerId)
    if (!this.provider) {
      return
    }


    this.idInputValueSubscription = this.idInputCtrl.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((val: string) => {
      this.idLookupTrigger.next(val)
      this.propagateChange(val)
    })

    this.lookupState = this.idLookupTrigger.pipe(
      distinctUntilChanged(),
      tap((id) => this.validityLoading = !!id),
      throttleTime(500, asyncScheduler, {leading: true, trailing: true}),
      switchMap(id => {
        if (!id) {
          return of(RecordLookupState.BlankId)
        }

        return this.provider.getValidity(id).pipe(
          map(valid => valid ? RecordLookupState.Valid : RecordLookupState.Invalid),
          catchError((_: unknown) => of(RecordLookupState.Error)),
          finalize(() => this.validityLoading = false)
        )
      }),
      takeUntil(this.unsubscribe$),
      share()
    )

    this.recordDescription = this.lookupState.pipe(
      switchMap(state => {
        const getDescriptionObservable = this.provider.getDescription(this.idInputCtrl.value as string).pipe(
          catchError((_err: unknown) => of(null)),
          finalize(() => this.recordDescriptionLoading = false)
        )

        if (state === RecordLookupState.Valid) {
          this.recordDescriptionLoading = true
          return getDescriptionObservable
        }

        return of(null)
      }),
      share()
    )

    /*
         Workaround: the async pipe in the template does not properly subscribe to the recordDescription observable for some reason
         Manually subscribing to the shared observable seems to trigger a subscription in the template.
     */
    this.recordDescription.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {})

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
  }

  // -- ControlValueAccessor

  propagateChange = (_id: string): void => {}

  registerOnChange(fn: (id: string) => void): void {
    this.propagateChange = fn
  }

  registerOnTouched(): void {
  }

  writeValue(value: string): void {
    // TODO remove workaround for https://github.com/angular/angular/issues/29218
    setTimeout(() => {
      this.idInputCtrl.setValue(value, {emitEvent: false})
      this.idLookupTrigger.next(value)
    })
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.idInputCtrl.disable()
    } else {
      this.idInputCtrl.enable()
    }
  }

  emitLookup(): void {
    this.lookupClick.emit(new ExternalNormDataLookupFormAction(this.providerId, this.lookupOptions))
  }
}
