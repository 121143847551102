import { Component, Inject, Injectable, ViewChild } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog"
import { SubformComponent } from "../../../../../shared/subform.component"
import { ExternalNormDataLookupToolComponent, } from "../external-norm-data-lookup-tool/external-norm-data-lookup-tool.component"
import { ExternalNormDataMapperInterface } from "../external-norm-data-mapper.interface"

export interface ExternalNormDataLookupDialogData<T, C extends Component = unknown> {
  providerId: string
  lookupOptions?: Record<string, string>
  formType: typeof SubformComponent
  mapper: ExternalNormDataMapperInterface<unknown, T>
  initialData?: T
  fixedData?: T
  formComponentInputs?: {[K in keyof C]?: string}
}

@Injectable({
  providedIn: "root"
})
export class ExternalNormDataLookupDialogService {
  constructor(private dialog: MatDialog) {}
  open<R, C = unknown>(data: ExternalNormDataLookupDialogData<R, C>): MatDialogRef<ExternalNormDataLookupDialogComponent<R>, R> {
    return this.dialog.open<
    ExternalNormDataLookupDialogComponent<R>, ExternalNormDataLookupDialogData<R, C>, R
    >(ExternalNormDataLookupDialogComponent, {
      minWidth: "700px",
      height: "80vh",
      data: data
    })
  }
}

export type ExternalNormDataLookupDialogRef<T> = MatDialogRef<ExternalNormDataLookupDialogComponent<T>, T>

@Component({
  templateUrl: './external-norm-data-lookup-dialog.component.html',
  styleUrls:   ['./external-norm-data-lookup-dialog.component.css']
})
export class ExternalNormDataLookupDialogComponent<T> {

  @ViewChild(ExternalNormDataLookupToolComponent) lookupTool: ExternalNormDataLookupToolComponent<T>

  constructor(
    @Inject(MatDialogRef) private dialogRef: ExternalNormDataLookupDialogRef<T>,
    @Inject(MAT_DIALOG_DATA) public data: ExternalNormDataLookupDialogData<T>
  ) {
    if (!data) {
      throw new Error("ExternalNormDataLookupDialog data must be set!")
    }
  }

  close(): void {
    this.dialogRef.close(this.lookupTool.mappingResult)
  }

}
