import { Injectable } from "@angular/core"
import { BehaviorSubject, Subject } from "rxjs"
import { distinctUntilChanged, map } from "rxjs/operators"
import { InscImage } from "../../../shared/models"
import { ImageParent } from "../../../shared/models/image.model"

export interface ImageSidebarOptions {
  enableDelete: boolean
  enableCloseButton: boolean
}

const imageSidebarDefaultOptions: ImageSidebarOptions = {
  enableDelete: false,
  enableCloseButton: false
}

@Injectable({
  providedIn: 'root'
})
export class ImageSidebarService {

  private images = new Subject<Partial<InscImage>[]>()
  readonly images$ = this.images.asObservable()
  readonly showSidebar$ = this.images$.pipe(
    map(images => images?.length > 0),
    distinctUntilChanged()
  )

  private parent = new Subject<ImageParent>()
  readonly parent$ = this.parent.asObservable()

  private afterSave = new Subject<Partial<InscImage>[]>()
  readonly afterSave$ = this.afterSave.asObservable()

  private afterDelete = new Subject<Partial<InscImage>[]>()
  readonly afterDelete$ = this.afterDelete.asObservable()

  private editMode = new BehaviorSubject(false)
  readonly editMode$ = this.editMode.asObservable()

  private configuration = new BehaviorSubject(imageSidebarDefaultOptions)
  readonly configuration$ = this.configuration.asObservable()

  hasUnsavedChanges = false

  setEditMode(editMode: boolean): void {
    this.editMode.next(editMode)
  }

  setImages(images: Partial<InscImage>[], configuration: ImageSidebarOptions, parent?: ImageParent): void {
    const dedupImages = images?.filter((image, pos) =>
      pos === images.findIndex(_image => _image.id === image.id)
    )
    if (configuration !== this.configuration.value) {
      this.configuration.next(configuration)
    }
    this.images.next(dedupImages)
    this.parent.next(parent)
    this.setEditMode(false)
  }

  unsetImages(): void {
    this.images.next([])
    this.parent.next(null)
  }

  emitSaveAndUpdateView(updates: Partial<InscImage>[]): void {
    this.afterSave.next(updates)
    this.setImages(updates, this.configuration.value)
  }

  emitDeleteAndUpdateView(updates: Partial<InscImage>[], deleted: Partial<InscImage>[]): void {
    this.afterDelete.next(deleted)
    this.setImages(updates, this.configuration.value)
  }


  constructor() { }
}
