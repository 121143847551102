<h2 matDialogTitle>Datierung auswählen</h2>
<mat-dialog-content class="mat-typography">

  <div class="row-border-bottom">
    <insc-dating-chooser #datingChooser [initialDatingSpecification]="data"></insc-dating-chooser>
  </div>

  <div class="chosen-dating-info">
    @if (datingChooser.chosenDatingSpecification) {
      <div>
        Gewählte Datierung: <em>{{ datingChooser.chosenDatingSpecification.description }}</em>
      </div>
    }
    @if (!datingChooser.chosenDatingSpecification) {
      <div>
        <em>Keine gültige Auswahl</em>
      </div>
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button matDialogClose color="danger">Abbrechen</button>
  <button
    mat-button
    (click)="closeWithResult(datingChooser.chosenDatingSpecification)"
    color="primary"
  [disabled]="!datingChooser.chosenDatingSpecification">Übernehmen</button>
</mat-dialog-actions>
