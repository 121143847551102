<ng-container [formGroup]="formGroup">
  <mat-form-field>
    <input matInput type="text" formControlName="name">
    <mat-label>Name der Lizenz</mat-label>
    <mat-error inscError errorControlName="name"></mat-error>
  </mat-form-field>

  <mat-form-field class="flex">
    <textarea class="license-text-input" matInput formControlName="text"></textarea>
    <mat-label>Lizenztext</mat-label>
    <mat-error inscError errorControlName="text"></mat-error>
  </mat-form-field>

  <mat-form-field class="flex">
    <textarea matInput formControlName="summary"></textarea>
    <mat-label>Kurzusammenfassung der Nutzungsbedingungen</mat-label>
    <mat-error inscError errorControlName="summary"></mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput type="text" formControlName="url">
    <mat-label>Externer Link zur Lizenz (optional)</mat-label>
    <mat-error inscError errorControlName="url"></mat-error>
  </mat-form-field>
</ng-container>
