import { Component, Input, OnDestroy, OnInit, Optional, Self } from "@angular/core"
import { ControlValueAccessor, NgControl } from "@angular/forms"

import { MatDialog } from "@angular/material/dialog"

import { Subject } from "rxjs"
import { NormDataService } from "../../../../services/norm-data.service"

import { NormDataEntry } from "../../../../shared/models"
import { RegisterEntryDialogService } from "../../../registers/register-entry-dialog/register-entry-dialog.service"

@Component({
  selector:     'insc-person-organization-input',
  template: `
                @if (value) {
                  <div>
                    @switch (value.type) {
                      @case ('Person') {
                        <insc-person-entry-view [personEntry]="value" [disabled]="disabled"></insc-person-entry-view>
                      }
                      @case ('Organization') {
                        <insc-organization-entry-view [organizationEntry]="value"
                        [disabled]="disabled"></insc-organization-entry-view>
                      }
                      @case ('HistoricalPerson') {
                        <insc-historical-person-entry-view [historicalPersonEntry]="value"
                        [disabled]="disabled"></insc-historical-person-entry-view>
                      }
                      @case ('HistoricalOrganization') {
                        <insc-historical-organization-entry-view [historicalOrganizationEntry]="value"
                        [disabled]="disabled"></insc-historical-organization-entry-view>
                      }
                    }
                  </div>
                } @else {
                  <button id="emptySelectButton" type="button" [disabled]="disabled" mat-raised-button (click)="openEditor()">
                    <span [ngStyle]="ngControl.invalid ? {'color': 'red'} : {}">{{allowNN ? "N.N." : "auswählen..."}}</span>
                  </button>
                }
                <div id="editButtonContainer">
                  <button [color]="ngControl.invalid ? 'warn' : 'primary'" class="selectOrEditButton" mat-button type="button"
                    [disabled]="disabled" (click)="openEditor()"><small class="form-text">Auswählen / Bearbeiten</small></button>
                  </div>
                `,
  styles: [`
        #emptySelectButton {
        	display: block;
        	width: 100%;
        }

        #editButtonContainer {
        	display: block;
        	width: 100%;
        	text-align: right;
        }

        .selectOrEditButton {
        	line-height: 18px;
        	padding: 0 2px 9px 2px;
        }

        .selectOrEditButton:hover {
          background-color: initial;
        }

    `],
  providers: [
    // {
    // 	provide: NG_VALUE_ACCESSOR,
    // 	useExisting: forwardRef(() => PersonOrganizationInputComponent),
    // 	multi: true
    // },
    NormDataService, RegisterEntryDialogService,
    // need to provide MatDialog here so the Dialog contents inherits our providers above
    MatDialog]
})
export class PersonOrganizationInputComponent implements ControlValueAccessor, OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>()

  @Input() allowNN = false
  @Input() types: string[]

  public value: NormDataEntry
  public disabled = false

  constructor(
    private normDataService: NormDataService,
    private normDataDialogService: RegisterEntryDialogService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }
  }

  ngOnInit() {
    if (!this.types || this.types?.length === 0) {
      throw new Error("At least one type must be set.")
    }
  }

  openEditor() {

    const dialogRef = this.normDataDialogService.open({
      types: this.types,
      allowNN: this.allowNN,
      selectEntry: this.value
    })

    dialogRef.subscribe(selectedEntry => {
      if (selectedEntry || this.allowNN) {
        this.propagateChange(selectedEntry)
        this.value = selectedEntry
      }
    })
  }

  // noinspection JSUnusedLocalSymbols
  propagateChange = (_: any) => {}

  writeValue(value) {
    this.value = value
  }

  resetField() {
    this.propagateChange(null)
    this.value = null
  }

  registerOnChange(fn) {
    this.propagateChange = fn
  }

  registerOnTouched() {}

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
