@if (data.iptcDatasets) {
  <insc-table-iptc-editor matDialogContent
    [iptcDatasets]="data.iptcDatasets"
  ></insc-table-iptc-editor>
}
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="warn">Abbrechen</button>
  <button mat-button color="primary" (click)="save()">Übernehmen</button>
</mat-dialog-actions>

