import { CollectionViewer, DataSource, ListRange } from "@angular/cdk/collections"
import { BehaviorSubject, from, merge, Observable, of, Subject, Subscription } from "rxjs"
import { distinctUntilChanged, map, mergeScan, switchMap, tap } from "rxjs/operators"
import { QueryParams, QueryResult, QueryResults } from "../../services/data.service"
import { InscEntity } from "../models/entity.model"


export class ViewRangeChangeEvent {
  constructor(readonly page: number) {}
}

export class QueryParamsChangeEvent<Q extends QueryParams = QueryParams> {
  constructor(readonly paramChanges: Q) {}
}

export type ChangeEvent = ViewRangeChangeEvent | QueryParamsChangeEvent

export type GetSearchResultsFunc<T extends InscEntity, Q extends QueryParams = QueryParams> = (queryParams: Q) => Observable<QueryResults<T>>

export class VirtualScrollSearchDataSource<T extends InscEntity, Q extends QueryParams = QueryParams> extends DataSource<QueryResult<T> | undefined> {
  private cachedRecords: QueryResult<T>[] = []
  private dataStream = new BehaviorSubject<(QueryResult<T> | undefined)[]>(this.cachedRecords)
  private subscription = new Subscription()
  private fetchedPages = new Set<number>()

  private _searchResults = new Subject<QueryResults<T>>()
  readonly searchResults$ = this._searchResults.asObservable()

  private pageSize = 50


  constructor(
    readonly getSearchResults: GetSearchResultsFunc<T, Q>,
    readonly queryParams: Observable<Q>,
    readonly getRenderedRangeFn: () => ListRange
  ) {
    super()

  }

  connect(collectionViewer: CollectionViewer): Observable<(QueryResult<T> | undefined)[]> {
    const viewRangeChangeEvents = collectionViewer.viewChange.pipe(
      switchMap(range => Array.from(this._getPagesForRange(range))),
      map(pages => new ViewRangeChangeEvent(pages))
    )

    const queryParamChangeEvents = this.queryParams.pipe(
      map(queryParams => new QueryParamsChangeEvent<Q>(queryParams))
    )

    const requests = merge(viewRangeChangeEvents, queryParamChangeEvents).pipe(
      mergeScan<ChangeEvent, Q>((currentParams, changeEvent) => {
        const newParams: Q[] = []

        if (changeEvent instanceof ViewRangeChangeEvent) {
          newParams.push({
            ...currentParams,
            page:      changeEvent.page,
            page_size: this.pageSize
          })
        } else {

          // side effect: param change, invalidate caches
          this.cachedRecords = []
          this.fetchedPages.clear()
          // end side effect

          const renderedRange = this.getRenderedRangeFn()
          const pages = this._getPagesForRange(renderedRange)

          pages.forEach(page => newParams.push({
            ...changeEvent.paramChanges,
            page_size: this.pageSize,
            page
          } as Q))
        }

        return from(newParams)
      }, {page_size: this.pageSize} as Q)
    )

    this.subscription = requests.pipe(
      distinctUntilChanged((previous, current) => JSON.stringify(previous) === JSON.stringify(current)),
      switchMap(queryParams => {
        const page = queryParams.page
        const getData = this.fetchedPages.has(page) ? of(this.cachedRecords) : this._fetchPage(queryParams)

        return getData.pipe(
          map(data => ({page, data}))
        )
      }),
    ).subscribe(({page, data}) => {
      this.fetchedPages.add(page)
      this.cachedRecords = data
      this.dataStream.next(this.cachedRecords)
    })

    return this.dataStream
  }

  disconnect(_: CollectionViewer): void {
    this.subscription.unsubscribe()
  }

  private _fetchPage(params: Q) {
    return this.getSearchResults(params).pipe(
      tap(results => this._searchResults.next(results)),
      map(results => {
        const startIndex = (results.page - 1) * this.pageSize
        const deleteCount = this.pageSize
        const items = results.results
        const newData = Array.from({length: results.count, ...this.cachedRecords})

        newData.splice(startIndex, deleteCount, ...items)

        return newData
      }),
    )
  }

  private _getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize) + 1
  }

  private _getPagesForRange(range: ListRange | null): number[] {
    if (!range || range.end === 0) {
      return [1]
    }
    const startPage = this._getPageForIndex(range.start)
    const endPage = this._getPageForIndex(range.end - 1)
    const pages: number[] = []
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }
    return pages
  }
}
