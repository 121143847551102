import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router"
import { Observable } from "rxjs"
import { InscObjectService } from "../../services/data.service"
import { InscObject } from "../../shared/models/object.model"

@Injectable({providedIn: "root"})
export class InscObjectResolver  {
  constructor(private inscObjectService: InscObjectService) {}

  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<InscObject> {
    const cloneObjectId = route.paramMap.get("cloneObjectId")
    const objectId = route.paramMap.get("objectId")
    return cloneObjectId ? this.inscObjectService.clone(cloneObjectId) : this.inscObjectService.get(objectId)
  }
}
