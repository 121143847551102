import { Directive, EventEmitter, Optional, Output } from "@angular/core"
import { ControlContainer, FormControl, FormGroup } from "@angular/forms"

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SubformComponent {

  @Output() externalActionTriggered = new EventEmitter<any>()

  // noinspection JSUnusedLocalSymbols
  static buildFormGroup(object?: any, destroyable = true): FormGroup {
    throw new Error("buildFormGroup needs to be implemented in subclass of SubformComponent")
  }

  static destroyableControl(existing: any, destroyable = true) {
    return destroyable ? {
      _destroy: new FormControl(false)
    } : {}
  }

  constructor(@Optional() private controlContainer: ControlContainer) { }

  get formGroup(): FormGroup {
    if (!(this.controlContainer.control instanceof FormGroup)) {
      throw new Error("formGroupName needs to be set to the name of a form group instance.")
    }

    return this.controlContainer.control
  }

}
