import { Injectable } from '@angular/core'
import { environment } from "../../environments/environment"
import { ImageParent, InscImage } from "../shared/models/image.model"

/**
 * Helper service for generating image download links and descriptions.
 */
@Injectable({
  providedIn: 'root'
})
export class ImageDownloadService {

  readonly baseUrl = environment.apiUrl

  /**
   Generates a download link for the given image IDs and version.
   If a parent inscription or insc_object is provided, the link is
   created with the parent's information, resulting in the parent name
   being appended to the download file name if the download is a zip file
   with multiple images.

   @param imageIds - Array of image IDs to download.
   @param version - The version of the images (default is "original").
   @param parent - Optional parameter to specify parent information.
   @returns The generated download link.
   */
  getDownloadLink(imageIds: string[], version = "original", parent?: ImageParent) {
    if (parent) {
      return `${this.baseUrl}/${parent.type}s/${parent.id}/download_images/${imageIds.join(",")}/${version}`
    } else {
      return `${this.baseUrl}/download_images/${imageIds.join(",")}/${version}`
    }
  }

  /**
   * Calculates the total size of the provided images for a specific version.
   *
   * @param images - Array of images with partial InscImage data.
   * @param version - The version of the images (default is "original").
   * @returns The total size of the images in bytes.
   */
  getTotalSize(images: Partial<InscImage>[], version: string = "original"): number {
    return images.reduce(
      (totalSize, image) => totalSize + this.getSize(image, version),
      0
    )
  }

  private getSize(image: Partial<InscImage>, version: string): number {
    return version === "original"
           ? image?.image_file?.size || 0
           : image?.download_versions?.[version]?.size || 0
  }
}
