import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Observable, Subject } from "rxjs"
import { map, share, switchMap, takeUntil } from "rxjs/operators"
import { ImageService, QueryParams, QueryResults } from "../../../services/data.service"
import { InscImage } from "../../../shared/models/image.model"
import { InscObject } from "../../../shared/models/object.model"
import { SearchControlDirective } from "../../../shared/search/search-controls/search-control.directive"
import { ImageManagementGroupComponent } from "../../images/image-management/image-management-group.component"
import { ImageManagementDirective, ImageSelection } from "../../images/image-management/image-management.directive"
import { ImageSidebarService } from "../../images/image-sidebar/image-sidebar.service"
import { OverviewPagesService } from "../overview-pages.service"


/**
 * TODO: outdated, update docs
 * A faceted search as the primary interface to the database.
 *
 * ### Data flow
 *
 * There is a specific desiderate for this interface: the user should be
 * able to share the URL to specific search results, so the search parameters need to be represented in
 * the URL parameters.
 *
 * This is why the data flow in this component works as follows:
 *  1.  on component initialization and any change in the URL paramters,
 *      a search request to the backend is made using these parameters, see
 *      {@link searchResults$} and {\@link routeParametersToSearchRequest}.
 *  2.  when the user interacts with the search interface, ie. changes any
 *      parameters, the URL parameters are updated (by triggering a router
 *      navigation, see {\@link collectSearchParamsFromSearchControls} and
 *      {@link ngOnInit}.
 *  3.  the backend sends the search results along with a "replay" of the query parameters.
 *      The "replayed" parameters can be enriched with additional information to display to
 *      the user, for example a human-readable description for the dating parameter, and
 *      get passed to the search controls so they can update their internal state accordingly
 *      (using their {@link SearchControl.resultParams|resultParams} input in the template).
 *      This also allows for the search request to be modified in the backend if needed.
 *  4.  start over on user interaction with the search controls.
 *
 *  This means actual search requests are only made when the URL parameters change,
 *  the URL serving as the "source of truth".
 *
 *  ### Component design
 *
 *  The component template contain search controls that implement the {@link SearchControl} interface.
 *  The search controls manage search paramters like dating, fulltext, filters etc. and know
 *  how to:
 *
 *    - build {@link QueryParams|query parameters} for the search field they are responsible for, based on their
 *      internal state
 *    - build {@link CriteriumDescription}s based on their internal state, used for building the chip list
 *    - set their internal state to the search parameters sent by the backend as part of the query result
 *
 *  Every search control needs a {@link SearchControlDirective} `inscSearchControl="field"` providing common
 *  configuration options and behaviors for interacting with a search control (similar to angular
 *  form controls and the `formControlName` directive).
 *
 *  Which search controls are displayed on the page changes according to the selected record type. For
 *  example, the recording date control is only available when searching for images. Thus, on component
 *  initialization and everytime the record type changes, we subscribe to observables from the search
 *  controls that 1) notify us when the user interacted with it triggering a search param change and 2)
 *  when the search criteriaChange$ change in response to a change in the search params.
 *
 *  ### {@link CriteriumDescription}s / Search Criteria Chip List
 *
 *  For each active search criterium (like a fulltext search term, one selected facet), a "chip" is
 *  displayed above the results to signify which critera are active. Each chip has a field-and-value-
 *  description and a button for removing it.  The view model for a criterium chip is a
 *  {@link CriteriumDescription}. A search control can provide one or more criteriaChange$ - the fulltext field
 *  does only ever provide one (the search term), but the facet list can provide multiple, as each
 *  selected facet value should be represented (and removable) by a seperate chip.
 *
 *  The search controls notify emit a list of their currently active criteriaChange$ through the
 *  {@link SearchControlDirective.criteria$} observable, which we then collect and combine to display
 *  the chip list.
 *
 *  See {@link FulltextSearchControlComponent} for an example of a simple search control interacting with
 *  it's {@link SearchControlDirective} and this component.
 */
@Component({
  selector:    'insc-image-overview-page',
  templateUrl: './image-overview-page.component.html',
  styleUrls:   ['./image-overview-page.component.scss']
})
export class ImageOverviewPageComponent implements OnInit, OnDestroy {

  /**
   * an observable that emits new search results.
   * Initialized in {@link ngAfterViewInit}
   */
  searchResults$: Observable<QueryResults<InscImage>>
  resultImages$: Observable<InscImage[]>

  private _selectedImages = new Set<InscImage>()
  get selectedImages(): InscImage[] { return Array.from(this._selectedImages) }
  get selectedIds(): string[] { return this.selectedImages.map(image => image.id) }

  private unsubscribe$ = new Subject<void>()

  @ViewChild(ImageManagementGroupComponent) imageManagementGroup: ImageManagementGroupComponent
  @ViewChild(ImageManagementDirective) imageMgmt: ImageManagementDirective

  constructor(
    private imageService: ImageService,
    private route: ActivatedRoute,
    private imageSidebar: ImageSidebarService,
    private overviewPagesService: OverviewPagesService
  ) { }

  /**
   * Initialization tasks that do not depend on ViewChildren
   */
  ngOnInit(): void {

    // create an observable that makes a backend request and emits the search results
    // (displayed using an async pipe in the template) in response to a change in the
    // route parameters. The router always emits a param change on component initialization,
    // so that an initial request is always made.

    this.searchResults$ = this.overviewPagesService.searchRequestFromUrlQueryParams(this.route).pipe(
      takeUntil(this.unsubscribe$),
      switchMap(queryParams => this.imageService.all(queryParams)),
      share()
    )

    this.resultImages$ = this.searchResults$.pipe(
      map(results => results.results.map(result => result.data)),
    )

    this.imageSidebar.afterDelete$.subscribe(deletedImages => {
      this._selectedImages.forEach(selectedImage => {
        if (deletedImages.some(deletedImage => deletedImage.id === selectedImage.id)) {
          this._selectedImages.delete(selectedImage)
        }
      })
    })
  }

  ngOnDestroy(): void {
    this.imageSidebar.unsetImages()
    this.unsubscribe$.complete()
  }

  onQueryParamsChange(queryParams: QueryParams): void {
    this.overviewPagesService.navigateToSearch(queryParams)
  }

  addSelection(selection: ImageSelection[], currentImages: InscImage[]): void {
    const selectedIds = this.selectedIds
    const addedSelections = currentImages.filter(image =>
      !selectedIds.includes(image.id) && selection.includes(image.id)
    )

    this._selectedImages.forEach(selectedImage => {
      if (!selection.includes(selectedImage.id)) {
        this._selectedImages.delete(selectedImage)
      }
    })

    addedSelections.forEach(selectedImage => this._selectedImages.add(selectedImage))

    this.imageSidebar.setImages(this.selectedImages, {enableCloseButton: false, enableDelete: true})
  }

  /*  onImagesDeselected(selection: ImageSelection[], currentImages: InscImage[]) {
    this.selectedImages = this.selectedImages.filter(selectedImage => !selection.includes(selectedImage.id))
    this.imageSidebar.setImages(this.selectedImages)
  }*/

  clearSelections(): void {
    this.imageMgmt.clear()
  }

  allSelectedOf(images: InscImage[]): boolean {
    return images.every(image => this.selectedIds.includes(image.id))
  }

  noneSelectedOf(images: InscImage[]): boolean {
    return images.every(image => !this.selectedIds.includes(image.id))
  }

  getAllUniqueRelatedInscObjects(image: Partial<InscImage>): Partial<InscObject>[] {
    return [
      ...image.insc_objects,
      ...image.inscriptions.flatMap(inscription => inscription.insc_objects)
    ].filter(
      (currentInscObject, index, allInscObjects) =>
        allInscObjects.findIndex(testInscObject => testInscObject.id === currentInscObject.id) === index
    )
  }

  // get those inscriptions for insc objects that are linked with the current image
  getInscriptionsForInscObject(image: Partial<InscImage>, inscObject: Partial<InscObject>) {
    return image.inscriptions.filter(inscription => inscription.insc_objects.some(_inscObject => _inscObject.id === inscObject.id))
  }
}
