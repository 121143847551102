import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, ReplaySubject, Subject } from "rxjs"
import { pluck } from "rxjs/operators"
import { environment } from "../../environments/environment"
import { ImageParent, InscImage } from "../shared/models/image.model"

export enum ImageUploadEventType {
  Started, Progress, Uploaded, Finished
}

export interface ImageUploadProgressEvent {
  type: ImageUploadEventType
  loaded?: number
  total?: number
  updatedImageRecord?: InscImage
}
export const isImageUploadProgressEvent = (varToCheck: unknown): varToCheck is ImageUploadProgressEvent => {
  if (!varToCheck) { return }
  const { type } = (varToCheck as ImageUploadProgressEvent)
  return !!ImageUploadEventType[type]
}

@Injectable({
  providedIn: 'root'
})
export class ImageFileService {

  constructor(private http: HttpClient) {}

  uploadImageFile(imageId: string | null, file: File, parent: ImageParent | null): Observable<ImageUploadProgressEvent> {
    const formData = new FormData()
    formData.append("image_file[file]", file)
    parent && parent.isContext && formData.append("context", "true")

    let baseUrl = environment.apiUrl
    let imageFileSegment = "image_files"
    if (parent) {
      baseUrl += "/" + parent.type + "s/" + parent.id

      if (parent.isContext) {
        imageFileSegment = "context_image_files"
      }
    }

    const url = imageId ? `${baseUrl}/images/${imageId}/${imageFileSegment}` : `${baseUrl}/${imageFileSegment}`
    const req = new HttpRequest('POST', url, formData, { reportProgress: true })

    const uploadProgressSubject = new ReplaySubject<ImageUploadProgressEvent>()

    uploadProgressSubject.next({
      type: ImageUploadEventType.Started
    })

    this.http.request(req).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        uploadProgressSubject.next({
          type:   ImageUploadEventType.Progress,
          total:  event.total,
          loaded: event.loaded
        })
      } else if (event instanceof HttpResponse) {
        const response = <HttpResponse<{image: InscImage}>>event
        uploadProgressSubject.next({
          type: ImageUploadEventType.Finished,
          updatedImageRecord: response.body.image
        })
        uploadProgressSubject.complete()
      }
    })

    return uploadProgressSubject.asObservable()
  }

  deleteImageFile(imageFileId: string) {
    return this.http.delete<{image: InscImage}>(`${environment.apiUrl}/image_files/${imageFileId}`).pipe(
      pluck("image")
    )
  }
}

