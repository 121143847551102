<insc-data-group>
	<div inscDataField>
		<div *inscFieldLabel>Betreff</div>
    <div *inscFieldValue>{{ comment.subject }}</div>
	</div>
	<div inscDataField layout="horizontal">
		<div *inscFieldLabel>Bemerkung</div>
    <div *inscFieldValue>{{ comment.content }}</div>
	</div>
</insc-data-group>
