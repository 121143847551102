import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router"
import { Observable } from "rxjs"
import { InscriptionService } from "../../services/data.service"
import { Inscription } from "../../shared/models/inscription.model"

@Injectable({providedIn: "root"})
export class InscriptionResolver  {
  constructor(private inscriptionService: InscriptionService) {}

  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Inscription> {
    const cloneInscriptionId = route.paramMap.get("cloneInscriptionId")
    const inscriptionId = route.paramMap.get("inscriptionId")
    return cloneInscriptionId ? this.inscriptionService.clone(cloneInscriptionId) : this.inscriptionService.get(inscriptionId)
  }
}
