import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Observable } from "rxjs"
import { pluck } from "rxjs/operators"
import { UserService } from "../../../../services/user.service"
import { User } from "../../../../shared/models/user.model"

export interface UserListRouteData {
  users: User[]
}

@Component({
  selector: 'insc-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  users: Observable<User[]>

  columnsToDisplay = ["id", "name", "first_name", "last_name", "email", "active", "edit"]

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // get user list from UserResolver
    const routeData = this.route.data as Observable<UserListRouteData>
    this.users = routeData.pipe(pluck("users"))
  }


  edit(id: number): Promise<boolean> {
    return this.router.navigate(["/admin/users", id])
  }


}
