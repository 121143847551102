<h2 matDialogTitle>Metadaten übernehmen</h2>
<div class="dialog-content" matDialogContent>

  <!-- source column group -->
  <div class="column-group source">
    <!-- record picker column -->
    <div class="label-wrapper">
      <div class="label-background">
        <div class="label">Vorhandene Aunahme wählen</div>
      </div>
    </div>

    <insc-record-picker
      [getSearchResults]="recordPickerGetSearchResults"
    ></insc-record-picker>

    <!-- source metadata picker column -->
    <div class="source-picker-wrapper">
      <div class="label-wrapper">
        <div class="label-background">
          <div class="label">Zu übernehmende Metadaten wählen</div>
        </div>
      </div>
      @if ({image: selectedImage | async}; as imageObj) {
        @if (imageObj.image) {
          <insc-multi-image-editor #originImageView  [images]="[imageObj.image]">
            <ng-template #checkboxIcon let-checkbox>
              @if (checkbox.checked) {
                <mat-icon color="accent" class="transfer-checkbox-icon checked">forward
                </mat-icon>
              }
              @if (!checkbox.checked) {
                <mat-icon class="transfer-checkbox-icon unchecked">forward</mat-icon>
              }
            </ng-template>
            <ng-container *inscMultiFieldAccessory="let key = key; let multiControl = multiControl">
              <div class="pick-checkbox">
                <mat-checkbox #checkbox (change)="onFieldAccessoryClick($event, key)">
                  <ng-container *ngTemplateOutlet="checkboxIcon; context: { $implicit: checkbox}"></ng-container>
                </mat-checkbox>
              </div>
            </ng-container>
            <ng-container *inscMultiGroupAccessory="let key = key; let item = value">
              <div class="pick-checkbox group-item">
                <mat-checkbox #checkbox (change)="onGroupAccesoryClick($event, key, item)">
                  <ng-container *ngTemplateOutlet="checkboxIcon; context: { $implicit: checkbox}"></ng-container>
                </mat-checkbox>
              </div>
            </ng-container>
          </insc-multi-image-editor>
        }
        @if (!imageObj.image) {
          <div class="no-images-selected-view">
            <div>Keine Aufnahme ausgewählt</div>
          </div>
        }
      }
    </div>
  </div>

  <!-- result column -->
  <div class="column-group">
    <div class="label-wrapper">
      <div class="label-background">
        <div class="label">Resultat</div>
      </div>
    </div>
    <insc-multi-image-editor #editedImagesView [images]="editedImages"></insc-multi-image-editor>
  </div>
</div>

<div matDialogActions align="end">
  <button color="warn" mat-button type="button" mat-dialog-close>
    <mat-icon>close</mat-icon>
    <span>Abbrechen</span>
  </button>
  <button color="primary" mat-button type="button" (click)="onApply()" [disabled]="(editedImagesView.hasChanges | async) === false">
    <mat-icon>checkmark</mat-icon>
    <span>Übernehmen</span>
  </button>
</div>
