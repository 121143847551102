import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from "@angular/forms"
import { MatAutocompleteModule } from "@angular/material/autocomplete"
import { MatButtonModule } from "@angular/material/button"
import { MatCardModule } from "@angular/material/card"
import { MatDialogModule } from "@angular/material/dialog"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatIconModule, MatIconRegistry } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatSelectModule } from "@angular/material/select"
import { MatTooltipModule } from "@angular/material/tooltip"
import { DomSanitizer } from "@angular/platform-browser"
import { ExternalNormDataIdInputComponent } from "./external-norm-data-id-input/external-norm-data-id-input.component"
import { ExternalNormDataLookupDialogComponent } from "./external-norm-data-lookup-dialog/external-norm-data-lookup-dialog.component"
import {
  ExternalNormDataLookupFieldComponent
} from "./external-norm-data-lookup-tool/external-norm-data-lookup-field/external-norm-data-lookup-field.component"
import {
  ExternalNormDataLookupFormDirective,
  ExternalNormDataLookupToolComponent
} from "./external-norm-data-lookup-tool/external-norm-data-lookup-tool.component"


@NgModule({
  declarations: [ExternalNormDataIdInputComponent, ExternalNormDataLookupFieldComponent, ExternalNormDataLookupToolComponent, ExternalNormDataLookupFormDirective, ExternalNormDataLookupDialogComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule
  ],
  exports: [
    ExternalNormDataIdInputComponent,
    ExternalNormDataLookupToolComponent,
    ExternalNormDataLookupFormDirective
  ]
})
export class ExternalNormDataModule {
  constructor(private iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('gnd', sanitizer.bypassSecurityTrustResourceUrl('/assets/gnd.svg'))
    iconRegistry.addSvgIcon('wikidata', sanitizer.bypassSecurityTrustResourceUrl('/assets/wd.svg'))
    iconRegistry.addSvgIcon('geonames', sanitizer.bypassSecurityTrustResourceUrl('/assets/gn.svg'))
    iconRegistry.addSvgIcon('germania_sacra', sanitizer.bypassSecurityTrustResourceUrl('/assets/gs.svg'))
    iconRegistry.addSvgIcon('germania_sacra_monasteries', sanitizer.bypassSecurityTrustResourceUrl('/assets/gs.svg'))

  }
}
