import { Platform } from "@angular/cdk/platform"
import { ChangeDetectorRef, Component, forwardRef, Inject, Injectable, Input, OnChanges, OnDestroy, OnInit } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms"
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, NativeDateAdapter } from "@angular/material/core"
import { MatCalendar, MatDatepicker } from "@angular/material/datepicker"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"
import { YearOnlyHeaderComponent } from "./year-only-header.component"

export interface DateSpec {
  year: number | null
  month: number | null
  day: number | null
}

export enum DatePrecision {
  Year = 0, Month = 1, Day = 2
}

export function dateSpecToDate(spec: DateSpec): Date {
  return new Date(
    spec.year,
    spec.month ? spec.month - 1 : 0,
    spec.day || 1
  )
}

export function dateToDateSpec(date: Date): DateSpec {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  }
}


@Injectable()
export class DateStateService<D> {
  private _precision: DatePrecision = DatePrecision.Year
  private _date: D

  get precision(): DatePrecision { return this._precision }
  get date(): D { return this._date }

  setDateState(date: D, precision: DatePrecision): void {
    this._date = date
    this._precision = precision
  }
}

@Injectable()
export class RecordingDateRangePickerDateAdapter extends NativeDateAdapter {

  constructor(
    private dateStateService: DateStateService<Date>
  ) {
    super("de-DE")
  }

  format(date: Date, _displayFormat: unknown): string | null {
    if (!date) {
      return null
    }

    switch (this.dateStateService.precision) {
      case DatePrecision.Year:
        return `${this.getYear(date)}`
      case DatePrecision.Month:
        return `${this.getMonthNames("long")[this.getMonth(date)]} ${this.getYear(date)}`
      case DatePrecision.Day:
        return `${this.getDate(date)}. ${this.getMonthNames("long")[this.getMonth(date)]} ${this.getYear(date)}`
    }
  }
}

/**
 * A calender header that wraps the default MatCalendar header.
 *
 * Hack to get access to the date selection event when a day is selected and DatepickerActions are shown.
 */
@Component({
  template: `<mat-calendar-header></mat-calendar-header>`
})
export class CalendarHeaderComponent implements OnDestroy {
  private _unsubscribe = new Subject<void>()

  constructor(private calendar: MatCalendar<Date>,
    private dateAdapter: DateAdapter<Date>,
    private dateStateService: DateStateService<Date>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef) {

    calendar.stateChanges.pipe(
      takeUntil(this._unsubscribe)
    ).subscribe(() => cdr.markForCheck())

    calendar.yearSelected.pipe(
      takeUntil(this._unsubscribe)
    ).subscribe((date: Date) => {
      this.dateStateService.setDateState(date, DatePrecision.Year)
    })

    calendar.monthSelected.pipe(
      takeUntil(this._unsubscribe)
    ).subscribe((date: Date) => {
      this.dateStateService.setDateState(date, DatePrecision.Month)

    })

    calendar.selectedChange.pipe(
      takeUntil(this._unsubscribe)
    ).subscribe((date: Date) => {
      this.dateStateService.setDateState(date, DatePrecision.Day)
    })
  }

  ngOnDestroy(): void {
    this._unsubscribe.next()
    this._unsubscribe.complete()
  }
}

@Component({
  selector:    'insc-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls:   ['./date-range-picker.component.scss'],
  providers:   [
    DateStateService,
    Platform,
    {
      provide: DateAdapter, useClass: RecordingDateRangePickerDateAdapter, deps: [
        DateStateService,
        Platform
      ]
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true
    }
  ]
})
export class DateRangePickerComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() compact = false

  @Input() placeholder: string
  @Input() yearOnly = false
  @Input() minYear = null
  @Input() maxYear = null

  yearOnlyHeader = YearOnlyHeaderComponent
  calendarHeader =  CalendarHeaderComponent

  minDate
  maxDate

  internalDate = new UntypedFormControl()

  constructor(
    private dateStateService: DateStateService<Date>,
    @Inject(DateAdapter) private dateAdapter: RecordingDateRangePickerDateAdapter
  ) { }

  get startView(): "month" | "year" | "multi-year" {
    switch (this.dateStateService.precision) {
      case DatePrecision.Year: return "multi-year"
      case DatePrecision.Month: return "year"
      case DatePrecision.Day: return "month"
    }
  }

  private _toDate(spec: DateSpec): Date {
    return dateSpecToDate(spec)
  }

  private _toSpec(date: Date): DateSpec {
    if (!date) {
      return null
    }

    return {
      year: date.getFullYear(),
      month: this.dateStateService.precision > DatePrecision.Year ? date.getMonth() + 1 : null,
      day: this.dateStateService.precision > DatePrecision.Month ? date.getDate() : null
    }
  }

  selectedDateString(): string {
    return this.dateAdapter.format(this.dateStateService.date, null)
  }

  propagateValue(date: Date): void {
    this.internalDate.setValue(date)
    const spec = this._toSpec(date)
    this.propagateChange(spec)
  }

  ngOnInit(): void {
    this.ngOnChanges()
  }

  ngOnChanges(): void {
    this.minDate = this.minYear ? new Date(this.minYear) : new Date(1800, 0, 1)
    this.maxDate = this.maxYear ? new Date(this.maxDate) : new Date()
  }

  writeValue(value: DateSpec): void {
    if (!value) {
      this.dateStateService.setDateState(null, DatePrecision.Year)
      this.internalDate.setValue(null, { emitEvent: false })
      return
    }

    let precision: DatePrecision
    if (value.day) {
      precision = DatePrecision.Day
    } else if (value.month) {
      precision = DatePrecision.Month
    } else {
      precision = DatePrecision.Year
    }

    const date = this._toDate(value)

    this.internalDate.setValue(this._toDate(value), { emitEvent: false })
    this.dateStateService.setDateState(date, precision)
  }

  // noinspection JSUnusedLocalSymbols
  propagateChange = (_: unknown): void => {}

  registerOnChange(fn: (_: unknown) => void): void {
    this.propagateChange = fn
  }

  registerOnTouched(): void {}

  onYearSelected(normalizedYear: Date, picker: MatDatepicker<unknown>): void {
    if (this.yearOnly) {
      this.dateStateService.setDateState(null, DatePrecision.Year)
      this.propagateValue(normalizedYear)
      picker.close()
    }
  }

  onSelectDate(picker: MatDatepicker<unknown>): void {
    this.propagateValue(this.dateStateService.date)
    picker.close()
  }

  reset(): void {
    this.propagateValue(null)
    this.dateStateService.setDateState(null, DatePrecision.Year)
  }
}
