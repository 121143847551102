import { AsyncPipe } from "@angular/common"
import { Component, Inject, Injectable } from "@angular/core"

import { MatButtonModule } from "@angular/material/button"
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { Observable, of } from "rxjs"
import { catchError } from "rxjs/operators"
import { ImageFileMetadataViewComponent } from "./image-file-metadata-view.component"
export interface ImageFileMetadataViewDialogData {
  imageTitle: string
  filename: string
  metadata$: Observable<ImageFileMetadataViewComponent["metadata"]>
}

export type ImageFileMetadataViewDialogRef = MatDialogRef<ImageFileMetadataViewDialogComponent, void>

@Injectable({
  providedIn: 'root'
})
export class ImageFileMetadataViewDialogService {
  constructor(private matDialog: MatDialog) {}

  open(data: ImageFileMetadataViewDialogData): ImageFileMetadataViewDialogRef {
    return this.matDialog.open(ImageFileMetadataViewDialogComponent, {
      width: "80vw",
      data
    })
  }
}

@Component({
  selector: 'insc-image-file-metadata-view-dialog',
  standalone: true,
  imports: [ImageFileMetadataViewComponent, MatDialogModule, MatButtonModule, MatProgressSpinnerModule, AsyncPipe],
  templateUrl: 'image-file-metadata-view-dialog.component.html',
  styleUrls: ['image-file-metadata-view-dialog.component.scss']
})
export class ImageFileMetadataViewDialogComponent {
  metadata$: ImageFileMetadataViewDialogData["metadata$"]

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: ImageFileMetadataViewDialogData,
    @Inject(MatDialogRef) readonly dialogRef: ImageFileMetadataViewDialogRef
  ) {
    this.metadata$ = data.metadata$.pipe(
      catchError(() => {
        this.dialogRef.close()
        return of(null)
      })
    )
  }
}
