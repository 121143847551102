import { Injectable } from "@angular/core"

import { Observable } from "rxjs"
import { finalize, first, take, tap } from "rxjs/operators"
import { UserService } from "../../../../services/user.service"
import { User } from "../../../../shared/models"

@Injectable({
  providedIn: 'root'
})
export class UsersResolver  {

  constructor(private userService: UserService) { }

  resolve(): Observable<User[]> {
    return this.userService.all()
  }
}
