import { AbstractControl, AbstractControlOptions, FormArray, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { merge, Observable, Subject } from "rxjs"
import { map } from "rxjs/operators"

export type RecordId = string | number

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type MultiRecordControl = MultiRecordFormControl | MultiRecordFormArray

export class ValueWrapper<T> {
  constructor(public value: T) {}
}


export interface MultiRecordFormControlRecordValue<T> {
  recordId: RecordId
  value: T
}
export type CompareFn<T> = (a: T, b: T) => boolean

export type MultiRecordFormControlCommonValue<T> = ValueWrapper<T> | "untouchedMultipleValues" | "dirtyMultipleValues"

export interface MultiControl {
  get dirty(): boolean
}
export class MultiRecordFormControl<T = unknown> implements MultiControl {

  private _recordValues: MultiRecordFormControlRecordValue<T>[]

  get recordValues() { return this._recordValues }

  get dirty() { return this.abstractControl.dirty || this.recordValues !== this.originalRecordValues }

  get placeholder() {
    const commonValue = this.getCommonValue()
    if (commonValue === "untouchedMultipleValues") {
      return "[unterschiedliche Werte]"
    } else if (commonValue === "dirtyMultipleValues") {
      return "[einzelne Datensätze geändert]"
    }

    if (this.valueIsEmptyFunc(commonValue.value)) {
      return this.emptyPlaceholderString
    }
  }

  constructor(
    readonly abstractControl: AbstractControl,
    private originalRecordValues: MultiRecordFormControlRecordValue<T>[],
    private compareFn: CompareFn<T>,
    private emptyPlaceholderString: string,
    private valueIsEmptyFunc: (value: unknown) => boolean
  ) {
    this.setFormControlValueToCommonValue(originalRecordValues)
    this._recordValues = originalRecordValues
  }

  getCommonValue(): MultiRecordFormControlCommonValue<T> {
    if (this.abstractControl.dirty) {
      return new ValueWrapper(this.abstractControl.value)
    }

    const commonValue = this.getCommonValueFromRecordValues(this._recordValues)
    return commonValue
           ?? (this.recordValues === this.originalRecordValues ? "untouchedMultipleValues" : "dirtyMultipleValues")


    // return commonValue ?? "untouchedMultipleValues"
  }

  reset(): void {
    this.setFormControlValueToCommonValue(this.originalRecordValues)
    this.abstractControl.markAsPristine()
  }

  resetWith(recordValues: MultiRecordFormControlRecordValue<T>[]) {
    this._recordValues = recordValues
    this.originalRecordValues = recordValues
    this.reset()
  }

  updateValue(value: T): void {
    this.abstractControl.setValue(value)
    this.abstractControl.markAsDirty()
  }

  updateWith(recordValues: MultiRecordFormControlRecordValue<T>[]) {
    this._recordValues = recordValues
    this.setFormControlValueToCommonValue(recordValues)
  }

  private getCommonValueFromRecordValues(recordValues: MultiRecordFormControlRecordValue<T>[]) {
    const allOriginalValues = recordValues.map(recordValue => recordValue.value)
    const allValuesAreEqual = allOriginalValues.every(value => this.compareFn(value, allOriginalValues[0]))
    if (allValuesAreEqual) {
      return new ValueWrapper(allOriginalValues[0])
    }
  }

  private setFormControlValueToCommonValue(recordValues: MultiRecordFormControlRecordValue<T>[]) {
    const commonValue = this.getCommonValueFromRecordValues(recordValues)
    const formControlValue = commonValue instanceof ValueWrapper ? commonValue.value : null
    this.abstractControl.setValue(formControlValue, { emitEvent: false })
  }


}

export interface MultiRecordValueArray<T> {
  recordId: RecordId
  array: T[]
}

export type ArrayItemControlBuilderFunc<T> = (item?: T) => AbstractControl

export type MultiRecordFormArrayCommonValueState = "allEmpty" | "noCommonValues" | "hasCommonValues"

export type IdentifiableItem = Record<string, unknown> & { id: unknown }
export function isIdentifiableItem(item: unknown): item is IdentifiableItem {
  return item instanceof Object && item.hasOwnProperty("id")
}

export class MultiRecordFormArray<T = unknown | IdentifiableItem> implements MultiControl {
  private recordArrayItemFormControlMap = new WeakMap<AbstractControl, T[]>()
  private itemControlsMarkedForRemoval = new WeakSet<AbstractControl>()

  private recordArrays: MultiRecordValueArray<T>[] = []

  get dirty() { return this.formArray.dirty || this.recordArrays !== this.originalRecordArrays }

  constructor(
    readonly formArray: FormArray<AbstractControl<T>>,
    private originalRecordArrays: MultiRecordValueArray<T>[],
    private arrayItemControlBuilder: ArrayItemControlBuilderFunc<T>,
    private compareFn: CompareFn<T>,
    private isRelationship: boolean
  ) {
    if (originalRecordArrays.length === 0) {
      return
    }

    this.recordArrays = [...originalRecordArrays]

    this.initializeFormArrayWithCommonItems(originalRecordArrays)
  }

  get commonValueState(): MultiRecordFormArrayCommonValueState {
    if (this.formArray.length > 0) {
      return "hasCommonValues"
    } else if (this.originalRecordArrays.every(origArray => origArray.array.length === 0)) {
      return "allEmpty"
    } else {
      return "noCommonValues"
    }
  }

  getRecordArrays() {
    return this.recordArrays
  }

  private initializeFormArrayWithCommonItems(recordArrays: MultiRecordValueArray<T>[]) {
    this.formArray.clear()

    // -- find common items from records

    // iterate through the array items of the first record and check
    // if all other records share this value
    const firstRecordItemArray = recordArrays[0].array
    const otherRecordItemArrays = recordArrays
      .map(recordArray => recordArray.array)
      .slice(1)

    for (const item of firstRecordItemArray) {
      const otherItemOccurances: T[][] = otherRecordItemArrays.map(
        recordItemArray => recordItemArray.filter(testItem => this.compareFn(testItem, item))
      )

      const everyRecordHasItem = otherItemOccurances.every(itemOccurances => itemOccurances.length > 0)

      if (everyRecordHasItem) {
        const commonItemControl = this.arrayItemControlBuilder(item)
        const allItemOccurances = [item].concat(...otherItemOccurances)
        this.recordArrayItemFormControlMap.set(commonItemControl, allItemOccurances)

        this.formArray.push(commonItemControl)
      }
    }
  }

  resetAt(index: number) {
    // TODO this can't work, does it??
    const control = this.formArray.at(index)
    const itemOccurances = this.recordArrayItemFormControlMap.get(control)
    control.reset(itemOccurances[0])
  }

  removeNewItem(index: number): void {
    if (this.isNewItem(index)) {
      this.formArray.removeAt(index)
    } else {
      throw new Error(`Item at index ${index} is not new.`)
    }
  }

  markForRemoval(index: number, remove: boolean = true): void {
    const control = this.formArray.at(index)

    if (remove) {
      this.itemControlsMarkedForRemoval.add(control)

      control.patchValue({...control.value})
      control.markAsDirty()
    } else {
      this.itemControlsMarkedForRemoval.delete(control)
    }
  }

  isMarkedForRemoval(index: number): boolean {
    const control = this.formArray.at(index)
    return this.itemControlsMarkedForRemoval.has(control)
  }

  isNewItem(index: number): boolean {
    const control = this.formArray.at(index)
    return !this.recordArrayItemFormControlMap.has(control)
  }

  reset(): void {
    this.initializeFormArrayWithCommonItems(this.originalRecordArrays)
    this.formArray.markAsPristine()
  }

  resetWith(recordArrays: MultiRecordValueArray<T>[]) {
    this.recordArrays = recordArrays
    this.originalRecordArrays = recordArrays
    this.reset()
  }

  updateWith(recordArrays: MultiRecordValueArray<T>[]) {
    this.recordArrays = recordArrays
    this.initializeFormArrayWithCommonItems(this.recordArrays)
  }

  hasCommonItem(item: T): boolean {
    return this.formArray.controls.some(
      (existingItemControl) => this.compareFn(existingItemControl.value as T, item)
    )
  }

  addNewCommonItem(item?: T): void {
    const newCommonItem = this.arrayItemControlBuilder(item)
    this.formArray.push(newCommonItem)
  }

  addCommonItems(items: T[]): void {
    items.forEach(item => {
      const itemIsNotNullOrEmpty =  item && Object.keys(item).length > 0

      if (!this.hasCommonItem(item) || itemIsNotNullOrEmpty) {
        const formControlForItem = this.arrayItemControlBuilder(item)
        this.formArray.push(formControlForItem)
        if (itemIsNotNullOrEmpty) {
          formControlForItem.markAsDirty()
        }
      }
    })
  }

  hasCommonItems(): boolean {
    return this.formArray.length > 0
  }

  getUpdatedCommonItems(): T[] {
    return this.formArray.controls
      .filter(control => control.dirty)
      .map(control => control.value as T)
  }

  removeCommonItem(item: T): void {
    const formControlsForItem = this.formArray.controls.filter(
      control => this.compareFn(control.value as T, item)
    )
    formControlsForItem.forEach(formGroup => {
      this.formArray.removeAt(this.formArray.controls.indexOf(formGroup))
      this.formArray.markAsDirty()
    }
    )
  }

  updateItemAt(index: number, newItem: Partial<T>): void {
    const control = this.formArray.controls[index]
    control.patchValue(newItem as T)
    control.markAsDirty()
  }

  getUpdatedArrayForRecordId(recordId: RecordId): T[] {
    const originalItems: T[] = this.recordArrays.find(recordArray => recordArray.recordId === recordId).array
    const updatedControls = this.formArray.controls.filter(control => control.dirty)

    const existingAndUpdatedItems: T[] = []
    for (const originalItem of originalItems) {
      // find updated FormGroup for this item
      const updatedControl = updatedControls.find(control => this.recordArrayItemFormControlMap.get(control)?.includes(originalItem))

      if (this.itemControlsMarkedForRemoval.has(updatedControl)) {
        continue
      }

      if (updatedControl) {
        const controlValue = this.getControlValue(updatedControl)
        if (!this.isRelationship && isIdentifiableItem(controlValue) && isIdentifiableItem(originalItem)) {
          existingAndUpdatedItems.push({...controlValue, id: originalItem.id})
        } else {
          existingAndUpdatedItems.push(controlValue)
        }
      } else {
        existingAndUpdatedItems.push(originalItem)
      }
    }

    const newItems = updatedControls
      .filter(control => this.recordArrayItemFormControlMap.has(control) === false)
      .map(control => this.getControlValue(control))

    return [...existingAndUpdatedItems, ...newItems]
  }

  getControlValue(control: AbstractControl): T {
    return control instanceof UntypedFormGroup || control instanceof UntypedFormArray
      ? control.getRawValue() as T
      : control.value as T
  }

  getValueAt(index: number): T {
    const control = this.formArray.at(index)
    return this.getControlValue(control)
  }
}

export interface MultiRecordControlDefinition {
  control: UntypedFormControl | UntypedFormArray
  compareFn?: CompareFn<unknown>,
  emptyPlaceholder?: string,
  valueIsEmptyFn?: (value: unknown) => boolean
}

export interface MultiRecordArrayDefinition<T> extends MultiRecordControlDefinition {
  control: UntypedFormArray
  formGroupBuilder: ArrayItemControlBuilderFunc<T>
  isRelationship?: boolean
  compareFn?: CompareFn<unknown>
}

const isMultiRecordArrayDefinition = (controlDef: MultiRecordControlDefinition): controlDef is MultiRecordArrayDefinition<unknown> =>
  controlDef.control instanceof UntypedFormArray

export type IdentifiableRecord = Record<string, unknown> & { id: string }
export function isIdentifiableRecord(record: unknown): record is IdentifiableRecord {
  return record instanceof Object && record.hasOwnProperty("id")
}

export class MultiRecordFormManager {

  readonly formGroup: UntypedFormGroup
  private singleRecordChangedSubject = new Subject<void>()
  readonly changed: Observable<void>

  private multiRecordControls: Record<string, MultiRecordControl> = {}

  private readonly defaultValueCompare: CompareFn<unknown> = (a: unknown, b: unknown) => a === b
  private readonly defaultValueIsEmptyFn: (value: unknown) => boolean = (value) => value == null || value === ""
  private readonly defaultEmptyValuePlaceholder: string = "[alle leer]"
  private readonly defaultArrayItemCompareValue = (item: unknown) => {
    const ignoreKeys = ["id", "created_at", "updated_at"]
    return JSON.stringify(item, (key, value: unknown) => ignoreKeys.includes(key) ? undefined : value)
  }
  private readonly defaultArrayItemCompare: CompareFn<unknown> = (a: unknown, b: unknown) => {
    return this.defaultValueCompare(
      this.defaultArrayItemCompareValue(a),
      this.defaultArrayItemCompareValue(b)
    )
  }

  get dirty() { return Object.values(this.multiRecordControls).some(multiControl => multiControl.dirty) }

  constructor(private records: IdentifiableRecord[], multiRecordFormDefinition: Record<string, MultiRecordControlDefinition | MultiRecordArrayDefinition<unknown>>, formGroupOptions: AbstractControlOptions = {}) {
    const formControls: Record<string, UntypedFormControl | UntypedFormArray> = {}

    if (!records.every(record => isIdentifiableRecord(record))) {
      throw new Error("All records need to be objects with an 'id' property.")
    }

    for (const [key, multiControlDefinition] of Object.entries(multiRecordFormDefinition)) {

      if (isMultiRecordArrayDefinition(multiControlDefinition)) {
        // build a multi record form array
        const originalArrayItems = this.buildRecordArrayArray(records, key)

        this.multiRecordControls[key] = new MultiRecordFormArray(
          multiControlDefinition.control,
          originalArrayItems,
          multiControlDefinition.formGroupBuilder,
          multiControlDefinition.compareFn || this.defaultArrayItemCompare,
          !!multiControlDefinition.isRelationship
        )

      } else {
        // build a multi record form control
        const originalRecordValues = this.buildRecordValueArray(records, key)

        this.multiRecordControls[key] = new MultiRecordFormControl(multiControlDefinition.control,
          originalRecordValues,
          multiControlDefinition.compareFn || this.defaultValueCompare,
          multiControlDefinition.emptyPlaceholder || this.defaultEmptyValuePlaceholder,
          multiControlDefinition.valueIsEmptyFn || this.defaultValueIsEmptyFn
        )

      }

      formControls[key] = multiControlDefinition.control
    }

    this.formGroup = new UntypedFormGroup(formControls, formGroupOptions)
    this.changed = merge(this.formGroup.statusChanges, this.singleRecordChangedSubject).pipe(
      map(() => {})
    )
  }

  private buildRecordValueArray(records: IdentifiableRecord[], key: string, currentRecordValues?: MultiRecordFormControlRecordValue<unknown>[]) {
    const recordValueArray: MultiRecordFormControlRecordValue<unknown>[] = records.map(record => ({
        recordId: record["id"],
        value:    key in record ? record[key] : currentRecordValues?.find(recordValue => recordValue.recordId === record.id)?.value
      }))
    return recordValueArray
  }

  private buildRecordArrayArray(records: IdentifiableRecord[], key: string, currentRecordArrays?: MultiRecordValueArray<unknown>[]) {
    return records.map(record => {
      let array
      if (key in record) {
        array = record[key]
      } else {
        const currentRecordArray = currentRecordArrays?.find(recordArray => recordArray.recordId === record.id)?.array
        array = currentRecordArray ?? []
      }

      return ({
        recordId: record["id"], array
      })
    })
  }

  resetWithRecords(records: IdentifiableRecord[]) {
    this.records = records
    for (const [key, multiRecordControl] of Object.entries(this.multiRecordControls)) {
      if (multiRecordControl instanceof MultiRecordFormArray) {
        const newRecordValueArrays = this.buildRecordArrayArray(records, key)
        multiRecordControl.resetWith(newRecordValueArrays)
      } else {
        const newRecordValues = this.buildRecordValueArray(records, key)
        multiRecordControl.resetWith(newRecordValues)
      }
    }
  }

    updateRecordsFrom(recordUpdates: IdentifiableRecord[]) {
    let changed = false
    for (const [key, multiRecordControl] of Object.entries(this.multiRecordControls)) {
      if (!recordUpdates.some(recordUpdate => key in recordUpdate)) {
        continue
      }

      if (multiRecordControl instanceof MultiRecordFormArray) {
        const newRecordValueArrays = this.buildRecordArrayArray(recordUpdates, key, multiRecordControl.getRecordArrays())
        multiRecordControl.updateWith(newRecordValueArrays)
      } else {
        const newRecordValues = this.buildRecordValueArray(recordUpdates, key, multiRecordControl.recordValues)
        multiRecordControl.updateWith(newRecordValues)
      }

      changed = true
    }

    if (changed) {
      this.singleRecordChangedSubject.next()
    }
  }

  getRecordUpdates(): IdentifiableRecord[] {
    return this.records.map(record => {
      const recordId = record.id
      const updatedControlEntries = Object.entries(this.multiRecordControls)
        .filter(([key, multiControl]) => multiControl.dirty)
        .map<[string, unknown]>(([key, multiControl]) => {
        let updatedValue: unknown

        if (multiControl instanceof MultiRecordFormControl) {
          const formControl = this.formGroup.get(key)
          updatedValue = formControl.dirty ? this.formGroup.get(key).value : multiControl.recordValues.find(recordValue => recordValue.recordId === record.id).value
        } else if (multiControl instanceof MultiRecordFormArray) {
          const multiRecordFormArray = this.multiRecordControls[key] as MultiRecordFormArray
          updatedValue = multiRecordFormArray.getUpdatedArrayForRecordId(recordId)
        }

        return [key, updatedValue]
      })


      return {
        id: recordId,
        ...Object.fromEntries(updatedControlEntries)
      }
    })
  }

  getUpdatedRecords(): IdentifiableRecord[] {
    const updates = this.getRecordUpdates()
    return this.records.map(record => {
      const updatesForRecord = updates.find(recordUpdate => recordUpdate.id === record.id)
      return {...record, ...updatesForRecord}
    })
  }

  getCommonValues(): Record<string, unknown> {
    return this.formGroup.getRawValue() as Record<string, unknown>
  }

  getCommonUpdatedValues(): Record<string, unknown> {
    const updatedEntries = Object.entries(this.multiRecordControls)
      .filter(([_key, multiControl]) => multiControl.dirty)
      .map<[string, unknown]>(([key, multiControl]) => {
      const value = multiControl instanceof MultiRecordFormArray
        ? multiControl.getUpdatedCommonItems()
        : this.formGroup.get(key).value as unknown

      return [key, value]
    })

    return Object.fromEntries(updatedEntries)
  }

  updateWithValuesFrom(record: Record<string, unknown>): void {
    for (const [key, value] of Object.entries(record)) {
      const multiControl = this.multiRecordControls[key]
      if (multiControl instanceof MultiRecordFormControl) {
        multiControl.updateValue(value)
      } else if (multiControl instanceof MultiRecordFormArray) {
        multiControl.addCommonItems(value as unknown[])
      }
    }
  }

  reset(key: string): void {
    this.multiRecordControls[key].reset()
  }

  get(key: string): MultiRecordControl {
    return this.multiRecordControls[key]
  }
}

