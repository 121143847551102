import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from "@angular/forms"
import { MatButtonModule } from "@angular/material/button"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatTableModule } from "@angular/material/table"
import { InscFormFieldErrorMessageComponent } from "../../../shared/components/./form-components/insc-form-field-error-message.component"
import { UserEditorComponent } from "./user-editor/user-editor.component"
import { UserListComponent } from "./user-list/user-list.component"

import { UserManagementPageRoutingModule } from "./user-management-page-routing.module"
import { UserManagementPageComponent } from "./user-management-page.component"

@NgModule({
  imports: [
    CommonModule,
    UserManagementPageRoutingModule,
    ReactiveFormsModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    InscFormFieldErrorMessageComponent,
    MatCheckboxModule,
  ],
  declarations: [
    UserManagementPageComponent,
    UserListComponent,
    UserEditorComponent
  ]
})
export class UserManagementPageModule { }
