@if (currentCriteria?.length > 0) {
  <mat-chip-listbox class="filter-chips">
    @for (criterium of currentCriteria; track criterium) {
      <mat-chip-option [removable]="true">
        <span class="field">{{ criterium.displayName }}</span>
        <span class="value">{{ criterium.displayValue }}</span>
        <mat-icon matChipRemove (click)="removeCriterium.emit(criterium)">cancel</mat-icon>
      </mat-chip-option>
    }
    @if (currentCriteria?.length > 1) {
      <mat-chip-option class="reset-chip" (click)="resetCriteria.emit()">
        <em>alle zurücksetzen</em>
        <mat-icon matChipRemove (click)="resetCriteria.emit()">cancel</mat-icon>
      </mat-chip-option>
    }
  </mat-chip-listbox>
}
