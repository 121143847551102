import { Injectable } from "@angular/core"
import { HistoricalPersonEntry } from "../../../../../../shared/models/historical-person-entry.model"
import { ExternalNormDataMapperInterface } from "../../external-norm-data-mapper.interface"
import { WikidataEntity } from "./wikidata-api"
import { getName, getTimeframeString } from "./wikidata-formatting-helpers"

@Injectable({
  providedIn: "root"
})
export class WikidataEntityToInscHistoricalPersonMapper implements ExternalNormDataMapperInterface<WikidataEntity, Partial<HistoricalPersonEntry>> {

  /* Wikidata Property IDs:
   P1036: GND ID
   P569: date of birth
   P570: date of death
   */

  map(normDataRecord: WikidataEntity): Partial<HistoricalPersonEntry> {
    return {
      name: getName(normDataRecord),
      comment: normDataRecord.descriptions?.de?.value,
      timeframe: getTimeframeString(normDataRecord.claims),
      wikidata_id: normDataRecord.id,
      gnd_id: normDataRecord.claims?.P1036?.[0].mainsnak.datavalue?.value
    }
  }

}
