<div>
  <insc-norm-data-expansion-panel [disabled]="disabled">
    <div inscNormDataPanelEntryTitle>
      <span class="name" [ngClass]="{'insc-disabled-text': disabled}">{{ locationDisplayHelper.getLocationNameWithLostAnnotation(location.city) }}</span>
    </div>
    <div inscNormDataPanelEntryDescription>
      {{ ancestorAndOwnNames }}
    </div>
    <div inscNormDataPanelEntrySubtitle>

    </div>
    <div inscNormDataPanelEntryDetails>
      <h4>Adresse</h4>
      @if (location.address) {
        <insc-address-view [address]="location.address"></insc-address-view>
      }
    </div>
  </insc-norm-data-expansion-panel>
</div>
