<ng-container [formGroup]="formGroup">

  <mat-form-field>
  	<input matInput  formControlName="name">
  	<mat-label>Zeichenführer</mat-label>
  	<mat-error inscError errorControlName="name"></mat-error>
  </mat-form-field>
  <div>
     <insc-single-vocabulary-select label="Art der des Zeichens" formControlName="sign_type" vocabularyName="sign_type"></insc-single-vocabulary-select>
  </div>

</ng-container>
