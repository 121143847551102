import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from "@angular/forms"
import { MatButtonModule } from "@angular/material/button"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatListModule } from "@angular/material/list"
import { MatTooltipModule } from "@angular/material/tooltip"
import { ConfirmationDialogComponent } from "../../../dialogs/confirmation-dialog.component"
import { ValueFilterPipe } from "../../../pipes/value-filter.pipe"
import { VocabularyEditorComponent } from "./vocabulary-editor.component"

@NgModule({
  imports: [
    CommonModule,

    ReactiveFormsModule,

    MatIconModule,
    MatInputModule,
    MatListModule,
    MatButtonModule,
    MatTooltipModule,

    ConfirmationDialogComponent,
    ValueFilterPipe
  ],
  declarations: [
    VocabularyEditorComponent
  ],
  exports: [
    VocabularyEditorComponent
  ]
})
export class VocabularyEditorModule { }
