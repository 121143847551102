import { Component, OnDestroy, ViewChild } from "@angular/core"
import { ControlContainer, UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { Subject } from "rxjs"
import { LocationDisplayHelperService } from "../../../../../services/location-display-helper.service"
import { SubformComponent } from "../../../../../shared/subform.component"

import { LocationInputComponent } from "./location-input.component"

@Component({
  selector:    'insc-locationing-form',
  templateUrl: './locationing-form.component.html',
  styleUrls:   ['./locationing-form.component.scss']
})
export class LocationingFormComponent extends SubformComponent implements OnDestroy {

  private unsubscribe$ = new Subject<void>()

  get locationTypeString() { return this.locationDisplayHelper.getLocationTypeString(this.formGroup.value["location"]?.type)}

  @ViewChild('locationComponent', { static: true }) locationComponent: LocationInputComponent

  constructor(private locationDisplayHelper: LocationDisplayHelperService, controlContainer: ControlContainer) {
    super(controlContainer)
  }

  static buildFormGroup(locationing, destroyable = true) {

    return new UntypedFormGroup({
      id:            new UntypedFormControl(locationing.id || ''),
      location:    	 new UntypedFormControl(locationing.location || null),
      specification: new UntypedFormControl(locationing.specification || ''),
      timeframe:     new UntypedFormControl(locationing.timeframe || ''),
      ...this.destroyableControl(locationing.id, destroyable)
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

}
