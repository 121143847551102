import { Injectable } from "@angular/core"
import * as QueryString from "qs"
import { IStringifyOptions } from "qs"
import { QueryParams } from "./data.service"


export const queryStringOptions: IStringifyOptions = {
  skipNulls:        true,
  arrayFormat:      "brackets",
  encodeValuesOnly: true
}

@Injectable({
  providedIn: 'root'
})
export class QueryStringService {

  paramsToQueryString(params: QueryParams = {}) {
    return QueryString.stringify(params, queryStringOptions)
  }

}
