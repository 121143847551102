import { Injectable } from "@angular/core"
import { HistoricalPersonEntry } from "../../../../../../shared/models/historical-person-entry.model"
import { ExternalNormDataMapperInterface } from "../../external-norm-data-mapper.interface"
import { LobidGNDPersonRecord } from "./gnd-api"
import { getTimeframeString } from "./gnd-formatting-helpers"


@Injectable({
  providedIn: "root"
})
export class GndPersonToInscHistoricalPersonMapper implements ExternalNormDataMapperInterface<LobidGNDPersonRecord, Partial<HistoricalPersonEntry>> {
  map(normDataRecord: LobidGNDPersonRecord): Partial<HistoricalPersonEntry> {
    const wikidataUrl = normDataRecord.sameAs
      ?.find(entry => entry.collection.abbr === "WIKIDATA")
      ?.id

    const wikidataId = wikidataUrl?.substring(wikidataUrl.lastIndexOf("/") + 1)

    return {
      name:              normDataRecord.preferredName,
      alternative_names: normDataRecord.variantName,
      comment:           normDataRecord.biographicalOrHistoricalInformation?.join("\n\n"),
      timeframe:         getTimeframeString(normDataRecord),
      gnd_id:            normDataRecord.gndIdentifier,
      wikidata_id:       wikidataId
    }
  }


}
