import { Directive, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { NormDataEntry } from "../../shared/models/norm-data-entry.model"
import { RegisterEntryMetadata } from "./shared/register-editor-layout/register-entry-metadata-view/register-entry-metadata-view.component"


@Directive()
export class RegisterEditorBaseAbstractComponent implements OnChanges {
  //
  // @Input() set showEntry(value: RegisterEntryDef) { this.currentEntry.next(value) }

  @Input() type: string
  @Input() showSidebar = false
  @Input() allowEdit = false
  @Input() selectedId: string | null

  form: UntypedFormGroup

  metadata: RegisterEntryMetadata

  @Input() allowNN = false

  get formDirty() { return this.form && this.form.dirty}
  @Input() selectionHandler = (id: string) => { this.selectedId = id }

  getSelectedEntry(): NormDataEntry {
    throw new Error("getSelectedEntry() needs to be implemented in a subclass")
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnChangesBefore(changes)

    this.checkAllowEdit()

    this.ngOnChangesAfter(changes)
  }

  checkAllowEdit() {
    if (this.form) {
      if (this.allowEdit) {
        this.form.enable({emitEvent: false})
      } else {
        this.form.disable({emitEvent: false})
      }
    }
  }


  // noinspection JSUnusedLocalSymbols
  protected ngOnChangesBefore(changes: SimpleChanges): void {}
  // noinspection JSUnusedLocalSymbols
  protected ngOnChangesAfter(changes: SimpleChanges): void {}

  // prevent accidental navigation
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.formDirty) {
      $event.returnValue = true
    }
  }

  protected getMetadata = (entry: NormDataEntry): RegisterEntryMetadata => entry && ({
    id:         entry.id,
    createdAt:  entry.created_at,
    updatedAt:  entry.updated_at,
    creator:    entry.creator,
    lastEditor: entry.last_editor
  })

}
