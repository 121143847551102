import { OverlayContainer } from "@angular/cdk/overlay"
import { Component, HostBinding } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { MatSnackBar } from "@angular/material/snack-bar"
import { Title } from "@angular/platform-browser"
import { NavigationEnd, Router } from "@angular/router"
import { ResizeEvent } from "angular-resizable-element"
import { Observable, pairwise } from "rxjs"
import { map, startWith } from "rxjs/operators"
import { environment } from "../environments/environment"
import { LoginDialogComponent } from "./features/user-management/login-dialog/login-dialog.component"
import { AuthService } from "./services/auth.service"
import { HttpActivityService } from "./services/http-activity.service"
import { UserPreferences, UserPreferencesService } from "./services/user-preferences.service"
import { darkenAnimation } from "./shared/animations/darkenAnimation"

import { SnackbarService } from "./shared/snackbars/snackbar.service"
import { ImageSidebarService } from "./features/images/image-sidebar/image-sidebar.service"
import { ImageUploadDialogService } from "./features/images/image-uploader/image-upload-dialog.service"

@Component({
  selector: 'insc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [darkenAnimation]
})
export class AppComponent {
  appTitle = 'Inschriften'

  userSettingsOpen: boolean = false

  imageSidebarWidth$ = this.preferences.userPreferences.pipe(
    map(prefs => prefs.imageSidebarWidth)
  )
  colorMode$ = this.preferences.userPreferences.pipe(
    map(prefs => prefs.colorMode)
  )

  @HostBinding("class") colorModeClass: string

  readonly isPlayground = environment.playground

  constructor(
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private router: Router,
    public auth: AuthService,
    public activity: HttpActivityService,
    readonly imageSidebar: ImageSidebarService,
    private imageUploadDialog: ImageUploadDialogService,
    private title: Title,
    protected preferences: UserPreferencesService,
    protected overlay: OverlayContainer
  ) {

    this.title.setTitle(`${environment.titlePrefix}${this.appTitle}`)

    this.colorMode$.subscribe(colorMode => {
      if (this.colorModeClass) {
        this.overlay.getContainerElement().classList.remove(this.colorModeClass)
      }

      if (colorMode === "auto") {
        this.colorModeClass = null
        return
      }

      this.colorModeClass = `${colorMode}-theme`

      this.overlay.getContainerElement().classList.add(this.colorModeClass)
    })
  }

  openLoginDialog() {
    this.dialog.open(LoginDialogComponent)
  }

  openImageUploader() {
    // this.sn.openFromComponent(TestComponent)

    this.imageUploadDialog.open({parent: null})
  }

  logout() {
    this.auth.logout()
    this.snackbarService.showSuccessWithMessage(`Erfolgreich ausgeloggt.`)
    void this.router.navigate(["/login"])
  }

  onResizeEnd(event: ResizeEvent, currentSidebarWidth: number) {
    if (typeof event.edges.left === "number") {
      const newWidth = currentSidebarWidth - event.edges.left
      this.preferences.set("imageSidebarWidth", newWidth)
    }
  }

  isActive(route: string) {
    return this.router.isActive(route, {paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'})
  }

  toggleUserSettings(event: MouseEvent) {
    event.stopPropagation()
    this.userSettingsOpen = !this.userSettingsOpen
  }
}
