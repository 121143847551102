@if ({
  searchString: registerEditorLayout?.searchChanges | async,
  entries: entries | async
  }; as obs) {
  <insc-register-editor-layout [loading]="searchResultsLoading">
    <div inscRegisterEditorMenu>
      <mat-selection-list #entryList class="entry-list" [multiple]="false">
        @if (allowNN) {
          <mat-list-option #listEntries
            (click)="selectionHandler(null)"
            [attr.data-id]="null"
            [selected]="selectedId == null">
            <em>N.N.</em>
          </mat-list-option>
          <mat-divider></mat-divider>
        }
        <!--suppress JSUnusedGlobalSymbols -->
        @for (entry of obs.entries; track entry) {
          <mat-list-option #listEntries
            class="list-item"
            [selected]="selectedId == entry.id"
            (click)="selectionHandler(entry.id)"
            [attr.data-id]="entry.id">
            <div class="list-item-content">
              <span class="list-item-title" innerHTML="{{entry.name | highlight: obs.searchString }}"></span>
              <span class="norm-data-icons">
                @if (entry['gnd_id']) {
                  <mat-icon class="norm-data-icon" svgIcon="gnd"></mat-icon>
                }
                @if (entry['wikidata_id']) {
                  <mat-icon class="norm-data-icon" svgIcon="wikidata"></mat-icon>
                }
                @if (entry['germania_sacra_id']) {
                  <mat-icon class="norm-data-icon" svgIcon="germania_sacra"></mat-icon>
                }
              </span>
            </div>
          </mat-list-option>
        }
        @if (creationMode) {
          <mat-list-item #creationEntry class="selected">
            <em>Neuer Eintrag...</em>
          </mat-list-item>
        }
      </mat-selection-list>
      @if (allowEdit) {
        @switch (type) {
          @case ('historical_persons') {
            <button [matMenuTriggerFor]="historicalPersonCreateOptionsMenu"
              mat-button class="add-button" color="primary">
              <mat-icon>add</mat-icon>
              Neuen Eintrag anlegen
            </button>
          }
          @case ('historical_organizations') {
            <button [matMenuTriggerFor]="historicalOrganizationCreateOptionsMenu"
              mat-button class="add-button" color="primary">
              <mat-icon>add</mat-icon>
              Neuen Eintrag anlegen
            </button>
          }
          @default {
            <button mat-button color="primary" class="add-button" (click)="create()">
              <mat-icon>add</mat-icon>
              Neuen Eintrag anlegen
            </button>
          }
        }
        <mat-menu #historicalPersonCreateOptionsMenu>
          <button mat-menu-item (click)="create()">Leerer Eintrag</button>
          <button mat-menu-item (click)="createByLookup('gnd', {type: 'person'})">Aus GND</button>
          <button mat-menu-item (click)="createByLookup('wikidata', {type: 'person'})">Aus Wikidata</button>
          <button mat-menu-item (click)="createByLookup('germania_sacra')">Aus Germania Sacra</button>
        </mat-menu>
        <mat-menu #historicalOrganizationCreateOptionsMenu>
          <button mat-menu-item (click)="create()">Leerer Eintrag</button>
          <button mat-menu-item (click)="createByLookup('germania_sacra_monasteries')">Aus Germania Sacra</button>
        </mat-menu>
      }
    </div>
    @if (form) {
      <div inscRegisterEditorForm>
        <div>
          @switch (type) {
            @case ('persons') {
              <insc-person-entry-form [formGroup]="form"></insc-person-entry-form>
            }
            @case ('organizations') {
              <insc-organization-entry-form
              [formGroup]="form"></insc-organization-entry-form>
            }
            @case ('historical_persons') {
              <insc-historical-person-entry-form
                [formGroup]="form"
                (externalActionTriggered)="onFormExternalAction($event)"
              ></insc-historical-person-entry-form>
            }
            @case ('historical_organizations') {
              <insc-historical-organization-entry-form
                [formGroup]="form"
                (externalActionTriggered)="onFormExternalAction($event)"
              ></insc-historical-organization-entry-form>
            }
            @case ('licenses') {
              <insc-license-form
              [formGroup]="form"></insc-license-form>
            }
            @case ('literature_entries') {
              <insc-literature-entry-form
              [formGroup]="form"></insc-literature-entry-form>
            }
          }
        </div>
      </div>
    }
    @if (form && allowEdit) {
      <div inscRegisterEditorActions>
        @if (isSaving) {
          <mat-spinner diameter="16"></mat-spinner>
        }
        @if (creationMode) {
          <button mat-button color="warn" [disabled]="isSaving" (click)="cancelCreate()">Abbrechen und
            Verwerfen
          </button>
        }
        @if (!creationMode) {
          <button mat-button color="warn" [disabled]="isSaving" (click)="delete()">Löschen</button>
        }
        <button mat-button color="primary" [disabled]="isSaving" (click)="save()">Speichern</button>
      </div>
    }
    @if (form && showSidebar) {
      <div inscRegisterEditorSidebar>
        <insc-register-entry-metadata-view [metadata]="metadata"
        [recordType]="getRecordTypeForActivityLink(type)"></insc-register-entry-metadata-view>
        <insc-associated-record-view [associatedQueries]="associatedQueries"
        [selectedEntry]="selectedEntry"></insc-associated-record-view>
      </div>
    }
  </insc-register-editor-layout>
}
