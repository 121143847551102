<!--<button (click)="test()">Open table editor</button>-->
<!--<a [routerLink]="'first'">first</a>-->
<!--<a [routerLink]="'second'">second</a>-->
<!--<insc-table-iptc-editor style="width: 100%; height: 100%" [iptcDatasets]="iptcDatasets$ | async"></insc-table-iptc-editor>-->
<!--<insc-image-uploader style="width: 1200px; height: 500px; border: 1px dotted black; padding: 1rem;"></insc-image-uploader>-->
@if (metadata$ | async; as metadata) {
  <div>
    <insc-image-file-metadata-view [metadata]="metadata"/>
  </div>
}
