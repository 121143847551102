@for (control of multiRecordFormArray.formArray.controls; track control; let last = $last; let index = $index; let even = $even; let odd = $odd) {
  <div class="entry">
    @if (editable) {
      <div class="entry-content">
        <ng-container *ngTemplateOutlet="this.innerControl; context: {$implicit: control, index, last, even, odd}"></ng-container>
      </div>
    }
    @if (!editable) {
      <div class="entry-content">
        <ng-container *ngTemplateOutlet="this.readonlyView, context: {$implicit: getControlValue(control), index, last, even, odd}"></ng-container>
      </div>
    }
    @if (fieldAccessoryTpl) {
      <div class="field-accessory">
        <ng-container *ngTemplateOutlet="fieldAccessoryTpl; context: getFieldAccessoryContext(getControlValue(control), index)"></ng-container>
      </div>
    }
  </div>
}
@if (multiRecordFormArray.commonValueState === 'allEmpty') {
  <div class="entry view-placeholder">[alle leer]</div>
}
@if (multiRecordFormArray.commonValueState === 'noCommonValues') {
  <div class="entry view-placeholder">[unterschiedliche Werte]</div>
}
