import { Component } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { PersonEntry } from "../../../../shared/models/person-entry.model"

import { SubformComponent } from "../../../../shared/subform.component"
import { AddressFormComponent } from "../../shared/subforms/address-form.component"

@Component({
  selector: 'insc-person-entry-form',
  templateUrl: 'person-entry-form.component.html',
  styleUrls: ["shared-styles.scss", "../../shared/register-form-styles.scss"]
})
export class PersonEntryFormComponent extends SubformComponent {
  static buildFormGroup(personEntry: PersonEntry) {
    return new FormGroup({
      id:         new FormControl(personEntry?.id || null),
      first_name: new FormControl(personEntry?.first_name || ""),
      last_name:  new FormControl(personEntry?.last_name || ""),
      address:    AddressFormComponent.buildFormGroup(personEntry?.address)
    })
  }
}
