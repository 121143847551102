// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript

import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
  standalone: true,
  name: 'formatBytes'
})
export class FormatBytesPipe implements PipeTransform {

  transform(byteVal: string | number, decimals = 2): string {
    const bytes = typeof byteVal === 'number' ? byteVal : parseInt(byteVal, 10)

    if (bytes === 0) {
      return '0 Bytes'
    }

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    const result = parseFloat(`${bytes / Math.pow(k, i)}`).toFixed(dm)
    return `${result} ${sizes[i]}`
  }
}
