import { Component, Input, OnChanges, SimpleChanges } from "@angular/core"
import { LocationDisplayHelperService } from "../../../../services/location-display-helper.service"
import { ObjectLocation } from "../../../../shared/models"

@Component({
  selector:    'insc-location-view',
  templateUrl: './location-view.component.html',
  styleUrls:   ['./location-view.component.css']
})
export class LocationViewComponent implements OnChanges {
  @Input() location: ObjectLocation
  @Input() disabled = false

  ancestorAndOwnNames: string

  constructor(public locationDisplayHelper: LocationDisplayHelperService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if ("location" in changes) {
      this.ancestorAndOwnNames = this.locationDisplayHelper.getOwnAndAncestorNames(this.location)
    }
  }

}
