import { CdkConnectedOverlay, CdkOverlayOrigin } from "@angular/cdk/overlay"
import { CommonModule, registerLocaleData } from "@angular/common"
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http"
import localeDe from "@angular/common/locales/de"
import { LOCALE_ID, NgModule } from "@angular/core"
import { MatButtonModule } from "@angular/material/button"
import { MatButtonToggleModule } from "@angular/material/button-toggle"
import { MatCardModule } from "@angular/material/card"
import { MatLineModule, MatNativeDateModule } from "@angular/material/core"
import { MatIconModule, MatIconRegistry } from "@angular/material/icon"
import { MAT_LIST_CONFIG, MatListModule } from "@angular/material/list"
import { MatMenuModule } from "@angular/material/menu"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatSidenavModule } from "@angular/material/sidenav"
import { MatSnackBarModule } from "@angular/material/snack-bar"
import { MatToolbarModule } from "@angular/material/toolbar"
import { MatTooltipModule } from "@angular/material/tooltip"
import { BrowserModule, DomSanitizer } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { RouterLink, RouterOutlet } from "@angular/router"
import { ResizableModule } from "angular-resizable-element"
import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { ImageSidebarModule } from "./features/images/image-sidebar/image-sidebar.module"
import { ImageUploaderModule } from "./features/images/image-uploader/image-uploader.module"
import { LoginDialogModule } from "./features/user-management/login-dialog/login-dialog.module"
import { IfUserPermissionDirective } from "./shared/directives/auth/if-user-permission.directive"
import { AuthInterceptor } from "./shared/http-interceptors/auth.interceptor"
import { BusyInterceptor } from "./shared/http-interceptors/busy.interceptor"
import { ErrorInterceptor } from "./shared/http-interceptors/error.interceptor"
import { TestPageModule } from "./test-page/test-page.module"

registerLocaleData(localeDe)

// import { HistoricalPersonEntryViewComponent } from './shared/views/historical-person-entry-view.component';

// import { HistoricalOrganizationEntryViewComponent } from './shared/views/historical-organization-entry-view.component';


@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [CommonModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        MatToolbarModule,
        MatButtonModule,
        RouterLink,
        MatMenuModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        ResizableModule,
        ImageSidebarModule,
        RouterOutlet,
        MatTooltipModule,
        MatSnackBarModule,
        MatNativeDateModule,
        ImageUploaderModule,
        LoginDialogModule,
        TestPageModule,
        IfUserPermissionDirective,
        CdkConnectedOverlay,
        MatListModule,
        CdkOverlayOrigin,
        MatCardModule,
        MatButtonToggleModule,
        MatLineModule], providers: [
        { provide: MAT_LIST_CONFIG, useValue: { hideSingleSelectionIndicator: true } },
        { provide: HTTP_INTERCEPTORS, useClass: BusyInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: "de-DE" },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon('AWK', sanitizer.bypassSecurityTrustResourceUrl('/assets/awk-nrw.svg'))
  }
}
