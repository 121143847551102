import { Directive, Input, OnChanges, TemplateRef } from "@angular/core"
import { AbstractAuthDirective } from "./abstract-auth.directive"

@Directive({
  standalone: true,
  selector: '[inscIfUserPermission]'
})
export class IfUserPermissionDirective extends AbstractAuthDirective<string> implements OnChanges {

  /* eslint-disable @angular-eslint/no-input-rename */
  @Input("inscIfUserPermission") requiredValue: string
  @Input("inscIfUserPermissionElse") elseTemplateRef: TemplateRef<never>
  /* eslint-enable @angular-eslint/no-input-rename */

  meetsCondition(): boolean {
    return this.auth.hasPermission(this.requiredValue)
  }

  ngOnChanges(): void {
    if (!this.requiredValue) {
      throw new Error("inscIfUserPermission directive needs a value")
    }
  }
}
