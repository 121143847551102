<div>
  @if (historicalOrganizationEntry) {
    <insc-norm-data-expansion-panel [disabled]="disabled">
      <div inscNormDataPanelEntryTitle>{{ historicalOrganizationEntry.name }}</div>
      <div inscNormDataPanelEntryDetails>
        <h4>Alternative Schreibweisen</h4>
        <ul>
          @for (altName of historicalOrganizationEntry.alternative_names; track altName) {
            <li>{{altName}}</li>
          }
        </ul>
        <h4>Zeitliche Einordnung</h4>
        <p>{{historicalOrganizationEntry.timeframe}}</p>
        <h4>Kommentar</h4>
        <p>{{historicalOrganizationEntry.comment}}</p>
      </div>
    </insc-norm-data-expansion-panel>
  } @else {
    <insc-norm-data-expansion-panel>
      <div inscNormDataPanelEntryTitle>N.N.</div>
    </insc-norm-data-expansion-panel>
  }
</div>
