import { Injectable, Injector, Type } from "@angular/core"
import { AbstractExternalNormDataProviderService } from "./abstract-external-norm-data-provider.service"
import { GeonamesNormDataProviderService } from "./external-norm-data-providers/geonames/geonames-norm-data-provider.service"
import {
  GermaniaSacraMonasteryNormDataProviderService
} from "./external-norm-data-providers/germania-sacra/germania-sacra-monastery-norm-data-provider.service"
import {
  GermaniaSacraPersonNormDataProviderService
} from "./external-norm-data-providers/germania-sacra/germania-sacra-person-norm-data-provider.service"
import { GNDNormDataProviderService } from "./external-norm-data-providers/gnd/gnd-norm-data-provider.service"
import { WikidataNormDataProviderService } from "./external-norm-data-providers/wikidata/wikidata-norm-data-provider.service"

export const externalNormDataProviderMap: {[key: string]: Type<AbstractExternalNormDataProviderService<any>>} = {
  "gnd": GNDNormDataProviderService,
  "geonames": GeonamesNormDataProviderService,
  "wikidata": WikidataNormDataProviderService,
  "germania_sacra": GermaniaSacraPersonNormDataProviderService,
  "germania_sacra_monasteries": GermaniaSacraMonasteryNormDataProviderService
}

@Injectable({
  providedIn: 'root'
})
export class ExternalNormDataProviderResolverService {

  constructor(private injector: Injector) {}

  get availableProviders() { return Object.keys(externalNormDataProviderMap).join(", ") }

  get(providerId: string): AbstractExternalNormDataProviderService<any> {
    if (externalNormDataProviderMap.hasOwnProperty(providerId)) {
      return this.injector.get<AbstractExternalNormDataProviderService<any>>(externalNormDataProviderMap[providerId])
    } else {
      throw new Error(`Could not find provider for ID ${providerId}. Available providers: ${this.availableProviders}.`)
    }
  }
}
