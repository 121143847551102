import { Injectable } from "@angular/core"

@Injectable({
  providedIn: 'root'
})
export class LocationTypeDefsService {
  
  private readonly _validChildTypes = {
    City: ["PrincipalTown", "SubLocation", "MuseumCollection", "PrivateProperty", "Manager"],
    PrincipalTown: ["FreeSite", "Building"],
    SubLocation: ["FreeSite", "Building"],
    Building: ["FreeSite", "Building"],
    FreeSite: ["FreeSite", "Building"]
  }
  
  private readonly _typeNames = {
    City: "Stadt/Gemeinde",
    PrincipalTown: "Hauptort",
    SubLocation: "Ortsteil",
    MuseumCollection: "Museum/Sammlung",
    PrivateProperty: "Privatbesitz",
    Manager: "Übrige Verwalter",
    FreeSite: "Freier Standort",
    Building: "Bauwerk"
  }
  
  locationTypeName(locationType: string) { return this._typeNames[locationType] }
  validChildrenForLocationType(locationType: string) { return this._validChildTypes[locationType] }
  
  constructor() { }
}
