<div class="sidebar">
  <mat-form-field class="search-field" appearance="outline" subscriptSizing="dynamic">
    <mat-label>Suche</mat-label>
    <input matInput [formControl]="searchControl">
    <mat-spinner matTextSuffix
      class="search-spinner"
      diameter="18"
      [@searchSpinner]="loading ? 'fade-in' : 'fade-out'"
    ></mat-spinner>
    <div matIconSuffix class="search-field-suffix">
      @if (searchControl.value) {
        <button
          mat-icon-button matSuffix (click)="searchControl.setValue('')"><mat-icon fontSet="material-icons-outlined">cancel</mat-icon></button>
        }
        <mat-icon>search</mat-icon>

      </div>
    </mat-form-field>
    <div class="sidebar-content">
      <ng-content select="[inscRegisterEditorMenu]"></ng-content>
    </div>
  </div>

  @if (formDirective) {
    <div class="form-container">
      <div class="form">
        <ng-content select="[inscRegisterEditorForm]"></ng-content>
      </div>
    </div>
  } @else {
    @if (!loading) {
      <div class="no-entry">
        <em>Kein Eintrag ausgewählt</em>
      </div>
    }
  }

  <div class="editor-actions">
    <ng-content select="[inscRegisterEditorActions]"></ng-content>
  </div>

  @if (formDirective && sidebarDirective) {
    <div class="associations">
      <ng-content select="[inscRegisterEditorSidebar]">
      </ng-content>
    </div>
  }


