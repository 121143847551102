import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from "@angular/cdk/scrolling"
import { CommonModule, NgOptimizedImage } from "@angular/common"
import { NgModule } from "@angular/core"
import { MatListModule } from "@angular/material/list"
import { MatTab, MatTabGroup } from "@angular/material/tabs"
import { ActivityLinkComponent } from "../../shared/components/activity-link/activity-link.component"
import { VocabularyEditorModule } from "../../shared/components/vocabularies/vocabulary-editor/vocabulary-editor.module"
import { ImageManagementModule } from "../images/image-management/image-management.module"
import { VocabulariesPageRoutingModule } from "./vocabularies-page-routing.module"
import { VocabulariesPageComponent } from "./vocabularies-page.component"
import { AssociatedRecordScrollViewportDirective, VocabularyEditorHostComponent } from "./vocabulary-editor-host.component"

@NgModule({
  imports: [
    CommonModule,
    VocabulariesPageRoutingModule,
    VocabularyEditorModule,

    MatListModule,
    ActivityLinkComponent,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
    NgOptimizedImage,
    MatTabGroup,
    MatTab,
    CdkFixedSizeVirtualScroll,
    ImageManagementModule,
  ],
  declarations: [
    VocabulariesPageComponent,
    VocabularyEditorHostComponent,
    AssociatedRecordScrollViewportDirective
  ]
})
export class VocabulariesPageModule { }
