import { Injectable } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { of } from "rxjs"
import { GlobalConfirmNavigationService } from "../../../services/global-confirm-navigation.service"
import { ImageParent } from "../../../shared/models/image.model"
import { ImageUploadDialogComponent, NewImageUploadDialogRef } from "./image-upload-dialog.component"

export interface ImageUploadDialogData {
  linkedRecordName?: string
  parent?: ImageParent
}

@Injectable({
  providedIn: 'root'
})
export class ImageUploadDialogService {

  constructor(
    private dialog: MatDialog,
    private globalConfirmNavigationService: GlobalConfirmNavigationService
  ) { }

  open(data: ImageUploadDialogData) {
    this.globalConfirmNavigationService.confirmNavigationWith(() => of(false))

    const dialogRef: NewImageUploadDialogRef = this.dialog.open<ImageUploadDialogComponent,
    ImageUploadDialogData,
    boolean>(ImageUploadDialogComponent, {
      data:              data,
      disableClose:      true,
      closeOnNavigation: false,
      width:             "80vw",
      height:            "80vh"
    })

    dialogRef.afterClosed().subscribe(() => this.globalConfirmNavigationService.allowNavigation())

    return dialogRef
  }
}
