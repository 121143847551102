<ng-template #rangeOptionTemplate let-range="range">
  <div class="option-desc">{{ $any(range).desc }}</div>
  <div class="insc-dating-chooser-option-dates">
    @switch ($any(range).type) {
      @case ('day') {
        <ng-container class="option-dates">tagesgenau</ng-container>
      }
      @case ('century') {
        <ng-container class="option-dates">Jahrhundert</ng-container>
      }
      @case ('year') {
        <ng-container class="option-dates">Jahr</ng-container>
      }
      @case ('around') {
        <ng-container class="option-dates">Jahr ±5</ng-container>
      }
    }
  </div>
</ng-template>

<ng-container [formGroup]="formGroup">

  <!-- open / closed range type chooser -->
  <div class="col-border-right range-type-chooser">
    <mat-button-toggle-group class="range-type-toggle" formControlName="range_type">
      <mat-button-toggle value="closed" class="narrow">geschlossen</mat-button-toggle>
      <mat-button-toggle value="open" class="narrow">offen</mat-button-toggle>
    </mat-button-toggle-group>

    @if (formGroup.value['range_type'] === 'open') {
      <mat-radio-group class="open-range-type-radio" formControlName="range_modifier">
        <mat-radio-button value="open_left_exclusive">vor</mat-radio-button>
        <mat-radio-button value="open_right_exclusive">nach</mat-radio-button>
        <mat-radio-button value="open_left_inclusive">oder früher</mat-radio-button>
        <mat-radio-button value="open_right_inclusive">oder später</mat-radio-button>
      </mat-radio-group>
    }
  </div>

  <!-- dating range chooser -->
  <div class="range-choosers">
    <mat-form-field class="primary range-input" [ngClass]="{open: formGroup.value['range_type'] === 'open'}">
      <mat-label>Zeitraum o. Zeitpunkt{{ formGroup.controls['extend'].value == true ? ' (ab)' : '' }}</mat-label>
      <input #inputPrimary type="text"
        matInput formControlName="primary" [matAutocomplete]="autoPrimary"
        #autocompletePrimary="matAutocompleteTrigger"
        (keydown)="onInputKeydown($event, autocompletePrimary, formGroup.controls['primary'])">
      <mat-autocomplete [autoActiveFirstOption]="true" #autoPrimary="matAutocomplete"
        [displayWith]="getDisplayValue">
        @for (range of suggestedRangesPrimary; track range) {
          <mat-option style="height: 64px;" [value]="range">
            <ng-container *ngTemplateOutlet="rangeOptionTemplate; context: {range: range}"></ng-container>
          </mat-option>
        }
      </mat-autocomplete>
      @if (formGroup.controls['primary'].invalid) {
        <mat-error>{{formGroup.controls["primary"].errors['invalid']}}
        </mat-error>
      }
    </mat-form-field>

    @if (formGroup.value['range_type'] === 'closed') {
      <div class="secondary">
        <mat-checkbox class="extend" formControlName="extend">bis</mat-checkbox>
        <mat-form-field class="range-input">
          <input type="text" aria-label="Number" matInput formControlName="secondary"
            [matAutocomplete]="autoSecondary" #autocompleteSecondary="matAutocompleteTrigger"
            (keydown)="onInputKeydown($event, autocompleteSecondary, formGroup.controls['secondary'])">
          <mat-label>{{formGroup.value.extend ? 'Zeitraum o. Zeitpunkt (bis)' : ''}}</mat-label>
          <mat-autocomplete [autoActiveFirstOption]="true" #autoSecondary="matAutocomplete"
            [displayWith]="getDisplayValue">
            @for (range of suggestedRangesSecondary; track range) {
              <mat-option [value]="range">
                <ng-container *ngTemplateOutlet="rangeOptionTemplate; context: {range: range}"></ng-container>
              </mat-option>
            }
          </mat-autocomplete>
          @if (formGroup.controls['secondary'].invalid) {
            <mat-error>{{formGroup.controls["secondary"].errors['invalid']}}
            </mat-error>
          }
        </mat-form-field>
      </div>
    }

    <div class="description">
      <div class="mat-caption"><b>Mögliche Angabeformate</b></div>
      <ul class="formats">
        <li><span class="format-type">Tagesgenaues Datum:</span> <span class="format-format">(T)T.(M)M.(J)JJJ</span>
        <span class="format-example">(z.B. 29.3.1788)</span></li>
        <li><span class="format-type">Jahr:</span> <span class="format-format">(J)JJJ</span> <span
      class="format-example">(z.B. 800, 1234)</span></li>
      <li><span class="format-type">Jahr ±5:</span> <span class="format-format">um (J)JJJ</span> <span
    class="format-example">(z.B. um 850)</span></li>
    <li><span class="format-type">Jahrhundert:</span> <span class="format-format">X. Jh.</span> <span
  class="format-example">(z.B. 11. Jh.)</span></li>
  <li><span class="format-type">Jahrhundertabschnitt:</span> <span class="format-format">X. Jh. X. H./D./V.</span><span class="format-example">(z.B. 11 Jh. 2. H.)</span></li>
  <li><span class="format-type">Jahrhundertbereich:</span> <span class="format-format">X. Jh. A./M./E. </span><span class="format-example">(z.B. 7. Jh. E.)</span></li>
</ul>
</div>

</div>
</ng-container>


