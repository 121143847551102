<mat-form-field class="entry-field">
  <mat-chip-grid #chipGrid>
    @for (chipEntry of formControlValue; track chipEntry) {
      <mat-chip-row [removable]="true" (removed)="remove(chipEntry)">
        {{ chipEntry }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
    }
    <input matInput #entryInput
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matAutocomplete]="auto"
      [formControl]="inputCtrl">
  </mat-chip-grid>
  <mat-label>{{label}}</mat-label>
  <mat-error inscError [errorControl]="ngControl.control"></mat-error>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [autoActiveFirstOption]="true">
    @if (vocabulary$ | async; as vocabulary) {
      <mat-optgroup
        label="Erlaubte Begriffe für {{vocabulary.display}} – Mehrfachauswahl"
        class="insc-vocabulary-select-autocomplete-optgroup"
        >
        @for (entry of (vocabulary.entries | valueFilter: {pattern: entryInput.value, filterByProp: 'name'}); track entry) {
          @if (!ngControl.value?.includes(entry.original.name)) {
            <mat-option
              [value]="entry.original.name"
              [ngClass]="{'insc-mat-option-with-subtitle': !!entry.original.comment}">
              <div class="insc-mat-option-title" [innerHtml]="entry.highlighted.name"></div>
              @if (entry.original.comment) {
                <div class="insc-mat-option-subtitle">{{entry.original.comment}}</div>
              }
            </mat-option>
          }
        }
      </mat-optgroup>
    }
  </mat-autocomplete>
  <div matSuffix>
    @if (!inputCtrl.disabled && showEditButton) {
      <button type="button" mat-icon-button (click)="openEditor($event)">
        <mat-icon matTooltip="Vokabular bearbeiten">
          edit_note
        </mat-icon>
      </button>
    }
    <ng-container *ngTemplateOutlet="extraSuffixTpl"></ng-container>
  </div>
</mat-form-field>
@if (hasEditorialState) {
  <insc-editorial-state-select
    [formControl]="editorialStateFormControl"
  class="editorial-state-select"></insc-editorial-state-select>
}
