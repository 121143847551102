<ng-container [formGroup]="formGroup">
  <mat-form-field>
    <input matInput  type="text" formControlName="name">
    <mat-label>Name</mat-label>
    <mat-error inscError errorControlName="name"></mat-error>
  </mat-form-field>
  <mat-form-field class="abbreviation">
     <input matInput  type="text" formControlName="abbreviation">
     <mat-label>Abkürzung</mat-label>
     <mat-error inscError errorControlName="abbreviation"></mat-error>
   </mat-form-field>
  <div class="subform">
    <h4>Adresse</h4>
    <insc-address-form formGroupName="address"></insc-address-form>
  </div>
</ng-container>
