import { HttpClient, HttpParams } from "@angular/common/http"
import { Component, OnDestroy, OnInit } from "@angular/core"
import { UntypedFormBuilder } from "@angular/forms"
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from "@angular/material-moment-adapter"
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core"
import { MatPaginatorIntl, PageEvent } from "@angular/material/paginator"
import { ActivatedRoute, ParamMap, Router } from "@angular/router"
import moment from "moment"
import { BehaviorSubject, Observable, Subject } from "rxjs"
import { map, share, switchMap, takeUntil } from "rxjs/operators"

import { environment } from "../../../../environments/environment"
import { MatPaginatorIntlDE } from "../../../shared/mat-paginator-intl-de"

moment.locale('de')

@Component({
  selector: 'insc-activities-page',
  templateUrl: './activities-page.component.html',
  styleUrls: ['./activities-page.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlDE}
  ],
})
export class ActivitiesPageComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>()

  filterForm = this.formBuilder.group({
    item_type: "",
    activity_type: this.formBuilder.control([]),
    date_from: null,
    date_to: null,
    item_id: null
  })

  defaultFilterFormValue = {
    item_type: "",
    activity_type: ["create", "update", "destroy", "link", "unlink", "vocabulary"],
    date_from: null,
    date_to: null,
    item_id: null
  }

  itemNames = {
    "InscObject": "Inschriftenträger",
    "Image": "Aufnahme",
    "Inscription": "Inschrift",
    "Person": "Person",
    "Organization": "Organisation",
    "HistoricalPerson": "Historische Person",
    "HistoricalOrganization": "Historische Organisation",
    "Location": "Standort",
    "Vocabulary": "Vokabular",
    "ObjectGroup": "Objektgruppe"
  }

  itemTypes = Object.keys(this.itemNames)

  itemLinks = {
    "InscObject": "objects",
    "Inscription": "inscriptions",
    "Image": "images",
    "Person": "registers/persons",
    "Organization": "registers/organizations",
    "HistoricalPerson": "registers/historical_persons",
    "HistoricalOrganization": "registers/historical_organizations",
    "Location": "registers/locations"
  }

  actionNames = {
    "update": "geändert",
    "destroy": "gelöscht",
    "create": "erstellt"
  }

  changeTypeNames = {
    "update_assoc": "geändert",
    "add_assoc": "hinzugefügt",
    "delete_assoc": "entfernt"
  }

  crudActivities = ['create', 'update', 'destroy']
  linkActivities = ['link', 'unlink']

  activityTypeNames = {
    "create": "Datensatz erstellt",
    "update": "Datensatz geändert",
    "destroy": "Datensatz gelöscht",
    "link": "Datensatzverknüpfung hinzugefügt",
    "unlink": "Datensatzverknüpfung entfernt",
    "vocabulary": "Vokabular bearbeitet",
    "vocabulary_change": "Verknüpfter Vokabulareintrag geändert",
    "knowledge_change": "Verknüpfter Registereintrag geändert"
  }
  activityTypes = Object.keys(this.activityTypeNames)

  activitiesResult$: Observable<any>
  groupedActivities$: Observable<{
    keys: string[]
    groups: {[relativeTime: string]: any[]}
  }>

  page = 1
  pageSize = 50

  pageParams$ = new Subject<{page_size: number; page: number}>()

  totalResults$ = new BehaviorSubject<number>(0)

  today = moment()

  itemName$: Observable<string>
  specificItemMode = (): boolean => this.filterForm.value.item_id?.length > 0

  constructor(
    private http: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    // when user changes filter, update route query params, reset page but preserve page size
    this.filterForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(formValue => {
        const params = {
          ...formValue,
          activity_type: this.encodeArray(formValue["activity_type"]),
          item_id: this.encodeArray(formValue["item_id"]),
          item_type: this.encodeArray(formValue["item_type"]),
          date_from: this.encodeDate(formValue["date_from"]),
          date_to: this.encodeDate(formValue["date_to"]),
        }

        void this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {...params, page_size: this.pageSize}
        })
      })

    // when user changes page options, update route query params, preserve filter
    this.pageParams$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(pageParams => {
        this.pageSize = pageParams.page_size
        this.page = pageParams.page

        void this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {page_size: pageParams.page_size, page: pageParams.page},
          queryParamsHandling: "merge"
        })
      })

    // when url query params change, make API request
    const routeQueryParams$ = this.route.queryParamMap.pipe(map(this.paramMapToObject))

    routeQueryParams$.pipe(takeUntil(this.unsubscribe$)).subscribe(queryParams => {
      // trigger a form value change if no query params present
      // which triggers a route change which triggers an API request
      // with default parameters pre-set in the form
      if (Object.keys(queryParams).length === 0) {
        this.filterForm.setValue(this.defaultFilterFormValue)
        return
      }

      const formValue = {
        ...queryParams,
        activity_type: this.decodeArray(queryParams["activity_type"]),
        item_id: this.decodeArray(queryParams["item_id"]),
        item_type: this.decodeArray(queryParams["item_type"]),
        date_from: this.decodeDate(queryParams["date_from"]),
        date_to: this.decodeDate(queryParams["date_to"]),
      }

      // also update values shown in controls in case the route was changed
      // using browser navigation buttons etc.
      this.filterForm.patchValue(formValue, { emitEvent: false })
      this.pageSize = queryParams["page_size"]
      this.page = queryParams["page"]
    })

    this.load(routeQueryParams$)

  }

  onPageChange(event: PageEvent) {
    this.pageParams$.next({
      page: event.pageIndex + 1,
      page_size: event.pageSize
    })
  }

  load(params$: Observable<{[p: string]: string} | HttpParams>) {
    this.activitiesResult$ = params$.pipe(
      switchMap(params => this.http.get<any[]>(`${environment.apiUrl}/activities`, {params: params})),
      share()
    )

    this.groupedActivities$ = this.activitiesResult$.pipe(
      map(activitiesResult => {
        const grouped = {}
        activitiesResult.activities.forEach(activity => {
          const relativeTime = moment(activity.created_at).fromNow()
          if (grouped[relativeTime]) {
            grouped[relativeTime].push(activity)
          } else {
            grouped[relativeTime] = [activity]
          }
        })
        return {
          keys: Object.keys(grouped),
          groups: grouped
        }
      }),
      share()
    )

    this.activitiesResult$.pipe(
      map(results => results["total"]),
      takeUntil(this.unsubscribe$)
    ).subscribe(this.totalResults$)

    this.itemName$ = this.activitiesResult$.pipe(
      map(results => results["item_name"])
    )
  }

  multipleItemIdsSelected(): boolean {
    return (this.filterForm.get('item_id').value?.length > 1)
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  private encodeArray = (array) =>
    Array.isArray(array) ? array.join(",") : array

  private decodeArray = (string) => string ? string.split(",") : []

  private encodeDate = (momentDate) => moment.isMoment(momentDate) ? momentDate.format() : null
  private decodeDate = (dateString) => dateString ? moment(dateString) : null

  private paramMapToObject = (paramMap: ParamMap) => paramMap.keys.reduce((paramObj, key) => {
    paramObj[key] = paramMap.get(key)
    return paramObj
  }, {})
}
