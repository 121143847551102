import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from "@angular/forms"
import { MatAutocompleteModule } from "@angular/material/autocomplete"
import { MatButtonModule } from "@angular/material/button"
import { MatChipsModule } from "@angular/material/chips"
import { MatDialogModule } from "@angular/material/dialog"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatSelectModule } from "@angular/material/select"
import { MatTooltipModule } from "@angular/material/tooltip"
import { ValueFilterPipe } from "../../../pipes/value-filter.pipe"
import { InscFormFieldErrorMessageComponent } from "../../form-components/insc-form-field-error-message.component"
import { VocabularyEditorModule } from "../vocabulary-editor/vocabulary-editor.module"
import { VocabularySelectExtraSuffixDirective } from "./abstract-vocabulary-select.component"
import { EditorialStateSelectComponent } from "./editorial-state-select.component"
import { MultiVocabularySelectComponent } from "./multi-vocabulary-select.component"
import { SingleVocabularySelectComponent } from "./single-vocabulary-select.component"
import { VocabularyEditorDialogComponent } from "./vocabulary-editor-dialog.component"


@NgModule({
  declarations: [
    MultiVocabularySelectComponent,
    SingleVocabularySelectComponent,
    VocabularySelectExtraSuffixDirective,
    VocabularyEditorDialogComponent,
    EditorialStateSelectComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ValueFilterPipe,
    InscFormFieldErrorMessageComponent,
    MatChipsModule,
    MatSelectModule,
    MatDialogModule,
    VocabularyEditorModule
  ],
  exports: [
    MultiVocabularySelectComponent,
    SingleVocabularySelectComponent,
    VocabularySelectExtraSuffixDirective,
  ]
})
export class VocabularyFormComponentsModule { }
