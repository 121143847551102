import { ApplicationRef, enableProdMode } from "@angular/core"
import { enableDebugTools } from "@angular/platform-browser"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"

import { AppModule } from "./app/app.module"
import { environment } from "./environments/environment"

if (environment.production) {
  enableProdMode()
}

const bootstrap = () => platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(moduleRef => {
    const applicationRef = moduleRef.injector.get(ApplicationRef);
    const componentRef = applicationRef.components[0];
    // allows to run `ng.profiler.timeChangeDetection();`
    enableDebugTools(componentRef);
  })

bootstrap()
