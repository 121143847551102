import { Injectable } from "@angular/core"

@Injectable({
  providedIn: 'root'
})
export class SearchStateService {
  private _lastSearchUrl: string = null
  get lastSearchUrl(): string { return this._lastSearchUrl }

  constructor() {}

  cacheSearchUrl(url: string): void {
    this._lastSearchUrl = url
  }
}
