import { Injectable } from "@angular/core"
import { ObjectLocation } from "../../../../../../shared/models/object-location.model"
import { ExternalNormDataMapperInterface } from "../../external-norm-data-mapper.interface"
import { WikidataEntity } from "./wikidata-api"
import { getName } from "./wikidata-formatting-helpers"

@Injectable({
  providedIn: "root"
})
export class WikidataEntityToInscLocationMapper implements ExternalNormDataMapperInterface<WikidataEntity, Partial<ObjectLocation>> {

  /* Wikidata Property IDs:
      P625: geocoordinates
      P439: german municipality ID (amtl. Gemeindeschlüssel)
      P1036: GND ID
      P1566: Geonames ID
   */

  map(normDataRecord: WikidataEntity): Partial<ObjectLocation> {
    return {
      name: getName(normDataRecord),
      description: normDataRecord.descriptions?.de?.value,
      wikidata_id: normDataRecord.id,
      lat: normDataRecord.claims?.["P625"]?.[0].mainsnak?.datavalue?.value.latitude?.toString(),
      lng: normDataRecord.claims?.["P625"]?.[0].mainsnak?.datavalue?.value.longitude?.toString(),
      district_key: normDataRecord.claims?.["P439"]?.[0].mainsnak?.datavalue?.value,
      geonames_id: normDataRecord.claims?.["P1566"]?.[0].mainsnak.datavalue?.value
    }
  }

}
