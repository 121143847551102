<!-- suffix button to reset field to the previously saved value -->
<ng-template #resetButton let-formControlName="formControlName">
  @if (editable && formGroup.get(formControlName).dirty) {
    <button mat-icon-button
      (click)="reset(formControlName)"
      matTooltip="Änderungen zurücksetzen">
      <mat-icon>undo</mat-icon>
    </button>
  }
</ng-template>

@if (formGroup) {
  <div class="container" [formGroup]="formGroup">
    <!-- START FORM FIELD TEMPLATES -->
    <ng-template #textField let-formControlName="formControlName">
      <insc-multi-field [editable]="editable" [multiRecordFormControl]="getMultiControl(formControlName)">
        <mat-form-field>
          <input matInput [placeholder]="getMultiControl(formControlName).placeholder" [formControlName]="formControlName">
          <div matSuffix>
            <ng-container *ngTemplateOutlet="resetButton; context: {formControlName}"></ng-container>
          </div>
        </mat-form-field>
      </insc-multi-field>
      <ng-container *ngTemplateOutlet="fieldAccessoryTpl; context: {key: formControlName, multiControl: getMultiControl(formControlName)}"></ng-container>
    </ng-template>
    <ng-template #registerField
      let-formControlName="formControlName"
      let-nullMeansNN="nullMeansNN"
      let-lookupFunc="lookupFunc"
      let-getDisplayValue="getDisplayValue"
      >
      <insc-multi-field [editable]="editable" #multiField [getDisplayValue]="getDisplayValue" [multiRecordFormControl]="getMultiControl(formControlName)">
        <mat-form-field [ngClass]="{readonly: !editable}">
          <input readonly matInput [value]="multiField.displayValue" [placeholder]="getMultiControl(formControlName).placeholder">
          @if (showEmptyButton(formControlName)) {
            <button mat-icon-button matSuffix matTooltip="Feld leeren" (click)="clearField(formControlName)"><mat-icon>close</mat-icon></button>
          }
          @if (lookupFunc) {
            <button mat-icon-button matSuffix matTooltip="Im Register nachschlagen" (click)="lookupFunc(this.formGroup.get(formControlName))"><mat-icon>library_books</mat-icon></button>
          }
          <ng-container matSuffix *ngTemplateOutlet="resetButton; context: {formControlName}"></ng-container>
        </mat-form-field>
      </insc-multi-field>
      <ng-container *ngTemplateOutlet="fieldAccessoryTpl; context: {key: formControlName, multiControl: getMultiControl(formControlName)}"></ng-container>
    </ng-template>
    <!-- END FORM FIELD TEMPLATES -->
    <!-- FIELDS -->
    <div class="form-row">
      <div class="label">Bezeichnung</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'name'}"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">Dateiname</div>
      <insc-multi-field [editable]="editable" [multiRecordFormControl]="getMultiControl('filename_override')">
        <mat-form-field>
          <input matInput [placeholder]="filenameOverridePlaceholder()" [formControlName]="'filename_override'">
          <div matSuffix>
            <ng-container *ngTemplateOutlet="resetButton; context: {formControlName: 'filename_override'}"></ng-container>
          </div>
        </mat-form-field>
        <ng-container *inscMultiFieldReadonlyView="let value">
          {{ value }}
          <div class="view-placeholder">{{ filenameOverridePlaceholder() }}</div>
        </ng-container>
      </insc-multi-field>
      <ng-container *ngTemplateOutlet="fieldAccessoryTpl; context: {key: 'filename_override', multiControl: getMultiControl('filename_override')}"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">Fotograf/-in</div>
    <ng-container *ngTemplateOutlet="registerField; context: {
      formControlName: 'photographer',
      lookupFunc: choosePhotographer,
      getDisplayValue: getPhotographerDisplayValue
    }"></ng-container>
    </div>
    @if (getMultiArray('editors'); as editorsMultiArray) {
      <div class="form-row">
        <div class="label">
          <div>Bearbeiter/-in</div>
          @if (images.length > 1 && editorsMultiArray.hasCommonItems()) {
            <div>(gemeinsame Werte)</div>
          }
        </div>
        <insc-multi-group class="editors-group" [ngClass]="{editable}"
          [editable]="editable"
          [multiRecordFormArray]="editorsMultiArray"
          #editorsMultiGroup
          >
          <div *inscMultiGroupInnerControl="let control; let index = index; let last = last" class="editor-field-row">
            <mat-form-field [ngClass]="{readonly: !editable}" class="editor-form-field">
              @if (editorsMultiArray.isNewItem(index)) {
                <mat-icon matIconPrefix class="compact-prefix" color="accent" matTooltip="Neuer Wert">auto_awesome</mat-icon>
              }
              @if (editorsMultiArray.isMarkedForRemoval(index)) {
                <mat-icon matIconPrefix class="compact-prefix" color="warn" matTooltip="Wird gelöscht">delete</mat-icon>
              }
              <input readonly matInput class="item" [disabled]="editorsMultiArray.isMarkedForRemoval(index)" [value]="getEditorDisplayValue(control.value)" placeholder="leer">
              <button mat-icon-button matSuffix [disabled]="editorsMultiArray.isMarkedForRemoval(index)" matTooltip="Im Register nachschlagen" (click)="chooseEditor(control)"><mat-icon>library_books</mat-icon></button>
              @if (!editorsMultiArray.isMarkedForRemoval(index)) {
                <button mat-icon-button matSuffix matTooltip="Eintrag löschen" (click)="deleteArrayItem(editorsMultiArray, index)"><mat-icon>delete</mat-icon></button>
              }
              @if (editorsMultiArray.isMarkedForRemoval(index)) {
                <button mat-icon-button matSuffix matTooltip="Eintrag wiederherstellen" (click)="editorsMultiArray.markForRemoval(index, false)"><mat-icon>restore_from_trash</mat-icon></button>
              }
              @if (last) {
                <button mat-icon-button matSuffix matTooltip="Eintrag hinzufügen" (click)="editorsMultiArray.addNewCommonItem()"><mat-icon>add</mat-icon></button>
              }
            </mat-form-field>
          </div>
          <div *inscMultiGroupReadonlyView="let formValue">
            <div class="editors-item">
              <div class="editors-item-value">{{ getEditorDisplayValue(formValue) }}</div>
          <ng-container *ngTemplateOutlet="groupAccessoryTpl; context: {
          key: 'editors',
          value: formValue,
          multiControl: getMultiArray('editors')
        }"></ng-container>
            </div>
          </div>
        </insc-multi-group>
        @if (editable && editorsMultiArray.commonValueState !== 'hasCommonValues') {
          <button class="add-button-for-empty-array"  mat-icon-button matSuffix matTooltip="Eintrag hinzufügen" (click)="editorsMultiArray.addNewCommonItem()"><mat-icon>add</mat-icon></button>
        }
      </div>
    }
    <div class="form-row">
      <div class="label">Film-/&thinsp;Negativ-/&thinsp;Diapositiv-&thinsp;Nummer</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'negative_number'}"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">Rechte&shy;inhaber/-&thinsp;in</div>
    <ng-container *ngTemplateOutlet="registerField; context: {
      formControlName: 'copyright_holder',
      lookupFunc: chooseCopyrightHolder,
      getDisplayValue: getCopyrightHolderDisplayValue
    }"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">Lizenz</div>
    <ng-container *ngTemplateOutlet="registerField; context: {
      formControlName: 'license',
      lookupFunc: chooseLicenseEntry,
      getDisplayValue: getLicenseDisplayValue
    }"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">Aufnahmedatum</div>
      <insc-multi-field #recordingDateMultiField [editable]="editable" [getDisplayValue]="getRecordingDateDisplayValue" [multiRecordFormControl]="getMultiControl('recording_date')">
        <mat-form-field>
          <input readonly matInput [value]="recordingDateMultiField.displayValue" (click)="chooseRecordingDate(formGroup.get('recording_date'))" [placeholder]="getMultiControl('recording_date').placeholder">
          @if (showEmptyButton('recording_date')) {
            <button mat-icon-button matSuffix matTooltip="Feld leeren" (click)="clearField('recording_date')"><mat-icon>close</mat-icon></button>
          }
          <button mat-icon-button matSuffix matTooltip="bearbeiten" (click)="chooseRecordingDate(formGroup.get('recording_date'))"><mat-icon>edit</mat-icon></button>
          <ng-container matIconSuffix *ngTemplateOutlet="resetButton; context: {formControlName: 'recording_date'}"></ng-container>
        </mat-form-field>
      </insc-multi-field>
      <ng-container *ngTemplateOutlet="fieldAccessoryTpl; context: {key: 'recording_date', multiControl: getMultiControl('recording_date')}"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">Aufnahme&shy;kategorie</div>
      <insc-multi-field [editable]="editable" [multiRecordFormControl]="getMultiControl('recording_category')">
        <insc-single-vocabulary-select
          [compact]="true"
          [placeholder]="getMultiControl('recording_category').placeholder"
          vocabularyName="recording_category"
          formControlName="recording_category">
          <ng-container *inscVocabularySelectExtraSuffix>
            <ng-container *ngTemplateOutlet="resetButton; context: {formControlName: 'recording_category'}"></ng-container>
          </ng-container>
        </insc-single-vocabulary-select>
      </insc-multi-field>
      <ng-container *ngTemplateOutlet="fieldAccessoryTpl; context: {key: 'recording_category', multiControl: getMultiControl('recording_category')}"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">Beschreibung</div>
      <insc-multi-field [editable]="editable" [multiRecordFormControl]="getMultiControl('content')">
        <mat-form-field>
          <textarea matInput cdkTextareaAutosize formControlName="content" [placeholder]="getMultiControl('content').placeholder"></textarea>
          <div matSuffix>
            <ng-container *ngTemplateOutlet="resetButton; context: {formControlName: 'content'}"></ng-container>
          </div>
        </mat-form-field>
        <ng-container *inscMultiFieldReadonlyView="let value" class="image-description">
          <div [ngStyle]="{'white-space': 'pre-line'}">{{ value }}</div>
          @if (getMultiControl('content').placeholder) {
            <div class="view-placeholder">{{ getMultiControl('content').placeholder }}</div>
          }
        </ng-container>
      </insc-multi-field>
      <ng-container *ngTemplateOutlet="fieldAccessoryTpl; context: {key: 'content', multiControl: getMultiControl('content')}"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">Aufnahmeort</div>
    <ng-container *ngTemplateOutlet="registerField; context: {
      formControlName: 'recording_location',
      lookupFunc: chooseRecordingLocation,
      getDisplayValue: getLocationDisplayValue
    }"></ng-container>
    </div>
    <!-- FIELD GROUPS -->
    <!-- DELETE OVERLAY -->
    <ng-template #deleteOverlay let-multiArray="multiArray" let-index="index">
      @if (groupItemIsDeleted(multiArray, index)) {
        <div class="destroy-overlay">
          <mat-icon class="compact-prefix" matPrefix color="warn" matTooltip="Wird gelöscht">delete</mat-icon>
          <em>wird beim speichern gelöscht</em>
        </div>
      }
    </ng-template>
    <!-- DELETE BUTTON -->
    <ng-template #deleteButton let-multiArray="multiArray" let-index="index">
      @if (!groupItemIsDeleted(multiArray, index)) {
        <button mat-icon-button matTooltip="Eintrag löschen" (click)="deleteGroupItem(multiArray, index)"><mat-icon>delete</mat-icon></button>
      }
      @if (groupItemIsDeleted(multiArray, index)) {
        <button mat-icon-button matTooltip="Eintrag wiederherstellen" (click)="restoreGroupItem(multiArray, index)"><mat-icon>restore_from_trash</mat-icon></button>
      }
    </ng-template>
    <div class="form-group">
      <div class="form-group-title">
        <div>Kommentare @if (images.length > 1 && getMultiArray('comments').hasCommonItems()) {
          <em>(gemeinsame Einträge)</em>
        } </div>
        <div class="form-group-title-buttons">
          <ng-container *ngTemplateOutlet="resetButton; context: {formControlName: 'comments'}"></ng-container>
          @if (editable) {
            <button class="add-button" mat-icon-button (click)="getMultiArray('comments').addNewCommonItem()" matTooltip="Neuer Kommentar für alle ausgewählten Aufnahmen"><mat-icon>add</mat-icon></button>
          }
        </div>
      </div>
      <insc-multi-group #commentsGroup class="group" [editable]="editable" [multiRecordFormArray]="getMultiArray('comments')">
        <div *inscMultiGroupInnerControl="let formGroup; let last = last; let odd = odd; let index = index" [formGroup]="formGroup" [ngClass]="{'odd': odd}" class="entry-row">
          <div class="group-entry">
            <form class="group-form">
              <div class="group-entry-field">
                <div class="group-entry-field-label">Betreff</div>
                <mat-form-field class="group-entry-field-value">
                  <input matInput formControlName="subject">
                </mat-form-field>
              </div>
              <div class="group-entry-field">
                <div class="group-entry-field-label">Bemerkung</div>
                <mat-form-field class="group-entry-field-value">
                  <textarea matInput formControlName="content"></textarea>
                </mat-form-field>
              </div>
              <ng-container *ngTemplateOutlet="deleteOverlay; context: {multiArray: getMultiArray('comments'), index}"></ng-container>
            </form>
            <ng-container *ngTemplateOutlet="deleteButton; context: {multiArray: getMultiArray('comments'), index}"></ng-container>
          </div>
        </div>
        <div *inscMultiGroupReadonlyView="let formValue; let last = last; let odd = odd" [ngClass]="{'odd': odd}" class="entry-row">
          <div class="group-entry">
            <div class="group-view">
              <div class="group-entry-field">
                <div class="group-entry-field-label">Betreff</div>
                <div class="group-entry-field-value">{{formValue["subject"]}}</div>
              </div>
              <div class="group-entry-field">
                <div class="group-entry-field-label">Bemerkung</div>
                <div class="group-entry-field-value">{{formValue["content"]}}</div>
              </div>
            </div>
          <ng-container *ngTemplateOutlet="groupAccessoryTpl; context: {
          key: 'comments',
          value: formValue,
          multiControl: getMultiArray('comments')
        }"></ng-container>
          </div>
        </div>
      </insc-multi-group>
    </div>
    <div class="form-group">
      <div class="form-group-title">
        <div>Verwendungsnachweise @if (images.length > 1 && getMultiArray('literature_references').hasCommonItems()) {
          <em>(gemeinsame Einträge)</em>
        }</div>
        <div class="form-group-title-buttons">
          <ng-container *ngTemplateOutlet="resetButton; context: {formControlName: 'literature_references'}"></ng-container>
          @if (editable) {
            <button class="add-button" mat-icon-button (click)="getMultiArray('literature_references').addNewCommonItem()" matTooltip="Neuer Verwendungsnachweis für alle ausgewählten Aufnahmen"><mat-icon>add</mat-icon></button>
          }
        </div>
      </div>
      <insc-multi-group #literatureReferencesGroup class="group" [editable]="editable" [multiRecordFormArray]="getMultiArray('literature_references')">
        <div *inscMultiGroupInnerControl="let formGroup; let last = last; let odd = odd; let index = index" [formGroup]="formGroup" [ngClass]="{'odd': odd}" class="entry-row">
          <div class="group-entry">
            <form class="group-form">
              <div class="group-entry-field">
                <div class="group-entry-field-label">Literatur&shy;eintrag</div>
                <mat-form-field class="group-entry-field-value">
                  <input  matInput readonly [value]="formGroup.value['literature_entry']?.name">
                  <button mat-icon-button matSuffix matTooltip="Feld leeren"><mat-icon>close</mat-icon></button>
                  <button mat-icon-button matSuffix matTooltip="Im Register nachschlagen" (click)="chooseLiteratureEntry(formGroup)"><mat-icon>library_books</mat-icon></button>
                </mat-form-field>
              </div>
              <div class="group-entry-field">
                <div class="group-entry-field-label">Betreff</div>
                <mat-form-field class="group-entry-field-value">
                  <input matInput formControlName="ref_type">
                </mat-form-field>
              </div>
              <div class="group-entry-field">
                <div class="group-entry-field-label">Stelle</div>
                <mat-form-field class="group-entry-field-value">
                  <input matInput formControlName="reference">
                </mat-form-field>
              </div>
              <ng-container *ngTemplateOutlet="deleteOverlay; context: {multiArray: getMultiArray('literature_references'), index}"></ng-container>
            </form>
            <ng-container *ngTemplateOutlet="deleteButton; context: {multiArray: getMultiArray('literature_references'), index}"></ng-container>
          </div>
        </div>
        <div *inscMultiGroupReadonlyView="let formValue; let last = last; let odd = odd" [ngClass]="{'odd': odd}" class="entry-row">
          <div class="group-entry">
            <div class="group-view">
              <div class="group-entry-field">
                <div class="group-entry-field-label">Betreff</div>
                <div class="group-entry-field-value">{{formValue["ref_type"]}}</div>
              </div>
              <div class="group-entry-field">
                <div class="group-entry-field-label">Kurztitel</div>
                <div class="group-entry-field-value">
                  @if (formValue['literature_entry']?.url) {
                    <a target="_blank" [href]="formValue['literature_entry']?.url">{{ formValue['literature_entry']?.name }}</a>
                  } @else {
                    {{ formValue['literature_entry']?.name }}
                  }
                </div>
              </div>
              <div class="group-entry-field">
                <div class="group-entry-field-label">Stelle</div>
                <div class="group-entry-field-value">
                  {{ formValue["reference"] }}
                </div>
              </div>
              <div class="group-entry-field">
                <div class="group-entry-field-label">URN</div>
                <div class="group-entry-field-value">
                  {{ formValue['literature_entry']?.urn }}
                </div>
              </div>
            </div>
          <ng-container *ngTemplateOutlet="groupAccessoryTpl; context: {
          key: 'literature_references',
          value: formValue,
          multiControl: getMultiArray('literature_references')
        }"></ng-container>
          </div>
        </div>
      </insc-multi-group>
    </div>
  </div>
}
