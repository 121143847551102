import { AfterContentInit, ContentChildren, Directive, EventEmitter, Input, OnDestroy, Output, QueryList } from "@angular/core"
import { combineLatest, merge, Observable, Subject } from "rxjs"
import { delay, map, scan, startWith, switchMap, takeUntil } from "rxjs/operators"
import { QueryParams } from "../../services/data.service"
import { PageChangeEvent, PaginatorComponent } from "./paginator/paginator.component"
import { CriteriumDescription, SearchControlDirective, SearchControlParamsChangeEvent } from "./search-controls/search-control.directive"
import { SortChangeEvent, SortControlComponent } from "./sort-control/sort-control.component"

export class ResetEvent {
  constructor() {}
}

export type SearchChangeEvent = SearchControlParamsChangeEvent | PageChangeEvent | SortChangeEvent | ResetEvent

@Directive({
  selector: '[inscSearchController]',
  exportAs: 'inscSearchController'
})
export class SearchControllerDirective implements AfterContentInit, OnDestroy {

  @Input() queryParams: QueryParams
  @Output() queryParamsChange = new EventEmitter<QueryParams>()

  @ContentChildren(SearchControlDirective, {descendants: true}) searchControlDirectives: QueryList<SearchControlDirective>
  @ContentChildren(PaginatorComponent, {descendants: true}) paginators: QueryList<PaginatorComponent>
  @ContentChildren(SortControlComponent, {descendants: true}) sortControls: QueryList<SortControlComponent>

  private resetEvents = new Subject<ResetEvent>()

  /**
   * an observable that emits {@link CriteriumDescription}s
   * for the chip list. Initialized in {@link ngOnInit}
   */
  currentCriteria$: Observable<CriteriumDescription[]>

  private unsubscribe$ = new Subject<void>()

  constructor() { }

  /**
   * Initialization tasks that are dependent on ContentChildren.
   *
   * Observables created in this change detection hook that are suscribed to in the template
   * using the `async` pipe need to wait for the next change detection cycle to avoid
   * `ExpressionChangedAfterItHasBeenCheckedError`s.
   *
   * This is done using the `delay(0)` operator. For a thorough explanation see
   * {@link https://blog.angular-university.io/angular-debugging/}.
   */
  ngAfterContentInit(): void {

    // subscribe to parameter changes from user interactions with search controls
    const searchControlParamChanges = this.searchControlDirectives.changes.pipe(
      startWith(this.searchControlDirectives),

      // collect all param changes from search controls resulting from user interaction with them
      switchMap(() =>
        merge(
          ...this.searchControlDirectives.map(control => control.paramChange$)
        )
      )
    )

    const pageParamChanges = merge(...this.paginators.map(paginator => paginator.pageChanges))
    const sortParamChanges = merge(...this.sortControls.map(sortControl => sortControl.sortByChanges))

    const allParamChanges = merge(searchControlParamChanges, pageParamChanges, sortParamChanges, this.resetEvents)

    allParamChanges.pipe(
      takeUntil(this.unsubscribe$),
      // compute the new query params from the previous query params and the current change event
      // rxjs `scan` works similar to `reduce` for arrays.
      scan((previousParams: QueryParams, changeEvent: SearchChangeEvent) =>
        this.buildQueryParamsFromParamChange(previousParams, changeEvent), this.queryParams
      )
    ).subscribe(this.queryParamsChange)

    // initialize the {@link currentCriteria$} observable
    this.currentCriteria$ = this.collectCriteriumDescriptionsFromSearchControls().pipe(
      delay(0)
    )
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  removeCriterium(criterium: CriteriumDescription): void {
    this.searchControlDirectives
      ?.find(control => control.inscSearchControl === criterium.name)
      ?.remove(criterium)
  }

  resetCriteria() {
    this.resetEvents.next(new ResetEvent())
  }


  /**
   * Computes new query params based on the current query params (that can be empty or from a previous search)
   * and an event describing a param change comping from a search control or sort/page controls.
   * Depending on the type of event, some or all previous parameters are reset or merged/re-used.
   *
   * @param previousParams the previous QueryParams
   * @param changeEvent the current search event as the basis for the new query params
   * @returns {QueryParams}
   */
  private buildQueryParamsFromParamChange(previousParams: QueryParams, changeEvent: SearchChangeEvent): QueryParams {
    let newParams: QueryParams


    if (changeEvent instanceof SearchControlParamsChangeEvent) {
      // when one of the search controls changes, keep the params from the other fields
      // but reset pageSize, page and sort_by (by not setting them)
      const changedParams = changeEvent.change
      newParams = {
        ...previousParams,
        ...changedParams,
        sort_by: null,
        page: null,
        page_size: null
      }

    } else if (changeEvent instanceof SortChangeEvent) {
      // when sort changes, keep all other params
      newParams = {
        ...previousParams,
        sort_by: changeEvent.sortBy
      }
    } else if (changeEvent instanceof PageChangeEvent) {
      // when page options change, keep all other params
      newParams = {
        ...previousParams,
        page:      changeEvent.page,
        page_size: changeEvent.pageSize
      }
    } else if (changeEvent instanceof ResetEvent) {
      const { page, page_size, sort_by } = previousParams
      newParams = {page, page_size, sort_by}
    }

    // clean blank values
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const cleanedParams = Object.fromEntries(
      Object.entries(newParams).filter(([_, value]) => value != null )
    ) as QueryParams
    return cleanedParams
  }

  // private getChangedOrCurrentValue<T extends keyof QueryParams>(currentParams: Partial<QueryParams>, changedParams: Partial<QueryParams>, field: T) {
  //   if (changedParams?.hasOwnProperty(field)) {
  //     return changedParams[field]
  //   } else {
  //     return currentParams?.[field]
  //   }
  // }

  /**
   * Creates an observable by combining all current criteriaChange$ from all search controls.
   *
   * @returns {Observable<CriteriumDescription[]>} an observable continously emitting current
   * search criteriaChange$.
   */
  private collectCriteriumDescriptionsFromSearchControls(): Observable<CriteriumDescription[]> {

    /**
     * Function that collects all search controls, combines their {@link SearchControlDirective.criteria$}
     * emittances and maps them to a flat array.
     */
    const subscribeToCombinedCritera: () => Observable<CriteriumDescription[]> = () => combineLatest(
      this.searchControlDirectives.map(ctrl => ctrl.criteria$)
    ).pipe(
      map(controlCriteria => [].concat(...controlCriteria) as CriteriumDescription[]),
      // unsubscribe when the search controls changes, making this observable obsolete
      takeUntil(this.searchControlDirectives.changes)
    )

    // displayed search controls can change according to the current record type.
    // every time the they change, re-collect them and re-susbcribe to their criteriaChange$.
    return this.searchControlDirectives.changes.pipe(
      // the ViewChildren QueryLists changes event does emit when initially set, so get this observable started
      // manually
      startWith(this.searchControlDirectives),
      switchMap(subscribeToCombinedCritera)
    )
  }

}
