<h1 matDialogTitle>
  <mat-icon class="provider-icon" [svgIcon]="data.providerId"></mat-icon>
  Normdaten nachschlagen
</h1>

<insc-external-norm-data-lookup-tool
  matDialogContent
  [dataMapper]="data.mapper"
  [subformComponentType]="data.formType"
  [providerId]="data.providerId"
  [initialData]="data.initialData"
  [subformComponentInputs]="data.formComponentInputs"
  [fixedData]="data.fixedData"
  [lookupOptions]="data.lookupOptions"
></insc-external-norm-data-lookup-tool>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]>Abbrechen</button>
  <button mat-button color="primary" (click)="close()">Übernehmen</button>
</mat-dialog-actions>

