import { AsyncPipe, CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { MatRippleModule } from "@angular/material/core"
import { MatIconModule } from "@angular/material/icon"
import { MatButtonModule } from "@angular/material/button"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatDialogModule } from "@angular/material/dialog"
import { MatMenuModule } from "@angular/material/menu"
import { MatTabsModule } from "@angular/material/tabs"
import { MatTooltipModule } from "@angular/material/tooltip"
import { RouterLink, RouterModule } from "@angular/router"
import { ImageViewerModule } from "../../../shared/components/image-viewer/image-viewer.module"
import { RecordPickerComponent } from "../../../shared/components/record-picker/record-picker.component"
import { FormatBytesPipe } from "../../../shared/pipes/format-bytes.pipe"
import { ImageMetadataEditorsModule } from "../image-metadata-editors/image-metadata-editors.module"
import { ImageDownloadMenuComponent } from "../shared/components/image-download-menu.component"
import { ImageSidebarComponent } from "./image-sidebar.component"

@NgModule({
  declarations: [
    ImageSidebarComponent
  ],
  exports: [
    ImageSidebarComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    RouterLink,
    ImageViewerModule,
    MatTabsModule,
    ImageMetadataEditorsModule,
    MatRippleModule,
    MatButtonModule,
    FormatBytesPipe,
    ImageDownloadMenuComponent

  ]
})
export class ImageSidebarModule { }
