import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"

import { EMPTY, Observable, of, Subject, throwError } from "rxjs"
import { catchError, switchMap, tap } from "rxjs/operators"
import { LoginDialogService } from "../../features/user-management/login-dialog/login-dialog.service"
import { AuthService } from "../../services/auth.service"
import { NotAuthorizedError } from "../../services/errors"

import { inscApiOnly } from "./insc-api-only.decorator"

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  // TODO: Implement actual token refresh without re-supplying credentials
  private _tokenRefreshedSubject = new Subject<boolean>()
  get tokenRefresh() {
    this._tokenRefreshedSubject.subscribe({
      complete: () => this._tokenRefreshedSubject = new Subject<boolean>()
    })

    // if this is the first observer
    if (this._tokenRefreshedSubject.observers.length === 1) {

      // login dialog returns true when logged in successfully, null when closed without login
      this.loginDialog.open("Sitzung abgelaufen, bitte erneut einloggen.")
        .pipe(tap(loggedIn => !loggedIn && this.auth.logout()))
        .subscribe(this._tokenRefreshedSubject)
    }

    return this._tokenRefreshedSubject
  }


  constructor(private auth: AuthService, private loginDialog: LoginDialogService) { }

  private addAuthToken(request: HttpRequest<any>) {
    const authToken = this.auth.getAuthToken()
    if (!authToken) {
      return request
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`
      }
    })
  }

  @inscApiOnly()
  intercept(request: HttpRequest<any>, next: HttpHandler) {

    const currentUser = this.auth.getCurrrentUser()
    return next.handle(this.addAuthToken(request)).pipe(

      // handle re-auth on token expiration
      catchError(err => {
        if (err instanceof NotAuthorizedError) {
          return this.tokenRefresh.pipe(
            switchMap(
              loggedIn => loggedIn
                ? next.handle(this.addAuthToken(request))
                : of(EMPTY)
            )
          )
        }

        return throwError(err)
      }),
      // handle user data update on server (roles etc.)
      switchMap( (origResp: HttpEvent<any>) => new Observable<HttpEvent<any>>(subscriber => {
        if (origResp instanceof HttpResponse) {
          if (
            currentUser != null
            && new Date(currentUser.updated_at) < new Date(origResp.headers.get("X-User-Last-Updated-At"))
          ) {
            this.auth.reloadUserData().subscribe(() => {
              subscriber.next(origResp)
              subscriber.complete()
            })
          } else {
            subscriber.next(origResp)
            subscriber.complete()
          }
        } else {
          subscriber.next(origResp)
        }
      }))
    )
  }
}
