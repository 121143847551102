import { AfterContentInit, Component, ElementRef, Input, QueryList, SimpleChanges, ViewChild, ViewChildren } from "@angular/core"
import { Observable, of, ReplaySubject } from "rxjs"
import { catchError, delay, finalize, startWith, switchMap, tap } from "rxjs/operators"
import { ImageService, InscObjectService, QueryParams } from "../../../services/data.service"
import { ValidationError } from "../../../services/errors"
import { FormService } from "../../../services/form.service"

import { NormDataService } from "../../../services/norm-data.service"
import { ReusableDialogsService } from "../../../shared/dialogs/reusable-dialogs.service"
import { NormDataEntry } from "../../../shared/models"
import { SnackbarService } from "../../../shared/snackbars/snackbar.service"
import { SubformComponent } from "../../../shared/subform.component"
import { RegisterEditorBaseAbstractComponent } from "../register-editor-base-abstract.component"
import {
  ExternalNormDataLookupFormAction
} from "../shared/external-norm-data/external-norm-data-id-input/external-norm-data-id-input.component"
import {
  ExternalNormDataLookupDialogData,
  ExternalNormDataLookupDialogService
} from "../shared/external-norm-data/external-norm-data-lookup-dialog/external-norm-data-lookup-dialog.component"
import { ExternalNormDataMapperInterface } from "../shared/external-norm-data/external-norm-data-mapper.interface"
import {
  GermaniaSacraMonasteryToInscHistoricalOrganizationMapper
} from "../shared/external-norm-data/external-norm-data-providers/germania-sacra/germania-sacra-monastery-to-insc-historical-organization.mapper"
import {
  GermaniaSacraPersonToInscHistoricalPersonMapper
} from "../shared/external-norm-data/external-norm-data-providers/germania-sacra/germania-sacra-person-to-insc-historical-person.mapper"
import {
  GndPersonToInscHistoricalPersonMapper
} from "../shared/external-norm-data/external-norm-data-providers/gnd/gnd-person-to-insc-historical-person.mapper"
import {
  WikidataEntityToInscHistoricalPersonMapper
} from "../shared/external-norm-data/external-norm-data-providers/wikidata/wikidata-entity-to-insc-historical-person.mapper"
import { AssociatedRecordQuery } from "../shared/register-editor-layout/associated-record-view/associated-record-view.component"
import { RegisterEditorLayoutComponent } from "../shared/register-editor-layout/register-editor-layout.component"
import { RegisterEntryMetadata } from "../shared/register-editor-layout/register-entry-metadata-view/register-entry-metadata-view.component"
import { HistoricalOrganizationEntryFormComponent } from "./forms/historical-organization-entry-form.component"
import { HistoricalPersonEntryFormComponent } from "./forms/historical-person-entry-form.component"
import { LicenseFormComponent } from "./forms/license-form.component"
import { LiteratureEntryFormComponent } from "./forms/literature-entry-form.component"
import { OrganizationEntryFormComponent } from "./forms/organization-entry-form.component"
import { PersonEntryFormComponent } from "./forms/person-entry-form.component"

@Component({
  selector:    'insc-basic-register-editor',
  templateUrl: './basic-register-editor.component.html',
  styleUrls:   ['./basic-register-editor.component.scss']
})
export class BasicRegisterEditorComponent extends RegisterEditorBaseAbstractComponent implements AfterContentInit {

  entries: Observable<NormDataEntry[]>

  searchResultsLoading = false

  metadata: RegisterEntryMetadata

  relatedImagesSubject = new ReplaySubject<QueryParams>(1)
  associatedQueries: AssociatedRecordQuery[] = []

  @ViewChild(RegisterEditorLayoutComponent) registerEditorLayout: RegisterEditorLayoutComponent
  @ViewChild('entryList', { read: ElementRef }) entryListRef: ElementRef
  @ViewChildren('listEntries', {read: ElementRef}) listEntryRefs: QueryList<ElementRef>
  @ViewChild('creationEntry', { read: ElementRef }) creationEntryRef: ElementRef

  creationMode: boolean

  protected selectedEntry: NormDataEntry

  protected isSaving = false


  @Input() selectionHandler = (id: string): void => {
    if (this.formDirty) {
      this.reusableDialogsService.openUnsavedChangesConfirmationDialog().afterClosed().subscribe(
        result => result === true && this.selectId(id)
      )
    } else {
      this.selectId(id)
    }
  }



  constructor(
    private normDataService: NormDataService,
    private snackbarService: SnackbarService,
    private reusableDialogsService: ReusableDialogsService,
    private formService: FormService,
    private lookupDialog: ExternalNormDataLookupDialogService,
    private gndPersonMapper: GndPersonToInscHistoricalPersonMapper,
    private wikidataPersonMapper: WikidataEntityToInscHistoricalPersonMapper,
    private germaniaSacraPersonMapper: GermaniaSacraPersonToInscHistoricalPersonMapper,
    private germaniaSacraMonasteryMapper: GermaniaSacraMonasteryToInscHistoricalOrganizationMapper,
    private imageService: ImageService,
    private inscObjectService: InscObjectService
  ) { super() }

  getSelectedEntry(): NormDataEntry {
    return this.selectedEntry
  }

  ngOnChangesBefore(changes: SimpleChanges): void {

    if ("type" in changes && this.type != null) {
      this.associatedQueries = this.getAssociatedQueries(this.type)

      if (!changes["type"].isFirstChange()) {
        if (this.selectedId) {
          this.selectId(null)
        }
        this.reloadEntries()
      }
    }

    if ("selectedId" in changes) {
      if (!changes["selectedId"].isFirstChange()) {
        this.selectId(this.selectedId)
      }
    }
  }


  ngAfterContentInit(): void {

    setTimeout(() => {
      this.form = null
      this.entries = this.registerEditorLayout.searchChanges.pipe(
        startWith(""),
        tap(() => this.searchResultsLoading = true),
        switchMap((queryString) => this.normDataService.getEntries(this.type, queryString).pipe(
          catchError(() => {
            return of(null)
          }),
        )),
        tap(() => this.searchResultsLoading = false),

        // there seems to be a timing issue with scroll into view with Chrome and sometimes
        // with Firefox when the editor is opened in a dialog for the first time
        delay(300),

        tap(() => setTimeout(() => this.scrollIdIntoView(this.selectedId)))
      )
      this.selectId(this.selectedId)
    })
  }

  selectId(id: string): void {
    this.creationMode = false

    if (id == null || id.length === 0) {
      this.selectedId = null
      this.form = null
      this.selectedEntry = null
    } else {
      this.selectedId = id
      this.normDataService.getEntry(this.type, id).subscribe(entry => this.setEntry(entry))
    }
  }

  private setEntry(entry: NormDataEntry): void {
    const formComponent = this.getForm(this.type)
    this.form = formComponent.buildFormGroup(entry)
    this.checkAllowEdit()

    this.metadata = this.getMetadata(entry)
    this.selectedEntry = entry

    this.scrollIdIntoView(entry.id)

    this.relatedImagesSubject.next({filters: {
      "photographer_id": [entry.id]
    }})
  }

  save(): void {
    const formValue = this.form.getRawValue() as NormDataEntry
    const isNew = !this.selectedId

    const action =
      isNew
        ? this.normDataService.saveEntry(this.type, formValue)
        : this.normDataService.updateEntry(this.type, formValue)

    this.isSaving = true
    this.form.disable()

    action.pipe(
      finalize(() => {
        this.isSaving = false
        this.form.enable()
      })
    ).subscribe({
      next: entry => {
        this.creationMode = false

        this.setEntry(entry)
        if (isNew) {
          this.snackbarService.showSuccessWithMessage("Eintrag angelegt")
        } else {
          this.snackbarService.showSuccessWithMessage("Eintrag gespeichert")
        }

        this.reloadEntries()

        this.scrollIdIntoView(entry.id)
        this.selectId(entry.id)
      },
      error: (error: unknown) => {
        if (error instanceof ValidationError) {
          this.formService.applyValidationErrors(this.form, error.field_errors)
        }
      }
    })
  }

  delete(): void {
    this.normDataService.deleteEntry(this.type, this.selectedId).subscribe(() => {
      this.selectedId = null
      this.form = null

      this.reloadEntries()

      this.snackbarService.showSuccessWithMessage("Eintrag gelöscht")
    })
  }

  create(initialData: Partial<NormDataEntry> = {}): void {
    this.creationMode = true
    this.selectedId = null
    this.form = this.getForm(this.type).buildFormGroup(initialData)
    setTimeout(() => this.scrollElemIntoView(this.creationEntryRef))
    if (initialData) {
      this.form.markAsDirty()
    }
  }

  createByLookup(providerId: string, lookupOptions: Record<string, string> = {}): void {
    this.lookup(providerId, null, lookupOptions).subscribe(result => result && this.create(result))
  }

  onFormExternalAction(externalAction: unknown): void {
    if (externalAction instanceof ExternalNormDataLookupFormAction) {
      this.lookup(externalAction.providerId, this.form.getRawValue() as NormDataEntry, externalAction.lookupOptions).subscribe(result => {
        if (result) {
          this.form = this.getForm(this.type).buildFormGroup(result)
          this.form.markAsDirty()
        }
      })
    }
  }

  private lookup(providerId: string, initialData: Partial<NormDataEntry> = null, lookupOptions: Record<string, string> = {}) {
    const mappers: Record<string, ExternalNormDataMapperInterface<unknown, unknown>> = {
      gnd: this.gndPersonMapper,
      wikidata: this.wikidataPersonMapper,
      germania_sacra: this.germaniaSacraPersonMapper,
      germania_sacra_monasteries: this.germaniaSacraMonasteryMapper
    }

    if (!mappers[providerId]) {
      throw new Error(`No mapper defined for providerId ${providerId}`)
    }

    const lookupParams = {
      historical_persons: {
        formType: HistoricalPersonEntryFormComponent,
      },
      historical_organizations: {
        formType: HistoricalOrganizationEntryFormComponent
      }
    }

    return this.lookupDialog.open<Partial<NormDataEntry>>({
      ...lookupParams[this.type],
      providerId: providerId,
      initialData: initialData ?? null,
      fixedData: {id: initialData?.id},
      mapper: mappers[providerId],
      lookupOptions
    } as ExternalNormDataLookupDialogData<Partial<NormDataEntry>>).afterClosed()
  }

  cancelCreate(): void {
    this.creationMode = false
    this.form = null
  }

  scrollIdIntoView(id: string): void {

    // do nothing if the entry list is not initialized yet
    if (!this.listEntryRefs) {
      return
    }

    const entryElem = this.listEntryRefs.find(entryRef => (entryRef.nativeElement as HTMLElement).dataset["id"] == id)
    if (entryElem) {
      this.scrollElemIntoView(entryElem)
    }
  }

  scrollElemIntoView(elem: ElementRef): void {
    const nativeEntryElem = elem.nativeElement as HTMLElement
    const entryListElem = this.entryListRef.nativeElement as HTMLElement

    const entryRect = nativeEntryElem.getBoundingClientRect()
    const listRect = entryListElem.getBoundingClientRect()

    if (entryRect.bottom > listRect.bottom || entryRect.top < listRect.top) {
      nativeEntryElem.scrollIntoView({behavior: "smooth", block: "nearest", inline: "center"})
    }
  }

  private reloadEntries() {
    this.registerEditorLayout.resetSearch()
  }


  getForm(type: string): typeof SubformComponent {
    switch (type) {
      case "persons": return PersonEntryFormComponent
      case "organizations": return OrganizationEntryFormComponent
      case "historical_persons": return HistoricalPersonEntryFormComponent
      case "historical_organizations": return HistoricalOrganizationEntryFormComponent
      case "licenses": return LicenseFormComponent
      case "literature_entries": return LiteratureEntryFormComponent
      default: throw new Error("Invalid: " + type)
    }
  }

  getAssociatedQueries(type: string): AssociatedRecordQuery[] {
    switch (type) {
      case "persons":
        return [{
          title:            "Aufnahmen als Fotograf/-in",
          getSearchResults: (queryParams) => this.imageService.all(queryParams),
          overviewRoute:    "images",
          getQueryParams:   (entry) => ({
            filters: {
              "photographer.id": [entry.id]
            }
          }),
        }, {
          title:            "Aufnahmen als Rechteinhaber/-in",
          getSearchResults: (queryParams) => this.imageService.all(queryParams),
          overviewRoute:    "images",
          getQueryParams:   (entry) => ({
            filters: {
              "copyright_holder.id": [entry.id],
              "copyright_holder.type": ["person"]
            }
          })
        },
          {
            title:            "Aufnahmen als Bearbeiter/-in",
            getSearchResults: (queryParams) => this.imageService.all(queryParams),
            overviewRoute:    "images",
            getQueryParams:   (entry) => ({
              filters: {
                editor_ids: [entry.id]
              }
            })
          }]
      case "organizations": return [{
        title:            "Aufnahmen",
        getSearchResults: (queryParams) => this.imageService.all(queryParams),
        overviewRoute:    "images",
        getQueryParams:   (entry) => ({
          filters: {
            "copyright_holder.id": [entry.id],
            "copyright_holder.type": ["organization"]
          }
        })
      }]
      case "historical_persons": return [{
        title:            "Inschriftenträger",
        getSearchResults: (queryParams) => this.inscObjectService.all(queryParams),
        overviewRoute:    "objects",
        getQueryParams:   (entry) => ({
          filters: {
            "person_organizations.person_id": [entry.id]
          }
        })
      }]
      case "historical_organizations": return [{
        title:            "Inschriftenträger",
        getSearchResults: (queryParams) => this.inscObjectService.all(queryParams),
        overviewRoute:    "objects",
        getQueryParams:   (entry) => ({
          filters: {
            "person_organizations.organization_id": [entry.id]
          }
        })
      }]
      case "licenses": return [{
        title:            "Aufnahmen",
        getSearchResults: (queryParams) => this.imageService.all(queryParams),
        overviewRoute:    "images",
        getQueryParams:   (entry) => ({
          filters: {
            "license.id": [entry.id]
          }
        })
      }]
      case "literature_entries": return [{
        title:            "Inschriftenträger",
        getSearchResults: (queryParams) => this.inscObjectService.all(queryParams),
        overviewRoute:    "objects",
        getQueryParams:   (entry) => ({
          filters: {
            "literature_references.literature_entry.id": [entry.id]
          }
        })
      }, {
        title:            "Aufnahmen",
        getSearchResults: (queryParams) => this.imageService.all(queryParams),
        overviewRoute:    "objects",
        getQueryParams:   (entry) => ({
          filters: {
            "literature_references.literature_entry.id": [entry.id]
          }
        })
      }]
      default: throw new Error("Invalid: " + type)
    }
  }

  getRecordTypeForActivityLink(type: string): string {
    switch (type) {
      case "persons": return "Person"
      case "organizations": return "Organization"
      case "historical_persons": return "HistoricalPerson"
      case "historical_organizations": return "HistoricalOrganization"
      case "literature_entries": return "LiteratureEntry"
      case "license": return "License"
    }
  }
}
