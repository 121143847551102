import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling"
import {
  AfterContentInit,
  Component,
  DestroyRef,
  Directive,
  HostBinding,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from "@angular/core"
import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { ReplaySubject } from "rxjs"
import { QueryParams, QueryResult } from "../../../../../../services/data.service"
import { InscEntity } from "../../../../../../shared/models/entity.model"
import { NormDataEntry } from "../../../../../../shared/models/norm-data-entry.model"
import { GetSearchResultsFunc, VirtualScrollSearchDataSource } from "../../../../../../shared/search/virtual-scroll-search.data-source"

interface AssociatedRecordResultContext {
  $implicit: QueryResult<InscEntity>
}

@Directive({
  selector: 'ng-template[associatedRecordResult]',
})
export class AssociatedRecordResultDirective {
  static ngTemplateContextGuard(
    _dir: AssociatedRecordResultDirective,
    _ctx: unknown
  ): _ctx is AssociatedRecordResultContext {
    return true;
  }
}

@Component({
  selector:    "insc-associated-record-list",
  templateUrl: "./associated-record-list.component.html",
  styleUrls:   ["./associated-record-list.component.scss"]
})
export class AssociatedRecordListComponent implements AfterContentInit, OnChanges {

  @Input() entry: NormDataEntry
  @Input({required: true}) title: string
  @Input({required: true}) getQueryParams: (entry: NormDataEntry) => QueryParams
  @Input({required: true}) getSearchResults: GetSearchResultsFunc<InscEntity>
  @Input({required: true}) overviewRoute: string
  @Input({required: true}) resultTemplate: TemplateRef<AssociatedRecordResultContext>

  @Input() canCollapse: boolean = false;
  @Input() collapsed: boolean = false;

  protected dataSource: VirtualScrollSearchDataSource<InscEntity>
  protected readonly queryParamsSubject = new ReplaySubject<QueryParams>(1)

  @ViewChild(CdkVirtualScrollViewport) virtualScrollViewport: CdkVirtualScrollViewport

  @HostBinding("style.flex") get flex() { return this.collapsed ? "0" : "1"}

  protected currentResults = 0
  protected collapsible = false

  private destroyRef = inject(DestroyRef)


  ngAfterContentInit() {
    this.collapsible = this.canCollapse

    this.dataSource = new VirtualScrollSearchDataSource<InscEntity>(
      (queryParams) => this.getSearchResults(queryParams),
      this.queryParamsSubject.asObservable(),
      () => this.virtualScrollViewport?.getRenderedRange()
    )

    this.dataSource.searchResults$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(
      searchResults => {
        this.currentResults = searchResults.count
        this.collapsed = searchResults.count === 0
        this.collapsible = this.canCollapse && searchResults.count > 0
      }
    )
  }


  ngOnChanges(changes: SimpleChanges) {
    if ('entry' in changes) {
      if (this.entry) {
        this.queryParamsSubject.next(this.getQueryParams(this.entry))
      }
    }
  }

  headerClick() {
    if (this.collapsible) {
      this.collapsed = !this.collapsed
    }
  }

  overviewQueryParams(): { q: string } {
    return {q: JSON.stringify(this.getQueryParams(this.entry))}
  }
}

