import { SelectionModel } from "@angular/cdk/collections"
import { CdkVirtualScrollViewport, ScrollingModule } from "@angular/cdk/scrolling"
import { AsyncPipe, NgTemplateOutlet } from "@angular/common"
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from "@angular/core"
import { MatDividerModule } from "@angular/material/divider"
import { MatListModule, MatSelectionList, MatSelectionListChange } from "@angular/material/list"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { BehaviorSubject, Observable } from "rxjs"
import { map } from "rxjs/operators"
import { QueryParams, QueryQuickFilterParams, QueryResult, QueryResults } from "../../../services/data.service"
import { InscEntity } from "../../models/entity.model"
import { HighlightPipe } from "../../pipes/highlight.pipe"
import { SearchModule } from "../../search/search.module"
import { VirtualScrollSearchDataSource } from "../../search/virtual-scroll-search.data-source"

export type RecordPickerSelection<T extends InscEntity> = T["id"]
export type ExtraQueryParams = Omit<QueryParams, "page" | "page_size">

const DEFAULT_QUICK_FILTER: keyof QueryQuickFilterParams = "name_quick_filter"

@Component({
  standalone:  true,
  selector:    "insc-record-picker",
  templateUrl: "./record-picker.component.html",
  styleUrls:   ["./record-picker.component.scss"],
  imports: [
    SearchModule,
    MatDividerModule,
    ScrollingModule,
    MatListModule,
    MatProgressSpinnerModule,
    HighlightPipe,
    AsyncPipe,
    NgTemplateOutlet
  ],
})
export class RecordPickerComponent<T extends InscEntity> implements OnInit, OnChanges {

  @Input() getSearchResults: (queryParams: QueryParams) => Observable<QueryResults<T>>

  @Input() selectedRecords: RecordPickerSelection<T>[] = []
  @Output() selectedRecordsChange = new EventEmitter<RecordPickerSelection<T>[]>()

  @Input() extraQueryParams: ExtraQueryParams = {}

  @Input() multiple = false
  @Input() disabled = false

  @Input() subtitleTemplate: TemplateRef<{ $implicit: QueryResult<T>, query?: string }>
  @Input({transform: (value: string) => value ?? DEFAULT_QUICK_FILTER}) quickFilter: keyof QueryQuickFilterParams

  @ViewChild(CdkVirtualScrollViewport) virtualScrollViewport: CdkVirtualScrollViewport
  @ViewChild(MatSelectionList) selectionList: MatSelectionList

  queryParamsSubject = new BehaviorSubject<QueryParams>({})
  queryParams = this.queryParamsSubject.asObservable().pipe(
    map(queryParams => ({...this.extraQueryParams, ...queryParams}))
  )

  dataSource: VirtualScrollSearchDataSource<T>

  selectionModel: SelectionModel<RecordPickerSelection<T>>

  ngOnInit(): void {
    this.selectionModel = new SelectionModel(this.multiple)
    this.selectionModel.select(...this.selectedRecords)

    this.selectionModel.changed.subscribe(() => {
      this.selectedRecords = this.selectionModel.selected
      this.selectedRecordsChange.emit(this.selectedRecords)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {

    if ('getSearchResults' in changes || 'selectedRecords' in changes || 'extraQueryParams' in changes) {
      this.queryParamsSubject.next(this.queryParamsSubject.value)
    }

    if ('getSearchResults' in changes) {
      this.dataSource = new VirtualScrollSearchDataSource(
        this.getSearchResults,
        this.queryParams,
        () => this.virtualScrollViewport?.getRenderedRange()
      )
    }

    if ('selectedRecords' in changes) {
      this.selectionModel?.clear()
      this.selectionModel?.select(...this.selectedRecords)
    }
  }

  onQueryParamsChange(params: QueryParams): void {
    this.queryParamsSubject.next(params)
  }

  isSelected(record: T): boolean {
    if (!record) {
      return false
    }

    return this.selectionModel.isSelected(record.id)
  }


  onSelectionListSelectionChange(event: MatSelectionListChange) {
    const selectedOptions = event.options.filter(option => option.selected)
    const deselectedOptions = event.options.filter(option => !option.selected)

    this.selectionModel.select(...selectedOptions.map(option => option.value.id))
    this.selectionModel.deselect(...deselectedOptions.map(option => option.value.id))

  }
}
