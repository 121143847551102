import { Injectable } from "@angular/core"
import Pica from "pica"
import { from, Observable } from "rxjs"
import { map } from "rxjs/operators"
import * as UTIF from "utif"

@Injectable({
  providedIn: 'root'
})
export class ImageProcessorService {

  tifToCanvas(file: File): Observable<HTMLCanvasElement> {
    return from(file.arrayBuffer()).pipe(
      map(arrayBuffer => {

        const ifds = UTIF.decode(arrayBuffer)
        const ifd = ifds[0]
        UTIF.decodeImage(arrayBuffer, ifd)
        const rgba = UTIF.toRGBA8(ifd)
        const canvas = document.createElement("canvas")
        canvas.width = ifd.width
        canvas.height = ifd.height
        const renderingContext = canvas.getContext("2d")
        const imageData = renderingContext.createImageData(ifd.width, ifd.height)
        for (let i = 0; i < rgba.length; i++) {
          imageData.data[i] = rgba[i]
        }
        renderingContext.putImageData(imageData, 0, 0)

        return canvas
      })
    )
  }

  resize(canvas: HTMLCanvasElement, targetWidth, targetHeight) {
    const widthFactor = targetWidth / canvas.width
    const heightFactor = targetHeight / canvas.height
    const scaleFactor = Math.min(widthFactor, heightFactor)
    const newWidth = canvas.width * scaleFactor
    const newHeight = canvas.height * scaleFactor
    const scaledCanvas = document.createElement("canvas")
    scaledCanvas.width = newWidth
    scaledCanvas.height = newHeight
    const resizer = new Pica({
      tile: 2048
    })

    return from(resizer.resize(canvas, scaledCanvas))
  }

  constructor() { }
}
