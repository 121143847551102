<h2 mat-dialog-title class="link-result-heading">Bestehende Objektgruppe zuweisen</h2>
<mat-dialog-content class="content">

  <mat-form-field appearance="outline">
    <mat-icon matPrefix>search</mat-icon>
    <input matInput  [(ngModel)]="filterValue">
    <mat-label>Filter</mat-label>
  </mat-form-field>

  <form class="object-group-list" [formGroup]="selectObjectGroupForm">
    <mat-list>
      <mat-radio-group name="results" formControlName="object_group_id">
        <mat-list-item>
          <mat-radio-button [value]="null"><span><em>Keine Objektgruppe zuordnen</em></span></mat-radio-button>
        </mat-list-item>
        <mat-divider></mat-divider>
        @for (result of results$ | async | valueFilter: {pattern: filterValue, filterByProp: 'name'}; track result) {
          <mat-list-item >
            <mat-radio-button [value]="result.original.id">
              <div [innerHtml]="result.highlighted.name"></div>
              <div class="object-group-type">
                @switch (result.original['object_group_type']) {
                  @case ('fragments') {
                    <span>Fragmente</span>
                  }
                  @case ('cycle') {
                    <span>Zyklus</span>
                  }
                  @case ('ensemble') {
                    <span>Ensemble</span>
                  }
                }
              </div>
            </mat-radio-button>
          </mat-list-item>
        }
      </mat-radio-group>
    </mat-list>
  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="dialogRef.close()">
    Abbrechen
  </button>
  <button mat-button (click)="confirmSelection()" color="primary">
    Speichern
  </button>
</mat-dialog-actions>
