import { AsyncPipe } from "@angular/common";
import { AfterViewInit, Component, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from "@angular/core"
import { ControlValueAccessor, NgControl, ReactiveFormsModule, UntypedFormControl } from "@angular/forms"
import { MatAutocompleteModule } from "@angular/material/autocomplete"
import { ErrorStateMatcher } from "@angular/material/core"
import { MatInput, MatInputModule } from "@angular/material/input"
import { Observable, Subject } from "rxjs"

import { map, takeUntil } from "rxjs/operators"
import { AutocompleteService, AutocompleteType } from "../../../services/autocomplete.service"
import { FacetResult } from "../../../services/data.service"
import { ValueFilterPipe } from "../../pipes/value-filter.pipe"

@Component({
  standalone: true,
  selector:   "insc-autocomplete-textfield",
  template:   `
                  <mat-form-field class="full-width">
                    <input matInput [formControl]="inputCtrl"
                      [placeholder]="label"
                      [matAutocomplete]="auto">
                    <mat-error>{{ ngControl.errors?.valueNotAllowedError }}</mat-error>
                    <mat-autocomplete #auto="matAutocomplete">
                      @if ((facets | async); as facets) {
                        <mat-optgroup label="Bisher verwendete Begriffe">
                          @for (facet of facets | valueFilter: {pattern: inputCtrl.value, filterByProp: 'key'}; track facet) {
                            <mat-option
                            [value]="facet.original.key" [innerHtml]="facet.highlighted.key"></mat-option>
                          }
                        </mat-optgroup>
                      }
                    </mat-autocomplete>
                  </mat-form-field>
                  
                  `,
  styles:     [`
                 #edit-button {
                   color: grey;
                   cursor: pointer;
                 }

                 .full-width {
                   width: 100%;
                 }
               `],
  imports: [
    ReactiveFormsModule,
    MatAutocompleteModule,
    ValueFilterPipe,
    AsyncPipe,
    MatInputModule
]
})
export class AutocompleteTextfieldComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, ErrorStateMatcher {

  private unsubscribe$ = new Subject<void>()

  @Input() label: string
  @Input() type: AutocompleteType
  @Input() field: string

  inputCtrl = new UntypedFormControl()
  @ViewChild(MatInput, { static: true }) private inputEl: MatInput

  facets: Observable<FacetResult[]>

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private autocompleteService: AutocompleteService
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }
  }

  ngOnInit() {
    this.facets = this.autocompleteService.values(this.type, this.field).pipe(
      map(resultsArray => resultsArray.filter(result => result.key !== ""))
    )

    this.inputCtrl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.propagateChange(value))
  }

  ngAfterViewInit() {
    setTimeout(() => this.inputEl.errorStateMatcher = this)
  }

  // Control Value Accessor, ErrorStateMatcher

  // noinspection JSUnusedLocalSymbols
  propagateChange = (_: any) => {}

  writeValue(value) {
    this.inputCtrl.setValue(value)
  }

  registerOnChange(fn) {
    this.propagateChange = fn
  }

  registerOnTouched() {}

  isErrorState(): boolean {
    return !this.ngControl.valid && !this.ngControl.disabled
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.inputCtrl.disable() : this.inputCtrl.enable()
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }


}
