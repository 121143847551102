<h1 class="title">Vokabularien bearbeiten</h1>
<mat-nav-list class="vocabulary-list">
  @for (vocabulary of (vocabularies$ | async); track vocabulary) {
    <a mat-list-item
      [routerLink]="vocabulary.name"
      routerLinkActive="active" #rla="routerLinkActive"
      [activated]="rla.isActive"
      >
      <div matListItemTitle>{{vocabulary.display}}</div>
    </a>
  }
</mat-nav-list>
<div class="vocabulary-editor-container">
  <router-outlet></router-outlet>
</div>
