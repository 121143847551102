<mat-card [ngClass]="{expanded: !collapsed, collapsible}">
  <div (click)="headerClick()" class="header" [ngClass]="{'no-results': currentResults === 0}">
    <span class="title">
      {{ title }} ({{ currentResults }})
    </span>
    @if (currentResults > 0) {
      <a
        [routerLink]="['/overview/' + overviewRoute]"
        [queryParams]="overviewQueryParams()"
        target="_blank"
        matTooltip="In der Übersicht öffnen"
        matRipple
        (click)="$event.stopPropagation()">
        <mat-icon class="go-to-overview-icon">open_in_new</mat-icon>
      </a>
    }
    <span class="spacer"></span>
    @if (collapsible) {
      <mat-icon>
        {{ collapsed ? 'expand_more' : 'expand_less' }}
      </mat-icon>
    }
  </div>

  <cdk-virtual-scroll-viewport class="content" autosize>
    <ul class="list">
      <li *cdkVirtualFor="let record of dataSource">
        <span [ngTemplateOutlet]="resultTemplate" [ngTemplateOutletContext]="{$implicit: record}"></span>
      </li>
    </ul>
  </cdk-virtual-scroll-viewport>
</mat-card>
