import { CommonModule } from "@angular/common"
import { HttpClient } from "@angular/common/http"
import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core"
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, ValidationErrors } from "@angular/forms"
import { MatAutocompleteModule, MatAutocompleteTrigger } from "@angular/material/autocomplete"
import { MatButtonToggleModule } from "@angular/material/button-toggle"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatInputModule } from "@angular/material/input"
import { MatRadioModule } from "@angular/material/radio"
import { Subject } from "rxjs"
import { filter, takeUntil } from "rxjs/operators"

import { DateRangeService, isRangeDescription, RangeDescription } from "../../../services/date-range.service"
import { DatingSpecification } from "../../models"

const rangeStringValidator = (ctrl: UntypedFormControl): ValidationErrors => {
  const errors: ValidationErrors = {}

  if (ctrl.enabled) {
    if (ctrl.value === '') {
      errors['invalid'] = 'Eingabe erforderlich'
    }

    if (ctrl.value && !isRangeDescription(ctrl.value)) {
      errors['invalid'] = 'Eingabeformat ungültig'
    }
  }

  return errors
}



@Component({
  standalone:  true,
  selector:    "insc-dating-chooser",
  templateUrl: "./dating-chooser.component.html",
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCheckboxModule
  ],
  styleUrls:   ["./dating-chooser.component.scss"]
})
export class DatingChooserComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {

  private unsubscribe$ = new Subject<void>()

  formGroup = new UntypedFormGroup({
    primary:             new UntypedFormControl('', rangeStringValidator),
    secondary:               new UntypedFormControl({value: '', disabled: true}, [(ctrl: UntypedFormControl) => {
      return ctrl.parent.value['extend'] === true ? rangeStringValidator(ctrl) : null
    }, (ctrl: UntypedFormControl) => {
      const errors: ValidationErrors = {}
      if (ctrl.parent.controls['primary'].valid) {
        const primaryRangeDescription: RangeDescription = ctrl.parent.controls['primary'].value
        const secondaryRangeDescription: RangeDescription = ctrl.value
        if (!secondaryRangeDescription || new Date(secondaryRangeDescription.to) < new Date(primaryRangeDescription.from)) {
          errors['invalid'] = "\"von\" darf nicht kleiner sein als \"bis\""
        }
      }
      return errors
    }]),
    range_type:       new UntypedFormControl('closed'),
    range_modifier:   new UntypedFormControl({value: 'closed', disabled: true}),
    extend:           new UntypedFormControl(false)
  })

  suggestedRangesPrimary: RangeDescription[] = []
  suggestedRangesSecondary: RangeDescription[] = []

  @Input() initialDatingSpecification: DatingSpecification | null = null
  @Output() datingSpecificationChange: EventEmitter<DatingSpecification | null> = new EventEmitter()


  private _chosenDatingSpecification: DatingSpecification | null = null
  set chosenDatingSpecification(spec: DatingSpecification) {
    this.datingSpecificationChange.emit(spec)
    this._chosenDatingSpecification = spec
  }
  get chosenDatingSpecification() { return this._chosenDatingSpecification }

  errors: string[] = []

  @ViewChild('autocompletePrimary', { static: true }) autocompletePrimary: MatAutocompleteTrigger
  @ViewChildren('autocompleteSecondary') autocompleteSecondary: QueryList<MatAutocompleteTrigger>
  @ViewChild('inputPrimary', { static: true }) inputPrimary: ElementRef

  constructor(
    private http: HttpClient,
    private dateRangeService: DateRangeService) {}

  ngOnInit() {

    const primaryInputCtrl = this.formGroup.controls['primary']
    const secondaryInputCtrl = this.formGroup.controls['secondary']
    const extendCtrl = this.formGroup.controls['extend']


    this.dateRangeService.getMatchingRanges(primaryInputCtrl.valueChanges.pipe(
      filter(val => typeof val === 'string'),
      takeUntil(this.unsubscribe$)
    )).subscribe(ranges => {
      this.suggestedRangesPrimary = ranges
      this.applyMatchingRangeDescription(primaryInputCtrl, this.suggestedRangesPrimary)
    })

    this.dateRangeService.getMatchingRanges(
      secondaryInputCtrl.valueChanges.pipe(
        filter(val => typeof val === 'string'),
        takeUntil(this.unsubscribe$)
      )).subscribe(ranges => {
      this.suggestedRangesSecondary = ranges
      this.applyMatchingRangeDescription(secondaryInputCtrl, this.suggestedRangesSecondary)
    })

    this.formGroup.controls['range_type'].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(range_type => {
        const rangeModifierCtrl = this.formGroup.controls['range_modifier']

        if (range_type === 'closed') {
          rangeModifierCtrl.setValue('closed', {emitEvent: false})
          rangeModifierCtrl.disable({emitEvent: false})

          extendCtrl.enable({emitEvent: false})
        } else {
          if (rangeModifierCtrl.value === 'closed') {
            rangeModifierCtrl.setValue('open_left_exclusive', {emitEvent: false})
          }
          rangeModifierCtrl.enable({emitEvent: false})

          extendCtrl.disable({emitEvent: false})
          extendCtrl.setValue(false)
        }
      })

    extendCtrl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(extend => {
        if (extend) {
          secondaryInputCtrl.enable()
        } else {
          secondaryInputCtrl.reset(null, {emitEvent: false})
          secondaryInputCtrl.disable()
        }
      })

    this.formGroup.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(status => {

      const primary = this.formGroup.value['primary']
      if (status === 'VALID' && primary) {
        console.log(this.formGroup.value)

        const secondary = this.formGroup.value['secondary']
        const range_modifier = this.formGroup.value['range_modifier']

        this.dateRangeService.getSpecification(primary.desc, secondary ? secondary.desc : null, range_modifier)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(spec => {
            this.chosenDatingSpecification = spec
          })
      } else {
        this.chosenDatingSpecification = null
      }
    })

    this.ngOnChanges()
  }

  ngAfterContentInit() {
    setTimeout(() => this.inputPrimary.nativeElement.focus())
  }

  ngOnChanges() {
    if (this.initialDatingSpecification) {
      this.formGroup.patchValue({
        primary: this.initialDatingSpecification.primary,
        secondary: this.initialDatingSpecification.secondary,
        range_modifier: this.initialDatingSpecification.range_modifier,
        range_type: this.initialDatingSpecification.range_modifier === 'closed' ? 'closed' : 'open',
        extend: !!this.initialDatingSpecification.secondary
      })
    } else {
      this.formGroup.patchValue({
        primary: "",
        secondary: "",
        range_modifier: "closed"
      })
    }
  }

  private applyMatchingRangeDescription(ctrl: AbstractControl, suggestedRanges: RangeDescription[]) {
    const matchingRangeDesc = suggestedRanges.find((rangeDesc) => rangeDesc.desc === ctrl.value)

    if (matchingRangeDesc) {
      ctrl.setValue(matchingRangeDesc, {emitEvent: false})
      this.formGroup.updateValueAndValidity()
    }
  }


  // update value of the input fields to the corresponding RangeDescription object when
  // keyboard-navigating through the list
  onInputKeydown(event: KeyboardEvent, autocompleteTrigger: MatAutocompleteTrigger, control: AbstractControl) {
    if (["ArrowDown", "ErrorUp"].includes(event.key)) {
      if (autocompleteTrigger.activeOption) {
        control.setValue(autocompleteTrigger.activeOption.value, {emitEvent: false})
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  getDisplayValue = range => range ? range.desc : null
}
