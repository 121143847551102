<insc-data-group>
  <div inscDataField>
    <div *inscFieldLabel>Zeichenführer</div>
    <div *inscFieldValue>{{ sign.name }}</div>
  </div>
  <div inscDataField>
    <div *inscFieldLabel>Art</div>
    <div *inscFieldValue>{{ sign.sign_type }}</div>
  </div>
</insc-data-group>
