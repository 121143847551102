import { NgClass } from "@angular/common";
import { Component, forwardRef, Input, OnDestroy, OnInit } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormBuilder } from "@angular/forms"
import { MatRadioModule } from "@angular/material/radio"
import equal from "fast-deep-equal"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"
import { RecordingDate } from "../../../models/image.model"
import { DateRangePickerModule } from "../date-range-picker/date-range-picker.module"

@Component({
  standalone:  true,
  selector:    "insc-recording-date-picker",
  templateUrl: "./recording-date-picker.component.html",
  styleUrls:   ["./recording-date-picker.component.scss"],
  imports: [
    ReactiveFormsModule,
    MatRadioModule,
    NgClass,
    DateRangePickerModule
],
  providers:   [
    {
      provide:     NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordingDatePickerComponent),
      multi:       true
    }
  ]

})
export class RecordingDatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

  private unsubscribe$ = new Subject<void>()


  INITIAL_VALUE = {
    type: "single",
    primary: null,
    secondary: null
  }

  recordingDateForm = this.formBuilder.group(this.INITIAL_VALUE)

  constructor(private formBuilder: UntypedFormBuilder) { }

  propagateChange = (_recordingDate: RecordingDate) => {}

  ngOnInit(): void {
    this.recordingDateForm.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(value => {
      const { primary, secondary } = value
      if (!primary && !secondary) {
        this.propagateChange(null)
      } else {
        const recordingDate = value["type"] === "single"
          ? {earliest: primary, latest: primary }
          : { earliest: primary, latest: secondary}

        this.propagateChange(recordingDate)
      }
    })
  }

  registerOnChange(fn: (recordingDate: RecordingDate) => void): void {
    this.propagateChange = fn
  }

  registerOnTouched(fn: any): void { }

  writeValue(recordingDate: RecordingDate): void {

    if (!recordingDate) {
      this.recordingDateForm.setValue(this.INITIAL_VALUE, {emitEvent: false, onlySelf: true})
    } else {
      if (equal(recordingDate.earliest, recordingDate.latest)) {
        this.recordingDateForm.setValue({type: "single", primary: recordingDate.earliest, secondary: null}, {emitEvent: false, onlySelf: true})
      } else {
        this.recordingDateForm.setValue({type: "range", primary: recordingDate.earliest, secondary: recordingDate.latest}, {emitEvent: false, onlySelf: true})
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

}
