<insc-record-page-container
  [record]="inscription"
  activityLinkType="Inscription"
  recordType="inscription"
  [previewImageUrl]="inscription?.images[0]?.preview_url"
  (save)="save()"
  (delete)="delete()"
  >

  <div inscRecordTitle>
    @if (inscription) {
      {{ inscription.name }}
    } @else {
      <em>Neue Inschrift</em>
    }
  </div>

  <!-- BEGIN subtitle: name of linked objects and linking controls -->
  <div inscRecordSubtitle>
    <!-- REUSABLE TEMPLATES -->

    <!-- template for an enabled linking button -->
    <ng-template #linkingButton>
      <button mat-icon-button type="button" class="linking-button"
        (click)="openObjectLinkChooser()"
        matTooltip="Verknüpfung(en) hinzufügen oder Inschrift auf anderen Inschriftenträger verschieben"
        >
        <mat-icon>link</mat-icon>
      </button>
    </ng-template>


    <!-- ACTUAL CONTENT FOR RECORD SUBTITLE (Inschriftenträger name and linking button) -->
    @switch (linkingStatus) {
      <!-- this is a new inscription with a preset parent object -->
      <!-- get parent object name from route state -->
      @case (LinkingStatus.NewWithParent) {
        Inschriftenträger: <a [routerLink]="['/objects', parentInscObject.id]">{{parentInscObject.name}}</a>
      }
      <!-- The inscription is linked with one object that does not have an object group:
      cannot be linked with additional objects -->
      @case (LinkingStatus.LinkedWithoutObjectGroup) {
        Inschriftenträger:  <a [routerLink]="['/objects', inscription.insc_objects[0].id]">{{ inscription.insc_objects[0].name }}</a>
        <ng-container *ngTemplateOutlet="linkingButton"></ng-container>
      }
      <!-- The inscription is linked to one ore more objects from an object group:
      can be linked with other objects from this object group or moved -->
      @case (LinkingStatus.LinkedWithObjectGroup) {
        Objektgruppe: {{ inscription.object_group.name }}
        <ng-container *ngTemplateOutlet="linkingButton"></ng-container>
        @for (insc_object of inscription.insc_objects; track insc_object) {
          <li>
            <a [routerLink]="['/objects', insc_object.id]">{{ insc_object.name }}</a>
          </li>
        }
      }
    }
  </div>
  <!-- END subtitle -->

</insc-record-page-container>
