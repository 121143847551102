<mat-card class="section mat-elevation-z0">
  <mat-card-content>
    <mat-card-subtitle class="title">
      <div><ng-content select="[inscRecordSidebarSectionTitle]"></ng-content></div>
      <div class="title-button"><ng-content select="[inscRecordSidebarSectionTitleButton]"></ng-content></div>
    </mat-card-subtitle>
    <ng-content select="[inscRecordSidebarSectionContent]"></ng-content>
  </mat-card-content>
  @if (sidebarActionsDirectives.length > 0) {
    <mat-card-actions align="end"><ng-content select="[inscRecordSidebarSectionActions]"></ng-content></mat-card-actions>
  }
</mat-card>
