<ng-container [formGroup]="formGroup">

  <mat-form-field>
  	<input matInput  formControlName="name">
  	<mat-label>Wappenführer</mat-label>
  	<mat-error inscError errorControlName="name"></mat-error>
  </mat-form-field>
  <div>
   	<insc-single-vocabulary-select label="Wappenart" formControlName="emblem_type" vocabularyName="emblem_type"></insc-single-vocabulary-select>
  </div>
  <div>
  	<mat-checkbox formControlName="proof_of_heritage">Ahnenprobe</mat-checkbox>
  </div>

</ng-container>
