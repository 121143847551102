import { Pipe, PipeTransform } from "@angular/core"
import escapeStringRegexp from "escape-string-regexp"

@Pipe({
  standalone: true,
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(value: string, searchString: string): string {
    if (!searchString) {
      return value
    }

    const regex = new RegExp(escapeStringRegexp(searchString), "gi")

    return value.replace(regex, `<em class="highlight">$&</em>`)
  }

}
