<nav class="header mat-typography">

  <mat-toolbar class="nav-menu" color="primary">
    <!-- This fills the remaining space of the current row -->
    <div class="title-wrapper">
      <a mat-button class="menu-button" style="margin-left: -16px" routerLink="/">
        @if (isPlayground) {
          <span class="app-title">Bilddatenbank – ⚠️ PLAYGROUND ⚠️</span>
        }
        @if (!isPlayground) {
          <span class="app-title">Bilddatenbank – Inschriftenstelle Bonn</span>
        }
      </a>
    </div>
    <img src="assets/awk-nrw.svg" class="logo">

    <div class="menu-buttons hide-sm">
      <a mat-button routerLink="/overview/objects" class="menu-button" routerLinkActive="active"><span>Inschriftenträger</span></a>
      <a mat-button routerLink="/overview/images" class="menu-button" routerLinkActive="active"><span>Aufnahmen</span></a>
      <a mat-button routerLink="/vocabularies" class="menu-button" routerLinkActive="active"><span>Vokabularien</span></a>
      <button mat-button class="menu-button" [ngClass]="{active: isActive('/registers')}"
        [matMenuTriggerFor]="registersMenu"
        ><span>Register</span></button>
        <button mat-button class="menu-button" [ngClass]="{active: isActive('/objects/new')}"
          *inscIfUserPermission="'update_records'"
          [matMenuTriggerFor]="newMenu"
          > <span
          ><mat-icon style="vertical-align: text-bottom;">add</mat-icon>Neu</span></button>
          <button mat-button [matMenuTriggerFor]="adminMenu" class="menu-button" [ngClass]="{active: isActive('/admin')}"><span>Verwaltung</span></button>
        </div>

        <div class="main-menu-tool-buttons">
          @if (activity.busy | async) {
            <mat-spinner diameter="22"></mat-spinner>
          }

          <button class="responsive-menu-button hide-gt-sm" mat-icon-button [mat-menu-trigger-for]="responsiveMenu">
            <mat-icon>menu</mat-icon>
          </button>

          @if (auth.currentUser | async; as user) {
            <button mat-button
              matTooltip="Einstellungen"
              cdkOverlayOrigin
              #userSettingsTrigger="cdkOverlayOrigin"
              (click)="toggleUserSettings($event)"
              >
              <mat-icon>tune</mat-icon>
              <span>{{user.name}}</span>
            </button>
            <button mat-icon-button (click)="logout()" matTooltip="ausloggen">
              <mat-icon>logout</mat-icon>
            </button>
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="userSettingsTrigger"
              [cdkConnectedOverlayOpen]="userSettingsOpen"
              (overlayOutsideClick)="toggleUserSettings($event)"
              >
              <mat-card class="insc-app-user-settings-overlay">
                <mat-list>
                  <mat-list-item class="theme-toggle-container">
                    <mat-icon matListItemIcon>palette</mat-icon>
                    <mat-button-toggle-group [value]="colorMode$ | async" (change)="preferences.set('colorMode', $event.value)">
                      <mat-button-toggle value="light" matTooltip="Heller Modus"><mat-icon>light_mode</mat-icon></mat-button-toggle>
                      <mat-button-toggle value="dark" matTooltip="Dunkler Modus"><mat-icon>dark_mode</mat-icon></mat-button-toggle>
                      <mat-button-toggle value="auto" matTooltip="Automatischer Modus (vom Betriebssystem gesteuert)">auto</mat-button-toggle>
                    </mat-button-toggle-group>
                  </mat-list-item>
                </mat-list>
                <mat-action-list>
                  <a mat-list-item routerLink="/account" routerLinkActive="active">
                    <mat-icon matListItemIcon>manage_accounts</mat-icon>
                    <span>Accounteinstellungen</span>
                  </a>
                </mat-action-list>
              </mat-card>
            </ng-template>
          } @else {
            <button mat-icon-button matTooltip="einloggen" (click)="openLoginDialog()"><mat-icon>login</mat-icon></button>
          }
        </div>
      </mat-toolbar>

      <mat-menu #responsiveMenu>
        <a mat-menu-item routerLink="/overview/objects" routerLinkActive="active"><span>Inschriftenträger</span></a>
        <a mat-menu-item routerLink="/overview/images" routerLinkActive="active"><span>Aufnahmen</span></a>
        <a mat-menu-item routerLink="/vocabularies" routerLinkActive="active"><span>Vokabularien</span></a>
        <button mat-menu-item [matMenuTriggerFor]="registersMenu" [ngClass]="{active: isActive('/registers')}"><span>Register</span></button>
        <button mat-menu-item [ngClass]="{active: isActive('/objects/new')}"
          *inscIfUserPermission="'update_records'"
          [matMenuTriggerFor]="newMenu">
          <mat-icon>add</mat-icon>
          <span
          >Neu</span></button>
          <button mat-menu-item [matMenuTriggerFor]="adminMenu" [ngClass]="{active: isActive('/admin')}"><span>Verwaltung</span></button>
        </mat-menu>

        <mat-menu #newMenu="matMenu">
          <a mat-menu-item routerLink="/objects/new" routerLinkActive="active">Inschriftenträger</a>
          <button mat-menu-item (click)="openImageUploader()">Bilddateien hochladen</button>
        </mat-menu>

        <mat-menu #adminMenu="matMenu">
          <a *inscIfUserPermission="'manage_users'" mat-menu-item routerLink="/admin/users" routerLinkActive routerLinkActive="active">Benutzerverwaltung</a>
          <a mat-menu-item [routerLink]="['/admin', 'activities']" [queryParams]="{}" routerLinkActive routerLinkActive="active">Aktivitätsprotokoll</a>
        </mat-menu>

        <mat-menu #registersMenu="matMenu">
          <a mat-menu-item routerLink="/registers/persons" routerLinkActive="active"><span>Personen</span></a>
          <a mat-menu-item routerLink="/registers/organizations" routerLinkActive="active"><span>Organisationen</span></a>
          <a mat-menu-item routerLink="/registers/historical_persons" routerLinkActive="active"><span>Historische Personen</span></a>
          <a mat-menu-item routerLink="/registers/historical_organizations" routerLinkActive="active"><span>Historische Organisationen</span></a>
          <a mat-menu-item routerLink="/registers/locations" routerLinkActive="active"><span>Standorte</span></a>
          <a mat-menu-item routerLink="/registers/literature_entries" routerLinkActive="active"><span>Literatur</span></a>
          <a mat-menu-item routerLink="/registers/licenses" routerLinkActive="active"><span>Bildlizenzen</span></a>
        </mat-menu>
      </nav>

      @if (imageSidebarWidth$ | async; as imageSidebarWidth) {
        <mat-sidenav-container class="content mat-typography" [hasBackdrop]="imageSidebar.editMode$ | async" autosize>
          <!-- IMAGE SIDEBAR -->
          <mat-sidenav position="end"
            [opened]="imageSidebar.showSidebar$ | async"
            [disableClose]="true"
            mode="side"
            class="image-sidebar mat-elevation-z1"
            [ngStyle]="{width: imageSidebarWidth + 'px'}"
            mwlResizable
            [enableGhostResize]="true"
            (resizeEnd)="onResizeEnd($event, imageSidebarWidth)"
            >
            <div class="resize-handle" mwlResizeHandle [resizeEdges]="{ left: true }"></div>
            <insc-image-sidebar></insc-image-sidebar>
          </mat-sidenav>
          <!-- CONTENT -->
          <mat-sidenav-content>
            <router-outlet></router-outlet>
            <!--      <div class="busy-overlay" [@darkenAnimation]="(activity.busy | async) ? 'darken' : 'brighten'"></div>-->
          </mat-sidenav-content>
        </mat-sidenav-container>
      }
