import { Injectable } from "@angular/core"
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from "@angular/forms"
import { CommentFormComponent } from "../features/records/shared/subforms/comment-form.component"
import { DatingFormComponent } from "../features/records/shared/subforms/dating-form.component"
import { EmblemElementFormComponent } from "../features/records/object-page/subforms/emblem-element-form.component"
import { IconographyDescFormComponent } from "../features/records/object-page/subforms/iconography-desc-form.component"
import { LiteratureReferenceFormComponent } from "../features/records/shared/subforms/literaturereference-form/literaturereference-form.component"
import { LocationingFormComponent } from "../features/records/object-page/subforms/locationing-form/locationing-form.component"
import { MarkElementFormComponent } from "../features/records/object-page/subforms/mark-element-form.component"
import { OriginFormComponent } from "../features/records/object-page/subforms/origin-form.component"
import { PersonOrganizationFormComponent } from "../features/records/object-page/subforms/person-organization-form.component"
import { SignElementFormComponent } from "../features/records/object-page/subforms/sign-element-form.component"
import { SubformComponent } from "../shared/subform.component"

import { IValidationErrorDict } from "./errors"

export type ArraysFrom<T> = { [K in keyof T as T[K] extends unknown[] ? K : never]: T[K] }

@Injectable({providedIn: "root"})
export class FormService {

  private formComponents = {
    datings:                  DatingFormComponent,
    person_organizations:     PersonOrganizationFormComponent,
    origins:                  OriginFormComponent,
    comments:                 CommentFormComponent,
    iconography_descs:        IconographyDescFormComponent,
    literature_references:    LiteratureReferenceFormComponent,
    emblems:                  EmblemElementFormComponent,
    marks:                    MarkElementFormComponent,
    signs:                    SignElementFormComponent,
    provenience_locationings: LocationingFormComponent
  }

  applyValidationErrors(form: UntypedFormGroup, field_errors: IValidationErrorDict): void {
    for (const fieldName of Object.keys(field_errors)) {
      const formPathArray = fieldName.match(/(\w+)/g).map((match) => isNaN(+match) ? match : parseInt(match, 10))
      form.get(formPathArray).setErrors({invalid: field_errors[fieldName]})
      form.get(formPathArray).markAsTouched()
    }
  }

  mapToFormArray(dataArray: unknown[], type: string ): UntypedFormArray {
    return new UntypedFormArray(dataArray.map( (entry) => this.getFormGroupFor(type, entry) ))
  }

  getFormGroupFor(type: string, withDataFrom: unknown): UntypedFormGroup {
    const component = this.formComponents[type] as typeof SubformComponent
    if (!component){
      throw new Error(`No SubformComponent provided for '${type}'.`)
    }

    return component.buildFormGroup(withDataFrom, true)
  }

  getNewFormGroupFor(type: string): UntypedFormGroup {
    return this.getFormGroupFor(type, {})
  }

  addNewSubformItem(formArray: AbstractControl, type: string): void {
    if (!(formArray instanceof UntypedFormArray)) {
      return
    }
    formArray.push(this.getNewFormGroupFor(type))
  }

  private swap(formArray: AbstractControl, firstIndex: number, secondIndex: number): void {
    if (!(formArray instanceof UntypedFormArray)) {
      return
    }

    const secondItem = formArray.at(firstIndex)
    formArray.removeAt(firstIndex)
    formArray.insert(secondIndex, secondItem)
  }

  moveUp(formArray: AbstractControl, index: number): void {
    if (!(formArray instanceof UntypedFormArray)) {
      return
    }

    // do nothing if first element
    if (index === 0) {
      return
    }

    this.swap(formArray, index, index - 1)
  }

  moveDown(formArray: AbstractControl, index: number): void {
    if (!(formArray instanceof UntypedFormArray)) {
      return
    }

    // do nothing if last element
    if (formArray.length === index + 1) {
      return
    }

    this.swap(formArray, index, index + 1)
  }

  toggleDestroy(formArray: AbstractControl, index: number): void {
    if (!(formArray instanceof UntypedFormArray)) {
      return
    }

    const formGroup = (<UntypedFormGroup>formArray.at(index))
    if (formGroup.controls["_destroy"].value) {
      formGroup.controls["_destroy"].setValue(false)
      formGroup.enable()
    } else {
      // remove formgroup if it is newly added
      // TODO: find better way to define newly-addedness
      const formGroupValue = formGroup.value as Record<string, unknown>
      if (Object.keys(formGroupValue).every((key) => !formGroupValue[key])) {
        formArray.removeAt(index)
      } else {
        formGroup.controls["_destroy"].setValue(true)
        formGroup.disable()
      }
    }
  }

  isMarkedForDestruction = (formGroup: AbstractControl): boolean => {
    if (!(formGroup instanceof UntypedFormGroup)) {
      throw new Error("Argument localValuesFormGroup must be of type FormGroup")
    }

    return formGroup.controls._destroy.value as boolean
  }

  destroyButtonText =
    (formGroup: AbstractControl): string => this.isMarkedForDestruction(formGroup) ? "entfernen rückgängig machen" : "entfernen"
}
