import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot } from "@angular/router"
import { Observable } from "rxjs"
import { AuthService } from "../../../../services/auth.service"
import { UserService } from "../../../../services/user.service"
import { User } from "../../../../shared/models"

@Injectable({
  providedIn: 'root'
})
export class UserResolver  {

  constructor(private userService: UserService, private authService: AuthService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<User> {
    const userId = route.paramMap.get('userId')
    if (userId) {
      return this.userService.get(route.paramMap.get('userId'))
    } else {
      const ownUserId = this.authService.getCurrrentUser().id
      return this.userService.get(ownUserId)
    }
  }
}
