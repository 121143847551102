import { Injectable } from "@angular/core"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import { RecordingDate } from "../../../../shared/models/image.model"
import { RecordingDateDialogComponent } from "./recording-date-dialog.component"

export type RecordingDateDialogRef = MatDialogRef<RecordingDateDialogComponent, RecordingDate>

@Injectable({providedIn: 'root'})
export class RecordingDateDialogService {
  constructor(private dialog: MatDialog) {}

  open(initialRecordingDate: RecordingDate) {
    return this.dialog.open<RecordingDateDialogComponent, RecordingDate, RecordingDate>(RecordingDateDialogComponent, {
      data: initialRecordingDate,
      width: "500px"
    })
  }
}
