import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { merge, Observable, Subject } from "rxjs"
import { filter, map, tap } from "rxjs/operators"
import { environment } from "../../environments/environment"
import { InscImage } from "../shared/models/image.model"
import { Inscription } from "../shared/models/inscription.model"
import { InscObject } from "../shared/models/object.model"
import { VocabularyEntry } from "../shared/models/vocabulary-entry.model"
import { Vocabulary } from "../shared/models/vocabulary.model"
import { QueryParams, QueryResults } from "./data.service"
import { QueryStringService } from "./query-string.service"

export type VocabularyEntryAssociatedRecordType<T> = T extends "insc_objects" ? InscObject :
                         T extends "inscriptions" ? Inscription :
                         T extends "images" ? InscImage :
                         never;

@Injectable({
  providedIn: 'root'
})
export class VocabularyService {

  readonly baseUrl = `${environment.apiUrl}/vocabularies`
  private _vocabulariesUpdates = new Subject<Vocabulary[]>()
  private _vocabularyUpdates = new Subject<Vocabulary>()

  constructor(protected http: HttpClient, protected queryString: QueryStringService) { }

  getVocabularies(): Observable<Vocabulary[]> {
    return merge(
      this._vocabulariesUpdates,
      this.http.get<{vocabularies: Vocabulary[]}>(`${this.baseUrl}`).pipe(
        map(response => response.vocabularies)
      )
    )
  }

  getVocabulary(vocabularyName: string): Observable<Vocabulary> {
    return merge(
      this._updatesForVocabulary(vocabularyName),
      this.http.get<{vocabulary: Vocabulary}>(`${this.baseUrl}/${vocabularyName}`).pipe(
        map(response => response.vocabulary)
      )
    )
  }

  addEntry(vocabularyName: string, entry: string): Observable<Vocabulary> {
    return this.http.post<{vocabulary: Vocabulary}>(`${this.baseUrl}/${vocabularyName}/entries`, {
      vocabulary_entry: {name: entry}
    }).pipe(
      map(response => response.vocabulary),
      tap(vocabulary => this._vocabularyUpdates.next(vocabulary))
    )
  }

  updateEntry(vocabularyName: string, entry: Partial<VocabularyEntry> & {id: VocabularyEntry["id"]}): Observable<Vocabulary> {
    return this.http.put<{vocabulary: Vocabulary}>(`${this.baseUrl}/${vocabularyName}/entries/${entry.id}`, {
      vocabulary_entry: entry
    }).pipe(
      map(response => response.vocabulary),
      tap(vocabulary => this._vocabularyUpdates.next(vocabulary))
    )
  }

  removeEntry(vocabularyName: string, entry: VocabularyEntry): Observable<Vocabulary> {
    return this.http.delete<{vocabulary: Vocabulary}>(`${this.baseUrl}/${vocabularyName}/entries/${entry.id}`).pipe(
      map(response => response.vocabulary),
      tap(vocabulary => this._vocabularyUpdates.next(vocabulary))
    )
  }

  associatedRecords<T extends "insc_objects" | "inscriptions" | "images">(
    vocabularyName: string,
    entry_id: VocabularyEntry["id"],
    associated_type: T,
    query_params: QueryParams
  ): Observable<QueryResults<VocabularyEntryAssociatedRecordType<T>>> {
    const queryString = this.queryString.paramsToQueryString(query_params)
    return this.http.get<QueryResults<VocabularyEntryAssociatedRecordType<T>>>(`${this.baseUrl}/${vocabularyName}/entries/${entry_id}/${associated_type}?${queryString}`);
  }

  private _updatesForVocabulary(name: string) {
    return this._vocabularyUpdates.pipe(
      tap(voc => console.log(`Vocdate: ${voc.name}`)),
      filter(vocabulary => vocabulary.name === name)
    )
  }
}
