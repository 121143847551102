import { HttpHandler, HttpRequest } from "@angular/common/http"
import { environment } from "../../../environments/environment"

export function inscApiOnly(): MethodDecorator {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value
    descriptor.value = function(...args) {
      const req: HttpRequest<any> = args[0]
      const next: HttpHandler = args[1]

      // skip interceptor if request URL is not the app backend
      return req.url.startsWith(environment.apiUrl)
        ? originalMethod.apply(this, args)
        : next.handle(req)
    }
  }
}
