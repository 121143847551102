import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { catchError, map, share, switchMap } from "rxjs/operators"
import { AbstractExternalNormDataProviderService, ExternalNormDataLookupResult } from "../../abstract-external-norm-data-provider.service"
import { WikidataEntitiesResponse, WikidataEntity, WikidataLookupResponse } from "./wikidata-api"
import { getName, getTimeframeString } from "./wikidata-formatting-helpers"

@Injectable({
  providedIn: 'root'
})
export class WikidataNormDataProviderService extends AbstractExternalNormDataProviderService<WikidataEntity> {

  constructor(private http: HttpClient) {
    super(http)
  }

  getExternalLink(id: string): string {
    return `https://www.wikidata.org/wiki/${id}`
  }

  getValidity(id: string): Observable<boolean> {
    return this.http.head(`https://www.wikidata.org/wiki/Special:EntityData/${id}`, {observe: "response"}).pipe(
      catchError((err: unknown) => of(err)),
      map(res => {
        if (res instanceof HttpErrorResponse && res.status !== 400) {
          throw res
        }
        return res instanceof HttpResponse && Boolean(res?.ok)
      }),
      share()
    )
  }

  getEntryData(id: string): Observable<WikidataEntity> {
    return this.http.get<WikidataEntitiesResponse>(`https://www.wikidata.org/wiki/Special:EntityData/${id}.json`).pipe(
      map(entitiesResponse => entitiesResponse?.entities?.[id])
    )
  }

  protected descriptionFromEntryData(entryData: WikidataEntity): string {
    return [
      `${entryData?.labels?.de?.value}`,
      `${entryData?.descriptions?.de?.value}`
    ].filter(Boolean).join(", ")
  }

  get lookupAvailable(): boolean {
    return true
  }

  lookup(queryString: string, options: Record<string, string>): Observable<ExternalNormDataLookupResult[]> {
    const hasWBStatementPart = options?.type === "person" ? " haswbstatement:P31=Q5 " : " "
    const queryUrl = `https://www.wikidata.org/w/api.php?action=query&format=json&gsrsearch=${queryString}${hasWBStatementPart}haslabel:*&generator=search&origin=*`

    // get entity ids using mediawiki fulltext search
    // - better results than entity search with wbsearchentities
    // - significantly faster than using wikidata's SPARQL endpoint
    return this.http.get<WikidataLookupResponse>(queryUrl).pipe(
      catchError((err: unknown) => this.handleLookupError(err)),

      // map to a query for wbgetentities to retrieve labels (titles)
      map(queryResponse =>
        Object.keys(queryResponse?.query?.pages ?? {})
          .map(pageId => queryResponse.query?.pages?.[pageId].title)
          .join("|")
      ),
      // use wbgetentities to get entity titles suitable for display in lookup field
      switchMap(wikidataIdList => {
        if (!wikidataIdList) {
          return of(<WikidataEntitiesResponse>{})
        }

        const entitiesUrl = `https://www.wikidata.org/w/api.php?action=wbgetentities&ids=${wikidataIdList}&props=labels|descriptions|claims&format=json&origin=*`
        return this.http.get<WikidataEntitiesResponse>(entitiesUrl)
      }),
      map(entitiesResponse => {
        if (!entitiesResponse.entities) {
          return <ExternalNormDataLookupResult[]>[]
        }
        return Object.keys(entitiesResponse.entities).map(entityId => ({
          id: entityId,
          title: this.getResultTitle(entitiesResponse.entities[entityId]),
          description: entitiesResponse.entities[entityId].descriptions?.de?.value
        }))
      })
    )
  }

  private getResultTitle(entity: WikidataEntity): string {
    const timeframeString = getTimeframeString(entity.claims)
    const name = getName(entity)

    if (timeframeString) {
      return `${name} (${timeframeString})`
    }

    return name
  }
}
