import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { finalize } from "rxjs/operators"
import { HttpActivityService } from "../../services/http-activity.service"
import { inscApiOnly } from "./insc-api-only.decorator"

@Injectable({
  providedIn: 'root'
})
export class BusyInterceptor implements HttpInterceptor {

  constructor(private activity: HttpActivityService) {}

  @inscApiOnly()
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.activity.start()
    return next.handle(req).pipe(
      finalize(() => this.activity.stop())
    )
  }
}
