import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from "@angular/forms"
import { MatButtonModule } from "@angular/material/button"
import { MatDialogModule } from "@angular/material/dialog"
import { MatInputModule } from "@angular/material/input"
import { LoginDialogComponent } from "./login-dialog.component"
import { LoginDialogService } from "./login-dialog.service"

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule, MatInputModule, MatButtonModule,
        MatDialogModule
    ],
    declarations: [
        LoginDialogComponent
    ],
    exports: [
        LoginDialogComponent
    ],
    providers: [
      LoginDialogService
    ]
})
export class LoginDialogModule { }
