import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { VocabulariesPageComponent } from "./vocabularies-page.component"
import { VocabularyEditorHostComponent } from "./vocabulary-editor-host.component"

const routes: Routes = [
  { path: "", component: VocabulariesPageComponent, children: [
      { path: ":vocabulary_name/:entry_id", component: VocabularyEditorHostComponent},
      {path: ":vocabulary_name", redirectTo:  ":vocabulary_name/"}
    ]}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VocabulariesPageRoutingModule { }
