<insc-data-group>
  <div inscDataField>
    <div *inscFieldLabel>Markenführer</div>
    <div *inscFieldValue>{{mark.name}}</div>
  </div>
  <div inscDataField>
    <div *inscFieldLabel>Markenart</div>
    <div *inscFieldValue>{{mark.mark_type}}</div>
  </div>
</insc-data-group>
