import { ScrollingModule } from "@angular/cdk-experimental/scrolling"
import { CdkVirtualForOf, CdkVirtualScrollViewport } from "@angular/cdk/scrolling"
import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from "@angular/forms"
import { MatButtonModule } from "@angular/material/button"
import { MatCardModule } from "@angular/material/card"
import { MatRippleModule } from "@angular/material/core"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatTooltipModule } from "@angular/material/tooltip"
import { RouterModule } from "@angular/router"
import { ActivityLinkComponent } from "../../../../shared/components/activity-link/activity-link.component"
import {
  AssociatedRecordListComponent,
  AssociatedRecordResultDirective
} from "./associated-record-view/associated-record-list/associated-record-list.component"
import { AssociatedRecordViewComponent } from "./associated-record-view/associated-record-view.component"
import {
  RegisterEditorActionsDirective,
  RegisterEditorFormDirective,
  RegisterEditorLayoutComponent,
  RegisterEditorMenuDirective,
  RegisterEditorSidebarDirective
} from "./register-editor-layout.component"
import { RegisterEntryMetadataViewComponent } from "./register-entry-metadata-view/register-entry-metadata-view.component"

@NgModule({
  declarations: [
    RegisterEditorMenuDirective,
    RegisterEditorFormDirective,
    RegisterEditorActionsDirective,
    RegisterEditorLayoutComponent,
    RegisterEditorSidebarDirective,
    RegisterEntryMetadataViewComponent,
    AssociatedRecordListComponent,
    AssociatedRecordResultDirective,
    AssociatedRecordViewComponent
  ],
  imports: [
    CommonModule, RouterModule, ActivityLinkComponent, MatInputModule, MatProgressSpinnerModule, MatIconModule, ReactiveFormsModule, MatButtonModule, MatCardModule, CdkVirtualScrollViewport, ScrollingModule, CdkVirtualForOf, MatTooltipModule, MatRippleModule
  ],
  exports: [
    RegisterEditorMenuDirective,
    RegisterEditorFormDirective,
    RegisterEditorActionsDirective,
    RegisterEditorLayoutComponent,
    RegisterEditorSidebarDirective,
    RegisterEntryMetadataViewComponent,
    AssociatedRecordViewComponent,
    AssociatedRecordResultDirective
  ]
})
export class RegisterEditorLayoutModule { }
