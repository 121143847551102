<mat-form-field  [ngClass]="{compact: compact}" class="form-field">
  <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
  <mat-label>{{placeholder}}</mat-label>
  <input matInput readonly [formControl]="internalDate" [matDatepicker]="picker"
    (click)="picker.opened = !picker.opened"
    [min]="minDate" [max]="maxDate">

  @if (internalDate.value) {
    <button
      matIconSuffix mat-icon-button
      aria-label="Clear"
      matTooltip="Zurücksetzen"
      (click)="reset()"
      >
      <mat-icon>close</mat-icon>
    </button>
  }
  <mat-datepicker
    #picker
    [startView]="startView"
    (yearSelected)="onYearSelected($event, picker)"
    [calendarHeaderComponent]="yearOnly ? yearOnlyHeader : calendarHeader"
    >
    <mat-datepicker-actions>
      <div class="insc-date-range-picker-datepicker-actions-container">
        <mat-divider></mat-divider>
        <div class="selected-date">
          <span>Gewähltes Datum: </span>
          @if (selectedDateString()) {
            <span class="date">{{selectedDateString()}}</span>
          } @else {
            <span class="empty">nichts ausgewählt</span>
          }

        </div>
        <mat-divider></mat-divider>
        <div class="datepicker-action-buttons">
          <button mat-button matDatepickerCancel>Abbrechen</button>
          <button
            mat-raised-button color="primary"
            [disabled]="!selectedDateString()"
            (click)="onSelectDate(picker)"
            >
            OK
          </button>
        </div>
      </div>
    </mat-datepicker-actions>

  </mat-datepicker>
</mat-form-field>
