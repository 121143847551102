import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from "@angular/forms"
import { MatLineModule, MatRippleModule } from "@angular/material/core"
import { MatIconModule, MatIconRegistry } from "@angular/material/icon"
import { MatButtonModule } from "@angular/material/button"
import { MatCardModule } from "@angular/material/card"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatOptionModule } from "@angular/material/core"
import { MatInputModule } from "@angular/material/input"
import { MatListModule } from "@angular/material/list"
import { MatMenuModule } from "@angular/material/menu"
import { MatProgressBarModule } from "@angular/material/progress-bar"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatSelectModule } from "@angular/material/select"
import { MatTooltipModule } from "@angular/material/tooltip"
import { MatTreeModule } from "@angular/material/tree"
import { DomSanitizer } from "@angular/platform-browser"
import { InscFormFieldErrorMessageComponent } from "../../../shared/components/form-components/insc-form-field-error-message.component"
import { HighlightPipe } from "../../../shared/pipes/highlight.pipe"
import { ExternalNormDataModule } from "../shared/external-norm-data/external-norm-data.module"
import { RegisterEditorLayoutModule } from "../shared/register-editor-layout/register-editor-layout.module"
import { AddressFormComponent } from "../shared/subforms/address-form.component"
import { LocationFormComponent } from "./location-form/location-form.component"
import { LocationRegisterEditorComponent } from "./location-register-editor.component"
import { LocationTreeComponent } from "./location-tree.component"

@NgModule({
  imports: [
    CommonModule,

    ReactiveFormsModule,

    MatTreeModule, MatProgressBarModule, MatIconModule,
    MatRippleModule,

    MatCardModule, MatOptionModule, MatInputModule,
    MatSelectModule, MatButtonModule, MatTooltipModule, MatMenuModule, MatListModule, MatProgressSpinnerModule, RegisterEditorLayoutModule, ExternalNormDataModule, MatCheckboxModule, InscFormFieldErrorMessageComponent, MatLineModule, HighlightPipe, AddressFormComponent
  ],
  declarations: [
    LocationTreeComponent,
    LocationRegisterEditorComponent,
    LocationFormComponent
  ],
  exports: [
    LocationRegisterEditorComponent
  ]
})
export class LocationRegisterEditorModule {

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('City', sanitizer.bypassSecurityTrustResourceUrl('/assets/location-icons/free.svg'))
    iconRegistry.addSvgIcon('PrincipalTown', sanitizer.bypassSecurityTrustResourceUrl('/assets/location-icons/free.svg'))
    iconRegistry.addSvgIcon('SubLocation', sanitizer.bypassSecurityTrustResourceUrl('/assets/location-icons/sublocation.svg'))
    iconRegistry.addSvgIcon('MuseumCollection', sanitizer.bypassSecurityTrustResourceUrl('/assets/location-icons/museum.svg'))
    iconRegistry.addSvgIcon('PrivateProperty', sanitizer.bypassSecurityTrustResourceUrl('/assets/location-icons/private.svg'))
    iconRegistry.addSvgIcon('Manager', sanitizer.bypassSecurityTrustResourceUrl('/assets/location-icons/other.svg'))
    iconRegistry.addSvgIcon('FreeSite', sanitizer.bypassSecurityTrustResourceUrl('/assets/location-icons/freesite.svg'))
    iconRegistry.addSvgIcon('Building', sanitizer.bypassSecurityTrustResourceUrl('/assets/location-icons/building.svg'))
  }
}
