@if (literatureEntry) {
  <div>
    <insc-norm-data-expansion-panel>
      <div inscNormDataPanelEntryTitle>{{literatureEntry.name}}</div>
    </insc-norm-data-expansion-panel>
  </div>
} @else {
  <button id="emptySelectButton" type="button" [disabled]="disabled" mat-raised-button (click)="openEditor()"><span
  [ngStyle]="ngControl.invalid ? {'color': 'red'} : {}">Auswählen</span></button>
}


<div id="editButtonContainer">
  <button [color]="ngControl.invalid ? 'warn' : 'primary'" class="selectOrEditButton" mat-button type="button"
    [disabled]="disabled" (click)="openEditor()"><small class="form-text">Auswählen / Bearbeiten</small></button>
  </div>
