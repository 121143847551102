import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from "@angular/forms"
import { MatButtonModule } from "@angular/material/button"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatListModule } from "@angular/material/list"
import { MatMenuModule } from "@angular/material/menu"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatSelectModule } from "@angular/material/select"
import { RouterModule } from "@angular/router"
import { InscFormFieldErrorMessageComponent } from "../../../shared/components/form-components/insc-form-field-error-message.component"
import { HighlightPipe } from "../../../shared/pipes/highlight.pipe"
import { LocationRegisterEditorModule } from "../location-register-editor/location-register-editor.module"
import { ExternalNormDataModule } from "../shared/external-norm-data/external-norm-data.module"
import { RegisterEditorLayoutModule } from "../shared/register-editor-layout/register-editor-layout.module"
import { AddressFormComponent } from "../shared/subforms/address-form.component"
import { BasicRegisterEditorComponent } from "./basic-register-editor.component"
import { HistoricalOrganizationEntryFormComponent } from "./forms/historical-organization-entry-form.component"
import { HistoricalPersonEntryFormComponent } from "./forms/historical-person-entry-form.component"
import { LicenseFormComponent } from "./forms/license-form.component"
import { LiteratureEntryFormComponent } from "./forms/literature-entry-form.component"
import { OrganizationEntryFormComponent } from "./forms/organization-entry-form.component"
import { PersonEntryFormComponent } from "./forms/person-entry-form.component"

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,

    MatListModule, MatButtonModule, MatInputModule, LocationRegisterEditorModule, RegisterEditorLayoutModule, MatProgressSpinnerModule, MatIconModule, MatMenuModule, ExternalNormDataModule, InscFormFieldErrorMessageComponent, HighlightPipe, MatSelectModule, MatCheckboxModule, AddressFormComponent,
  ],
  declarations: [
    BasicRegisterEditorComponent,
    PersonEntryFormComponent,
    OrganizationEntryFormComponent,
    HistoricalPersonEntryFormComponent,
    HistoricalOrganizationEntryFormComponent,
    LicenseFormComponent,
    LiteratureEntryFormComponent,
  ],
  exports: [BasicRegisterEditorComponent]
})
export class BasicRegisterEditorModule { }
