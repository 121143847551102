<div class="data-group">

  @for (dataField of dataFields; track dataField) {
    <div
      class="data-field"
      [style.flex-direction]="dataField.layout === 'horizontal' ? 'row' : 'column'"
      [style.flex-wrap]="dataField.layout === 'horizontal' ? 'wrap' : 'nowrap'"
      >
      <!-- Label -->
      <div class="label" [style.flex]="dataField.layout === 'horizontal' ? '0 0 ' + labelColWidth : 0">
        <ng-container *ngTemplateOutlet="dataField.fieldLabelTemplate"></ng-container>
      </div>
      <!-- Value -->
      @if (dataField.valueIsKnown()) {
        <span class="values">
          <ul class="comma-separated">
            @for (fieldValueTpl of dataField.fieldValueTemplates; track fieldValueTpl) {
              <li>
                <ng-container *ngTemplateOutlet="fieldValueTpl"></ng-container>
              </li>
            }
          </ul>
          @if (dataField.editorialState === 'uncertain') {
            <em> (unsicher)</em>
          }
        </span>
      } @else {
        <div class="values unknown">{{
          dataField.editorialState === 'indeterminable' ? "nicht feststellbar" : "ungeklärt"
        }}</div>
      }
    </div>
  }
</div>
