<ng-template #entry let-key="key" let-value="value">
  @if (splitKey(key); as keyParts) {
    <dt>{{ keyParts.namespace }}:<wbr><span class="key">{{ keyParts.key }}</span></dt>
    <dd>
      @switch (getType(value)) {
        @case ('array') {
          <mat-chip-set>
            @for (item of toArray(value); track item) {
              <mat-chip>{{ item }}</mat-chip>
            }
          </mat-chip-set>
        }
        @default {
          <span>{{ value }}</span>
        }
      }
    </dd>
  }
</ng-template>

<dl>
  @for (group of fieldGroups; track group) {
    <div>
      @for (key of group.members; track key) {
        @if (metadata[key]; as value) {
          <ng-container
            *ngTemplateOutlet="entry; context: { key, value }">
          </ng-container>
        }
      }
    </div>
  }

  <div>
    @for (key of getUngroupedKeys(metadata); track key) {
      @if (metadata[key]; as value) {
        <ng-container
          *ngTemplateOutlet="entry; context: { key, value }">
        </ng-container>
      }
    }
  </div>

</dl>
