import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from "../../../services/can-deactivate-guard.service"
import { RegisterPageComponent } from "./register-page.component"

const routes: Routes = [
  {path: "", redirectTo: "persons/", pathMatch: "full"},
  {path: ":type/:id", component: RegisterPageComponent, canDeactivate: [CanDeactivateGuard]},
  {path: ":type", redirectTo:  ":type/"}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegisterPageRoutingModule { }
