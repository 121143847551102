<div>
  @if (historicalPersonEntry) {
    <insc-norm-data-expansion-panel [disabled]="disabled">
      <div inscNormDataPanelEntryTitle>{{ historicalPersonEntry.name }}</div>
      <div inscNormDataPanelEntryDetails>
        <h4>Alternative Schreibweisen</h4>
        <ul>
          @for (altName of historicalPersonEntry.alternative_names; track altName) {
            <li>{{altName}}</li>
          }
        </ul>
        <h4>Zeitliche Einordnung</h4>
        <p>{{historicalPersonEntry.timeframe}}</p>
        <h4>Kommentar</h4>
        <p>{{historicalPersonEntry.comment}}</p>
      </div>
    </insc-norm-data-expansion-panel>
  } @else {
    <insc-norm-data-expansion-panel>
      <div inscNormDataPanelEntryTitle>N.N.</div>
    </insc-norm-data-expansion-panel>
  }
</div>
