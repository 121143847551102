<div [ngClass]="{hidden: !editable}" class="edit-view">
  <ng-content></ng-content>
</div>
<div [ngClass]="{hidden: editable}" class="readonly-view">
  @if (!readonlyViewTpl) {
    <div class="readonly-value">
      {{ displayValue }}
      @if (multiRecordFormControl.placeholder) {
        <span class="view-placeholder">{{multiRecordFormControl.placeholder}}</span>
      }
    </div>
  }

  @if (readonlyViewTpl) {
    <div class="readonly-value">
      <ng-container *ngTemplateOutlet="readonlyViewTpl; context: {$implicit: displayValue, placeholder: multiRecordFormControl.placeholder}"></ng-container>
    </div>
  }
</div>

@if (fieldAccessoryTpl) {
  <div class="field-accessory">
    <ng-container *ngTemplateOutlet="fieldAccessoryTpl; context: fieldAccessoryContext"></ng-container>
  </div>
}
