import { Injectable } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { filter, map } from "rxjs/operators"
import { NormDataEntry } from "../../../shared/models/norm-data-entry.model"
import { NormDataEditorDialogResult, RegisterEditorDialogOptions, RegisterEntryDialogComponent, } from "./register-entry-dialog.component"


@Injectable({providedIn: "root"})
export class RegisterEntryDialogService {
  constructor(private dialogService: MatDialog) {}

  open<T = NormDataEntry>(dialogOptions: RegisterEditorDialogOptions) {
    return this.dialogService.open<RegisterEntryDialogComponent,
    RegisterEditorDialogOptions,
    NormDataEditorDialogResult<T>>(RegisterEntryDialogComponent, {
      data:         dialogOptions,
      width:        "800px",
      disableClose: true,
      panelClass:   "norm-data-editor-dialog-panel"
    }).afterClosed().pipe(
      filter(result => result != null),
      map(result => result.entry)
    )
  }
}
