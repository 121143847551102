<div class="form-container" matRipple #ripple="matRipple" [matRippleDisabled]="true">
  <div class="side-controls">
    @if (itemCountInArray > 1) {
      <button mat-icon-button
        [disabled]="isFirstInArray"
        (click)="moveUp()"
        matTooltip="nach oben bewegen"
        ><mat-icon>arrow_upward</mat-icon></button>
      }

      <button mat-icon-button matTooltip="{{destroyButtonText()}}" (click)="remove()">
        @if (!markedForDestruction) {
          <mat-icon>delete</mat-icon>
        }
        @if (markedForDestruction) {
          <mat-icon>redo</mat-icon>
        }
      </button>

      @if (itemCountInArray > 1) {
        <button mat-icon-button
          [disabled]="isLastInArray"
          (click)="moveDown()"
          matTooltip="nach unten bewegen"
          ><mat-icon>arrow_downward</mat-icon></button>
        }
      </div>
      <div class="item">
        @if (markedForDestruction) {
          <div matTooltip="Eintrag wird entfernt" matTooltipPosition="above" class="destruction-overlay"></div>
        }
        <ng-content></ng-content>
      </div>
    </div>
    @if (!isLastInArray) {
      <mat-divider class="divider"></mat-divider>
    }
