import { Component, Inject, ViewChild } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { IPTCDataset } from "../multi-iptc-editor/iptc-dataset.service"
import { TableIptcEditorComponent } from "./table-iptc-editor.component"


export interface TableIptcEditorDialogData {
  iptcDatasets: IPTCDataset[]
}

export type TableIptcEditorDialogRef = MatDialogRef<TableIptcEditorDialogComponent, IPTCDataset[]>

@Component({
  selector:      'insc-table-iptc-editor-dialog',
  templateUrl:   './table-iptc-editor-dialog.component.html',
  styleUrls:     ['./table-iptc-editor-dialog.component.scss']
})
export class TableIptcEditorDialogComponent {

  @ViewChild(TableIptcEditorComponent) tableIptcEditor: TableIptcEditorComponent

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: TableIptcEditorDialogData,
    @Inject(MatDialogRef) private dialogRef: TableIptcEditorDialogRef
  ) {}


  save() {
    // @ts-ignore
    this.dialogRef.close(this.tableIptcEditor.getUpdates())
  }

}
