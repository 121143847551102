<insc-record-page-container
  [record]="insc_object"
  activityLinkType="InscObject"
  recordType="object"
  [previewImageUrl]="insc_object?.images[0]?.preview_url"
  (save)="save()"
  (delete)="delete()"
  >

  <div inscRecordTitle>
    @if (insc_object) {
      {{ insc_object.name }}
    } @else {
      <em>Neuer Inschriftenträger</em>
    }
  </div>

  <div inscRecordSubtitle>Inschriftenträger</div>

  <!-- SIDEBAR SECTIONS -->

  <!-- Object Group -->
  @if (insc_object) {
    <ng-template inscRecordSidebarSection>
      <insc-record-sidebar-section class="object-group-section">
        <div inscRecordSidebarSectionTitle>Objektgruppe</div>
        <div inscRecordSidebarSectionTitleButton *inscIfUserPermission="'update_records'">
          <mat-menu #objectGroupMenu="matMenu">
            <div mat-menu-item disabled>Objektgruppen-Optionen</div>
            <button mat-menu-item (click)="linkObjectGroup()"><mat-icon>link</mat-icon>Bestehende zuweisen</button>
            <button mat-menu-item (click)="linkNewObjectGroup()"><mat-icon>flare</mat-icon>Neu erstellen & zuweisen</button>
            @if (insc_object.object_group) {
              <button mat-menu-item (click)="renameObjectGroup()"><mat-icon>edit</mat-icon>Aktuelle Objektgruppe bearbeiten</button>
            }
            @if (insc_object.object_group) {
              <button mat-menu-item (click)="unlinkObjectGroup()"><mat-icon>link_off</mat-icon>Aus Objektgruppe entfernen</button>
            }
          </mat-menu>
          <button class="card-menu-button" mat-icon-button [matMenuTriggerFor]="objectGroupMenu"><mat-icon>more_vert</mat-icon></button>
        </div>
        <div inscRecordSidebarSectionContent>
          @if (insc_object.object_group) {
            <div>
              {{ insc_object.object_group.name }}
              <ul>
                @for (group_object of insc_object.object_group.insc_objects; track group_object) {
                  @if (group_object.id != insc_object.id) {
                    <li><a [routerLink]="['/', 'objects', group_object.id]">{{group_object.name}}</a></li>
                  }
                }
              </ul>
            </div>
          } @else {
            <em>Keine Objektgruppe zugewiesen</em>
          }
        </div>
      </insc-record-sidebar-section>
    </ng-template>
  }

  <!-- Inscriptions -->
  @if (insc_object) {
    <ng-template inscRecordSidebarSection>
      <insc-record-sidebar-section>
        <div inscRecordSidebarSectionTitle>Verknüpfte Inschriften</div>
        <div inscRecordSidebarSectionContent>
          <insc-link-list #inscriptionList [allowRemove]="false" [allowReorder]="hasEditPermission | async"
            [links]="inscriptionLinks$ | async" (reorder)="reorderInscription($event, inscriptionList)">
          </insc-link-list>
        </div>
        <div inscRecordSidebarSectionActions>
          <button *inscIfUserPermission="'update_records'" mat-button (click)="newInscriptionHere()"><mat-icon>add</mat-icon><span>NEUE INSCHRIFT</span></button>
        </div>
      </insc-record-sidebar-section>
    </ng-template>
  }


</insc-record-page-container>
