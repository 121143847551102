<!-- scrollspy -->
<mat-card appearance="outlined" class="scrollspy-container">
  <mat-nav-list class="scrollspy">
    @for (section of formSections; track section; let lastSection = $last) {
      <a mat-list-item class="scrollspy-item" (click)="section.scrollIntoView()">
        {{section.sectionTitle}}
        @if (section.hasRepeatableForm) {
          <span>&nbsp;({{section.numberOfItems}})</span>
        }
      </a>
      @if (!lastSection) {
        <mat-divider></mat-divider>
      }
    }
  </mat-nav-list>
</mat-card>


<!-- the form -->
<div class="form">
  <ng-content></ng-content>
</div>

