import { Component, ViewChild } from "@angular/core"
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete"
import { MatChipGrid } from "@angular/material/chips"

import { AbstractVocabularySelectComponent } from "./abstract-vocabulary-select.component"

@Component({
  selector: 'insc-multi-vocabulary-select',
  templateUrl: 'multi-vocabulary-select.component.html',
  styleUrls: ['./vocabulary-select.scss']
})
export class MultiVocabularySelectComponent extends AbstractVocabularySelectComponent<string[]>  {

  @ViewChild("chipGrid", {static: true}) chipGrid: MatChipGrid

  vocabularySelectAfterInit(): void {
    this.ngControl.statusChanges.subscribe(status => {
      this.chipGrid.errorState = status === 'INVALID'
      if (status === 'INVALID') {
        // workaround to get the mat-error to show – couldn't get anywhere with a custom ErrorStateMatcher
        this.chipGrid.focus()
      }
    })
  }

  // formControlValue defined in AbstractVocabularySelect, defined here as array of strings
  selected(event: MatAutocompleteSelectedEvent): void {

    if (!this.formControlValue) {
      this.formControlValue = []
    }

    this.formControlValue.push(event.option.value)
    this.propagateChange(this.formControlValue)

    const inputElem = this.entryInputRef.nativeElement as HTMLInputElement
    inputElem.value = ''
    this.inputCtrl.setValue('')

  }

  remove(entry: string): void {
    const index = this.formControlValue.indexOf(entry)

    if (index >= 0) {
      this.formControlValue.splice(index, 1)
    }
    this.propagateChange(this.formControlValue)
  }
}
