import { Component, Input } from "@angular/core"
import { Dating } from "../../../../shared/models"


@Component({
  selector:    'insc-dating-view',
  templateUrl: './dating-view.component.html',
  styleUrls:   ['./dating-view.component.css']
})
export class DatingViewComponent {
  @Input() dating: Dating
}
