import { Component, Input } from "@angular/core"
import { CommonModule } from '@angular/common';
import { MatChipsModule } from "@angular/material/chips"
import { MatDividerModule } from "@angular/material/divider"
import { ImageMetadataRecord } from "../../../../../services/data.service"


@Component({
  selector:    'insc-image-file-metadata-view',
  standalone:  true,
  imports:     [CommonModule, MatChipsModule, MatDividerModule],
  templateUrl: './image-file-metadata-view.component.html',
  styleUrls:   ['./image-file-metadata-view.component.scss']
})
export class ImageFileMetadataViewComponent {
  @Input({required: true}) metadata: Record<string, Array<string | number> | number | string | boolean>

  // for grouping related metadata fields, loosely based on the mapping proposed by the MWG
  readonly fieldGroups = [
    {
      groupName: "Title",
      members: ["XMP-dc:Title", "IPTC:ObjectName", "XMP-photoshop:Headline", "IPTC:Headline"]
    },
    {
      groupName: "Description",
      members: ["EXIF:ImageDescription", "IPTC:Caption-Abstract", "XMP-dc:Description"],
    },
    {
      groupName: "Copyright",
      members: ["EXIF:Copyright", "IPTC:CopyrightNotice", "IFD0:Copyright", "XMP-dc:Rights", "XMP-xmpRights:Marked", "XMP-xmpRights:UsageTerms"],
    },
    {
      groupName: "Source",
      members: ["XMP-photoshop:Source", "IPTC:Source"]
    },
    {
      groupName: "CreateDate",
      members: ["Composite:SubSecCreateDate", "EXIF:CreateDate", "IPTC:DigitalCreationDate", "IPTC:DigitalCreationTime", "XMP-xmp:CreateDate", "Composite:DateTimeCreated", "Composite:DigitalCreationDateTime"],
    },
    {
      groupName: "Creator",
      members: ["EXIF:Artist", "IFD0:Artist", "IPTC:By-line", "XMP-dc:Creator"]
    },
    {
      groupName: "Editor",
      members: ["XMP-photoshop:CaptionWriter", "IPTC:Writer-Editor"]
    },
    {
      groupName: "Credit",
      members: ["IPTC:Credit", "XMP-pur:CreditLine", "XMP-photoshop:Credit"]
    },
    {
      groupName: "DateTimeOriginal",
      members: ["Composite:SubSecDateTimeOriginal", "EXIF:DateTimeOriginal", "IPTC:DateCreated", "IPTC:TimeCreated", "XMP-photoshop:DateCreated"],
    },
    {
      groupName: "ModifyDate",
      members: ["Composite:SubSecModifyDate", "EXIF:ModifyDate", "XMP-xmp:ModifyDate"],
    },
    {
      groupName: "Location",
      members: ["IPTC:Sub-location", "XMP-iptcCore:Location", "XMP-iptcExt:LocationShownSublocation"],
    },
    {
      groupName: "City",
      members: ["IPTC:City", "XMP-photoshop:City", "XMP-iptcExt:LocationShownCity"],
    },
    {
      groupName: "State",
      members: ["IPTC:Province-State", "XMP-photoshop:State", "XMP-iptcExt:LocationShownProvinceState"],
    },
    {
      groupName: "Country",
      members: [
        "IPTC:Country-PrimaryLocationName", "XMP-photoshop:Country", "XMP-iptcExt:LocationShownCountryName",
        "IPTC:Country-PrimaryLocationCode", "XMP-iptcCore:CountryCode"
      ],
    },
    {
      groupName: "Keywords",
      members: ["IPTC:Keywords", "XMP-dc:Subject"],
    }
  ]

  getUngroupedKeys(data: ImageMetadataRecord): string[] {
    return Object.keys(data).filter(key =>
      !this.fieldGroups.some(fieldGroup => fieldGroup.members.includes(key))
    )
  }

  getType(value: unknown): 'string' | 'number' | 'boolean' | 'array' | 'unknown' {
    if (typeof value === 'string') {
      return 'string';
    } else if (typeof value === 'number') {
      return 'number';
    } else if (typeof value === 'boolean') {
      return 'boolean';
    } else if (Array.isArray(value)) {
      return 'array';
    }
    return 'unknown';
  }

  toArray(value: any): Array<string | number> {
    return value
  }

  splitKey(namespacedKey: string): { namespace: string; key: string } {
    const groups = namespacedKey.split(":")
    return { namespace: groups[0], key: groups[1] }
  }

}
