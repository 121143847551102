import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms"
import { MatButtonModule } from "@angular/material/button"
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatDividerModule } from "@angular/material/divider"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatTooltipModule } from "@angular/material/tooltip"
import { CalendarHeaderComponent, DateRangePickerComponent } from "./date-range-picker.component"
import { YearOnlyHeaderComponent } from "./year-only-header.component";



@NgModule({
  declarations: [
    DateRangePickerComponent,
    YearOnlyHeaderComponent,
    CalendarHeaderComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDividerModule
  ],
  exports: [
    DateRangePickerComponent
  ]
})
export class DateRangePickerModule { }
