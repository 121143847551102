import { Component } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { SubformComponent } from "../../../../shared/subform.component"

@Component({
  selector:    'insc-mark-element-form',
  templateUrl: './mark-element-form.component.html',
  styleUrls:   ['./mark-element-form.component.scss']
})
export class MarkElementFormComponent extends SubformComponent {

  static buildFormGroup(mark, destroyable = true) {
    return new UntypedFormGroup({
      id: 				new UntypedFormControl(mark.id || null),
      name:				new UntypedFormControl(mark.name || ''),
      mark_type:   		new UntypedFormControl(mark.mark_type || ''),
      ...this.destroyableControl(mark.id, destroyable)
    })
  }

}
