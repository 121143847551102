<ng-container [formGroup]="recordingDateForm">
  <mat-radio-group class="radio-group" [formControlName]="'type'">
    <mat-radio-button value="single">Zeitpunkt</mat-radio-button>
    <mat-radio-button value="range">Zeitraum</mat-radio-button>
  </mat-radio-group>

  <div class="pickers">
    <insc-date-range-picker [formControlName]="'primary'"
      [placeholder]="recordingDateForm.value['type'] === 'single' ? 'Aufnahmedatum' : 'Aufnahmedatum (nach)'"
    ></insc-date-range-picker>
    <!--    <mat-error *ngIf="form.controls['recording_date_earliest'].invalid">{{form.controls['recording_date_earliest'].errors['invalid']}}</mat-error>-->
    @if (recordingDateForm.value['type'] === 'range') {
      <insc-date-range-picker
        [formControlName]="'secondary'"
        placeholder="Aufnahmedatum (vor)"
      ></insc-date-range-picker>
    }
    <!--    <mat-error *ngIf="form.controls['recording_date_latest'].invalid">{{form.controls['recording_date_latest'].errors['invalid']}}</mat-error>-->
  </div>

</ng-container>
