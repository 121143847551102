import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { MatButtonModule } from "@angular/material/button"
import { MatRippleModule } from "@angular/material/core"
import { MatDialogModule } from "@angular/material/dialog"
import { MatDividerModule } from "@angular/material/divider"
import { MatIconModule } from "@angular/material/icon"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatTooltipModule } from "@angular/material/tooltip"
import { ImageManagementModule } from "../image-management/image-management.module"
import { ImageMetadataEditorsModule } from "../image-metadata-editors/image-metadata-editors.module"
import { ImageUploadDialogComponent } from "./image-upload-dialog.component"
import { ImageUploaderComponent } from "./image-uploader.component"


@NgModule({
  declarations: [
    ImageUploaderComponent,
    ImageUploadDialogComponent
  ],
  exports: [
    ImageUploadDialogComponent,
    ImageUploaderComponent
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    ImageManagementModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    ImageMetadataEditorsModule
  ]
})
export class ImageUploaderModule { }
