import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, throwError } from "rxjs"
import { catchError } from "rxjs/operators"
import { APIError, HTTPErrorService, NotAuthorizedError } from "../../services/errors"
import { SnackbarService } from "../snackbars/snackbar.service"
import { inscApiOnly } from "./insc-api-only.decorator"

@Injectable({
  providedIn: "root"
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private snackbar: SnackbarService, private errorService: HTTPErrorService) {}

  isAuthError(error: HttpErrorResponse | APIError) {
    if (error instanceof APIError) {
      return (error instanceof NotAuthorizedError)
    }

    return error.status === 401
  }

  @inscApiOnly()
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error)
        const apiError = this.errorService.getError(error)
        if (!this.isAuthError(error)) {
          this.snackbar.showApiError(apiError)
        }
        return throwError(apiError || error)
      })
    )
  }

}
