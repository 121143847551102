import { Component } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { SubformComponent } from "../../../../shared/subform.component"

@Component({
  selector:    'insc-emblem-element-form',
  templateUrl: './emblem-element-form.component.html',
  styleUrls:   ['./emblem-element-form.component.css']
})
export class EmblemElementFormComponent extends SubformComponent {

  static buildFormGroup(emblem, destroyable = true) {
    return new UntypedFormGroup({
      id:               	new UntypedFormControl(emblem.id || null),
      name:				new UntypedFormControl(emblem.name || ''),
      emblem_type:   		new UntypedFormControl(emblem.emblem_type || ''),
      proof_of_heritage:  new UntypedFormControl(emblem.proof_of_heritage || false),
      ...this.destroyableControl(emblem.id, destroyable)
    })
  }

}
