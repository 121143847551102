<form class="user-form" [formGroup]="form" (submit)="save()">
  <mat-form-field>
    <input matInput formControlName="name">
    <mat-label>Name</mat-label>
    <mat-error inscError errorControlName="name"></mat-error>
  </mat-form-field>
  <mat-form-field>
    <input matInput formControlName="email">
    <mat-label>E-Mail</mat-label>
    <mat-error inscError errorControlName="email"></mat-error>
  </mat-form-field>

  <div class="row">
    <mat-form-field>
      <input matInput formControlName="first_name">
      <mat-label>Vorname</mat-label>
      <mat-error inscError errorControlName="first_name"></mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput formControlName="last_name">
      <mat-label>Nachname</mat-label>
      <mat-error inscError errorControlName="last_name"></mat-error>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>Passwort</mat-label>
      <input matInput type="password" placeholder="unverändert" formControlName="password"  />
      <mat-error inscError errorControlName="password"></mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-label>Passwort wiederholen</mat-label>
      <input
        matInput
        type="password"
        placeholder="unverändert"
        formControlName="password_confirmation"
        />
      <mat-error inscError errorControlName="password_confirmation"></mat-error>
    </mat-form-field>
  </div>

  <h4>Berechtigungen</h4>
  <div class="permissions" formGroupName="permissions">
    <mat-checkbox formControlName="update_records">Datensätze anlegen und bearbeiten</mat-checkbox>
    <mat-checkbox formControlName="update_vocabularies">Vokabulareinträge anlegen und bearbeiten</mat-checkbox>
    <mat-checkbox formControlName="update_knowledge">Registereinträge anlegen und bearbeiten</mat-checkbox>
    <mat-checkbox formControlName="approval">Datensätze freigeben</mat-checkbox>
    <mat-checkbox formControlName="manage_users">Benutzer und Rechte verwalten</mat-checkbox>
  </div>
  <mat-error inscError errorControlName="permissions"></mat-error>

  <div>
    @if (form.value.id) {
      @if (userCanSave && form.value.disabled == false) {
        <button mat-button color="warn" type="button"
          (click)="disable()">Account deaktivieren
        </button>
      }
      @if (userCanSave && form.value.disabled === true) {
        <button mat-button type="button" (click)="enable()">Account
          aktivieren
        </button>
      }
    }
    @if (userCanSave && form.value.disabled == false) {
      <button mat-button type="submit">Speichern</button>
    }
  </div>
</form>
