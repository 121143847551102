<insc-data-group>
  <div inscDataField>
    <div *inscFieldLabel>Spezifizierung</div>
    <div *inscFieldValue>{{ origin.specification }}</div>
  </div>
  <div inscDataField>
    <div *inscFieldLabel>Lokalisierungs&shy;grundlage</div>
    <div *inscFieldValue>{{ origin.based_on }}</div>
  </div>
  <div inscDataField>
    <div *inscFieldLabel>Kommentar</div>
    <div *inscFieldValue>{{ origin.comment }}</div>
  </div>
</insc-data-group>
