import { Injectable } from '@angular/core'
import { Observable } from "rxjs"

export type ConfirmationFunc = () => Observable<boolean>

@Injectable({
  providedIn: 'root'
})
export class GlobalConfirmNavigationService {

  private _confirmationFunc: ConfirmationFunc | null

  confirmNavigationWith(confirmationFunc: ConfirmationFunc) {
    this._confirmationFunc = confirmationFunc
  }

  allowNavigation() {
    this._confirmationFunc = null
  }

  canNavigate() {
    return this._confirmationFunc ? this._confirmationFunc() : true
  }

  constructor() { }
}
