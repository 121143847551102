import { Injectable } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { IPTCDataset } from "../multi-iptc-editor/iptc-dataset.service"
import { TableIptcEditorDialogComponent, TableIptcEditorDialogData, TableIptcEditorDialogRef } from "./table-iptc-editor-dialog.component"

@Injectable({providedIn: 'root'})
export class TableIptcEditorDialogService {

  constructor(private dialog: MatDialog) {}

  open(data: TableIptcEditorDialogData): TableIptcEditorDialogRef {
    return this.dialog.open<TableIptcEditorDialogComponent, TableIptcEditorDialogData, IPTCDataset[]>(
      TableIptcEditorDialogComponent, {
        width:     "95vw",
        maxWidth:  "95vw",
        height:    "95vh",
        maxHeight: "95vh",
        data
      })
  }
}
