import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { CanDeactivateGuard } from "../../services/can-deactivate-guard.service"
import { FormLeaveGuard } from "../../services/form-leave-guard.service"
import { UserResolver } from "../user-management/user-management-page/resolvers/user-resolver.service"
import { InscObjectResolver } from "./insc-object.resolver"
import { InscriptionDetailComponent } from "./inscription-page/inscription-detail.component"
import { InscriptionFormComponent } from "./inscription-page/inscription-form.component"
import { InscriptionPageComponent } from "./inscription-page/inscription-page.component"
import { InscriptionResolver } from "./inscription.resolver"
import { ObjectDetailComponent } from "./object-page/object-detail.component"
import { ObjectFormComponent } from "./object-page/object-form.component"
import { ObjectPageComponent } from "./object-page/object-page.component"

const routes: Routes = [
  {
    path: "objects", children: [
      { path: "", component: ObjectPageComponent, children: [
        { path: "new", component: ObjectFormComponent, canDeactivate: [FormLeaveGuard] }
      ]},
      { path: ":objectId", resolve: {insc_object: InscObjectResolver},  runGuardsAndResolvers: "always", children: [
        { path: "", component: ObjectPageComponent, children: [
          { path: "", component: ObjectDetailComponent, canDeactivate: [CanDeactivateGuard]},
          { path: "edit", component: ObjectFormComponent, canDeactivate: [FormLeaveGuard]},
        ]},

        { path: "inscriptions", component: InscriptionPageComponent, children: [
          { path: "new", component: InscriptionFormComponent, canDeactivate: [FormLeaveGuard] }
        ]},
        { path: "inscriptions/:inscriptionId", component: InscriptionPageComponent, resolve: { inscription: InscriptionResolver }, runGuardsAndResolvers: "always", children: [
          { path: "edit", component: InscriptionFormComponent, canDeactivate: [FormLeaveGuard] },
          { path: "", component: InscriptionDetailComponent, canDeactivate: [CanDeactivateGuard]}
        ]},
        { path: "inscriptions/:cloneInscriptionId", resolve: {inscription: InscriptionResolver}, component: InscriptionPageComponent, children: [
          { path: "clone", component: InscriptionFormComponent, canDeactivate: [FormLeaveGuard] }
        ]}
      ]},

      { path: ":cloneObjectId", component: ObjectPageComponent, resolve: {insc_object: InscObjectResolver }, children: [
        { path: "clone", component: ObjectFormComponent }
      ]}
    ]
  }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [FormLeaveGuard, CanDeactivateGuard, UserResolver]
})
export class RecordsRoutingModule { }
