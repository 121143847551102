<h2 matDialogTitle>Registereintag auswählen</h2>
<mat-tab-group matDialogContent class="content" [selectedIndex]="getSelectedIndex()" [dynamicHeight]="false">
  @for (type of dialogOptions.types; track type) {
    @switch (type) {
      @case ('locations') {
        <mat-tab [label]="tabLabels[type]" bodyClass="tab-body">
          <ng-template matTabContent>
            <insc-location-register-editor class="editor" #editorComp
              [allowEdit]="allowEdit"
              [showSidebar]="false"
              [selectedId]="getSelectedId(type)"
            ></insc-location-register-editor>
          </ng-template>
        </mat-tab>
      }
      @default {
        <mat-tab [label]="tabLabels[type]" bodyClass="tab-body">
          <ng-template matTabContent>
            <insc-basic-register-editor
              #editorComp
              class="editor"
              [showSidebar]="false"
              [selectedId]="getSelectedId(type)"
              [type]="type"
              [allowEdit]="allowEdit"
              [allowNN]="dialogOptions.allowNN"
            ></insc-basic-register-editor>
          </ng-template>
        </mat-tab>
      }
    }
  }
</mat-tab-group>

<mat-divider></mat-divider>
@if (editorLoaded) {
  <mat-dialog-actions align="end">
    <button mat-button #cancelButton type="button" (click)="close(null)">Abbrechen</button>
    <button mat-button #chooseButton type="button" [disabled]="!canSelect()"
      (click)="onChooseButtonClick()" color="primary">
      Auswählen
    </button>
  </mat-dialog-actions>
}

