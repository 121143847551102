import { Injectable } from "@angular/core"
import { MatDialog, MatDialogConfig } from "@angular/material/dialog"
import { ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogRef } from "./confirmation-dialog.component"

@Injectable({providedIn: "root"})
export class ReusableDialogsService {

  private readonly unsavedChangesDialogConfig: MatDialogConfig = {
    data: {
      message:   `Achtung! Beim Fortfahren gehen die nicht gespeicherten Änderungen verloren.`,
      yesAction: "FORTFAHREN UND ÄNDERUNGEN VERWERFEN",
      noAction:  "ÄNDERUNGEN BEHALTEN",
      icon:      "warning"
    }
  }

  constructor(private dialog: MatDialog) {}

  openUnsavedChangesConfirmationDialog(): ConfirmationDialogRef {
    return this.dialog.open(ConfirmationDialogComponent, this.unsavedChangesDialogConfig)
  }

  openUnsavedChangesDialogConfigWhen(conditionFunc: () => boolean, actionFunc: () => void): void {
    if (conditionFunc()) {
      this.dialog.open(ConfirmationDialogComponent, this.unsavedChangesDialogConfig).afterClosed().subscribe(confirmed => {
        if (confirmed) {
          actionFunc()
        }
      })
    } else {
      actionFunc()
    }
  }

  openConfirmationDialog(actionFunc: () => void, data: ConfirmationDialogData): void {
    this.dialog.open(ConfirmationDialogComponent, {data: data}).afterClosed().subscribe(confirmed => confirmed && actionFunc())
  }
}
