<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
  <mat-label>Sortierung</mat-label>
  <mat-select [value]="sortBy" (selectionChange)="emitSortChangeEvent($event.value)" [disabled]="disabled">
    <mat-option value="dating_asc">Datierung (aufsteigend)</mat-option>
    <mat-option value="dating_desc">Datierung (absteigend)</mat-option>
    <mat-option value="name">Bezeichnung</mat-option>
    <mat-option value="updated_at">Zuletzt bearbeitet</mat-option>
    <mat-option value="created_at">Zuletzt angelegt</mat-option>
  </mat-select>
</mat-form-field>
