import { Directive, EventEmitter, Input, NgZone, Output } from "@angular/core"
import { of, Subject } from "rxjs"
import { Options } from "sortablejs"
import { InscImage } from "../../../shared/models/image.model"

export type ImageSelection = InscImage["id"]

@Directive({
  selector: '[inscImageManagement]',
  exportAs: 'imageManagement'
})
export class ImageManagementDirective {

  @Input() inscImageManagement: string

  readonly dragGroupId: string

  sortableJsOptions: Options

  private drop = new Subject<{
    source: HTMLElement
    target: HTMLElement
    sourceIndex: number
    targetIndex: number
  }>()
  drop$ = this.drop.asObservable()

  private dragging = new Subject<boolean>()
  dragging$ = this.dragging.asObservable()

  @Output() imageSelectionChange = new EventEmitter<ImageSelection[]>()
  private _selections = new Set<ImageSelection>()

  @Input() canChangeSelection = () => of(true)

  get selections() { return Array.from(this._selections.values())}
  set selections(selections: ImageSelection[]) {
    this._selections.clear()
    selections.forEach(selection => this._selections.add(selection))
    this.imageSelectionChange.emit(this.selections)
  }

  constructor(
    private zone: NgZone
  ) {
    this.dragGroupId = `${this.inscImageManagement}_dragGroup`

    this.sortableJsOptions = {
      group: this.dragGroupId,
      animation: 150,
      emptyInsertThreshold: 100,
      onStart: () => this.zone.run(() => this.dragging.next(true)),
      onEnd: () => this.zone.run(() => this.dragging.next(false)),
      onAdd: event => this.drop.next({
        source: event.from,
        target: event.to,
        sourceIndex: event.oldIndex,
        targetIndex: event.newIndex
      }),
      onUpdate: event => this.drop.next({
        source: event.from,
        target: event.to,
        sourceIndex: event.oldIndex,
        targetIndex: event.newIndex
      })
    }
  }

  isSelected(id: ImageSelection) {
    return this._selections.has(id)
  }

  select(id: ImageSelection) {
    this.canChangeSelection().subscribe(canChange => {
      if (canChange) {
        this._selections.add(id)
        this.emitChange()
      }
    })
  }

  deselect(id: ImageSelection) {
    this.canChangeSelection().subscribe(canChange => {
      if (canChange) {
        this._selections.delete(id)
        this.emitChange()
      }
    })
  }

  toggle(id: ImageSelection) {
    if (this.isSelected(id)) {
      this.deselect(id)
    } else {
      this.select(id)
    }
  }


  exclusivelySelect(id: ImageSelection) {
    this.selections = [id]
  }

  exclusivelyToggle(id: ImageSelection) {
    this.selections = this.isSelected(id) ? [] : [id];
  }

  clear() {
    this.selections = []
  }

  // updateSelection(selection: ImageSelection, updatedImage: ImageSelection) {
  //   this._selections.delete(selection)
  //   this._selections.add(updatedImage)
  //   this.emitChange()
  // }

  private emitChange() {
    this.imageSelectionChange.emit(this.selections)
  }

}

