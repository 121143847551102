import { AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { ReusableDialogsService } from "../../../shared/dialogs/reusable-dialogs.service"
import { ImageUploadDialogData } from "./image-upload-dialog.service"
import { ImageUploaderComponent } from "./image-uploader.component"

export type NewImageUploadDialogRef = MatDialogRef<ImageUploadDialogComponent, boolean>

@Component({
  selector:    'insc-image-upload-dialog',
  templateUrl: './image-upload-dialog.component.html',
  styleUrls:   ['./image-upload-dialog.component.scss']
})
export class ImageUploadDialogComponent implements OnInit, AfterViewInit {

  @ViewChild(ImageUploaderComponent) uploader: ImageUploaderComponent

  constructor(
    @Inject(MatDialogRef) private dialogRef: NewImageUploadDialogRef,
    @Inject(MAT_DIALOG_DATA) readonly data: ImageUploadDialogData,
    private reusableDialogs: ReusableDialogsService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.uploader.openFileChooser()
  }

  close() {
    const unfinishedItemCount = this.uploader.itemsForUpload.length

    if (unfinishedItemCount > 0) {
      this.reusableDialogs.openConfirmationDialog(
        () => this.dialogRef.close(),
        {
          message: `Es ${
            unfinishedItemCount === 1 ? "befindet sich eine Datei" : `befinden sich ${unfinishedItemCount} Dateien`
          } in der Warteschlange, die noch nicht hochgeladen wurden.`,
          yesAction: "Dialog schließen und Dateien nicht hochladen",
          noAction: "Warteschlange beibehalten"
        }
      )
    } else {
      this.dialogRef.close(this.uploader.didUploadSomething)
    }
  }

}
