// https://github.com/angular/angular/commit/17eaef0311990e1a06c028f19bc6c63dcd775f31#diff-34807b3cec1b87c271211f0cc09e4716

import { Component, Host, Input, Optional, SkipSelf } from "@angular/core"
import { AbstractControl, ControlContainer, UntypedFormGroup } from "@angular/forms"


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  standalone: true,
  selector: 'mat-error[inscError]',
  template: `
	  {{ error }}
  `,
  styles: []
})
export class InscFormFieldErrorMessageComponent {

  private _parent: ControlContainer|null = null
  @Input() errorControlName: string
  @Input() errorControl: AbstractControl

  get error(): string | null {

    const formGroup = this._parent && this._parent.control as UntypedFormGroup
    const control =
      this.errorControlName
        ? formGroup.get(this.errorControlName)
        : this.errorControl

    if (control.hasError('invalid')) {
      return control.getError('invalid')
    }

    return null
  }

  constructor(@Optional() @Host() @SkipSelf() parent: ControlContainer) {
    this._parent = parent
  }

}
