<h2 mat-dialog-title>
  Originalmetadaten für <em>{{data.filename}}</em> <span class="image-name">{{data.imageTitle}}</span>
</h2>
<mat-dialog-content>
  @if (data.metadata$ | async; as metadata) {
    <insc-image-file-metadata-view [metadata]="metadata"></insc-image-file-metadata-view>
  } @else {
    <div class="loading-view">
      <mat-spinner/>
    </div>
  }
  <ng-template #loading>
    <div class="loading-view">
      <mat-spinner/>
    </div>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Schließen</button>
</mat-dialog-actions>
