import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"

import { Observable } from "rxjs"
import { debounceTime, distinctUntilChanged, pluck, switchMap } from "rxjs/operators"

import { environment } from "../../environments/environment"


import { DatingSpecification } from "../shared/models"


export type RangeDescriptionType = "day" | "century" | "year" | "around"

export interface RangeDescription {
  type: RangeDescriptionType
  desc: string
  from: string
  to: string
}

export function isRangeDescription(arg: unknown): arg is RangeDescription {
  return typeof arg === "object" && "desc" in arg && "from" in arg && "to" in arg
}

@Injectable({providedIn: 'root'})
export class DateRangeService {

  readonly baseUrl = environment.apiUrl

  constructor(private http: HttpClient) {
  }

  getMatchingRanges(input: Observable<string>): Observable<RangeDescription[]> {
    return input.pipe(debounceTime(300),
      distinctUntilChanged(),
      switchMap(inputValue =>
        this.http.get<{ ranges: RangeDescription[] }>(`${this.baseUrl}/date_ranges?q=${inputValue}`).pipe(
          pluck("ranges")
        )
      )
    )
  }

  getSpecification(primary: string, secondary: string, range_modifier: string = "closed"): Observable<DatingSpecification | null> {
    return this.http.get<{dating_spec: DatingSpecification}>(`${this.baseUrl}/dating_spec?primary=${primary}&secondary=${secondary ? secondary : ""}&range_modifier=${range_modifier}`)
      .pipe(pluck("dating_spec"))
  }
}
