import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef
} from "@angular/core"
import { ImageDownloadService } from "../../../../services/image-download.service"
import { ImageViewerService } from "../../../../shared/components/image-viewer/image-viewer.service"
import { ReusableDialogsService } from "../../../../shared/dialogs/reusable-dialogs.service"
import { ImageParent } from "../../../../shared/models/image.model"
import { ImageManagementGroupComponent } from "../../../images/image-management/image-management-group.component"
import { ImageManagementDirective } from "../../../images/image-management/image-management.directive"

const containerImageMarginCSSVariable = "--insc-record-image-list-image-margin"

@Directive({
  selector: "[inscRecordImageListTitle]"
})
export class RecordImageListTitleDirective {
  constructor(public templateRef: TemplateRef<void>) {}
}

@Directive({
  selector: "[inscRecordImageListSubtitle]"
})
export class RecordImageListSubtitleDirective {
  constructor(public templateRef: TemplateRef<void>) {}
}

/**
 * Directive that applies a margin to the images acting as grid gap
 */
@Directive({
  selector: "[inscRecordImageListImage]"
})
export class RecordImageListImageDirective {
  @HostBinding("style.margin-left") marginLeft = `var(${containerImageMarginCSSVariable})`;
  constructor(private recordImageListContainer: RecordImageListContainerComponent) {}
}

@Component({
  selector:    'insc-record-image-list-container',
  templateUrl: './record-image-list-container.component.html',
  styleUrls:   ['./record-image-list-container.component.scss', '../../record-detail-styles.scss']
})
export class RecordImageListContainerComponent implements OnInit, AfterViewInit {

  @Input() recordName: string
  @Input() showDownloadButton: boolean = false
  @Input() imageParent: ImageParent

  @Output() linkImageClicked = new EventEmitter<void>()
  @Output() uploadImagesClicked = new EventEmitter<void>()
  @Output() unlinkClicked = new EventEmitter<string[]>()

  @ContentChild(ImageManagementGroupComponent) imageGroup: ImageManagementGroupComponent

  @ContentChild(RecordImageListTitleDirective, {read: TemplateRef}) titleTpl: TemplateRef<void>
  @ContentChild(RecordImageListSubtitleDirective, {read: TemplateRef}) subtitleTpl: TemplateRef<void>

  protected contentWrapperStyle = {'margin-left': `calc(-1 * var(${containerImageMarginCSSVariable}))`}

  get hasSubtitle() {
    return !!this.subtitleTpl
  }

  constructor(
    public imageManagement: ImageManagementDirective,
    // public imageViewer: ImageViewerService,
    private reusableDialogs: ReusableDialogsService,
    private imageDownloadService: ImageDownloadService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.cdr.detectChanges()
  }

  unlinkSelected() {
    const selectedImages = this.imageGroup.selectedImages()
    this.reusableDialogs.openConfirmationDialog(
      () => this.unlinkClicked.emit(selectedImages.map(image => image.id)),
      {
        message: `Soll die Verknüpfung der ${selectedImages.length} ausgewählte(n) Aufnahme zu diesem Datensatz wirklich entfernt werden?`,
        yesAction: "Verknüpfung(en) entfernen",
        noAction: "Verknüpfung(en) behalten"
      }
    )
  }

  // getDownloadLink() {
  //   const ids = this.imageGroup.images.map(image => image.id)
  //   return this.imageDownloadService.getDownloadLink(ids)
  // }

}
