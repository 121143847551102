import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { catchError, map } from "rxjs/operators"
import { AbstractExternalNormDataProviderService, ExternalNormDataLookupResult } from "../../abstract-external-norm-data-provider.service"
import { GermaniaSacraPersonRecord, GermaniaSacraPersonResponse } from "./germania-sacra-person-api"
import { getTimeframeString } from "./germania-sacra-person-formatting-helpers"

@Injectable({
  providedIn: 'root'
})
export class GermaniaSacraPersonNormDataProviderService extends AbstractExternalNormDataProviderService<GermaniaSacraPersonRecord> {

  private readonly baseUrl = ` https://personendatenbank.germania-sacra.de/api/v1.0`

  constructor(private http: HttpClient) {
    super(http)
  }

  getValidity(id: string): Observable<boolean> {
    return this.http.get<GermaniaSacraPersonResponse>(`${this.baseUrl}/person?gsn=${id}&format=json`).pipe(
      map(res => Boolean(res.records?.length > 0) )
    )
  }

  getExternalLink(id: string): string {
    return `http://germania-sacra-datenbank.uni-goettingen.de/index/gsn/${id}`
  }

  getEntryData(id: string): Observable<GermaniaSacraPersonRecord> {
    return this.http.get<GermaniaSacraPersonResponse>(`${this.baseUrl}/person?gsn=${id}&format=json`).pipe(
      map(response => response.records?.[0])
    )
  }

  protected descriptionFromEntryData(entryData: GermaniaSacraPersonRecord): string {
    return [
      entryData.person.familienname,
      [entryData.person.vorname, entryData.person.namenspraefix].filter(Boolean).join(" ")
    ].filter(Boolean).join(", ")
  }

  get lookupAvailable(): boolean { return true }

  lookup(queryString: string): Observable<ExternalNormDataLookupResult[]> {
    return this.http.get<GermaniaSacraPersonResponse>(`${this.baseUrl}/person?name=${queryString}&format=json`).pipe(
      catchError((err: unknown) => this.handleLookupError(err)),
      map(response => response.records.map(record => ({
        id: record["item.gsn"][0].nummer,
        title: this.getResultTitle(record),
        description: this.getResultDescription(record)
      })))
    )
  }

  private getResultTitle(record: GermaniaSacraPersonRecord) {
    const timeframeString = getTimeframeString(record.person)
    if (timeframeString) {
      return `${this.descriptionFromEntryData(record)} (${timeframeString})`
    }

    return this.descriptionFromEntryData(record)
  }

  private getResultDescription(record: GermaniaSacraPersonRecord) {
    const parts = record.aemter.reduce((descStrings, amt) => {
      const orte = amt.ort === amt.dioezese
        ? [amt.ort]
        : [amt.ort, amt.dioezese]
      const filteredOrte = orte.filter(ort => !amt.institution.includes(ort))
      return descStrings.concat(amt.institution, ...filteredOrte)
    }, [record.person.titel, record.person.taetigkeit] as string[])

    const descString = parts.filter(Boolean).join(" | ")
    return descString.length > 0 ? descString : record.fundstellen.map(stelle => stelle.kurztitel).join(" | ")
  }


}
