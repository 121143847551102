import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from "@angular/forms"
import { MatOptionModule } from "@angular/material/core"
import { MatSelectModule } from "@angular/material/select"
import { RouterModule, Routes } from "@angular/router"
import { ActivitiesPageComponent } from "./activities-page.component"

const routes: Routes = [
  { path: "admin/activities", component: ActivitiesPageComponent }
]

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    ReactiveFormsModule,

    MatOptionModule, MatSelectModule
  ],
  exports: [
    RouterModule
  ]
})
export class ActivitiesPageRoutingModule { }
