import { Component, ContentChildren, ElementRef, Input, QueryList } from "@angular/core"
import { ControlContainer, UntypedFormArray } from "@angular/forms"
import { FormService } from "../../../../services/form.service"
import { RepeatableFormComponent } from "../repeatable-form/repeatable-form.component"

@Component({
  selector: 'insc-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss']
})
export class FormSectionComponent {

  @Input() sectionTitle: string
  @Input() showTitle = true

  get hasRepeatableForm() {return this.controlContainer && this.controlContainer.control instanceof UntypedFormArray}
  get numberOfItems() { return this.hasRepeatableForm && (<UntypedFormArray>this.controlContainer.control).length}

  @ContentChildren(RepeatableFormComponent) repeatableForms: QueryList<RepeatableFormComponent>

  constructor(
    private controlContainer: ControlContainer,
    private formService: FormService,
    private elementRef: ElementRef
  ) { }


  add() {
    if (typeof this.controlContainer.name === "number") {
      throw new Error("ControlContainer name needs to be a string, not a number.")
    }
    this.formService.addNewSubformItem(this.controlContainer.control, this.controlContainer.name)

    setTimeout(() => {
      const lastForm = this.repeatableForms.last
      lastForm.ripple.launch({})
      lastForm.scrollIntoView()
    })
  }

  scrollIntoView() {
    const elem: HTMLElement = this.elementRef.nativeElement
    elem.scrollIntoView({block: "nearest", behavior: "smooth", inline: "nearest"})
  }

}

