import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { SortablejsModule } from "nxt-sortablejs"
import { ImageManagementGroupComponent } from "./image-management-group.component"
import { ImageManagementDirective } from "./image-management.directive"
import { ImageTileComponent } from "./image-tile.component"


@NgModule({
  declarations: [
    ImageManagementGroupComponent,
    ImageManagementDirective,
    ImageTileComponent
  ],
  exports: [
    ImageManagementGroupComponent,
    ImageManagementDirective,
    ImageTileComponent
  ],
  imports: [
    CommonModule,
    SortablejsModule
  ]
})
export class ImageManagementModule { }
