<ng-container [formGroup]="formGroup">

  <insc-person-organization-input formControlName="entry" [types]="['historical_persons', 'historical_organizations']" [allowNN]="true"></insc-person-organization-input>

  @if (showExtraFields) {
    <div class="extra-fields">
      <mat-form-field>
        <input matInput type="text" formControlName="role">
        <mat-label>Rolle</mat-label>
        <mat-error inscError errorControlName="role"></mat-error>
      </mat-form-field>
      <mat-form-field class="authenticity">
        <input matInput type="text" formControlName="authenticity">
        <mat-label>Authentizität</mat-label>
        <mat-error inscError errorControlName="authenticity"></mat-error>
      </mat-form-field>
    </div>
  }
</ng-container>
