import { Component, OnInit } from "@angular/core"

import { Observable } from "rxjs"
import { VocabularyService } from "../../services/vocabulary.service"

import { Vocabulary } from "../../shared/models"

@Component({
  selector:    'insc-vocabularies-page',
  templateUrl: './vocabularies-page.component.html',
  styleUrls:   ['./vocabularies-page.component.scss']
})
export class VocabulariesPageComponent implements OnInit {

  vocabularies$: Observable<Vocabulary[]>

  constructor(
    private vocabularyService: VocabularyService,
  ) { }

  ngOnInit(): void {
    this.vocabularies$ = this.vocabularyService.getVocabularies()
  }

}


