import { Injectable } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { InscImage } from "../../../shared/models/image.model"
import {
  ImageMetadataPickerDialogComponent,
  ImageMetadataPickerDialogData,
  ImageMetadataPickerDialogRef,
  ImageMetadataPickerDialogResult
} from "./image-metadata-picker-dialog.component"

@Injectable({
  providedIn: 'root'
})
export class ImageMetadataPickerDialogService {

  constructor(private dialog: MatDialog) { }

  open(editedImages: Partial<InscImage>[]): ImageMetadataPickerDialogRef {
    return this.dialog.open<ImageMetadataPickerDialogComponent, ImageMetadataPickerDialogData, ImageMetadataPickerDialogResult>(
      ImageMetadataPickerDialogComponent,
      {
        data: { editedImages },
        height: "90vh",
        maxWidth: "95vw",
        disableClose: true
      }
    )
  }
}
