<h2 mat-dialog-title class="link-result-heading">Neue Objektgruppe erstellen und zuweisen</h2>
<form mat-dialog-content [formGroup]="form">
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>Art der Objektgruppe</mat-label>
    <mat-select formControlName="object_group_type" >
      <mat-option value="fragments">Fragmente</mat-option>
      <mat-option value="cycle">Zyklus</mat-option>
      <mat-option value="ensemble">Ensemble</mat-option>
    </mat-select>
  </mat-form-field>
	<mat-form-field subscriptSizing="dynamic">
		<input matInput  formControlName="name">
		<mat-label>Name der neuen Gruppe</mat-label>
	</mat-form-field>
</form>
<mat-dialog-actions align="end">
	 <button mat-button (click)="dialogRef.close()">
	   Abbrechen
	 </button>
  <button mat-button (click)="confirm()" [disabled]="form.invalid" color="primary">
    Speichern und zuweisen
  </button>
</mat-dialog-actions>
