<a class="link-overlay"  routerLink="/objects/{{result.id}}" (click)="launchRipple($event)"></a>
<mat-card matRipple appearance="raised" class="content-container">
  <div class="content">
    <div class="image-wrapper">
      @if (result.images[0]) {
        <img class="image"  [src]="result.images[0]['preview_url']">
      } @else {
        <div class="image-missing"></div>
      }
    </div>
    <div class="content-wrapper">
      <div class="text-wrapper">
        <h2 class="title">
          <span [innerHtml]="highlight.name || result.name"></span>
          @if (result['object_group']) {
            <span class="object-group" [innerHtml]="', ' + (highlight['object_group.name'] || result['object_group'].name)"></span>
          }
        </h2>

        @if (result['current_locationing']?.location?.city) {
          <div><span class="city" [innerHtml]="getAnnotatedLocationName(highlight['current_locationing']?.location?.city) || getAnnotatedLocationName(result['current_locationing']?.location?.city)"></span></div>
        }
        @if (!result['current_locationing']?.location?.city) {
          <div style="font-style: italic;">z. Zt. kein Standort zugeordnet</div>
        }
        @if (result['datings'].length > 0) {
          <div><span class="city">{{result['datings'][0].description}}</span></div>
        }
      </div>
      @if (result['inscriptions']?.length > 0) {
        <div class="inscriptions-wrapper" >
          <insc-result-card-link-list title="Inschriften">
            @for (inscription of result['inscriptions']; track inscription; let i = $index) {
              <insc-result-card-link-list-entry [link]="['/objects', result.id, 'inscriptions', inscription.id]">
                {{ inscription.name }}
              </insc-result-card-link-list-entry>
            }
          </insc-result-card-link-list>
        </div>
      }
    </div>
  </div>
</mat-card>
