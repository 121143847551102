import { Component, Input } from "@angular/core"
import { HistoricalOrganizationEntry } from "../../../../shared/models"

@Component({
  selector:    'insc-historical-organization-entry-view',
  templateUrl: './historical-organization-entry-view.component.html',
  styleUrls:   ['./historical-organization-entry-view.component.scss']
})
export class HistoricalOrganizationEntryViewComponent {
  @Input() historicalOrganizationEntry: HistoricalOrganizationEntry
  @Input() disabled = false
}
