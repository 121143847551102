import { Injectable } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"


import { ConfirmationDialogComponent } from "../shared/dialogs/confirmation-dialog.component"

export interface FormComponent {
  readonly form: UntypedFormGroup
}

@Injectable({providedIn: "root"})
export class FormLeaveGuard  {

  constructor(private dialog: MatDialog) {}

  canDeactivate(component: FormComponent) {
    if (component.form.dirty) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {data: {message: `Achtung! Beim Fortfahren gehen die nicht gespeicherten Änderungen verloren.`, yesAction: "FORTFAHREN UND ÄNDERUNGEN VERWERFEN", noAction: "ÄNDERUNGEN BEHALTEN", icon: "warning"}})
      return dialogRef.afterClosed()
    }

    return true
  }
}
