import { Injectable } from "@angular/core"
import { HistoricalOrganizationEntry } from "../../../../../../shared/models/historical-organization-entry.model"
import { ExternalNormDataMapperInterface } from "../../external-norm-data-mapper.interface"
import { GermaniaSacraMonasteryRecord } from "./germania-sacra-monastery-api"

@Injectable({
  providedIn: 'root'
})
export class GermaniaSacraMonasteryToInscHistoricalOrganizationMapper  implements ExternalNormDataMapperInterface<GermaniaSacraMonasteryRecord, Partial<HistoricalOrganizationEntry>> {

  map(normDataRecord: GermaniaSacraMonasteryRecord): Partial<HistoricalOrganizationEntry> {
    return {
      name: normDataRecord.name,
      germania_sacra_id: normDataRecord.gsnId,
      timeframe: normDataRecord.orderTimeRange.join(" – "),
      comment: normDataRecord.note
    }
  }
}
