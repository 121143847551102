import { Component, HostBinding, Inject, Injectable, ViewContainerRef } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog"
import { Observable } from "rxjs"
import { AuthService } from "../../../../services/auth.service"
import { Vocabulary } from "../../../models/vocabulary.model"

export interface VocabularyEditorDialogData {
  vocabulary$: Observable<Vocabulary>
}

@Injectable({providedIn: 'root'})
export class VocabularyEditorDialogService {

  constructor(private dialog: MatDialog) {
  }

  open(vocabulary$: Observable<Vocabulary>, viewContainerRef?: ViewContainerRef): MatDialogRef<VocabularyEditorDialogComponent> {
    return this.dialog.open(VocabularyEditorDialogComponent, {
      data: { vocabulary$ },
      height: '80vh',
      width: '500px',
      panelClass: "vocabulary-editor-dialog-panel",
      viewContainerRef
    })
  }
}


@Component({
  selector: 'insc-vocabulary-editor-dialog',
  template: `
      @if (data.vocabulary$ | async; as vocabulary) {
        <h2 matDialogTitle>{{ vocabulary.display }}</h2>
        <insc-vocabulary-editor matDialogContent [editable]="hasEditPermission | async" [vocabulary]="vocabulary"></insc-vocabulary-editor>
        <mat-dialog-actions align="end">
          <button mat-button [matDialogClose]="true">Schließen</button>
        </mat-dialog-actions>
      }
      `,
  styles: [`
             :host {
               display: grid;
               grid-template-rows: auto 1fr auto;
               grid-template-areas: "head" "content" "foot";

               height: 100%;
             }

             h2 { grid-area: head; }
             insc-vocabulary-editor { grid-area: content; }
  `]
})
export class VocabularyEditorDialogComponent {

  hasEditPermission = this.auth.permission('update_vocabularies')

  @HostBinding("attr.class") class = "vocabulary-select-dialog"

  constructor(
    public dialogRef: MatDialogRef<VocabularyEditorDialogComponent>,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: VocabularyEditorDialogData
  ) {}
}
