import { Component, Input } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { country_codes } from "../../../../shared/country_codes"
import { ObjectLocation } from "../../../../shared/models/object-location.model"
import { SubformComponent } from "../../../../shared/subform.component"
import { AddressFormComponent } from "../../shared/subforms/address-form.component"


@Component({
  selector:    'insc-location-form',
  templateUrl: './location-form.component.html',
  styleUrls:   ['./location-form.component.scss', '../../shared/register-form-styles.scss']
})
export class LocationFormComponent extends SubformComponent {

  static readonly locationTypesWithAddress = [
    "MuseumCollection", "Manager", "PrivateProperty", "FreeSite", "Building"
  ]

  readonly country_codes = country_codes

  @Input() parentType: string

  readonly validChildTypes = {
    City: ["PrincipalTown", "SubLocation", "MuseumCollection", "PrivateProperty", "Manager"],
    PrincipalTown: ["FreeSite", "Building"],
    SubLocation: ["FreeSite", "Building"],
    Building: ["FreeSite", "Building"],
    FreeSite: ["FreeSite", "Building"]
  }

  readonly typeNames = {
    City: "Stadt/Gemeinde",
    PrincipalTown: "Hauptort",
    SubLocation: "Ortsteil",
    MuseumCollection: "Museum/Sammlung",
    PrivateProperty: "Privatbesitz",
    Manager: "Übrige Verwalter",
    FreeSite: "Freier Standort",
    Building: "Bauwerk"
  }

  static buildFormGroup(location: Partial<ObjectLocation>) {
    const locationType = location.type || (location.parent_id ? '' : 'City')

    // optional controls that only some location types have
    const addressSubform = this.locationTypesWithAddress.includes(locationType) && {
      address: AddressFormComponent.buildFormGroup(location.address, false)
    }

    return new FormGroup({
      id:                      new FormControl(location.id || null),
      parent_id:               new FormControl(location.parent_id || null),
      name:                    new FormControl(location.name || ""),
      type:                    new FormControl(locationType),
      lost:                    new FormControl(location ? location.lost || false : false),
      description:             new FormControl(location.description || ""),
      district:                new FormControl(location.district || null),
      state:                   new FormControl(location.state || null),
      administrative_district: new FormControl(location.administrative_district || null),
      country_code:            new FormControl(location ? location.country_code || "DE" : "DE"),
      district_key:            new FormControl(location.district_key || null),
      district_type:           new FormControl(location.district_type || null),
      geonames_id:             new FormControl(location.geonames_id),
      wikidata_id:             new FormControl(location.wikidata_id),
      lat:                     new FormControl(location.lat || null),
      lng:                     new FormControl(location.lng || null),
      ...addressSubform,
    })
  }


}
