<!--suppress TypeScriptUnresolvedVariable -->
<mat-menu #newLocationSubmenu>
  <ng-template matMenuContent let-node="node" let-childType="childType">
    <button mat-menu-item (click)="createLocation(node, childType)">Leerer Datensatz</button>
    <button mat-menu-item (click)="createLocation(node, childType, 'wikidata')">Aus Wikidata</button>
    <button mat-menu-item (click)="createLocation(node, childType, 'geonames')">Aus Geonames</button>
  </ng-template>
</mat-menu>

<mat-menu class="mat-typography" #addMenu="matMenu">
  <ng-template matMenuContent let-node="node">
    <div class="insc-location-tree-add-menu-header mat-caption">Mögliche Unterstandorte für {{locationTypeDefs.locationTypeName(node.type)}} <b>{{node.item}}</b></div>
    @for (validChildType of locationTypeDefs.validChildrenForLocationType(node.type); track validChildType) {
      <button mat-menu-item
        [matMenuTriggerFor]="newLocationSubmenu"
        [matMenuTriggerData]="{node: node, childType: validChildType}">
        <mat-icon [svgIcon]="validChildType"></mat-icon>
        {{locationTypeDefs.locationTypeName(validChildType)}}
      </button>
    }
  </ng-template>
</mat-menu>

<ng-template #externalNormDataIcons let-node="node">
  @if (!node.isLoading) {
    @if (node.has_geonames_id) {
      <mat-icon class="normdata-icon" [inline]="true" svgIcon="geonames"></mat-icon>
    }
    @if (node.has_wikidata_id) {
      <mat-icon class="normdata-icon" [inline]="true" svgIcon="wikidata"></mat-icon>
    }
  }
</ng-template>

<ng-template #nodeName let-node="node">
  <div class="node-content" (click)="!isDisabled(node) && selectionHandler(node.id)">
    <mat-icon [svgIcon]="node.type"></mat-icon>
    <div class="name-wrapper" [ngClass]="{selected: _selectedId == node.id}">
      <span class="node-name">
        {{node.item}}
        <ng-container *ngTemplateOutlet="externalNormDataIcons; context:{node: node}"></ng-container>
      </span>
    </div>
    @if (allowCreateLocation) {
      <button
        mat-icon-button
        [matMenuTriggerFor]="addMenu"
        [matMenuTriggerData]="{node: node}"
        (menuOpened)="addMenuOpenForId = node.id"
        (menuClosed)="addMenuOpenForId = null"
        class="add-btn"
        matTooltip="Neuen Standort unterhalb anlegen"
        ><mat-icon>add</mat-icon></button>
      }
    </div>
  </ng-template>

  <ng-template #searchResults let-results="results">
    <div
      [@searchResultsVisibility]="queryString?.length > 0 ? 'fade-in' : 'fade-out'"
      class="search-result-overlay"
      >
      @if ($any(results)?.length > 0) {
        <mat-list class="search-result-list">
          @for (result of results; track result) {
            <mat-list-item
              (mousedown)="selectionHandler(result.id)"
              class="location-result"
              >
              <div mat-line class="search-result-line" [innerHTML]="locationDisplayHelper.getLocationNameWithLostAnnotation(result) | highlight: queryString"></div>
              <div mat-line class="search-result-line subtitle mat-caption">{{locationDisplayHelper.getLocationNameWithLostAnnotation(result.city)}}</div>
            </mat-list-item>
          }
        </mat-list>
      } @else {
        @if (!searchResultsLoading) {
          <div class="no-results">Keine Ergebnisse für <em>{{queryString}}</em></div>
        }
      }
    </div>

  </ng-template>
  <ng-container *ngTemplateOutlet="searchResults;context:{results: queryResults | async}"></ng-container>



  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" [trackBy]="trackBy">

    <!--suppress JSUnusedGlobalSymbols -->
    <mat-tree-node #nodes
      *matTreeNodeDef="let node"
      [ngClass]="getNodeClass(node)"
      matTreeNodePadding
      matTreeNodePaddingIndent="20"
      [attr.data-id]="node.id">

      <button class="expand-btn" mat-icon-button disabled></button>
      <ng-container *ngTemplateOutlet="nodeName;context:{node: node}"></ng-container>
    </mat-tree-node>

    <!--suppress JSUnusedGlobalSymbols -->
    <mat-tree-node #nodes
      *matTreeNodeDef="let node; when: hasChild"
      [ngClass]="getNodeClass(node)"
      matTreeNodePadding
      matTreeNodePaddingIndent="20"
      [attr.data-id]="node.id"
      >
      <button class="expand-btn" mat-icon-button matTreeNodeToggle [disabled]="isDisabled(node)"
        [attr.aria-label]="'toggle ' + node.item">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <ng-container *ngTemplateOutlet="nodeName;context:{node: node}"></ng-container>
      @if (node.isLoading) {
        <mat-progress-bar
          mode="indeterminate"
        class="tree-progress-bar"></mat-progress-bar>
      }
    </mat-tree-node>

    <!--suppress JSUnusedGlobalSymbols -->
    <mat-tree-node #nodes
      *matTreeNodeDef="let node; when: isNewNodeDummy"
      matTreeNodePadding
      matTreeNodePaddingIndent="20">

      <button class="expand-btn" mat-icon-button disabled></button>
      <mat-icon [svgIcon]="node.type"></mat-icon>
      <div class="name-wrapper selected"><span class="node-name selected"><em>{{node.item}}</em></span></div>
    </mat-tree-node>
  </mat-tree>
