import { Component, OnInit, ViewChild } from "@angular/core"
import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { MatDialog } from "@angular/material/dialog"
import { ActivatedRoute, GuardsCheckEnd, Router } from "@angular/router"
import { filter, map } from "rxjs/operators"
import { AuthService } from "../../../services/auth.service"
import { CanComponentDeactivate } from "../../../services/can-deactivate-guard.service"
import { ConfirmationDialogComponent } from "../../../shared/dialogs/confirmation-dialog.component"
import { ImageSidebarService } from "../../images/image-sidebar/image-sidebar.service"
import { RegisterEditorBaseAbstractComponent } from "../register-editor-base-abstract.component"

@Component({
  selector: 'insc-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements CanComponentDeactivate, OnInit {
  hasEditPermission$ = this.auth.permission('update_knowledge')
  currentType$ = this.route.paramMap.pipe(map(paramMap => paramMap.get("type")))
  currentId$ = this.route.paramMap.pipe(map(paramMap => paramMap.get("id")))
  navigation$ = this.router.events.pipe(
    filter(event => event instanceof GuardsCheckEnd),
    takeUntilDestroyed()
  )

  @ViewChild("editor") editor: RegisterEditorBaseAbstractComponent

  readonly tabs = [
    { type: "persons", label: "Personen" },
    { type: "organizations", label: "Organisationen" },
    { type: "historical_persons", label: "Historische Personen" },
    { type: "historical_organizations", label: "Historische Organisationen" },
    { type: "locations", label: "Standorte" },
    { type: "literature_entries", label: "Literatur" },
    { type: "licenses", label: "Bildlizenzen"}
  ]

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private imageSidebarService: ImageSidebarService,
    protected auth: AuthService,
  ) { }

  ngOnInit() {
    this.navigation$.subscribe(
      () => this.imageSidebarService.unsetImages()
    )
  }

  canDeactivate() {
    if (this.editor) {
      if (this.editor.formDirty) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {data: {message: `Achtung! Beim Fortfahren gehen die nicht gespeicherten Änderungen verloren.`, yesAction: "FORTFAHREN UND ÄNDERUNGEN VERWERFEN", noAction: "ÄNDERUNGEN BEHALTEN", icon: "warning"}})
        return dialogRef.afterClosed()
      }
    }

    return true
  }

  onEntrySelected = (id) =>
    this.router.navigate(["/registers", this.route.snapshot.paramMap.get("type"), id])
}
