<!--suppress TypeScriptUnresolvedVariable, AngularInvalidExpressionResultType -->
<!-- wat: Type MatDatepicker is not assignable to type MatDatepicker -->
<!-- TODO: type definitions for activities -->

<ng-template #changeDesc let-change="change">
  @if (change) {
    <div class="change-desc">
      <div><div class="label oldnew old">alt</div>{{change.old_human}}</div>
      <div><div class="label oldnew new">neu</div>{{change.new_human}}</div>
    </div>
  }
</ng-template>

<ng-template #changeList let-changes="changes">
  @if (changes) {
    <ul class="change-list">
      @for (change of changes; track change) {
        <li>
          <div class="change-header">
            <span class="field-name">{{change.field_name}}</span>
            {{changeTypeNames[change.change_type]}}
            @if (change.assoc_human_value) {
              <span class="field-value">– <span class="label">{{change.assoc_human_value}}</span></span>
            }
          </div>
          @if (change.change_type != 'update_attr') {
            <ng-container *ngTemplateOutlet="changeList;context:{changes: change.assoc_changes}"></ng-container>
          }
          @if (change.change_type == 'update_attr') {
            <ng-container *ngTemplateOutlet="changeDesc;context:{change}"></ng-container>
          }
        </li>
      }
    </ul>
  }
</ng-template>

<ng-template #vocabularyChange let-change="change">
  @if (change.change_type == 'add_entry') {
    <li class="vocab_change">
      Eintrag <span class="vocab-entry new">{{change.new_human}}</span> hinzugefügt
    </li>
  }
  @if (change.change_type == 'update_entry') {
    <li class="vocab_change">
      Eintrag <span class="vocab-entry old">{{change.old_human}}</span> in <span class="vocab-entry new">{{change.new_human}}</span> umbenannt
    </li>
  }
  @if (change.change_type == 'destroy_entry') {
    <li class="vocab_change">
      Eintrag <span class="vocab-entry old">{{change.old_human}}</span> entfernt
    </li>
  }

  @if (change.change_type == 'add_entry_comment') {
    <li class="vocab_change">
      Kommentar zu Eintrag <em class="vocab-entry">{{change.entry}}</em> hinzugefügt:
      <div><em class="new">{{change.new_human}}</em></div>
    </li>
  }
  @if (change.change_type == 'update_entry_comment') {
    <li class="vocab_change">
      Kommentar zu Eintrag <em class="vocab-entry">{{change.entry}}</em> geändert:
      <ng-container *ngTemplateOutlet="changeDesc; context: {change}"></ng-container>
    </li>
  }
  @if (change.change_type == 'remove_entry_comment') {
    <li class="vocab_change">
      Kommentar zu Eintrag <em class="vocab-entry">{{change.entry}}</em> entfernt:
      <div><em class="old">{{change.old_human}}</em></div>
    </li>
  }
</ng-template>

<ng-template #itemDesc let-itemType="itemType" let-itemName="itemName" let-itemId="itemId" let-itemDestroyed="itemDestroyed">
  <span class="item-desc">
    <span class="item-type">{{itemNames[itemType]}} </span>
    @if (!itemDestroyed) {
      <a class="item-name" routerLink="/{{itemLinks[itemType]}}/{{itemId}}">{{itemName}}</a>
    }
    @if (itemDestroyed) {
      <span class="item-name">{{itemName}} (gelöscht)</span>
    }
  </span>
</ng-template>

<ng-template #activityItemDesc let-activity="activity">
  <ng-container *ngTemplateOutlet="itemDesc;context:{
    itemType: activity.item_type,
    itemName: activity.item_name,
    itemId: activity.item_id,
    itemDestroyed: activity.item_destroyed
  }"></ng-container>
</ng-template>

<ng-template #userLink let-user="user">
  @if (user) {
    <a class="username" routerLink="/admin/users/{{user.id}}">{{user.name}}</a>
  } @else {
    <em>Unbekannt</em>
  }
</ng-template>

<ng-template #timeStamp let-activity="activity">
  <span class="timestamp">{{activity.created_at | date: "medium"}}</span>
</ng-template>

<ng-template #crudActivity let-activity="activity">
  <div class="activity-title">
    <ng-container *ngTemplateOutlet="timeStamp;context:{activity}"></ng-container>
    <span *ngTemplateOutlet="userLink;context:{user: activity.user}"></span>
    hat
    <ng-container *ngTemplateOutlet="activityItemDesc;context:{activity}"></ng-container>
    {{actionNames[activity.activity_type]}}.
  </div>
  <ng-container *ngTemplateOutlet="changeList;context:{changes: activity.item_changes}"></ng-container>
</ng-template>

<ng-template #linkActivity let-activity="activity">
  <div class="activity-title">
    <span *ngTemplateOutlet="userLink;context:{user: activity.user}"></span>
    hat
    @switch (activity.activity_type) {
      @case ('link') {
        <ng-container *ngTemplateOutlet="activityItemDesc;context:{activity}"></ng-container>
        verknüpft:
      }
      @case ('unlink') {
        Verknüpfungen zu
        <ng-container *ngTemplateOutlet="activityItemDesc;context:{activity}"></ng-container>
        entfernt:
      }
    }
    <ng-container *ngTemplateOutlet="timeStamp;context:{activity}"></ng-container>
  </div>
  <ul>
    @for (targetItem of activity.item_changes; track targetItem) {
      <li>
        <ng-container *ngTemplateOutlet="itemDesc;context:{
          itemType: targetItem.target_item_type,
          itemName: targetItem.target_item_name,
          itemId: targetItem.target_item_id,
          itemDestroyed: false
      }"></ng-container>
      </li>
    }
  </ul>
</ng-template>

<!-- content -->
<div class="content mat-typography insc-app-padding">

  <!-- header -->
  <h1>
    Akivitätsprotokoll
    @if (multipleItemIdsSelected()) {
      <span>(ausgewählte Datensätze)</span>
    }
    @if (itemName$ | async; as itemName) {
      <span>
    für <ng-container *ngTemplateOutlet="itemDesc;context:{
          itemType: filterForm.value['item_type'],
          itemName: itemName,
          itemId: filterForm.value['item_id'],
          itemDestroyed: false
      }"></ng-container>
    </span>
  }
</h1>

<!-- filters -->
<div class="menu">
  <div class="toolbar">
    <form [formGroup]="filterForm" >
      @if (!specificItemMode()) {
        <mat-form-field floatLabel="always">
          <mat-label>Datensatztyp</mat-label>
          <mat-select formControlName="item_type" multiple >
            <mat-label>alle</mat-label>
            @for (itemType of itemTypes; track itemType) {
              <mat-option [value]="itemType">{{itemNames[itemType]}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }

      <mat-form-field floatLabel="always">
        <mat-label>Art der Aktivität</mat-label>
        <mat-select formControlName="activity_type" multiple >
          <mat-label>alle</mat-label>
          @for (activityType of activityTypes; track activityType) {
            <mat-option [value]="activityType">{{activityTypeNames[activityType]}}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <!-- date range filter -->
      <ng-template #dateInput let-formControlName="formControlName" let-label="label">
        <mat-form-field floatLabel="always">
          <mat-label>{{label}}</mat-label>
          <input matInput [matDatepicker]="datePicker" [max]="today" formControlName={{formControlName}} readonly  (click)="datePicker.open()">
          <mat-label>unbegrenzt</mat-label>
          @if (filterForm.value[formControlName]) {
            <button matSuffix mat-icon-button aria-label="löschen" (click)="filterForm.controls[formControlName].reset()">
              <mat-icon>close</mat-icon>
            </button>
          }
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
      </ng-template>

      <ng-container *ngTemplateOutlet="dateInput; context: {formControlName: 'date_from', label: 'Datum (ab)'}"></ng-container>
      <ng-container *ngTemplateOutlet="dateInput; context: {formControlName: 'date_to', label: 'Datum (bis)'}"></ng-container>
    </form>
    <button mat-button (click)="ngOnInit()" matTooltip="aktualisieren"><mat-icon>refresh</mat-icon></button>
  </div>
  <div class="pagination">
    @if (pageParams$) {
      <mat-paginator
        [pageIndex]="page - 1"
        [length]="totalResults$ | async"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 50, 75, 100]" (page)="onPageChange($event)"
        [showFirstLastButtons]="true"
        >
      </mat-paginator>
    }
  </div>
</div>


<!-- ACTIVITY LIST -->
@if (groupedActivities$ | async; as groupedActivities) {
  <!-- activitiy group section by date -->
  @for (groupKey of groupedActivities.keys; track groupKey) {
    <div class="date-section">
      <h3 class="date-group-key">{{groupKey}}</h3>
      <!-- list of changes -->
      <div class="activity-list">
        @for (activity of groupedActivities.groups[groupKey]; track activity) {
          <mat-card appearance="outlined" class="activity-card">
            <mat-card-content>
              <!-- main record changes -->
              @if (crudActivities.includes(activity.activity_type)) {
                <div class="activity-item">
                  <ng-container *ngTemplateOutlet="crudActivity;context:{activity}"></ng-container>
                </div>
              }
              <!-- main linking changes -->
              @if (linkActivities.includes(activity.activity_type)) {
                <div class="activity-item">
                  <ng-container *ngTemplateOutlet="linkActivity;context:{activity}"></ng-container>
                </div>
              }
              <!-- main vocabulary-form-components changes -->
              @if (activity.activity_type === 'vocabulary-form-components') {
                <div class="activity-item">
                  <div class="activity-title">
                    <span *ngTemplateOutlet="userLink;context:{user: activity.user}"></span>
                    hat das Vokabular <em>{{activity.item_name}}</em> bearbeitet:
                    <ng-container *ngTemplateOutlet="timeStamp;context:{activity}"></ng-container>
                  </div>
                  <ul class="change-list">
                    @for (change of activity.item_changes; track change) {
                      <li>
                        <ng-container *ngTemplateOutlet="vocabularyChange; context:{change}"></ng-container>
                      </li>
                    }
                  </ul>
                </div>
              }
              <!-- associated vocabulary-form-components changes -->
              @if (activity.activity_type === 'vocabulary_change') {
                <div class="activity-item">
                  <div class="activity-title">
                    <span *ngTemplateOutlet="userLink;context:{user: activity.user}"></span>
                    hat einen mit
                    <ng-container *ngTemplateOutlet="activityItemDesc;context:{activity}"></ng-container>
                    in Verbindung stehenden Eintrag des Vokabulars <em>{{activity.item_changes[0]['field_name']}}</em> geändert.
                    <ng-container *ngTemplateOutlet="timeStamp;context:{activity}"></ng-container>
                  </div>
                  <ul class="change-list">
                    <ng-container *ngTemplateOutlet="vocabularyChange; context:{change: activity.item_changes[0]}"></ng-container>
                  </ul>
                </div>
              }
              <!-- associated knowledge changes -->
              @if (activity.activity_type === 'knowledge_change') {
                <div class="activity-item">
                  <div class="activity-title">
                    <span *ngTemplateOutlet="userLink;context:{user: activity.user}"></span>
                    hat einen mit
                    <ng-container *ngTemplateOutlet="activityItemDesc;context:{activity}"></ng-container>
                    in Verbindung stehenden Registereintrag
                    geändert.
                    <ng-container *ngTemplateOutlet="timeStamp;context:{activity}"></ng-container>
                  </div>
                  <div class="associated-item">
                <ng-container *ngTemplateOutlet="itemDesc;context:{
          itemType: activity.item_changes[0].assoc_type,
          itemName: activity.item_changes[0].assoc_human_value,
          itemId: activity.item_changes[0].assoc_id,
          itemDestroyed: false
      }"></ng-container>
                  </div>
                  <ng-container *ngTemplateOutlet="changeList; context:{changes: activity.item_changes[0].assoc_changes}"></ng-container>
                </div>
              }
            </mat-card-content>
          </mat-card>
        }
      </div>
    </div>
  }
}

</div>
