<form [formGroup]="formGroup">

  <mat-form-field class="name-input">
    <input matInput type="text"   id="name" formControlName="name">
    <mat-label>Bezeichnung</mat-label>
    <mat-error inscError errorControlName="name"></mat-error>
  </mat-form-field>

  <mat-form-field class="location-type">
    <mat-label>Standorttyp</mat-label>
    <mat-select formControlName="type">
      <mat-select-trigger>
        <div>
          <mat-icon [svgIcon]="formGroup.value['type']"></mat-icon>
          <span>{{typeNames[formGroup.value['type']]}}</span>
        </div>
      </mat-select-trigger>
      @if (parentType) {
        @for (type of validChildTypes[parentType]; track type) {
          <mat-option [value]="type">
            <mat-icon [svgIcon]="type"></mat-icon>
            {{typeNames[type]}}
          </mat-option>
        }
      } @else {
        <mat-option value="City">Stadt / Gemeinde</mat-option>
      }
    </mat-select>
    <mat-error inscError errorControlName="type"></mat-error>
  </mat-form-field>

  <mat-checkbox formControlName="lost">Verloren</mat-checkbox>


  <insc-external-norm-data-id-input
    label="Geonames-ID"
    providerId="geonames"
    formControlName="geonames_id"
    (lookupClick)="externalActionTriggered.emit($event)"
  ></insc-external-norm-data-id-input>
  <insc-external-norm-data-id-input
    label="Wikidata-ID"
    providerId="wikidata"
    formControlName="wikidata_id"
    (lookupClick)="externalActionTriggered.emit($event)"
  ></insc-external-norm-data-id-input>

  @if (parentType == null) {
    <mat-form-field  floatLabel="always">
      <mat-label>Regierungsbezirk</mat-label>
      <input matInput type="text" formControlName="administrative_district">
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-label>Kreis</mat-label>
      <input matInput type="text" formControlName="district" placeholder="kreisfrei">
    </mat-form-field>
    <mat-form-field  floatLabel="always">
      <mat-label>Bundesland</mat-label>
      <input matInput type="text" formControlName="state">
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-label>Land</mat-label>
      <mat-select formControlName="country_code">
        @for (entry of country_codes; track entry) {
          <mat-option [value]="entry.code">{{entry.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field  floatLabel="always">
      <mat-label>Amtl. Gemeindeschlüssel</mat-label>
      <input matInput type="text" formControlName="district_key">
    </mat-form-field>
    <mat-form-field  floatLabel="always">
      <mat-label>Gemeindetyp</mat-label>
      <input matInput type="text" formControlName="district_type">
    </mat-form-field>
  }
  <mat-form-field class="desc-input">
    <mat-label>Beschreibung</mat-label>
    <textarea matInput type="text"  formControlName="description"></textarea>
  </mat-form-field>

  @if (formGroup.controls['lat'] && formGroup.controls['lng']) {
    <div class="coordinate">
      <h4 style="margin: 0;">Geokoordinate</h4>
      <div>
        <mat-form-field>
          <mat-label>Latitude</mat-label>
          <input matInput type="text" formControlName="lat">
          <mat-error inscError errorControlName="lat"></mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Longitude</mat-label>
          <input matInput type="text" formControlName="lng">
          <mat-error inscError errorControlName="lng"></mat-error>
        </mat-form-field>
      </div>
    </div>
  }

  @if (formGroup.controls['address']) {
    <div class="subform">
      <h4>Adresse</h4>
      <insc-address-form formGroupName="address"></insc-address-form>
    </div>
  }

</form>
