import { DragDropModule } from "@angular/cdk/drag-drop"
import { CommonModule, NgOptimizedImage } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatButtonModule } from "@angular/material/button"
import { MatCardModule } from "@angular/material/card"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatRippleModule } from "@angular/material/core"
import { MatDialogModule } from "@angular/material/dialog"
import { MatDividerModule } from "@angular/material/divider"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatListModule } from "@angular/material/list"
import { MatMenuModule } from "@angular/material/menu"
import { MatRadioModule } from "@angular/material/radio"
import { MatSelectModule } from "@angular/material/select"
import { MatSidenavModule } from "@angular/material/sidenav"
import { MatTooltipModule } from "@angular/material/tooltip"
import { AutocompleteTextfieldComponent } from "../../shared/components/./form-components/autocomplete-textfield.component"
import { InscFormFieldErrorMessageComponent } from "../../shared/components/./form-components/insc-form-field-error-message.component"
import { ActivityLinkComponent } from "../../shared/components/activity-link/activity-link.component"
import { DatingChooserComponent } from "../../shared/components/dating-chooser/dating-chooser.component"
import { RecordPickerComponent } from "../../shared/components/record-picker/record-picker.component"
import {
  VocabularyFormComponentsModule
} from "../../shared/components/vocabularies/vocabulary-form-components/vocabulary-form-components.module"
import { IfUserPermissionDirective } from "../../shared/directives/auth/if-user-permission.directive"
import { HighlightPipe } from "../../shared/pipes/highlight.pipe"
import { ValueFilterPipe } from "../../shared/pipes/value-filter.pipe"
import { ImageManagementModule } from "../images/image-management/image-management.module"
import { ImageSidebarModule } from "../images/image-sidebar/image-sidebar.module"
import { ImageDownloadMenuComponent } from "../images/shared/components/image-download-menu.component"
import {
  ExternalNormDataIdLinkComponent
} from "../registers/shared/external-norm-data/external-norm-data-id-link/external-norm-data-id-link.component"
import { InscriptionDetailComponent } from "./inscription-page/inscription-detail.component"
import { InscriptionFormComponent } from "./inscription-page/inscription-form.component"
import { InscriptionPageComponent } from "./inscription-page/inscription-page.component"
import { ChooseObjectGroupDialogComponent } from "./object-page/dialogs/choose-object-group-dialog.component"
import { CreateOrEditObjectGroupDialogComponent } from "./object-page/dialogs/create-or-edit-object-group-dialog.component"
import { LinkListComponent } from "./object-page/link-list.component"
import { ObjectDetailComponent } from "./object-page/object-detail.component"
import { ObjectFormComponent } from "./object-page/object-form.component"
import { ObjectPageComponent } from "./object-page/object-page.component"
import { EmblemElementFormComponent } from "./object-page/subforms/emblem-element-form.component"
import { IconographyDescFormComponent } from "./object-page/subforms/iconography-desc-form.component"
import { LocationInputComponent } from "./object-page/subforms/locationing-form/location-input.component"
import { LocationingFormComponent } from "./object-page/subforms/locationing-form/locationing-form.component"
import { MarkElementFormComponent } from "./object-page/subforms/mark-element-form.component"
import { OriginFormComponent } from "./object-page/subforms/origin-form.component"
import { PersonOrganizationFormComponent } from "./object-page/subforms/person-organization-form.component"
import { PersonOrganizationInputComponent } from "./object-page/subforms/person-organization-input.component"
import { SignElementFormComponent } from "./object-page/subforms/sign-element-form.component"
import { EmblemViewComponent } from "./object-page/views/emblem-view.component"
import { HistoricalOrganizationEntryViewComponent } from "./object-page/views/historical-organization-entry-view.component"
import { HistoricalPersonEntryViewComponent } from "./object-page/views/historical-person-entry-view.component"
import { IconographyDescViewComponent } from "./object-page/views/iconography-desc-view.component"
import { LocationViewComponent } from "./object-page/views/location-view.component"
import { LocationingViewComponent } from "./object-page/views/locationing-view.component"
import { MarkViewComponent } from "./object-page/views/mark-view.component"
import { OrganizationEntryViewComponent } from "./object-page/views/organization-entry-view.component"
import { OriginViewComponent } from "./object-page/views/origin-view.component"
import { PersonEntryViewComponent } from "./object-page/views/person-entry-view.component"
import { PersonorganizationViewComponent } from "./object-page/views/personorganization-view.component"
import { SignViewComponent } from "./object-page/views/sign-view.component"

import { RecordsRoutingModule } from "./records-routing.module"
import { BackToResultsLinkComponent } from "./shared/back-to-results-link/back-to-results-link.component"
import { DataFieldDirective, DataGroupComponent, FieldLabelDirective, FieldValueDirective } from "./shared/data-group/data-group.component"
import { DatingChooserDialogComponent } from "./shared/dialogs/dating-chooser-dialog/dating-chooser-dialog.component"
import { LinkChooserDialogComponent } from "./shared/dialogs/link-chooser-dialog/link-chooser-dialog.component"
import { FormSectionComponent } from "./shared/form-section/form-section.component"
import { NormDataExpansionPanelModule } from "./shared/norm-data-expansion-panel/norm-data-expansion-panel.module"
import { RecordFormContainerComponent } from "./shared/record-form-container/record-form-container.component"
import {
  RecordImageListContainerComponent,
  RecordImageListImageDirective,
  RecordImageListSubtitleDirective,
  RecordImageListTitleDirective
} from "./shared/record-image-list/record-image-list-container.component"
import {
  RecordImageDirective,
  RecordPageContainerComponent,
  RecordSidebarSectionActionsDirective,
  RecordSidebarSectionContentDirective,
  RecordSidebarSectionDirective,
  RecordSidebarSectionTitleButtonDirective,
  RecordSidebarSectionTitleDirective,
  RecordSubtitleDirective,
  RecordTitleDirective
} from "./shared/record-page-container/record-page-container.component"
import { RecordSidebarSectionComponent } from "./shared/record-page-container/record-sidebar-section.component"
import { RepeatableFormComponent } from "./shared/repeatable-form/repeatable-form.component"
import { CommentFormComponent } from "./shared/subforms/comment-form.component"
import { DatingFormComponent } from "./shared/subforms/dating-form.component"
import { LiteratureEntryInputComponent } from "./shared/subforms/literaturereference-form/literature-entry-input.component"
import { LiteratureReferenceFormComponent } from "./shared/subforms/literaturereference-form/literaturereference-form.component"
import { AddressViewComponent } from "./shared/views/address-view.component"
import { CommentViewComponent } from "./shared/views/comment-view.component"
import { DatingViewComponent } from "./shared/views/dating-view.component"
import { LiteraturereferenceViewComponent } from "./shared/views/literaturereference-view.component"
import { LocationHeaderViewComponent } from "./shared/views/location-header-view.component"

@NgModule({
  imports: [
    CommonModule,
    RecordsRoutingModule,
    MatCardModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatRippleModule,
    MatTooltipModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    MatMenuModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    FormsModule,

    ImageSidebarModule,
    RecordPickerComponent,
    ImageManagementModule,
    ValueFilterPipe,
    VocabularyFormComponentsModule,
    InscFormFieldErrorMessageComponent,
    AutocompleteTextfieldComponent,
    ActivityLinkComponent,
    NormDataExpansionPanelModule,
    DatingChooserComponent,
    DragDropModule,
    IfUserPermissionDirective,
    ExternalNormDataIdLinkComponent,
    ImageDownloadMenuComponent,
    HighlightPipe
  ],
  exports: [
    FormSectionComponent,
    RepeatableFormComponent,
    RecordFormContainerComponent,
    RecordPageContainerComponent,
    RecordTitleDirective,
    RecordSubtitleDirective,
    RecordImageDirective,
    RecordSidebarSectionComponent,
    RecordSidebarSectionTitleDirective,
    RecordSidebarSectionContentDirective,
    RecordSidebarSectionActionsDirective,
    RecordSidebarSectionDirective,
    RecordImageListContainerComponent,
    RecordImageListTitleDirective,
    RecordImageListSubtitleDirective
  ],
  declarations: [
    ObjectFormComponent,
    InscriptionFormComponent,
    InscriptionDetailComponent,
    ObjectDetailComponent,
    DatingChooserDialogComponent,
    ObjectPageComponent,
    InscriptionPageComponent,
    CreateOrEditObjectGroupDialogComponent,
    ChooseObjectGroupDialogComponent,
    FormSectionComponent,
    RepeatableFormComponent,
    RecordFormContainerComponent,
    RecordPageContainerComponent,
    RecordTitleDirective,
    RecordSubtitleDirective,
    RecordImageDirective,
    RecordSidebarSectionComponent,
    RecordSidebarSectionTitleDirective,
    RecordSidebarSectionTitleButtonDirective,
    RecordSidebarSectionContentDirective,
    RecordSidebarSectionActionsDirective,
    RecordSidebarSectionDirective,
    RecordImageListContainerComponent,
    RecordImageListTitleDirective,
    RecordImageListSubtitleDirective,
    LinkChooserDialogComponent,

    CommentFormComponent,
    DatingFormComponent,
    EmblemElementFormComponent,
    IconographyDescFormComponent,
    LiteratureReferenceFormComponent,
    LiteratureEntryInputComponent,
    LocationingFormComponent,
    MarkElementFormComponent,
    OriginFormComponent,
    PersonOrganizationFormComponent,
    SignElementFormComponent,
    LocationInputComponent,
    PersonOrganizationInputComponent,
    LocationingFormComponent,
    BackToResultsLinkComponent,

    AddressViewComponent,
    CommentViewComponent,
    DatingViewComponent,
    EmblemViewComponent,
    MarkViewComponent,
    SignViewComponent,
    HistoricalPersonEntryViewComponent,
    HistoricalOrganizationEntryViewComponent,
    IconographyDescViewComponent,
    LiteraturereferenceViewComponent,
    LocationHeaderViewComponent,
    LocationViewComponent,
    LocationingViewComponent,
    OrganizationEntryViewComponent,
    OriginViewComponent,
    PersonEntryViewComponent,
    PersonorganizationViewComponent,

    DataGroupComponent,
    FieldValueDirective,
    FieldLabelDirective,
    DataFieldDirective,

    LinkListComponent,
    RecordImageListImageDirective,
  ]
})
export class RecordsModule { }

