import { Component } from "@angular/core"

@Component({
  selector:    'insc-search-toolbar',
  templateUrl: './search-toolbar.component.html',
  styleUrls:   ['./search-toolbar.component.scss']
})
export class SearchToolbarComponent {

  constructor() { }
}
