<h2 matDialogTitle>Datierungssuche</h2>
<mat-dialog-content class="mat-typography">

  <div class="row-border-bottom">
    <insc-dating-chooser #datingChooser [initialDatingSpecification]="initialDatingSpecification"
    (datingSpecificationChange)="onChangeDatingSpecification($event)"></insc-dating-chooser>
  </div>

  <div class="row-border-bottom search-options" [formGroup]="optionsFormGroup">
    <h3>Suchoptionen</h3>
    <div class="options-content">
      <div class="mat-caption">
        Die Suche mit geschlossenen Zeiträumen umfasst standardmäßig alle Datensätze, deren Datierungen im angegebenen
        Zeitraum liegen.
      </div>
      <div class="checkbox-wrapper">
        <mat-checkbox formControlName="intersects">
          Suche auch nach Datensätzen, deren Datierungszeiträume mit dem angegebenen Zeitraum überlappen
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="chosen-dating-info">
    @if (datingChooser.chosenDatingSpecification) {
      <div>
        Gewählter Suchbereich: <em class="mat-subtitle-2">{{ datingChooser.chosenDatingSpecification.description }}</em>
      </div>
    }
    @if (!datingChooser.chosenDatingSpecification) {
      <div>
        <em>Keine gültige Auswahl</em>
      </div>
    }
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button matDialogClose color="danger">Abbrechen</button>
  <button
    mat-button
    (click)="closeWithResult(datingChooser.chosenDatingSpecification)"
    color="primary"
  [disabled]="!datingChooser.chosenDatingSpecification">Übernehmen</button>
</mat-dialog-actions>
