import { Component, EventEmitter, Input, Output } from "@angular/core"
import { CriteriumDescription } from "../search-controls/search-control.directive"

@Component({
  selector:    'insc-search-criteria-chips',
  templateUrl: './criteria-chips.component.html',
  styleUrls:   ['./criteria-chips.component.scss']
})
export class CriteriaChipsComponent {

  @Input() currentCriteria: CriteriumDescription[]
  @Output() removeCriterium = new EventEmitter<CriteriumDescription>()
  @Output() resetCriteria = new EventEmitter<void>()

  constructor() { }
}
