import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { catchError, map, share } from "rxjs/operators"
import { AbstractExternalNormDataProviderService, ExternalNormDataLookupResult } from "../../abstract-external-norm-data-provider.service"

export interface GeonamesResponse {
  name?: string
  countryName?: string
  countryCode?: string
  // Bundesland
  adminName1?: string
  // Scheint der Regierungsbezirk zu sein
  adminName2?: string
  adminName3?: string
  adminName4?: string

  geonameId?: string
  lat?: string
  lng?: string
}

export interface GeonamesErrorResponse {
  status: {
    message: string
    value: number
  }
}

export interface GeonamesLookupResponse {
  totalResultsCount: number
  geonames: GeonamesResponse[]
}

export function isGeonamesErrorResponse(response: any): response is GeonamesErrorResponse {
  return 'status' in response
}

@Injectable({
  providedIn: 'root'
})
export class GeonamesNormDataProviderService extends AbstractExternalNormDataProviderService<GeonamesResponse> {

  private readonly lookupApiUsername = "awkinschriften"

  constructor(private http: HttpClient) {
    super(http)
  }

  getExternalLink(id: string): string {
    return `http://www.geonames.org/${id}`
  }

  getValidity(id: string): Observable<boolean> {
    return this.http.get<GeonamesResponse | GeonamesErrorResponse>(`https://www.geonames.org/getJSON?id=${id}&lang=de`).pipe(
      catchError((err: HttpErrorResponse) => of(err)),
      map(res => {
        if (res instanceof HttpErrorResponse) {
          if (res.status === 404) {
            return false
          }
          throw res
        }
        return Boolean(!isGeonamesErrorResponse(res))
      }),
      share()
    )
  }

  getEntryData<T = any>(id: string): Observable<T> {
    return this.http.get<T>(`https://www.geonames.org/getJSON?id=${id}&lang=de`)
  }

  protected descriptionFromEntryData(entryData: GeonamesResponse, includeName = true, includeCity = false): string {
    return [includeName && entryData?.name, includeCity && entryData?.adminName2, entryData?.adminName3, entryData?.adminName4, entryData?.adminName1, entryData?.countryName]
      .filter(Boolean)
      .filter((item, index, array) => array.indexOf(item) === index)
      .join(", ")
  }

  get lookupAvailable() { return true }

  lookup(queryString: string): Observable<ExternalNormDataLookupResult[]> {
    const headers = new HttpHeaders({
      accept: "application/json"
    })

    const queryUrl = `https://secure.geonames.org/search?username=${this.lookupApiUsername}&lang=de&countryBias=DE&maxRows=30&style=FULL&q=${queryString}`
    return this.http.get<GeonamesLookupResponse>(queryUrl, {headers: headers}).pipe(
      catchError((err: HttpErrorResponse) => this.handleLookupError(err)),
      map(response => {
        if (!response) {
          return <ExternalNormDataLookupResult[]>[]
        }

        return response.geonames.map(geoname => ({
          id: geoname.geonameId,
          title: geoname.name,
          description: this.descriptionFromEntryData(geoname, false, true)
        }))
      })
    )
  }


}
