<mat-form-field class="entry-field" [floatLabel]="compact ? 'always' : 'auto'">
  <input matInput #entryInput
    [matAutocomplete]="auto"
    [formControl]="inputCtrl"
    [placeholder]="placeholder"
    >
  <mat-label>{{label}}</mat-label>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)"  [autoActiveFirstOption]="true">
    @if (vocabulary$ | async; as vocabulary) {
      <mat-optgroup
        label="Erlaubte Begriffe für {{vocabulary.display}} – Einfachauswahl"
        class="insc-vocabulary-select-autocomplete-optgroup"
        >
        @for (entry of vocabulary.entries | valueFilter: {pattern: entryInput.value, filterByProp: 'name'}; track entry.original.id) {
          <mat-option
            [value]="entry.original.name"
            [ngClass]="{'insc-mat-option-with-subtitle': !!entry.original.comment}"
            >
            <div class="insc-mat-option-title" [innerHtml]="entry.highlighted.name"></div>
            @if (entry.original.comment) {
              <div class="insc-mat-option-subtitle">{{entry.original.comment}}</div>
            }
          </mat-option>
        }
      </mat-optgroup>
    }
  </mat-autocomplete>
  <div matSuffix>
    @if (entryInput.value && !inputCtrl.disabled) {
      <button mat-icon-button
        (click)="reset()">
        <mat-icon matTooltip="Feld leeren">cancel</mat-icon>
      </button>
    }
    @if (!inputCtrl.disabled && showEditButton) {
      <button mat-icon-button
        (click)="openEditor($event)">
        <mat-icon matTooltip="Vokabular bearbeiten">edit_note</mat-icon>
      </button>
    }
    <ng-container *ngTemplateOutlet="extraSuffixTpl"></ng-container>
  </div>

  <mat-error inscError [errorControl]="ngControl.control"></mat-error>
  <mat-error>{{ ngControl.errors?.valueNotAllowedError }}</mat-error>
</mat-form-field>
@if (hasEditorialState) {
  <insc-editorial-state-select
    [formControl]="editorialStateFormControl"
  class="editorial-state-select"></insc-editorial-state-select>
}
