import { LobidGNDPersonRecord } from "./gnd-api"

export const dateStringArrayToYears = (dateStrings: string[]): string =>
  dateStrings
    ?.map(dateString => dateString.split("-")[0])
    ?.join(" oder ")

export const getTimeframeString = ({dateOfBirth, dateOfDeath, dateOfBirthAndDeath}: LobidGNDPersonRecord): string => {
  const yearOfBirth = dateStringArrayToYears(dateOfBirth)
  const yearOfDeath = dateStringArrayToYears(dateOfDeath)

  if (yearOfBirth && yearOfDeath) {
    return `${yearOfBirth}-${yearOfDeath}`
  } else if (yearOfBirth) {
    return `geb. ${yearOfBirth}`
  } else if (yearOfDeath) {
    return `gest. ${yearOfDeath}`
  } else if (dateOfBirthAndDeath) {
    return dateOfBirthAndDeath.join(" oder ")
  }
}
