<ng-template #singleFileDownloadLink let-version="version" let-title="title">
  <a [href]="getDownloadLink(version)" mat-menu-item>
    <div>{{title}}</div>
    <div class="description">
      <span>{{images[0].download_versions[version].width}}x{{images[0].download_versions[version].height}}</span>
      <span> ({{images[0].download_versions[version].size | formatBytes}})</span>
    </div>
  </a>
</ng-template>

<ng-template #multiFileDownloadLink let-version="version" let-title="title" let-sizeDesc="sizeDesc">
  <a [href]="getDownloadLink(version)" mat-menu-item>
    <div>{{title}}</div>
    <div class="description">
      <span>{{sizeDesc}}</span>
      @if (sizeDesc) {
        <span> (ca. {{imageDownloadService.getTotalSize(images, version) | formatBytes}})</span>
      }
      @if (!sizeDesc) {
        <span>ca. {{imageDownloadService.getTotalSize(images, version) | formatBytes}}</span>
      }
    </div>
  </a>
</ng-template>

@if (images.length > 0) {
  <mat-menu class="insc-image-download-menu-panel">
    @if (images.length === 1) {
      <ng-container *ngTemplateOutlet="singleFileDownloadLink; context: {title: 'JPEG, Originalgröße', version: 'jpeg_full'}"></ng-container>
      <ng-container *ngTemplateOutlet="singleFileDownloadLink; context: {title: 'JPEG, mittel', version: 'jpeg_medium'}"></ng-container>
      <ng-container *ngTemplateOutlet="singleFileDownloadLink; context: {title: 'JPEG, klein', version: 'jpeg_small'}"></ng-container>
      <ng-container *ngTemplateOutlet="singleFileDownloadLink; context: {title: 'TIFF, Originalgröße', version: 'original'}"></ng-container>
    } @else {
      <ng-container *ngTemplateOutlet="multiFileDownloadLink; context: {title: 'JPEG, Originalgröße', version: 'jpeg_full'}"></ng-container>
      <ng-container *ngTemplateOutlet="multiFileDownloadLink; context: {title: 'JPEG, mittel', sizeDesc: 'längste Seite max. 1920px', version: 'jpeg_medium'}"></ng-container>
      <ng-container *ngTemplateOutlet="multiFileDownloadLink; context: {title: 'JPEG, klein', sizeDesc: 'längste Seite max. 1080px', version: 'jpeg_small'}"></ng-container>
      <ng-container *ngTemplateOutlet="multiFileDownloadLink; context: {title: 'TIFF, Originalgröße', version: 'original'}"></ng-container>
    }
  </mat-menu>
}
