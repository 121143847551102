import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { MatCardModule } from "@angular/material/card"
import { MatTabsModule } from "@angular/material/tabs"
import { RouterLink, RouterLinkActive } from "@angular/router"
import { BasicRegisterEditorModule } from ".././basic-register-editor/basic-register-editor.module"
import { LocationRegisterEditorModule } from "../location-register-editor/location-register-editor.module"
import { RegisterPageRoutingModule } from "./register-page-routing.module"
import { RegisterPageComponent } from "./register-page.component"


@NgModule({
  declarations: [RegisterPageComponent],
  imports: [
    CommonModule,
    RegisterPageRoutingModule,
    MatCardModule,
    MatTabsModule,
    RouterLink,
    RouterLinkActive,
    BasicRegisterEditorModule,
    LocationRegisterEditorModule
  ]
})
export class RegisterPageModule { }
