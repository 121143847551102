import { AfterViewInit, Component, OnInit } from "@angular/core"
import { Observable } from "rxjs"
import { IptcDatasetService } from "../features/images/image-metadata-editors/multi-iptc-editor/iptc-dataset.service"
import { ImageMetadataRecord, ImageService } from "../services/data.service"

@Component({
  selector:    "insc-test-page",
  templateUrl: "./test-page.component.html",
  styleUrls:   ["./test-page.component.scss"],
})
export class TestPageComponent implements OnInit, AfterViewInit {

  metadata$: Observable<ImageMetadataRecord>
  constructor(private imageService: ImageService, private ids: IptcDatasetService) {}
  ngOnInit(): void {
    this.metadata$ = this.imageService.metadata("15")
    // this.iptcDatasets$ = this.ios.get("28").pipe(
    //   take(1),
    //   map(o => [...o.images, ...o.context_images, ...o.inscriptions.flatMap(i => i.images)]),
    //   map(images => images.map(image => this.ids.buildIptcDataset(image)))
    // )
  }

  ngAfterViewInit(): void {


  }


  test() {

  }


}
