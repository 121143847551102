<insc-register-editor-layout #editorLayout [loading]="loading || locationTree?.searchResultsLoading">
  <div inscRegisterEditorMenu class="menu">
    <insc-location-tree [selectedId]="selectedId"
      (selection)="locationSelected($event)"
      [selectionHandler]="selectionHandler"
      (locationCreate)="onTreeCreateLocation($event)"
      [allowCreateLocation]="allowEdit"
      [queryString]="editorLayout.searchChanges | async"
    ></insc-location-tree>

    <mat-menu #createTopLevelLocationMenu>
      <button mat-menu-item (click)="createTopLevelLocation()">Leerer Datensatz</button>
      <button mat-menu-item (click)="createTopLevelLocation('wikidata')">Aus Wikidata</button>
      <button mat-menu-item (click)="createTopLevelLocation('geonames')">Aus Geonames</button>
    </mat-menu>

    @if (allowEdit) {
      <button
        class="add-button"
        mat-button color="primary"
        [matMenuTriggerFor]="createTopLevelLocationMenu"
        >
        <mat-icon>add</mat-icon>Neue Stadt/Gemeinde anlegen
      </button>
    }
  </div>

  @if (form) {
    <insc-location-form
      [formGroup]="form"
      [parentType]="selectionParentType"
      (externalActionTriggered)="onFormExternalAction($event)"
    inscRegisterEditorForm></insc-location-form>
  }

  @if (form && allowEdit) {
    <div inscRegisterEditorActions>
      @if (isSaving) {
        <mat-spinner diameter="16"></mat-spinner>
      }
      @if (isCreatingNewLocation()) {
        <button mat-button color="warn" [disabled]="isSaving" (click)="cancelCreate()">Abbrechen und Verwerfen</button>
      }
      @if (!isCreatingNewLocation()) {
        <button mat-button color="warn" [disabled]="isSaving" (click)="delete()">Löschen</button>
      }
      <button mat-button color="primary" [disabled]="isSaving" (click)="save()">Speichern</button>
    </div>
  }

  @if (showSidebar) {
    <div inscRegisterEditorSidebar>
      <insc-register-entry-metadata-view [metadata]="metadata">
      </insc-register-entry-metadata-view>
      <insc-associated-record-view [associatedQueries]="associatedQueries"
      [selectedEntry]="selectedEntry"></insc-associated-record-view>
    </div>
  }
</insc-register-editor-layout>
