import { Injectable } from "@angular/core"
import { HistoricalPersonEntry } from "../../../../../../shared/models/historical-person-entry.model"
import { ExternalNormDataMapperInterface } from "../../external-norm-data-mapper.interface"
import { GermaniaSacraPersonRecord } from "./germania-sacra-person-api"
import { getTimeframeString } from "./germania-sacra-person-formatting-helpers"

@Injectable({
  providedIn: 'root'
})
export class GermaniaSacraPersonToInscHistoricalPersonMapper implements ExternalNormDataMapperInterface<GermaniaSacraPersonRecord, Partial<HistoricalPersonEntry>> {

  map(normDataRecord: GermaniaSacraPersonRecord): Partial<HistoricalPersonEntry> {
    const name = [
      normDataRecord.person.familienname,
      [normDataRecord.person.vorname, normDataRecord.person.namenspraefix].filter(Boolean).join(" ")
    ].filter(Boolean).join(", ")

    return {
      name: name,
      timeframe: getTimeframeString(normDataRecord.person),
      gnd_id: normDataRecord.person.gndnummer,
      germania_sacra_id: normDataRecord["item.gsn"][0].nummer,
      comment: [
        normDataRecord.person.taetigkeit,
        normDataRecord.aemter
          .map(amt => [amt.institution])
          .join("\n"),
        normDataRecord.person.verwandtschaften
      ].filter(Boolean).join("\n")
    }
  }

}
