<ul cdkDropList (cdkDropListDropped)="drop($event)" class="list">
  @for (link of linkList; track link) {
    <li cdkDrag [cdkDragData]="link" cdkDragLockAxis="y" class="list-item">
      @if (allowReorder) {
        <div>
          <a [routerLink]="link.routerLink">{{link.name}}</a>
          <span cdkDragHandle><mat-icon class="button" [style.cursor]="'move'" [inline]="true">drag_handle</mat-icon></span>
        </div>
      } @else {
        <a [routerLink]="link.routerLink">{{link.name}}</a>
      }
      @if (allowRemove) {
        <span (click)="unlinkClick.emit(link.id)" matTooltip="Verknüpfung aufheben">
          <mat-icon [inline]="true" class="button">close</mat-icon>
        </span>
      }
    </li>
  }
</ul>
