@if (validityLoading || (lookupState | async); as lookupState) {
  <div>
    <mat-form-field>
      <mat-label>{{ label }}</mat-label>
      <mat-icon class="provider-icon" matPrefix [svgIcon]="providerId"></mat-icon>
      <input matInput [formControl]="idInputCtrl">
      @if (lookupState === lookupStates.Valid || lookupState === lookupStates.Invalid) {
        <mat-hint>
          @if (lookupState === lookupStates.Valid) {
            <span class="validity-icon">
              <mat-icon
                matTooltip="ID ist gültig"
              color="primary" class="id-icon">check</mat-icon>
            </span>
            <a [href]="provider?.getExternalLink(idInputCtrl.value)" target="_blank">Gültig
              @if (recordDescription | async; as recordDesc) {
                <span>({{ recordDesc }})</span>
              }
            </a>
          } @else {
            <span class="validity-icon">
              <mat-icon
                [inline]="true"
                matTooltip="ID ist ungültig"
              color="warn" class="id-icon">warning</mat-icon>
            </span>
            <span>Ungültig</span>
          }
        </mat-hint>
      }
      @if (lookupState === lookupStates.Error) {
        <mat-hint>
          <em>Fehler beim Abfragen der externen Normdatenbank</em>
        </mat-hint>
      }
      @if (validityLoading || recordDescriptionLoading) {
        <mat-spinner matTextSuffix diameter="17"></mat-spinner>
      }
      @if (provider?.lookupAvailable && (lookupEnabled !== false)) {
        <button
          (click)="emitLookup()"
          matTooltip="In Normdatenbank nachschlagen ({{providerId}})"
          mat-icon-button matIconSuffix><mat-icon>search</mat-icon></button>
        }
      </mat-form-field>
    </div>
  }
