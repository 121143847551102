import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { catchError, map } from "rxjs/operators"
import { AbstractExternalNormDataProviderService, ExternalNormDataLookupResult } from "../../abstract-external-norm-data-provider.service"
import { GermaniaSacraMonasteryApiResponse, GermaniaSacraMonasteryRecord } from "./germania-sacra-monastery-api"

@Injectable({
  providedIn: "root"
})
export class GermaniaSacraMonasteryNormDataProviderService extends AbstractExternalNormDataProviderService<GermaniaSacraMonasteryRecord> {

  private readonly baseUrl = `https://api.gs.sub.uni-goettingen.de/v1`

  constructor(private http: HttpClient) {
    super(http)
  }
  getExternalLink(id: string): string {
      return `https://klosterdatenbank.adw-goe.de/gsn/${id}`
  }
  getValidity(id: string): Observable<boolean> {
      return this.http.get<GermaniaSacraMonasteryApiResponse>(`${this.baseUrl}/monastery/${id}`).pipe(
        map(Boolean),
        catchError(() => of(false))
      )
  }


  getEntryData(id: string): Observable<GermaniaSacraMonasteryRecord> {
    return this.http.get<GermaniaSacraMonasteryRecord>(`${this.baseUrl}/monastery/${id}`)
  }

  protected descriptionFromEntryData(entryData: GermaniaSacraMonasteryRecord): string {
    return entryData.name
  }

  get lookupAvailable(): boolean { return true }

  lookup(queryString: string): Observable<ExternalNormDataLookupResult[]> {
    return this.http.get<GermaniaSacraMonasteryApiResponse>(
      `${this.baseUrl}/monasteries/list?q[0][field]=location&q[0][operator]=eq&q[0][value]=${queryString}`
    ).pipe(
      catchError((err: unknown) => this.handleLookupError(err)),
      map(response => response.list.map(record => ({
        id: record.gsnId,
        title: record.name,
        description: record.orderTimeRange.join(" – ")
      })))
    )
  }
}
