import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { FormLeaveGuard } from "../../../services/form-leave-guard.service"
import { UserResolver } from "./resolvers/user-resolver.service"
import { UsersResolver } from "./resolvers/users-resolver.service"
import { UserEditorComponent } from "./user-editor/user-editor.component"
import { UserListComponent } from "./user-list/user-list.component"
import { UserManagementPageComponent } from "./user-management-page.component"

const routes: Routes = [
  { path: "admin/users", component: UserManagementPageComponent, children: [
    {path: "", component: UserListComponent, resolve: { users: UsersResolver }},
    {path: "new", component: UserEditorComponent, canDeactivate: [FormLeaveGuard]},
    {path: ":userId", component: UserEditorComponent, canDeactivate: [FormLeaveGuard], resolve: { user: UserResolver } }
  ]},
  {path: "account", component: UserManagementPageComponent, children: [{
    path: "", component: UserEditorComponent, canDeactivate: [FormLeaveGuard], resolve: { user: UserResolver }, data: { accountPage: true}
  }]}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManagementPageRoutingModule { }
