@if (vocabulary$ | async; as vocabulary) {
  <div class="editor-container">
    <h2>{{ vocabulary.display }}</h2>
    <div class="subtitle mat-caption">
      Zuletzt bearbeitet: {{ vocabulary.updated_at | date: "medium" }} (<a
      routerLink="/admin/users/{{vocabulary.last_editor.id}}">{{ vocabulary.last_editor.name }}</a>)
      –
      <insc-activity-link [id]="vocabulary.id" type="Vocabulary"></insc-activity-link>
    </div>
    <insc-vocabulary-editor [editable]="hasEditPermission | async" [vocabulary]="vocabulary"
                            (entrySelected)="onSelectedEntryChange($event)"
                            [selectedEntryId]="selectedId$ | async"></insc-vocabulary-editor>
  </div>

  <div class="associated-records-container" *ngIf="selectedId$ | async as selectedId">
    <h2>{{ getEntryName(vocabulary, selectedId) }}</h2>
    <div class="subtitle mat-caption">Verknüpfte Datensätze</div>

    <mat-tab-group #associatedRecordTabGroup class="associated-records" [ngStyle]="getTabIndicatorStyle()">

      <!-- General template for associated record tabs -->
      <ng-template #linkResultListTab
                   let-title="title"
                   let-numResults="numResults"
                   let-dataSource="dataSource"
                   let-getRouterLink="getRouterLink"
                   let-descriptionTpl="descriptionTpl"
                   let-viewportId="viewportId"
      >
        <mat-tab #associatedRecordTab label="{{ title }} ({{numResults}})"
                 [disabled]="numResults < 1">
          <cdk-virtual-scroll-viewport [inscAssociatedRecordScrollViewport]="viewportId" class="associated-records-content" itemSize="56">
            <mat-action-list>
              <a
                *cdkVirtualFor="let result of dataSource"
                [routerLink]="getRouterLink(result)" target="_blank">
                <mat-list-item class="associated-record-item">
                  <img matListItemIcon class="associated-record-image"
                       ngSrc="{{ result.preview_url || '/assets/missing-image-icon.svg' }}" width="40" height="40"/>
                  <div class="associated-record-content">
                    <h3 class="associated-record-title">{{ result.name }}</h3>
                    <ng-container *ngTemplateOutlet="descriptionTpl; context: {$implicit: result}" />
                  </div>
                </mat-list-item>
              </a>
            </mat-action-list>
          </cdk-virtual-scroll-viewport>
        </mat-tab>
      </ng-template>


      <!-- OBJECTS TAB -->

      <ng-template #objectDescriptionTpl let-result>
        <h5 class="associated-record-title associated-record-description">
          {{ result.data.current_locationing.location.city.name }}
        </h5>
      </ng-template>

      <ng-container *ngTemplateOutlet="linkResultListTab; context: {
                title: 'Inschriftenträger',
                numResults: numObjects$ | async,
                dataSource: objectDataSource$ | async,
                getRouterLink: getObjectRouterLink,
                descriptionTpl: objectDescriptionTpl,
                viewportId: 'objects'
            }" />


      <!-- INSCRIPTIONS TAB -->

      <ng-template #inscriptionDescriptionTpl let-result>
        @for (insc_object of result.data.insc_objects; track insc_object.id) {
          <h5 class="associated-record-title associated-record-description">{{ insc_object.name }} ({{ insc_object.city.name }})</h5>
        }
      </ng-template>

      <ng-container *ngTemplateOutlet="linkResultListTab; context: {
                title: 'Inschriften',
                numResults: numInscriptions$ | async,
                dataSource: inscriptionDataSource$ | async,
                getRouterLink: getInscriptionRouterLink,
                descriptionTpl: inscriptionDescriptionTpl,
                viewportId: 'inscriptions'
            }" />


      <!-- IMAGES TAB -->

      <mat-tab #associatedRecordTab *ngIf="{
        numImages: numImages$ | async,
        images: entryInscImages$ | async
        } as obs" label="Aufnahmen ({{obs.numImages}})" [disabled]="obs.numImages < 1">
        <cdk-virtual-scroll-viewport inscAssociatedRecordScrollViewport="images" class="associated-records-content" itemSize="56">
          <mat-nav-list inscImageManagement #imageManagement="imageManagement"
                        (imageSelectionChange)="onEntryImageSelectionChange($event, obs.images)">
            <mat-list-item
              [activated]="imageManagement.isSelected(result.id)"
              *cdkVirtualFor="let result of imageDataSource$ | async"
              (click)="onEntryInscImageClick($event, imageManagement, result.id)"
              class="associated-record-item"
            >
              <img matListItemIcon class="associated-record-image" ngSrc="{{ result.preview_url || '/assets/missing-image-icon.svg' }}"
                   width="40" height="40"/>
              <div class="associated-record-content">
                <h3 class="associated-record-title">{{ result.name }}</h3>
                <h5 class="associated-record-title associated-record-description">
                  @if (result.data.inscriptions.length > 0) {
                    @for (inscription of result.data.inscriptions; track inscription.id) {
                      <div>{{ inscription.name }}</div>
                    }
                  } @else if (result.data.insc_objects.length > 0) {
                    @for (object of result.data.insc_objects; track object.id) {
                      <div>{{ result.data.insc_objects[0].name }}</div>
                    }
                  } @else {
                    <em>unverknüpft</em>
                  }
                </h5>
              </div>
            </mat-list-item>
          </mat-nav-list>
        </cdk-virtual-scroll-viewport>
      </mat-tab>
    </mat-tab-group>
  </div>
}
