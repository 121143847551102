import { animate, state, style, transition, trigger } from "@angular/animations"
import { CollectionViewer, DataSource, SelectionChange } from "@angular/cdk/collections"
import { FlatTreeControl } from "@angular/cdk/tree"
import {
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TrackByFunction,
  ViewChild,
  ViewChildren
} from "@angular/core"
import { MatTree } from "@angular/material/tree"
import { BehaviorSubject, merge, Observable, of, ReplaySubject, Subject } from "rxjs"
import { catchError, debounceTime, map, startWith, switchMap, takeUntil, tap } from "rxjs/operators"
import { ObjectLocationService } from "../../../services/data.service"
import { LocationDisplayHelperService } from "../../../services/location-display-helper.service"
import { ObjectLocation } from "../../../shared/models/object-location.model"
import { LocationTypeDefsService } from "../location-type-defs.service"

/** Flat node with expandable and level information */
export interface FlatNode {
  item: string
  id: string | null
  level: number
  expandable: boolean
  type: string | null
  isLoading: boolean
}

export class LocationFlatNode implements FlatNode {
  // these fields are used, why does TS report them?
  // noinspection JSUnusedGlobalSymbols
  constructor(readonly item: string, readonly has_geonames_id: boolean, readonly has_wikidata_id: boolean, readonly id: string, readonly level: number = 1, readonly expandable: boolean = false,
    readonly type: string | null,
    public isLoading: boolean = false) {}
}

export class NewLocationFlatNode implements FlatNode {
  // noinspection JSUnusedGlobalSymbols
  constructor(readonly parentId: string,
    readonly level: number = 1,
    readonly item: string = "Neuer Standort",
    readonly id: null = null,
    readonly expandable: false = false,
    readonly type: null = null,
    readonly isLoading: false = false) {}
}



@Injectable()
export class LocationDataSource extends DataSource<FlatNode> {

  dataChange: BehaviorSubject<FlatNode[]> = new BehaviorSubject<FlatNode[]>([])

  get data(): FlatNode[] { return this.dataChange.value }
  set data(value: FlatNode[]) {
    this.treeControl.dataNodes = value
    this.dataChange.next([])
    this.dataChange.next(value)
  }


  constructor(
    private treeControl: FlatTreeControl<FlatNode>,
    private locationService: ObjectLocationService,
    private locationDisplayHelper: LocationDisplayHelperService) {
    super()
  }

  connect(collectionViewer: CollectionViewer): Observable<FlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe(change => {
      if (change.added || change.removed) {

        this.handleTreeControl(change)
      }
    })

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data))
  }

  disconnect(): void {}

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<FlatNode>): void {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true))

    }

    if (change.removed) {
      change.removed.reverse().forEach(node => this.toggleNode(node, false))
    }
  }

  toNodes = (level: number, locations: Partial<ObjectLocation>[]): LocationFlatNode[] => locations.reduce((nodes, location) => {
    const node = new LocationFlatNode(this.locationDisplayHelper.getLocationNameWithLostAnnotation(location), !!location.geonames_id, !!location.wikidata_id, location.id, level, location.has_children, location.type)
    const children = [].concat(this.toNodes(level + 1, location.children || [])) as LocationFlatNode[]
    if (children.length > 0) {
      this.treeControl.expansionModel.select(node)
    }
    return nodes.concat(node, ...children)
  }, [] as LocationFlatNode[]
  )

  insertChildren(node: FlatNode, nodeIndex: number, locations: Partial<ObjectLocation>[]): void {
    const childNodes = this.toNodes(node.level + 1, locations)
    this.data.splice(nodeIndex + 1, 0, ...childNodes)
  }


  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: FlatNode, expand: boolean): void {
    const index = this.data.indexOf(node)

    if (!node.expandable || index < 0) { // If no children, or cannot find the node, no op
      return
    }

    if (expand) {
      node.isLoading = true
      this.locationService.locations(node.id).subscribe(locations => {
        this.insertChildren(node, index, locations)
        node.isLoading = false
        this.dataChange.next(this.data)
      })
    } else {
      const children = this.treeControl.getDescendants(node)
      this.data.splice(index + 1, children.length)
      this.dataChange.next(this.data)

    }
  }

  getParentNode(node: FlatNode): FlatNode | null {
    for (let i = this.data.indexOf(node) - 1; i >= 0; i--) {
      const currentNode = this.data[i]
      if (currentNode.level < node.level) {
        return currentNode
      }
    }
  }

  getNode(id: string): FlatNode {
    return this.data.find(node => node.id == id)
  }

  replaceNode(oldNode: FlatNode, newNode: FlatNode): void {
    this.data.splice(this.data.indexOf(oldNode), 1, newNode)
    this.data = this.data
  }

  removeNode(node: FlatNode): void {
    this.data.splice(this.data.indexOf(node), 1)
    this.dataChange.next(this.data)
  }

  getExpandedNodeIds(): string[] {
    return this.treeControl.expansionModel.selected.map(node => node.id)
  }

}

export class LocationSelection {
  id: string
  type: string
  parentId: string
  parentType: string
}

@Component({
  selector:    'insc-location-tree',
  templateUrl: './location-tree.component.html',
  styleUrls:   ['./location-tree.component.scss'],
  animations:  [
    trigger('searchResultsVisibility', [
      state('fade-in', style({ opacity: 1, "pointer-events": "auto" })),
      state('fade-out', style({ opacity: 0, "pointer-events": "none" })),
      transition('fade-in => fade-out', animate('200ms')),
      transition('fade-out => fade-in', animate('200ms')),
    ])
  ]
})
export class LocationTreeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() set selectedId(id: string) {
    this._selectedId = id
    this.selectedIdChange.next(id)
  }
  get selectedId(): string { return this._selectedId }

  private unsubscribe$ = new Subject<void>()

  addMenuOpenForId: string | null = null

  _selectedId: string | null = null
  private selectedIdChange = new ReplaySubject<string>(10)

  @Output() selection = new EventEmitter<LocationSelection>()



  @Output() locationCreate = new EventEmitter<{parentId: string; parentType: string; type: string; lookupProvider?: string; lookupOptions?: Record<string, string>}>()

  @ViewChild(MatTree, {static: true, read: ElementRef}) treeRef: ElementRef

  // TODO: HostListener, Dirty etc.

  treeControl: FlatTreeControl<FlatNode>

  dataSource: LocationDataSource

  @Input() allowCreateLocation = false
  @Input() queryString: string

  @ViewChildren('nodes') treeNodeElems: QueryList<ElementRef>

  creationMode = false

  private searchSubject = new Subject<string>()
  queryResults: Observable<Partial<ObjectLocation>[]>
  searchResultsLoading = false

  @Input() selectionHandler = (id: string): void =>  {
    this.selectId(id)
  }

  getLevel = (node: FlatNode): number => { return node.level }

  isExpandable = (node: FlatNode): boolean => { return node.expandable }

  hasChild = (_: number, _nodeData: FlatNode): boolean => {
    return _nodeData.expandable
  }

  trackBy: TrackByFunction<FlatNode> = (index, node) => node.id


  constructor(
    private locationService: ObjectLocationService,
    public locationDisplayHelper: LocationDisplayHelperService,
    public locationTypeDefs: LocationTypeDefsService) {

    this.treeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable)
    this.dataSource = new LocationDataSource(this.treeControl, locationService, locationDisplayHelper)
  }

  isNewNodeDummy = (_: number, _nodeData: FlatNode): boolean => _nodeData instanceof NewLocationFlatNode

  isDisabled = (node: FlatNode): boolean => this.creationMode && !(node instanceof NewLocationFlatNode)
  getNodeClass = (node: FlatNode): Record<string, boolean> => ({
    'menu-open': this.addMenuOpenForId == node.id,
    'disabled': this.isDisabled(node)
  })

  ngOnInit(): void {
    this.queryResults = this.searchSubject.pipe(
      startWith(""),
      debounceTime(500),
      tap(() => this.searchResultsLoading = true),
      switchMap(queryString => this.locationService.query(queryString).pipe(
        catchError((_err: unknown) => of(null))
      )),
      tap(() => this.searchResultsLoading = false),
      takeUntil(this.unsubscribe$)
    )
  }

  emitSelection(node: FlatNode): void {
    const parent = this.dataSource.getParentNode(node)
    this.selection.emit({
      id: node ? `${node.id}` : null,
      type: node ? node.type : null,
      parentId: parent && parent.id,
      parentType: parent && parent.type
    })
  }

  selectId(id: string, emitEvent = true): void {
    this.cancelCreateLocation()

    this._selectedId = `${id}`
    const node = this.dataSource.getNode(id)
    if (node) {
      emitEvent && this.emitSelection(node)
      setTimeout(() => this.scrollIdIntoView(id))
    } else {
      this.locationService.locations(null, this.dataSource.getExpandedNodeIds(), id).pipe(
        catchError(() => this.locationService.locations(null, this.dataSource.getExpandedNodeIds(), null)),
        map(locations => this.dataSource.toNodes(0, locations))
      ).subscribe(nodes => {
        this.dataSource.data = nodes
        setTimeout(() => {
          emitEvent && this.emitSelection(this.dataSource.getNode(id))
          setTimeout(() => this.scrollIdIntoView(id), 500)
        })
      })
    }
  }

  scrollIdIntoView(id: string): void {
    const nodeElem = this.treeNodeElems
      .find(elem => (elem.nativeElement as HTMLElement).dataset["id"] == id)

    if (nodeElem) {
      const nodeNativeElem = nodeElem.nativeElement as HTMLElement
      const treeNativeElem = this.treeRef.nativeElement as HTMLElement

      const nodeRect = nodeNativeElem.getBoundingClientRect()
      const treeRect = treeNativeElem.getBoundingClientRect()

      if (nodeRect.bottom > treeRect.bottom || nodeRect.top < treeRect.top) {
        nodeNativeElem.scrollIntoView({behavior: "smooth", block: "nearest"})
      }
    }
  }

  createLocation(parentNode: FlatNode, type: string, lookupProvider: string = null, lookupOptions: Record<string, string>): void {
    this.creationMode = true
    this.selectedId = null

    if (parentNode) {
      this.treeControl.expand(parentNode)
      const newNodeIndex = this.dataSource.data.indexOf(parentNode) + this.treeControl.getDescendants(parentNode).length + 1
      this.dataSource.data.splice(newNodeIndex, 0, new NewLocationFlatNode(parentNode.id, parentNode.level + 1))
      this.dataSource.dataChange.next(this.dataSource.data)
      this.locationCreate.next({parentId: parentNode.id, parentType: parentNode.type, type, lookupProvider, lookupOptions})
    } else {
      this.dataSource.data.push(new NewLocationFlatNode(null, 0))
      this.dataSource.dataChange.next(this.dataSource.data)
      this.locationCreate.next({parentId: null, parentType: null, type, lookupProvider, lookupOptions})
    }

    setTimeout(() => {
      return this.scrollIdIntoView(undefined)
    })
  }

  cancelCreateLocation(): void {
    const newNode = this.dataSource.data.find(node => node instanceof NewLocationFlatNode)
    // const parentNode = this.dataSource.getParentNode(newNode)
    const newNodeIndex = this.dataSource.data.indexOf(newNode)
    if (newNodeIndex >= 0) {
      this.dataSource.data.splice(newNodeIndex, 1)
    }
    this.dataSource.dataChange.next(this.dataSource.data)

    this.creationMode = false
  }

  updateNodeFor(location: Partial<ObjectLocation>): void {
    const node = this.dataSource.getNode(location.id)
    const newNode = new LocationFlatNode(this.locationDisplayHelper.getLocationNameWithLostAnnotation(location), !!location.geonames_id, !!location.wikidata_id, location.id, node.level, location.has_children, location.type)
    this.dataSource.replaceNode(node, newNode)
  }

  removeId(id: string): void {
    this.dataSource.removeNode(this.dataSource.getNode(id))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('selectedId' in changes) {
      this.selectId(this.selectedId)
    }

    if ('queryString' in changes) {
      this.searchSubject.next(this.queryString)
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
