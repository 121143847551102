<insc-data-group>
  <div inscDataField>
    <div *inscFieldLabel>
      @switch (personOrganization.entry?.type) {
        @case ('HistoricalPerson') {
          Person
        }
        @case ('HistoricalOrganization') {
          Organisation
        }
      }
    </div>
    <div *inscFieldValue>
      @if (personOrganization.entry) {
        {{personOrganization.entry.name}}
      } @else {
        N.N.
      }
    </div>
  </div>
  @if (personOrganization.entry && showExtraFields) {
    @if (personOrganization.entry?.type === 'HistoricalPerson') {
      <div inscDataField>
        <div *inscFieldLabel>GND-ID</div>
        <div *inscFieldValue>
          <insc-external-norm-data-id-link providerId="gnd" externalId="{{personOrganization.entry.gnd_id}}"></insc-external-norm-data-id-link>
        </div>
      </div>
      <div inscDataField>
        <div *inscFieldLabel>Wikidata-ID</div>
        <div *inscFieldValue>
          <insc-external-norm-data-id-link providerId="wikidata" externalId="{{personOrganization.entry.wikidata_id}}"></insc-external-norm-data-id-link>
        </div>
      </div>
      <div inscDataField>
        <div *inscFieldLabel>Germania Sacra-ID (GSN)</div>
        <div *inscFieldValue>
          <insc-external-norm-data-id-link providerId="germania_sacra" externalId="{{personOrganization.entry.germania_sacra_id}}"></insc-external-norm-data-id-link>
        </div>
      </div>
    }
    <div inscDataField>
      <div *inscFieldLabel>Rolle</div>
      <div *inscFieldValue>{{personOrganization.role}}</div>
    </div>
    <div inscDataField>
      <div *inscFieldLabel>Authentizität</div>
      <div *inscFieldValue>{{personOrganization.authenticity}}</div>
    </div>
  }
</insc-data-group>
