import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { MatExpansionModule } from "@angular/material/expansion"
import {
  NormDataExpansionPanelComponent,
  NormDataPanelEntryDescriptionDirective,
  NormDataPanelEntryDetailsDirective,
  NormDataPanelEntrySubtitleDirective,
  NormDataPanelEntryTitleDirective
} from "./norm-data-expansion-panel.component"


@NgModule({
  declarations: [
    NormDataExpansionPanelComponent,
    NormDataPanelEntryTitleDirective,
    NormDataPanelEntrySubtitleDirective,
    NormDataPanelEntryDetailsDirective,
    NormDataPanelEntryDescriptionDirective
  ],
  imports: [
    CommonModule,
    MatExpansionModule
  ],
  exports: [
    NormDataExpansionPanelComponent,
    NormDataPanelEntryTitleDirective,
    NormDataPanelEntrySubtitleDirective,
    NormDataPanelEntryDetailsDirective,
    NormDataPanelEntryDescriptionDirective
  ]
})
export class NormDataExpansionPanelModule { }
