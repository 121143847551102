import { Injectable } from '@angular/core'
import { MatDialog } from "@angular/material/dialog"
import { LoginDialogComponent, LoginDialogData } from "./login-dialog.component"

@Injectable()
export class LoginDialogService {

  constructor(private dialog: MatDialog) { }

  open(message?: string) {
    const data: LoginDialogData = message ? {message: message} : null
    return this.dialog.open(LoginDialogComponent, {data: data}).afterClosed()
  }
}
