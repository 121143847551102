<ng-container [formGroup]="formGroup">
  <mat-form-field>
    <input matInput  type="text" formControlName="specification">
    <mat-label>Spezifizierung</mat-label>
    <mat-error inscError errorControlName="specification"></mat-error>
  </mat-form-field>
  <mat-form-field>
    <input matInput  type="text" formControlName="based_on">
    <mat-label>Lokalisierungsgrundlage</mat-label>
    <mat-error inscError errorControlName="based_on"></mat-error>
  </mat-form-field>
  <mat-form-field>
    <textarea matInput  type="text" formControlName="comment"></textarea>
    <mat-label>Kommentar</mat-label>
    <mat-error inscError errorControlName="comment"></mat-error>
  </mat-form-field>
</ng-container>
