<ng-template #actionButtons>
  <div class="buttons">

    @if (imageGroup?.images.length > 0) {
      @switch (imageGroup.selectionStatus) {
        @case ('none') {
          <div class="action-button" role="button" matRipple (click)="imageGroup.selectAll()" matTooltip="Alle auswählen"><mat-icon>check_box_outline_blank</mat-icon></div>
        }
        @case ('some') {
          <div class="action-button" role="button" matRipple (click)="imageGroup.deselectAll()" matTooltip="Alle abwhälen"><mat-icon>indeterminate_check_box</mat-icon></div>
        }
        @case ('all') {
          <div class="action-button" role="button" matRipple (click)="imageGroup.deselectAll()" matTooltip="Alle abwählen"><mat-icon>check_box</mat-icon></div>
        }
      }
      <mat-divider vertical></mat-divider>
    }

    @if (imageGroup?.images.length > 0) {
      <div
        class="action-button"
        role="button"
        matRipple
        matTooltip="Verknüpfung für ausgewählte Aufnahme(n) entfernen"
        [ngClass]="{disabled: ['none', 'empty'].includes(imageGroup.selectionStatus)}"
        (click)="unlinkSelected()"
        >
        <mat-icon>link_off</mat-icon>
      </div>
    }

    <div
      class="action-button"
      role="button"
      matRipple
      matTooltip="Aufnahmen verknüpfen oder hochladen"
      [matMenuTriggerFor]="addImageMenu"
      >
      <mat-icon>add_photo_alternate</mat-icon>
    </div>

    <mat-menu #addImageMenu>
      <ng-template matMenuContent>
        <button mat-menu-item (click)="uploadImagesClicked.emit()">
          <mat-icon>publish</mat-icon>
          <span>Bilddateien hochladen</span>
        </button>
        <button mat-menu-item (click)="linkImageClicked.emit()">
          <mat-icon>insert_link</mat-icon>
          <span>Vorhandene Aufnahme verknüpfen</span>
        </button>
      </ng-template>
    </mat-menu>

    @if (showDownloadButton && imageGroup?.images.length > 0) {
      <mat-divider vertical></mat-divider>
      <div
        class="action-button"
        role="button"
        matRipple
        matTooltip="Ausgewählte Bilddateien herunterladen"
        [ngClass]="{disabled: imageGroup.selectionStatus === 'none'}"
        >
        <insc-image-download-menu #imageDownloadMenu
          [images]="imageGroup.selectedImages()"
          [parent]="imageParent"
        ></insc-image-download-menu>
        <a class="download-link" [matMenuTriggerFor]="imageDownloadMenu.menu"><mat-icon>file_download</mat-icon></a>
      </div>
    }
    <!--      <div-->
    <!--        class="action-button"-->
    <!--        role="button"-->
    <!--        matRipple-->
    <!--        matTooltip="Galerieansicht öffnen"-->
    <!--        (click)="imageViewer.open(recordName, imageGroup?.images)"-->
    <!--      >-->
    <!--        <mat-icon>photo_library</mat-icon>-->
  <!--      </div>-->
<!--    </ng-container>-->
</div>
</ng-template>

@if (!hasSubtitle) {
  <div class="header">
    <div class="title">
      <ng-container *ngTemplateOutlet="titleTpl"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
  </div>
} @else {
  <div class="header-with-subtitle">
    <ng-container *ngTemplateOutlet="titleTpl"></ng-container>
    <div class="header">
      <div class="title">
        <ng-container *ngTemplateOutlet="subtitleTpl"></ng-container>
      </div>
      <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
    </div>
  </div>
}


<div class="content-wrapper" [ngStyle]="contentWrapperStyle">
  <ng-content></ng-content>
</div>
