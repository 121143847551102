<ng-template #iptcControlHint let-iptcControl="iptcControl">
  @if (toIptcControl(iptcControl).state === 'generatedValue') {
    <mat-icon class="hint-icon" fontSet="material-icons-outlined">auto_fix_normal</mat-icon> <span>Vorgeschlagener Wert</span>
  }
  @if (toIptcControl(iptcControl).state === 'manualValue') {
    <mat-icon class="hint-icon manual">🅼</mat-icon> <span>Manuell gesetzter Wert</span>
  }
</ng-template>

<mat-menu #cellMenu="matMenu">
  <ng-template matMenuContent let-controlName="controlName" let-iptcValue="iptcValue">
    <button mat-menu-item (click)="copyValue(controlName, iptcValue)">
      <mat-icon>list</mat-icon>
      <span>Für {{this.selectionString}} übernehmen</span>
    </button>
  </ng-template>
</mat-menu>

<ng-template #cellButtons let-controlName="controlName" let-iptcControl="iptcControl">
  <div class="cell-buttons">
    <button mat-icon-button
      [matMenuTriggerFor]="cellMenu"
      [matMenuTriggerData]="{controlName, iptcValue: toIptcControl(iptcControl).iptcValue}"
      ><mat-icon>content_copy</mat-icon></button>
      @if (iptcControl.state === 'manualValue') {
        <button mat-icon-button
          matTooltip="Generierten Wert wiederherstellen"
          (click)="toIptcControl(iptcControl).resetToGenerated()"
          >
          <mat-icon>auto_fix_high</mat-icon>
        </button>
      }
    </div>
  </ng-template>

  <ng-template
    #addKeywordOverlay="cdkConnectedOverlay"
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="addKeywordOverlayCurrentOrigin"
    [cdkConnectedOverlayOpen]="addKeywordOverlayOpen"
    [cdkConnectedOverlayHasBackdrop]="true"
    (backdropClick)="removeAddKeywordOverlay()"
    (attach)="focusAddKeywordInput()"
    (detach)="onOverlayDetach()"
    >
    <div class="insc-table-iptc-editor-add-keyword-overlay">
      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Schlagwort hinzufügen</mat-label>
        <input matInput #addKeywordInput (keydown.enter)="addKeyword(addKeywordInput.value)">
      </mat-form-field>
    </div>
  </ng-template>






  <!-- KEYWORD CHIP TEMPLATES -->
  <mat-menu #keywordChipMenu>
    <ng-template matMenuContent let-iptcValue="iptcValue">
      <button mat-menu-item (click)="copyKeyword(iptcValue)">
        <mat-icon>playlist_add</mat-icon>
        Schlagwort für {{this.selectionString}} übernehmen
      </button>
      <button mat-menu-item (click)="removeKeyword(iptcValue)">
        <mat-icon>delete_sweep</mat-icon>
        Schlagwort für {{this.selectionString}} entfernen
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #keywordCellMenu>
    <ng-template matMenuContent let-formArray="formArray">
      <button mat-menu-item (click)="cloneKeywords(formArray)">Für {{this.selectionString}} angleichen</button>
    </ng-template>
  </mat-menu>


  <!-- A generated value that has been untouchend / left included in the override array -->
  <ng-template #untouchedGeneratedIPTCArrayItem let-formControl="formControl">
    <mat-chip-row class="keyword-chip generated">
      <insc-iptc-form-field-wrapper [formControl]="formControl" #iptcControl class="chip-content">
        <mat-icon inline
          class="prefix-icon"
          matTooltip="Generierter Wert: dieser Wert wurde anhand der Metadaten automatisch erzeugt."
          fontSet="material-icons-outlined"
        >auto_fix_normal</mat-icon>
        <span class="value">{{ iptcControl.innerControl.value }}</span>
        <button matChipRemove (click)="removeGeneratedIptcArrayItem(formControl)" matTooltip="Eintrag entfernen">
          <mat-icon>delete</mat-icon>
        </button>
        <button matChipRemove [matMenuTriggerFor]="keywordChipMenu" [matMenuTriggerData]="{iptcValue: formControl.value}" matTooltip="Aktionen für mehrere Datensätze">
          <mat-icon>content_copy</mat-icon>
        </button>
      </insc-iptc-form-field-wrapper>
    </mat-chip-row>
  </ng-template>

  <!-- A generated value that has been previously removed from the override array -->
  <!-- or marked for removal in the current editing session -->
  <ng-template #removedGeneratedIPTCArrayItem let-formControl="formControl">
    <mat-chip-row class="keyword-chip generated removed">
      <insc-iptc-form-field-wrapper [formControl]="formControl" #iptcControl class="chip-content">
        <mat-icon inline color="warn" class="prefix-icon" matTooltip="Aus IPTC-Daten entfernt (automatisch generierter Wert)"
        >auto_fix_off</mat-icon>
        <span class="value">{{ iptcControl.innerControl.value }}</span>
        <button matChipRemove (click)="restoreRemovedGeneratedIptcArrayItem(formControl)" matTooltip="Eintrag wiederherstellen">
          <mat-icon>restore_from_trash</mat-icon>
        </button>
        <button matChipRemove [matMenuTriggerFor]="keywordChipMenu" [matMenuTriggerData]="{iptcValue: formControl.value}" matTooltip="Aktionen für mehrere Datensätze">
          <mat-icon>content_copy</mat-icon>
        </button>
      </insc-iptc-form-field-wrapper>
    </mat-chip-row>
  </ng-template>

  <!-- A manually added value (not marked for removal) -->
  <ng-template #manualIPTCArrayItem let-formControl="formControl">
    <mat-chip-row class="keyword-chip manual">
      <insc-iptc-form-field-wrapper [formControl]="formControl" #iptcControl class="chip-content">
        <mat-icon inline class="prefix-icon manual" matTooltip="Manuell hinzugefügter Wert.">🅼</mat-icon>
        <span class="value">{{ iptcControl.innerControl.value }}</span>
        <button matChipRemove (click)="removeManuallyAddedIptcArrayItem(formControl)" matTooltip="Eintrag entfernen">
          <mat-icon>delete</mat-icon>
        </button>
        <button matChipRemove [matMenuTriggerFor]="keywordChipMenu" [matMenuTriggerData]="{iptcValue: formControl.value}" matTooltip="Aktionen für mehrere Datensätze">
          <mat-icon>content_copy</mat-icon>
        </button>
      </insc-iptc-form-field-wrapper>
    </mat-chip-row>
  </ng-template>

  <!-- A manually added value marked for removal -->
  <ng-template #manualIptcValueMarkedForRemoval let-formControl="formControl" class="chip-content">
    <mat-chip-row class="keyword-chip manual removed">
      <insc-iptc-form-field-wrapper [formControl]="formControl" #iptcControl>
        <mat-icon inline color="warn" class="prefix-icon" matTooltip="Wird gelöscht (manuell festgelegter Wert).">delete</mat-icon>
        <span class="value">{{ iptcControl.innerControl.value }}</span>
        <button matChipRemove (click)="restoreRemovedManualIptcArrayItem(formControl)" matTooltip="Eintrag wiederherstellen">
          <mat-icon>restore_from_trash</mat-icon>
        </button>
        <button matChipRemove [matMenuTriggerFor]="keywordChipMenu" [matMenuTriggerData]="{iptcValue: formControl.value}" matTooltip="Aktionen für mehrere Datensätze">
          <mat-icon>content_copy</mat-icon>
        </button>
      </insc-iptc-form-field-wrapper>
    </mat-chip-row>
  </ng-template>


  <!-- END KEYWORD CHIP TEMPLATES -->

  <div class="toolbar">
    <h1 class="title">IPTC-Daten bearbeiten</h1>
    <ng-template
      #selectColumnsOverlay="cdkConnectedOverlay"
      cdkConnectedOverlay
      [cdkConnectedOverlayFlexibleDimensions]="true"
      [cdkConnectedOverlayOrigin]="columnDisplayOverlayTrigger"
      [cdkConnectedOverlayOpen]="selectColumnsOverlayOpen"
      cdkConnectedOverlayPanelClass="mat-elevation-z2"
      (overlayOutsideClick)="selectColumnsOverlayOpen = false"
      (detach)="selectColumnsOverlayOpen = false"
      >
      <div class="insc-table-iptc-editor-select-columns-overlay" cdkDropList (cdkDropListDropped)="moveColumn($event)">
        @for (field of iptcFields; track field) {
          <div
            class="column-selection-checkbox"
            cdkDrag
            >
            <mat-checkbox
              [checked]="columnDisplaySelection.isSelected(field)"
              (change)="columnDisplaySelection.toggle(field)"
            >{{cellTitles[field]}}</mat-checkbox>
            <mat-icon cdkDragHandle class="drag-handle">drag_handle</mat-icon>
          </div>
        }
      </div>
    </ng-template>
    <button #columnDisplayOverlayTrigger="cdkOverlayOrigin"
      class="select-columns-button unlimited-width-badge"
      mat-stroked-button
      cdkOverlayOrigin
      (click)="selectColumnsOverlayOpen = !selectColumnsOverlayOpen"
      matBadge="{{columnDisplaySelection.selected.length}} von {{iptcFields.length}} sichtbar"
      [matBadgeHidden]="columnDisplaySelection.selected.length === iptcFields.length"
      matBadgeSize="small"
      matBadgeColor="warn"
      >
      <mat-icon fontSet="material-icons-outlined">view_column</mat-icon>
      <span>Spalten konfigurieren</span>
    </button>
    <mat-paginator
      class="insc-dense-paginator"
      [length]="dataSource.data.length"
      [pageSize]="10"
      [pageSizeOptions]="[5,10, 20]"
      [showFirstLastButtons]="true"
      >
    </mat-paginator>
  </div>

  <div class="table-wrapper">

    <table mat-table [trackBy]="trackBy" class="full-width-table" matSort aria-label="Elements">

      <!-- Text Field Column Template -->
      <ng-template #textFieldCol let-rowData="rowData" let-controlName="controlName">
        <ng-container [matColumnDef]="controlName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ cellTitles[controlName] }}</th>
          <td mat-cell *matCellDef="let rowData">
            <div class="cell-content">
              <insc-iptc-form-field-wrapper [formControl]="getIptcFormControl(rowData, controlName)" #iptcControl>
                <mat-form-field>
                  <mat-autocomplete #autocomplete="matAutocomplete" [hideSingleSelectionIndicator]="true">
                    @if (iptcControl.iptcValue?.generated) {
                      <mat-option [value]="iptcControl.iptcValue.generated">
                        <div class="iptc-table-editor-suggestion-menu">
                          <div class="suggestion-header">Vorgeschlagener Wert</div>
                          <div class="suggestion-value">{{iptcControl.iptcValue.generated}}</div>
                        </div>
                      </mat-option>
                    }
                  </mat-autocomplete>

                  <textarea
                    matInput
                    [formControl]="iptcControl.innerControl"
                    cdkTextareaAutosize [cdkAutosizeMinRows]="2" [cdkAutosizeMaxRows]="6"
                    [matAutocomplete]="autocomplete"
                    [class]="getCellClass(iptcControl.state)"
                  ></textarea>

                  <mat-hint>
                    <ng-container *ngTemplateOutlet="iptcControlHint; context: {iptcControl}"></ng-container>
                  </mat-hint>
                </mat-form-field>
              </insc-iptc-form-field-wrapper>

              <ng-container *ngTemplateOutlet="cellButtons; context: {controlName, iptcControl}"></ng-container>
            </div>
          </td>
        </ng-container>
      </ng-template>

      <!-- START COLUMNS -->

      <!-- Selection Column -->
      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <button mat-icon-button
            [disabled]="selectionCount === 0"
            matTooltip="Auswahl aufheben"
            (click)="clearSelection(); $event.stopPropagation()">
            <mat-icon
              [matBadge]="selectionCount"
              [matBadgeHidden]="selectionCount === 0"
              matBadgeSize="small"
              matBadgePosition="before"
              matBadgeColor="warn"
            >remove_done</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let rowData">
          <mat-checkbox [checked]="isSelected(rowData.imageId)" (change)="toggleSelection(rowData.imageId)">{{rowData.imageId}}</mat-checkbox>
        </td>
      </ng-container>

      <!-- Id Column -->
      <ng-container matColumnDef="image" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let rowData">
          <img [src]="toRowData(rowData).imagePreviewUrl" [ngStyle]="{width: '35px', height: '35px'}">
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell class="name" *matCellDef="let rowData">{{toRowData(rowData).imageName}}</td>
      </ng-container>


      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'title' }"></ng-container>
      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'headline' }"></ng-container>
      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'description' }"></ng-container>
      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'creator' }"></ng-container>
      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'description_writer' }"></ng-container>
      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'source' }"></ng-container>
      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'copyright_notice' }"></ng-container>

      <!-- marked ("Copyright-Status") column -->
      <ng-container matColumnDef="marked">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ cellTitles['marked'] }}</th>
        <td mat-cell *matCellDef="let rowData">
          <div class="cell-content">
            <insc-iptc-form-field-wrapper [formControl]="getIptcFormControl(rowData, 'marked')" #iptcControl>
              <mat-form-field>

                <mat-select [formControl]="iptcControl.innerControl" [ngClass]="getCellClass(iptcControl.state)">
                  <mat-option [value]="true">{{ iptcDatasetService.getCopyrightStatusString(true) }}</mat-option>
                  <mat-option [value]="false">{{ iptcDatasetService.getCopyrightStatusString(false) }}</mat-option>
                </mat-select>

                <mat-hint>
                  <ng-container *ngTemplateOutlet="iptcControlHint; context: {iptcControl}"></ng-container>
                </mat-hint>
              </mat-form-field>
            </insc-iptc-form-field-wrapper>

            <ng-container *ngTemplateOutlet="cellButtons; context: {controlName: 'marked', iptcControl: iptcControl}"></ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'credit_line' }"></ng-container>
      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'rights_usage_terms' }"></ng-container>
      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'city' }"></ng-container>
      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'sublocation' }"></ng-container>
      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'province_or_state' }"></ng-container>
      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'country' }"></ng-container>
      <ng-container *ngTemplateOutlet="textFieldCol; context: {controlName: 'country_code' }"></ng-container>

      <!-- KEYWORDS COLUMN -->
      <ng-container matColumnDef="keywords">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Schlagwörter</th>
        <td mat-cell *matCellDef="let rowData">
          @if ((getIptcFormControl(rowData, 'keywords')); as formArray) {
            <div class="cell-content">
              <mat-form-field class="keywords">
                <mat-chip-grid #chipGrid class="keyword-chip-grid" [ngClass]="hasKeywordsOverride(rowData) ? 'manual' : 'generated'">
                  @for (formControl of formArray.controls; track formControl) {
                    @if (formControl.value.isUntouched && !formControl.value.markedForRemoval) {
                      <ng-container *ngTemplateOutlet="untouchedGeneratedIPTCArrayItem; context: {formControl}"></ng-container>
                    }
                    @if (formControl.value.isManuallyRemoved || (!formControl.value.isManuallyAdded && formControl.value.markedForRemoval)) {
                      <ng-container *ngTemplateOutlet="removedGeneratedIPTCArrayItem; context: {formControl}"></ng-container>
                    }
                    @if ((formControl.value.isManuallyAdded || formControl.value.newlyAdded) && !formControl.value.markedForRemoval) {
                      <ng-container *ngTemplateOutlet="manualIPTCArrayItem; context: {formControl}"></ng-container>
                    }
                    @if (formControl.value.isManuallyAdded && formControl.value.markedForRemoval) {
                      <ng-container *ngTemplateOutlet="manualIptcValueMarkedForRemoval; context: {formControl}"></ng-container>
                    }
                  }
                  <button mat-icon-button #addOverlayTrigger="cdkOverlayOrigin"
                    matTooltip="Schlagwort hinzufügen"
                    class="add-keyword-button"
                    type="button"
                    cdkOverlayOrigin
                    (click)="showAddKeywordOverlay(addOverlayTrigger, formArray)">
                    <mat-icon>add</mat-icon></button>
                  </mat-chip-grid>
                  <input class="hidden-chip-input" matInput [matChipInputFor]="chipGrid" readonly>
                  <mat-hint>
                    @if (keywordsSetManually(toRowData(rowData))) {
                      <mat-icon class="hint-icon manual">🅼</mat-icon> <span>Manuell gesetzt</span>
                    } @else {
                      <mat-icon class="hint-icon" fontSet="material-icons-outlined">auto_fix_normal</mat-icon> <span>Vorgeschlagene Werte</span>
                    }
                  </mat-hint>
                </mat-form-field>
                <button mat-icon-button
                  [matMenuTriggerFor]="keywordCellMenu"
                  [matMenuTriggerData]="{formArray}"
                  ><mat-icon>content_copy</mat-icon></button>
                </div>
              }
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let even = even;" [ngClass]="{even: even}"></tr>
        </table>
      </div>
