import { Component } from "@angular/core"
import { FormArray, FormControl, FormGroup } from "@angular/forms"
import { HistoricalPersonEntry } from "../../../../shared/models/historical-person-entry.model"

import { SubformComponent } from "../../../../shared/subform.component"

@Component({
  selector:    'insc-historical-person-entry-form',
  templateUrl: 'historical-person-entry-form.component.html',
  styleUrls: ['shared-styles.scss']
})
export class HistoricalPersonEntryFormComponent extends SubformComponent {

  // noinspection DuplicatedCode
  static buildFormGroup(historicalPersonEntry: Partial<HistoricalPersonEntry>) {
    const alternativeNameFormGroups = (historicalPersonEntry?.alternative_names || []).map((name: string) => new FormControl(name))
    return new FormGroup({
      id:                new FormControl(historicalPersonEntry?.id || null),
      name:              new FormControl(historicalPersonEntry?.name || ""),
      comment:           new FormControl(historicalPersonEntry?.comment || ""),
      timeframe:         new FormControl(historicalPersonEntry?.timeframe || ""),
      gnd_id:            new FormControl(historicalPersonEntry?.gnd_id || ""),
      wikidata_id:       new FormControl(historicalPersonEntry?.wikidata_id || ""),
      germania_sacra_id: new FormControl(historicalPersonEntry?.germania_sacra_id || ""),
      alternative_names: new FormArray(alternativeNameFormGroups)
    })
  }

  addAltName() {
    (this.formGroup.controls["alternative_names"] as FormArray).push(new FormControl(''))
  }

  removeAltName(i: number) {
    (this.formGroup.controls["alternative_names"] as FormArray).removeAt(i)
  }
}
