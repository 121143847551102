import { Injectable, OnDestroy } from "@angular/core"
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms"
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router"
import { BehaviorSubject, EMPTY, Observable, Subject } from "rxjs"
import { catchError, pluck, share, switchMap, tap } from "rxjs/operators"
import { DataService } from "../../services/data.service"
import { ValidationError } from "../../services/errors"
import { FormService } from "../../services/form.service"
import { EditingStatus, InscEntity } from "../../shared/models/entity.model"
import { SnackbarService } from "../../shared/snackbars/snackbar.service"

export const recordPageStateServiceFactory =
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  <T extends InscEntity>(dataService: DataService<T>, snackbarService: SnackbarService, formService: FormService, router: Router, route: ActivatedRoute) =>
    new RecordPageStateService(dataService, snackbarService, formService, router, route)

/**
 * Shared functionality and state for the Record Pages
 */
@Injectable({providedIn: null})
export class RecordPageStateService<T extends InscEntity> implements OnDestroy {

  form: UntypedFormGroup | null

  get formValue(): Partial<T> { return this.form.getRawValue() as Partial<T>}

  _record = new BehaviorSubject<T>(null)
  get record(): T { return this._record.value }
  set record(record: T) { this._record.next(record) }
  get recordChanges(): Observable<T> {
    return this._record.asObservable().pipe(
      share()
    )
  }

  private unsubscribe$ = new Subject<void>()

  /**
   * @param dataService
   * @param snackbarService
   * @param formService
   * @param router
   * @param route
   */
  constructor(
    private dataService: DataService<T>,
    private snackbarService: SnackbarService,
    private formService: FormService,
    private router: Router,
    private route: ActivatedRoute
  ) {

    // respond to changes in the data from the route resolver
    route.data.pipe(
      pluck(this.dataService.dataType)
    ).subscribe(this._record)
  }


  /**
   * Common behaviours for all record pages after saving a record
   * @param saveResultSource the observable returned by a data service's
   *  _save_ method
   * @param navigationFunc a function that handles navigation after saving a record
   */
  processSaveResult(saveResultSource: Observable<T>, navigationFunc: () => Promise<boolean>): Promise<boolean> {

    return saveResultSource.pipe(
      // show validation errors in the form
      catchError(error => {
        if (error instanceof ValidationError) {
          this.formService.applyValidationErrors(this.form, error.field_errors)
        }
        return EMPTY
      }),

      // avoid "unsaved changes" confirmation dialogue when navigating back to the view
      tap(() => this.form.markAsPristine()),

      // When navigating back to the view after saving, the ID in the URL does not change
      // so the router does not fire a paramMap change and no new API request will be made.
      // Thus refresh the record with the save result
      tap(record => this.record = record),

      tap(() => this.snackbarService.showSuccessWithMessage("Gespeichert")),

      switchMap(_ => navigationFunc())
    ).toPromise()
  }

  delete(navigationOnSuccess: { commands: unknown[]; extras?: NavigationExtras}): Promise<boolean> {
    const {commands, extras} = navigationOnSuccess

    return this.dataService.delete(this.record.id).pipe(
      tap(() => this.snackbarService.showSuccessWithMessage("Gelöscht")),
      switchMap(() => this.router.navigate(commands, extras))
    ).toPromise()
  }

  setEditingStatus(status: EditingStatus): Observable<InscEntity> {
    return this.dataService.setEditingStatus(this.record.id, status)
  }

  /**
   * Helper that enables type inference for repeated form groups in template,
   * avoids linting errors.
   * @param {string} formArrayName
   * @returns {FormGroup[]}
   */
  itemsFor(formArrayName: keyof T): UntypedFormGroup[] {
    const formArray = this.form.controls[formArrayName as string]
    if (formArray instanceof UntypedFormArray) {
      return formArray.controls as UntypedFormGroup[]
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
