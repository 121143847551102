<insc-record-form-container>
  @if (recordPageState.form) {
    <form id="form" [formGroup]="recordPageState.form">
      <insc-form-section sectionTitle="Bezeichnung" [showTitle]="false">
        <mat-form-field appearance="outline">
          <mat-label>Bezeichnung</mat-label>
          <input matInput type="text" id="name" formControlName="name">
          <mat-error inscError errorControlName="name"></mat-error>
        </mat-form-field>
      </insc-form-section>
      <insc-form-section sectionTitle="Allgemein">
        <mat-form-field>
          <input matInput type="text"  formControlName="location_on_object">
          <mat-label>Anbringungsort am Inschriftenträger</mat-label>
          <mat-error inscError errorControlName="location_on_object"></mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Beschreibung</mat-label>
          <textarea matInput type="text"  formControlName="description"></textarea>
          <mat-error inscError errorControlName="description"></mat-error>
        </mat-form-field>
        <insc-single-vocabulary-select label="Sprache" formControlName="language" vocabularyName="language" [hasEditorialState]="true"></insc-single-vocabulary-select>
        <div class="text-form-checkboxes">
          <mat-checkbox formControlName="verses">Enthält Verse</mat-checkbox>
          <mat-checkbox formControlName="prose">Enthält Prosa</mat-checkbox>
        </div>
        <insc-multi-vocabulary-select label="Texttyp" formControlName="inscription_types" vocabularyName="inscription_type" [hasEditorialState]="true"></insc-multi-vocabulary-select>
        <insc-multi-vocabulary-select label="Material" formControlName="inscription_materials" vocabularyName="inscription_material" [hasEditorialState]="true"></insc-multi-vocabulary-select>
        <insc-multi-vocabulary-select label="Technik" formControlName="inscription_techniques" vocabularyName="inscription_technique" [hasEditorialState]="true"></insc-multi-vocabulary-select>
        <insc-single-vocabulary-select label="Zustand" formControlName="inscription_condition" vocabularyName="inscription_condition" [hasEditorialState]="true"></insc-single-vocabulary-select>
        <insc-multi-vocabulary-select label="Schriftart" formControlName="typefaces" vocabularyName="typeface" [hasEditorialState]="true"></insc-multi-vocabulary-select>
      </insc-form-section>
      <insc-form-section sectionTitle="Datierung" formArrayName="datings">
        @for (dating of recordPageState.itemsFor('datings'); track dating) {
          <insc-repeatable-form [formGroup]="dating">
            <insc-dating-form></insc-dating-form>
          </insc-repeatable-form>
        }
      </insc-form-section>
      <insc-form-section sectionTitle="Literatur" formArrayName="literature_references">
        @for (litRef of recordPageState.itemsFor('literature_references'); track litRef) {
          <insc-repeatable-form [formGroup]="litRef">
            <insc-literaturereference-form></insc-literaturereference-form>
          </insc-repeatable-form>
        }
      </insc-form-section>
      <insc-form-section sectionTitle="Kommentare" formArrayName="comments">
        @for (comment of recordPageState.itemsFor('comments'); track comment) {
          <insc-repeatable-form [formGroup]="comment">
            <insc-comment-form></insc-comment-form>
          </insc-repeatable-form>
        }
      </insc-form-section>
    </form>
  }
</insc-record-form-container>
