import { Component, Input, OnChanges } from "@angular/core"
import { LocationDisplayHelperService } from "../../../../services/location-display-helper.service"
import { Locationing } from "../../../../shared/models"

@Component({
  selector:    'insc-locationing-view',
  templateUrl: './locationing-view.component.html',
  styleUrls:   ['./locationing-view.component.scss']
})
export class LocationingViewComponent implements OnChanges {

  @Input() locationing: Locationing
  locationTypeString: string


  constructor(public locationDisplayHelper: LocationDisplayHelperService) { }

  ngOnChanges() {
    this.locationTypeString = this.locationDisplayHelper.getLocationTypeString(this.locationing.location.type)
  }


}
