import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, ViewChild } from "@angular/core"
import { Subject } from "rxjs"
import { filter, takeUntil } from "rxjs/operators"
import { InscImage } from "../../../shared/models/image.model"
import { ImageManagementDirective } from "./image-management.directive"

export class ImageDropEvent {
  constructor(
    readonly target: ImageManagementGroupComponent,
    readonly image: InscImage,
    readonly sourceGroupDescriptor: {type: string; id?: string},
    readonly index: number
  ) {}
}

@Component({
  selector:    'insc-image-management-group',
  templateUrl: './image-management-group.component.html',
  styleUrls:   ['./image-management-group.component.scss']
})
export class ImageManagementGroupComponent implements OnInit, OnChanges, OnDestroy {

  @Input() imageGroupDescriptor: {type: string; id?: string}
  @Input() images: Partial<InscImage[]>
  @Output() imageDrop = new EventEmitter<ImageDropEvent>()

  private unsubscribe$ = new Subject<void>()

  sortablejsOptions = this.imageManagementDirective?.sortableJsOptions
  @ViewChild('dragSource', {static: true}) dragSourceElem: ElementRef

  get selectionStatus(): "empty" | "all" | "none" | "some" {
    const selectedCount = this.selectedImages()?.length
    const imageCount = this.imageCount()

    if (imageCount === 0) {
      return "empty"
    } else if (selectedCount === 0) {
      return "none"
    } else if (selectedCount < imageCount) {
      return "some"
    } else {
      return "all"
    }
  }

  constructor(
    @Optional() private imageManagementDirective: ImageManagementDirective
  ) { }

  hasSelections(): boolean {
    return this.images?.some(image => this.imageManagementDirective.isSelected(image.id)) ?? false
  }

  selectAll(): void {
    this.images.forEach(image => this.imageManagementDirective.select(image.id))
  }

  deselectAll(): void {
    this.images.forEach(image => this.imageManagementDirective.deselect(image.id))
  }

  select(id: string): void {
    this.imageManagementDirective.select(id)
  }

  deselect(id: string): void {
    this.imageManagementDirective.deselect(id)
  }

  imageCount(): number {
    return this.images?.length ?? 0
  }

  selectedImages(): InscImage[] | undefined {
    return this.images
      ?.filter(image => this.imageManagementDirective.isSelected(image.id))
  }

  ngOnChanges(): void {
    if (!this.images) {
      throw new Error("images must be set")
    }
  }

  ngOnInit(): void {

    if (!this.imageGroupDescriptor) {
      throw new Error("imageGroupDescriptor must be set")
    }

    this.imageManagementDirective.drop$.pipe(
      filter(({target}) => target === this.dragSourceElem.nativeElement),
      takeUntil(this.unsubscribe$),
    ).subscribe(({source, targetIndex}) => {
      const dragItem: InscImage = this.images[targetIndex]
      let sourceGroupDescriptor = null
      try {
        console.log(source)
        sourceGroupDescriptor = JSON.parse((source).dataset.groupDescriptor) as {type: string; id?: string}
      } finally {
        this.imageDrop.emit(new ImageDropEvent(
          this,
          dragItem,
          sourceGroupDescriptor,
          targetIndex
        ))
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

}

