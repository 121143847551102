import { Component } from "@angular/core"
import { ControlContainer, FormControl, FormGroup } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import { Dating } from "../../../../shared/models/dating.model"
import { SubformComponent } from "../../../../shared/subform.component"

import { DatingChooserDialogComponent, DatingChooserDialogRef } from "../dialogs/dating-chooser-dialog/dating-chooser-dialog.component"


@Component({
  selector:    "insc-dating-form",
  templateUrl: "dating-form.component.html",
  styles:      [`
                  :host {
                    display: flex;
                    flex-direction: column;
                  }

                  .dating-input-suffix {
                    display: flex;
                    align-items: center;
                  }

                  .description {
                    font-weight: bold;
                    margin-bottom: 1em;
                    border-bottom: 1px dotted lightgrey;
                  }
                `]
})
export class DatingFormComponent extends SubformComponent {

  static buildFormGroup(dating: Dating, destroyable = true) {
    return new FormGroup({
      id:             new FormControl(dating.id || null),
      based_on:       new FormControl(dating.based_on || null),
      comment:        new FormControl(dating.comment || null),
      primary:        new FormControl(dating.primary || null),
      secondary:      new FormControl(dating.secondary || null),
      range_modifier: new FormControl(dating.range_modifier || "closed"),
      description:    new FormControl(dating.description || null),
      uncertain:      new FormControl(dating.uncertain || false),
      ...this.destroyableControl(dating.id, destroyable)
    })
  }


  constructor(
    controlContainer: ControlContainer,
    private dialog: MatDialog
  ) {
    super(controlContainer)
  }

  openDatingChooser() {
    const dialogRef: DatingChooserDialogRef = this.dialog.open(DatingChooserDialogComponent, {
      width: "700px",
      // height: '250px',
      data: {
        primary:        this.formGroup.value["primary"],
        secondary:      this.formGroup.value["secondary"],
        range_modifier: this.formGroup.value["range_modifier"]
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (!result) { return }

      this.formGroup.patchValue(result)
    })
  }


}

