<div class="table-container">
  <table mat-table [dataSource]="users">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let user"> {{user["id"]}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> NAME </th>
      <td mat-cell *matCellDef="let user"> {{user["name"]}} </td>
    </ng-container>

    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef> VORNAME </th>
      <td mat-cell *matCellDef="let user"> {{user["first_name"]}} </td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef> NACHNAME </th>
      <td mat-cell *matCellDef="let user"> {{user["last_name"]}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> E-MAIL </th>
      <td mat-cell *matCellDef="let user"> {{user["email"]}} </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef> AKTIV </th>
      <td mat-cell *matCellDef="let user">
        {{ user["disabled"] ? "" : "✔" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user">
        <button mat-icon-button (click)="edit(user['id'])"><mat-icon>edit</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
  </table>
</div>
<button mat-stroked-button routerLink="/admin/users/new">Neuer Benutzer</button>
