<ng-container
  inscSearchController
  #searchController="inscSearchController"
  (queryParamsChange)="onQueryParamsChange($event)"
  >

  @if ({
    results: searchResults$ | async,
    currentCriteria: searchController.currentCriteria$ | async
    }; as observables) {
    <h1 class="overview-title">
      Übersicht:
      @if (observables.currentCriteria?.length > 0) {
        Gefundene
      } @else {
        <span>alle </span>
      }
      Inschriftenträger
      @if (observables.results; as results) {
        <span>
          ({{results?.count}})
        </span>
      }
    </h1>
    <div class="container">
      <!-- FACET SIDEBAR -->
      <div class="facet-sidebar">
        <mat-card appearance="outlined" class="facet-content">
          <h2 matCardTitle>
            Filterkriterien
          </h2>
          <insc-dating-search-control
            inscSearchControl="dating"
            searchControlDisplayName="Datierung"
            [resultParams]="observables.results?.dating"
          ></insc-dating-search-control>
          <!-- temporarily disabled  https://gitlab.cceh.uni-koeln.de/inschriften/inschriften-orga/-/issues/121 -->
          <!--        <insc-fulltext-search-control
          inscSearchControl="fulltext"
          searchControlDisplayName="Volltext"
          [resultParams]="observables.results?.fulltext"
        ></insc-fulltext-search-control>-->
        <!--          <mat-divider class="filter-list-divider no-margin-bottom filter-list-full-with-content"></mat-divider>-->
        <insc-facet-list-search-control
          inscSearchControl="filters"
          [facetResults]="observables.results?.facets"
          [resultParams]="observables.results?.filters"
          >
          <insc-facet title="Standort" field="current_locationing.location.search_path" [initiallyExpanded]="true"></insc-facet>
          <insc-facet title="Standorttyp" field="current_locationing.location.location_type"></insc-facet>
          <insc-facet title="Objekttyp" field="object_types"></insc-facet>
          <insc-facet title="Kunstgattung" field="object_genres"></insc-facet>
          <insc-facet title="Material des Inschriftenträgers" field="object_materials"></insc-facet>
          <insc-facet title="Zustand des Inschriftenträgers" field="status"></insc-facet>
          <insc-facet title="Personen" field="person_organizations.person_role_path"></insc-facet>
          <insc-facet title="Organisationen" field="person_organizations.organization_role_path"></insc-facet>
          <insc-facet title="Ikonographisches Thema/Motiv" field="iconography_descs.iconography_topic"></insc-facet>
          <insc-facet title="Wappen/Marken/Zeichen" field="emblem_mark_sign_path"></insc-facet>
          <insc-facet title="Zyklus" field="cycle_object_group"></insc-facet>
          <insc-facet title="Ensemble" field="ensemble_object_group"></insc-facet>
          <insc-facet title="Fragmentgruppe" field="fragments_object_group"></insc-facet>
          <insc-facet title="Bearbeitungsstatus Inschriftenträger" field="editing_status_string"></insc-facet>
          <insc-facet title="Texttyp" field="inscriptions.inscription_types"></insc-facet>
          <insc-facet title="Literatur" field="literature_references.literature_entry.name"></insc-facet>
          <insc-facet title="Sprache" field="inscriptions.language"></insc-facet>
          <insc-facet title="Verse / Prosa" field="inscriptions.text_type"></insc-facet>
          <insc-facet title="Material der Inschriften" field="inscriptions.inscription_materials"></insc-facet>
          <insc-facet title="Technik" field="inscriptions.inscription_techniques"></insc-facet>
          <insc-facet title="Zustand der Inschriften" field="inscriptions.inscription_condition"></insc-facet>
          <insc-facet title="Schriftart" field="inscriptions.typefaces"></insc-facet>
          <insc-facet title="Bearbeitungsstatus Inschriften" field="inscriptions.editing_status_string"></insc-facet>
        </insc-facet-list-search-control>
      </mat-card>
    </div>
    <!-- SEARCH RESULTS -->
    <div class="content-wrap">
      <insc-search-toolbar>
        <insc-sort-control [compact]="true" [sortBy]="observables.results?.sort_by"></insc-sort-control>
          <insc-paginator [pageParams]="{
          page: observables.results?.page,
          pageSize: observables.results?.page_size,
          count: observables.results?.count
        }"></insc-paginator>
      </insc-search-toolbar>
      <insc-search-criteria-chips
        [currentCriteria]="observables.currentCriteria"
        (removeCriterium)="searchController.removeCriterium($event)"
        (resetCriteria)="searchController.resetCriteria()"
      ></insc-search-criteria-chips>
      @if (observables.results) {
        <div class="results-wrapper">
          @for (result of observables.results.results; track result) {
            <insc-object-result-card
            [result]="result['data']" [highlight]="result['highlight']"></insc-object-result-card>
          }
        </div>
      }
    </div>
  </div>
}

</ng-container>
