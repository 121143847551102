import { Component, Input, OnInit } from "@angular/core"
import { Origin } from "../../../../shared/models"


@Component({
  selector:    'insc-origin-view',
  templateUrl: './origin-view.component.html',
  styleUrls:   ['./origin-view.component.css']
})
export class OriginViewComponent implements OnInit {
  @Input() origin: Origin

  constructor() { }

  ngOnInit() {
  }

}
