import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"

import { environment } from "../../environments/environment"

import { NormDataEntry } from "../shared/models"

@Injectable({providedIn: 'root'})
export class NormDataService {

  baseUrl = `${environment.apiUrl}/knowledge`

  constructor(private http: HttpClient) { }

  getEntry(type: string, entryId: string): Observable<NormDataEntry> {
    return this.http.get<{entry: NormDataEntry}>(`${this.baseUrl}/${type}/${entryId}`)
      .pipe(map(result => result.entry))
  }

  getEntries<T extends NormDataEntry = NormDataEntry>(type: string, query: string = null): Observable<T[]> {
    let params = new HttpParams()
    if (query && query.length > 0) {
      params = params.append("query", query)
    }

    return this.http.get<{entries: T[]}>(`${this.baseUrl}/${type}`, {params: params}).pipe(
      map(result => result.entries)
    )
  }

  saveEntry(type: string, entry: NormDataEntry): Observable<NormDataEntry> {
    return this.http.post<{entry: NormDataEntry}>(`${this.baseUrl}/${type}`, { entry: entry })
      .pipe(map(result => result.entry))
  }

  updateEntry(type: string, entry: NormDataEntry): Observable<NormDataEntry> {
    return this.http.put<{entry: NormDataEntry}>(`${this.baseUrl}/${type}/${entry.id}`, { entry: entry })
      .pipe(map(result => result.entry))
  }

  deleteEntry(type: string, entryId: string): Observable<unknown> {
    return this.http.delete(`${this.baseUrl}/${type}/${entryId}`)
  }
}
