import { Component, Input } from "@angular/core"

@Component({
  selector: "insc-result-card-link-list-entry",
  template: `
              <a [routerLink]="link"><li><ng-content></ng-content></li></a>
  `,
  styles:   [`
    a {
        text-decoration: none;
    }

    a:hover, a:focus {
      text-decoration: underline;
    }
  `]
})
export class ResultCardLinkListEntryComponent {
  @Input() link
}

@Component({
  selector: "insc-result-card-link-list",
  template: `
                <mat-card-subtitle>{{title}}</mat-card-subtitle>
                <ul>
                    <ng-content></ng-content>
                </ul>
            `,
  styles: [`ul { padding-left: 1rem; margin: 0.4rem 0 0 0; }`]
})
export class ResultCardLinkListComponent {
  @Input() title: string
}
