import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"

@Injectable({
  providedIn: 'root'
})
export class HttpActivityService {
  private _busy = new BehaviorSubject(false)
  busy = this._busy.asObservable()
  
  private pendingRequests = 0
  
  start() {
    this.pendingRequests++
    setTimeout(() => this._busy.next(true))
  }
  
  stop() {
    this.pendingRequests > 0 && this.pendingRequests--
    
    if (this.pendingRequests === 0) {
      setTimeout(() => this._busy.next(false))
    }
  }
}
