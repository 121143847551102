<ng-container [formGroup]="formGroup">

  <mat-form-field>
    <mat-label>Eintragstyp</mat-label>
    <mat-select formControlName="literature_entry_type">
      <mat-option value="di_publication">DI-Band</mat-option>
      <mat-option value="other_publication">Nicht-DI-Publikation</mat-option>
      <mat-option value="literature">Sonstige Literatur</mat-option>
    </mat-select>
    <mat-error inscError errorControlName="literature_entry_type"></mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput type="text" formControlName="name">
    <mat-label>Kurztitel</mat-label>
    <mat-error inscError errorControlName="name"></mat-error>
  </mat-form-field>

  @if (formGroup.get('literature_entry_type').value === 'di_publication') {
    <mat-form-field>
      <input matInput type="text" formControlName="volume_no">
      <mat-label>DI-Bandnummer</mat-label>
      <mat-error inscError errorControlName="volume_no"></mat-error>
    </mat-form-field>
  }

  <mat-form-field>
    <input matInput type="number" formControlName="year">
    <mat-label>Jahr</mat-label>
    <mat-error inscError errorControlName="year"></mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput type="text" formControlName="url">
    <mat-label>URL</mat-label>
    <mat-error inscError errorControlName="url"></mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput type="text" formControlName="urn">
    <mat-label>URN</mat-label>
    <mat-error inscError errorControlName="urn"></mat-error>
  </mat-form-field>

  <mat-checkbox formControlName="unpublished">in Bearbeitung</mat-checkbox>

  <mat-form-field>
    <input matInput type="text" formControlName="citation_key">
    <mat-label>Zitierschlüssel</mat-label>
    <mat-error inscError errorControlName="citation_key"></mat-error>
  </mat-form-field>

  <mat-form-field>
    <textarea matInput formControlName="comment"></textarea>
    <mat-label>Kommentar</mat-label>
    <mat-error inscError errorControlName="comment"></mat-error>
  </mat-form-field>


</ng-container>
