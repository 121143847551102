<ng-container [formGroup]="formGroup">
	<mat-form-field>
		<input matInput  type="text" formControlName="subject">
		<mat-label>Betreff</mat-label>
		<mat-error inscError errorControlName="subject"></mat-error>
	</mat-form-field>
	<mat-form-field>
    <mat-label>Bemerkung</mat-label>
		<textarea matInput  type="text" formControlName="content"></textarea>
		<mat-error inscError errorControlName="content"></mat-error>
	</mat-form-field>
</ng-container>
