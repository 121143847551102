<insc-data-group>
  <div inscDataField>
    <div *inscFieldLabel>Wappenführer</div>
    <div *inscFieldValue>{{emblem.name}}</div>
  </div>
  <div inscDataField>
    <div *inscFieldLabel>Wappenart</div>
    <div *inscFieldValue>{{emblem.emblem_type}}</div>
  </div>
  <div inscDataField>
    <div *inscFieldLabel>Ahnenprobe</div>
    <div *inscFieldValue>{{ emblem.proof_of_heritage ? "Ja" : "Nein" }}</div>
  </div>
</insc-data-group>
