import { Component, ContentChild, ContentChildren, Directive, Input, QueryList, TemplateRef } from "@angular/core"
import { EditorialState } from "../../../../shared/models/value-with-editorial-state"

export type DataFieldLayout = "horizontal" | "vertical"

@Directive({
  selector: '[inscFieldLabel]'
})
export class FieldLabelDirective {
}

@Directive({
  selector: '[inscFieldValue]'
})
export class FieldValueDirective {
}

@Directive({
  selector: '[inscDataField]'
})
export class DataFieldDirective {

  @ContentChild(FieldLabelDirective, {read: TemplateRef, static: true}) fieldLabelTemplate
  @ContentChildren(FieldValueDirective, {read: TemplateRef, descendants: true}) fieldValueTemplates: QueryList<TemplateRef<void>>

  @Input() layout: DataFieldLayout = "horizontal"
  @Input() editorialState: EditorialState | null

  valueIsKnown() {
    return !(this.editorialState && ["unknown", "indeterminable"].includes(this.editorialState))
  }

}

@Component({
  selector:    'insc-data-group',
  templateUrl: './data-group.component.html',
  styleUrls:   ['./data-group.component.scss']
})
export class DataGroupComponent {
  @ContentChildren(DataFieldDirective) dataFields: QueryList<DataFieldDirective>
  @Input() labelColWidth = "8.5rem"
}
