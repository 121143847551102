import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from "@angular/common/http"
import { Injectable } from '@angular/core'
import { filter, map, pluck, tap } from "rxjs/operators"
import { environment } from "../../environments/environment"
import { User } from "../shared/models/user.model"
import { APIResult, EntityType } from "./data.service"

@Injectable({
  providedIn: 'root'
})
export class UserService {

  readonly endpoint = `${environment.apiUrl}/users`

  constructor(private http: HttpClient) {}

  all() {
    return this.http.get<{users: User[]}>(this.endpoint).pipe(
      map(response => response.users)
    )
  }

  get(id: User["id"]) {
    return this.http.get<APIResult<User>>(`${this.endpoint}/${id}`).pipe(
      map(response => response.user)
    )
  }

  save(user: Partial<User>) {
    const method = user.id ? "PUT" : "POST"
    const req = new HttpRequest(method, `${this.endpoint}/${user.id || ""}`, {user: user}, {responseType: "json"})

    return this.http.request<APIResult<User>>(req).pipe(
      filter(response => response.type === HttpEventType.Response),
      map((response: HttpResponse<APIResult<User>>) => response.body["user"])
    )
  }

  delete(id: User["id"]) {
    return this.http.delete(`${this.endpoint}/${id}`)
  }

  disable(id: User["id"]) {
    return this.http.put<APIResult<User>>(`${this.endpoint}/${id}/disable`, null).pipe(
      map(response => response.user)
    )
  }

  enable(id: User["id"]) {
    return this.http.put<APIResult<User>>(`${this.endpoint}/${id}/enable`, null).pipe(
      map(response => response.user)
    )
  }
}
