import { Injectable } from "@angular/core"
import { InscImage, IptcData } from "../../../../shared/models/image.model"

type IptcValueType = unknown | undefined

export interface IIptcValue<T extends IptcValueType = IptcValueType> {
  readonly generated: T,
  readonly value: T
}

export class IptcValue<T extends IptcValueType = IptcValueType> implements IIptcValue<T> {
  readonly generated: T
  readonly value: T

  get hasManualValue(): boolean {
    return this.value !== undefined
  }

  get isGenerated(): boolean {
    return this.generated !== undefined
  }

  get isUntouched(): boolean {
    return this.isGenerated && !this.hasManualValue
  }

  constructor(iptcValueData?: IIptcValue<T>) {
    this.generated = iptcValueData?.generated
    this.value = iptcValueData?.value
  }
}

export interface IptcArrayItemFlags {
  markedForRestauration: boolean
  markedForRemoval: boolean
  newlyAdded: boolean
}

export class IptcArrayItem<T extends IptcValueType = IptcValueType> extends IptcValue<T> implements IptcArrayItemFlags {
  readonly markedForRestauration = false
  readonly markedForRemoval = false
  readonly newlyAdded: boolean = false // why is "false" inferred here instead of "boolean"?

  get isManuallyRemoved(): boolean {
    return this.generated !== null && this.value === null
  }

  get isManuallyAdded(): boolean {
    return !this.isGenerated && this.hasManualValue
  }

  constructor(from?: IIptcValue<T>, flags: Partial<IptcArrayItemFlags> = {
    markedForRemoval: false,
    markedForRestauration: false,
    newlyAdded: false
  }) {
    super(from)
    Object.assign<IptcArrayItem, Partial<IptcArrayItemFlags>>(this, flags)
  }

  static newlyAddedItem<T extends IptcValueType = IptcValueType>(value?: T): IptcArrayItem<T> {
    return new IptcArrayItem({ generated: undefined, value }, { newlyAdded: true })
  }

  static itemMarkedForRemoval<T extends IptcValueType = IptcValueType>(from: IIptcValue<T>): IptcArrayItem<T> {
    return new IptcArrayItem({ ...from }, { markedForRemoval: true })
  }

  static itemMarkedForRestauration<T extends IptcValueType = IptcValueType>(from: IIptcValue<T>): IptcArrayItem<T> {
    return new IptcArrayItem({ ...from }, { markedForRestauration: true })
  }
}

type IPTCDatasetWithoutMetadata = {
  [Property in keyof IptcData]: IptcData[Property] extends (infer T)[] ? IptcArrayItem<T>[] : IptcValue<IptcData[Property]>
}
type IPTCDatasetMetadata = { id: string, image?: InscImage, _keywords_override: boolean }
export type IPTCDataset = IPTCDatasetWithoutMetadata & IPTCDatasetMetadata

@Injectable({
  providedIn: 'root'
})
export class IptcDatasetService {

  buildIptcDataset(image: Partial<InscImage>): IPTCDataset {
    const iptcDatasetEntries = Object
      .entries(image.iptc_defaults)
      .map(([key, generated_value]) => {
        let iptcValue: IptcValue | IptcValue[]
        if (generated_value instanceof Array) {
          iptcValue = this.getIptcValueArray(image.iptc_defaults[key], image.iptc_overrides[key])
        } else {
          if (generated_value || image.iptc_overrides[key] !== undefined) {
            iptcValue = new IptcValue({
              value: image.iptc_overrides[key], generated: generated_value
            })
          }
        }

        return [key, iptcValue]
      })

    const keywordsArrayHasOverrides = image.iptc_overrides.keywords !== undefined


    return {
      id: image.id,
      image,
      _keywords_override: keywordsArrayHasOverrides,
      ...Object.fromEntries(iptcDatasetEntries)
    }
  }

  serializeIptcDatasetToIptcOverrides(iptcDataset: IPTCDataset): Partial<IptcData> {
    const excludeKeysInOutput = ["id", "image", "_keywords_override"]


    const iptcValueEntries = Object
      .entries(iptcDataset)
      .filter(([key, value]) => value != null && !excludeKeysInOutput.includes(key))

    const iptcOverrideValueEntries = iptcValueEntries
      .filter(([_key, value]) => !(value instanceof Array))
      .filter(([_key, iptcValue]) => (iptcValue as IptcValue).value !== null)
      .map(([key, iptcValue]) => [key, (iptcValue as IptcValue).value])

    const iptcOverrideArrayEntries = iptcValueEntries
      .filter(([_key, value]) => value instanceof Array)
      .filter(([key, _value]) => iptcDataset[`_${key}_override`] === true)
      .map(([key, iptcArray]) => {
        const overrideValues = (iptcArray as IptcArrayItem[])
          .filter(iptcItem => (!iptcItem.isManuallyRemoved || iptcItem.markedForRestauration) && !iptcItem.markedForRemoval)
          .map(iptcItem => iptcItem.value ?? iptcItem.generated)
        return [key, overrideValues]
      })

    const iptcOverrideEntries = [...iptcOverrideArrayEntries, ...iptcOverrideValueEntries]

    return Object.fromEntries(iptcOverrideEntries)
  }

  getIptcEffectiveValue = <T>(iptcValue: IptcValue<T>) => iptcValue?.value !== undefined ? iptcValue?.value : iptcValue?.generated

  getCopyrightStatusString = (marked: boolean | null | undefined) =>
    marked != null
    ? marked === true ? "Durch Copyright geschützt" : "Public Domain"
    : null

  private getIptcValueArray(generatedArray: unknown[], overrideArray: unknown[]): IptcArrayItem[] {
    // override array ist undefined
    // alles generierte werte, nichts wurde verändert

    if (overrideArray == null) {
      return generatedArray.map(generatedEntry => (new IptcArrayItem({
        generated: generatedEntry, value: undefined
      })))
    }

    const untouchedGeneratedEntries: IptcArrayItem[] = generatedArray
      .filter(generatedEntry => overrideArray.includes(generatedEntry))
      .map(generatedEntry => (new IptcArrayItem({generated: generatedEntry, value: undefined})))

    const manuallyAddedEntries: IptcArrayItem[] = overrideArray
      .filter(overrideEntry => !generatedArray.includes(overrideEntry))
      .map(overrideEntry => (new IptcArrayItem({generated: undefined, value: overrideEntry})))

    const manuallyRemovedEntries: IptcArrayItem[] = generatedArray
      .filter(generatedEntry => !overrideArray.includes(generatedEntry))
      .map(generatedEntry => (new IptcArrayItem({generated: generatedEntry, value: null})))


    return [...untouchedGeneratedEntries, ...manuallyAddedEntries, ...manuallyRemovedEntries]
  }

}

