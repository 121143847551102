import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, of, throwError } from "rxjs"
import { map } from "rxjs/operators"


export interface ExternalNormDataLookupResult {
  id: string
  title: string
  description?: string
}

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractExternalNormDataProviderService<T> {

  readonly getDescription = (id: string): Observable<string> => {
    return this.getEntryData?.(id)?.pipe(
      map(entryData => this.descriptionFromEntryData(entryData))
    ) ?? of(null)
  }

  protected constructor(_http: HttpClient) {}
  abstract getExternalLink(id: string): string
  abstract getValidity(id: string): Observable<boolean>
  get lookupAvailable(): boolean { return false }

  getEntryData?(_id: string): Observable<T> {
    console.log("getEntryData not implemented for this external norm data provider.")
    return null
  }

  lookup?(_queryString: string, _options: Record<string, string>): Observable<ExternalNormDataLookupResult[]> {
    console.log("lookup not implemented for this external norm data provider.")
    return null
  }

  protected descriptionFromEntryData(_entryData: T): string {
    console.log("descriptionFromEntryData not implemented for this external norm data provider.")
    return null
  }

  protected handleLookupError(err: unknown) {
    console.log("External norm data lookup error:", err)
    return throwError(() => err)
  }
}
