import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { environment } from "../../environments/environment"
import { FacetResult } from "./data.service"

export type AutocompleteType = "object" | "image" | "inscription" | null

@Injectable({
  providedIn: "root"
})
export class AutocompleteService {

  readonly baseUrl = environment.apiUrl
  
  constructor(private http: HttpClient) { }

  public values(type: AutocompleteType, field: string): Observable<FacetResult[]> {
    const params = new HttpParams()
      .set("type", type)
      .set("field", field)

    return this.http.get<FacetResult[]>(`${this.baseUrl}/autocomplete`, {params})
  }
}
