import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core"
import { forkJoin, from, of, Subscription } from "rxjs"
import { catchError, concatMap, filter, map, switchMap, tap } from "rxjs/operators"
import { ImageService } from "../../../services/data.service"
import { APIError } from "../../../services/errors"
import { ImageUploadEventType, isImageUploadProgressEvent } from "../../../services/image-file.service"
import { ImageParent, InscImage } from "../../../shared/models/image.model"
import { ImageManagementGroupComponent } from "../image-management/image-management-group.component"
import { ImageSelection } from "../image-management/image-management.directive"
import { ImageTileComponent } from "../image-management/image-tile.component"
import { MultiImageEditorComponent } from "../image-metadata-editors/multi-image-editor/multi-image-editor.component"
import { ImageMetadataPickerDialogService } from "../image-metadata-picker-dialog/image-metadata-picker-dialog.service"
import { ImageMetadataReaderService } from "./image-metadata-reader.service"
import { ImageProcessorService } from "./image-processor.service"
import exifr from "exifr"

export interface ImageUpload {
  file: File
  inscImage: Partial<InscImage>
  status: "waiting" | "busy" | "ready" | "uploading" | "finished" | "previewError" | "uploadError" | "uploadCancelled"
  statusText: string
  progress: number
}

@Component({
  selector:    'insc-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls:   ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
  @Input() parent: ImageParent | null = null

  selectedImages: Partial<InscImage>[]

  previewSubscription: Subscription
  uploadSubscription: Subscription

  errorConsoleText = "Bereit...\n\n"

  showErrorConsole = false

  selectionStatus: "some" | "none" | "all" = "none"
  didUploadSomething = false

  uploadQueue: ImageUpload[] = []
  inscImages: Partial<InscImage>[] = []


  @ViewChildren(ImageTileComponent) imageTiles: QueryList<ImageTileComponent>


  @ViewChild(ImageManagementGroupComponent) imageManagementGroup: ImageManagementGroupComponent
  @ViewChild(MultiImageEditorComponent) multiImageView: MultiImageEditorComponent
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>

  imagesPluralMapping = {"=0": "Aufnahmen werden", "=1": "Aufnahme wird", "other": "Aufnahmen werden"}

  get itemsForUpload() { return this.uploadQueue.filter(item => this.isReadyForUpload(item)) }

  constructor(
    private imageService: ImageService,
    private imageProcessor: ImageProcessorService,
    private imageMetadataPickerDialogService: ImageMetadataPickerDialogService,
    private imageMetadataReader: ImageMetadataReaderService
  ) {}


  isUploadActive() {
    return this.uploadSubscription && !this.uploadSubscription.closed
  }
  isPreviewGenerationActive() {
    return this.previewSubscription && !this.previewSubscription.closed
  }

  isBusy() {
    return this.isUploadActive() || this.isPreviewGenerationActive()
  }

  ngOnInit(): void {
  }

  private addToUploadQueue(item: ImageUpload) {
    this.uploadQueue.push(item)
    this.updateInscImages()
  }

  private removeFromUploadQueue(index: number) {
    this.uploadQueue.splice(index, 1)
    this.updateInscImages()
  }

  private updateInscImages() {
    this.inscImages = this.uploadQueue.map(item => item.inscImage)
  }

  onImageSelectionChange(selection: ImageSelection[]) {
    this.selectedImages = this.uploadQueue
      .map(uploadItem => uploadItem.inscImage)
      .filter(inscImage => selection.includes(inscImage.id))

    const selectableImages = this.itemsForUpload
    if (this.selectedImages.length === 0) {
      this.selectionStatus = "none"
    } else if (this.selectedImages.length === selectableImages.length) {
      this.selectionStatus = "all"
    } else {
      this.selectionStatus = "some"
    }
  }

  onSelectImageFiles(fileInput: HTMLInputElement) {

    const toUploadItem = (file: File): ImageUpload => {
      const filenameLastDotIdx = file.name.lastIndexOf(".")
      const filename = file.name.slice(0, filenameLastDotIdx)
      return ({
        file:       file,
        inscImage:  {
          id:   `temp-${this.uploadQueue.length}`,
          name: filename,
        },
        status:     "waiting",
        progress:   0,
        statusText: "warte..."
      })
    }
    const addToQueue = (uploadItem: ImageUpload) => this.addToUploadQueue(uploadItem)

    const files = Array.from(fileInput.files)
    fileInput.value = null

    this.printLine("Lade Dateien...")

    this.previewSubscription = from(files).pipe(
      map(toUploadItem),
      tap(addToQueue),
      concatMap(uploadItem => {
        this.printLine(`Lese ${uploadItem.file.name}...`)
        uploadItem.status = "busy"
        uploadItem.statusText = "generiere Vorschau..."

        const canvas$ = this.imageProcessor.tifToCanvas(uploadItem.file).pipe(
          switchMap(canvas => this.imageProcessor.resize(canvas, 100, 100)),
          catchError(error => {
            console.log(error)
            this.printErrorLine(`Fehler beim Lesen von ${uploadItem.file.name}:\n${String(error)}`)
            uploadItem.status = "previewError"
            return of(null)
          })
        )

        const metadata$ = this.imageMetadataReader.getMetadata(uploadItem.file)

        return forkJoin([canvas$, metadata$]).pipe(
          map(([canvas, metadata]) => ({uploadItem, canvas, metadata}))
        )
      }),
    ).subscribe({
        next: ({uploadItem, canvas, metadata}) => {
          console.log(metadata)
          Object.assign(uploadItem.inscImage, {
            ...metadata,
            preview_url: canvas?.toDataURL()
          })
          uploadItem.statusText = null
          if (uploadItem.status !== "previewError") {
            uploadItem.status = "ready"
          }
        },
      error: () => {
        // TODO
      },
      complete: () => {
        const numErrors = this.uploadQueue.filter(item => item.status === "previewError").length
        const numSuccess = this.uploadQueue.length - numErrors
        this.printLine(`${numSuccess} Dateien gelesen, ${numErrors} Fehler.`, 2)
        this.imageTiles?.first?.select()
      }
    })
  }

  onFormChanges(updates: Partial<InscImage>[]) {
    // apply updates
    updates.forEach(update => {
      const updateImage = this.selectedImages.find(selectedImage => selectedImage.id === update.id)
      Object.assign(updateImage, update)
    })
  }

  onUpload() {
    this.imageManagementGroup.deselectAll()

    const prepareUploadMetadata = (uploadItem: ImageUpload) => {
      const inscImageMetadata = {...uploadItem.inscImage}

      delete inscImageMetadata.id
      delete inscImageMetadata.preview_url

      return ({uploadItem, inscImageMetadata})
    }

    this.printLine("Lade hoch...", 2)

    this.uploadSubscription = from(this.uploadQueue).pipe(
      filter(uploadItem => uploadItem.status !== "finished"),
      tap(uploadItem => {
        uploadItem.statusText = "warte..."
        uploadItem.status = "waiting"
      }),
      map(prepareUploadMetadata),
      concatMap(({uploadItem, inscImageMetadata}) => {
        uploadItem.status = "busy"
        uploadItem.statusText = "lese..."
        return this.imageService.saveWithFile(inscImageMetadata, uploadItem.file, this.parent).pipe(
          map(uploadProgressEvent => ({uploadProgressEvent, uploadItem})),
          catchError((error: unknown) => {
            console.log(error)
            this.printErrorLine(`Fehler beim Hochladen von ${uploadItem.inscImage.name}:\n${String(error)}`)
            if (error instanceof APIError) {
              const errorResponse: unknown = error.errorResponse
              if (errorResponse.hasOwnProperty('message')) {
                this.printErrorLine(errorResponse['message'] as string)
              }
            }
            uploadItem.status = "uploadError"
            return of({uploadProgressEvent: null, uploadItem})
          })
        )
      })
    ).subscribe(({uploadProgressEvent, uploadItem}) => {
      if (!isImageUploadProgressEvent(uploadProgressEvent)) {
        return
      }
      if (isImageUploadProgressEvent(uploadProgressEvent) && uploadProgressEvent.type === ImageUploadEventType.Started) {
      } else if (uploadProgressEvent.type === ImageUploadEventType.Progress) {
        uploadItem.status = "uploading"
        uploadItem.statusText = "lade hoch..."
        const total = uploadProgressEvent.total
        const progress = (100 / total) * uploadProgressEvent.loaded
        if (progress === 100) {
          uploadItem.status = "busy"
          uploadItem.statusText = "verarbeite..."
        } else {
          uploadItem.progress = progress
        }
      } else if (uploadProgressEvent.type === ImageUploadEventType.Finished) {
        uploadItem.status = "finished"
        uploadItem.statusText = null
        this.didUploadSomething = true
      }
    }, error => {
      // TODO
    }, () => {
      const numErrors = this.uploadQueue.filter(item => item.status === "uploadError").length
      this.printLine(`Hochladen abgeschlossen, ${numErrors} Fehler.`, 2)
    }
    )
  }

  uploadItemIsBusy(item: ImageUpload) {
    return ["busy", "waiting", "uploading", "finished"].includes(item.status)
  }

  isReadyForUpload(item: ImageUpload) {
    return ["ready", "previewError", "uploadError", "uploadCancelled"].includes(item.status)
  }

  cancelPreviewGeneration() {
    this.previewSubscription?.unsubscribe()
    const removeItems = this.uploadQueue.filter(uploadItem =>
      uploadItem.status !== "ready"
    )
    removeItems.forEach(item => {
      const index = this.uploadQueue.indexOf(item)
      this.removeFromUploadQueue(index)
    })

    this.printLine("⚠️ Hinzufügen vom Benutzer abgebrochen.")
  }

  cancelUpload() {
    this.uploadSubscription?.unsubscribe()
    this.uploadQueue.forEach(uploadItem => {
      if (uploadItem.status !== "finished") {
        uploadItem.status = "uploadCancelled"
      }
    })
    this.printLine("⚠️ Hochladen vom Benutzer abgebrochen.")
  }

  removeItem(index: number) {
    this.imageTiles.toArray()[index].deselect()
    this.removeFromUploadQueue(index)
  }

  selectAll() {
    const imageTiles = this.imageTiles.toArray()
    this
      .findUploadItemIndices(item => item.status !== "finished")
      .forEach(itemIndex => imageTiles[itemIndex].select())
  }

  printLine(text: string, lineBreaks: number = 1) {
    this.errorConsoleText += text + "\n".repeat(lineBreaks)
  }

  printErrorLine(text: string, lineBreaks: number = 1) {
    this.printLine(`❌ ${text}`, lineBreaks)
    this.showErrorConsole = true
  }

  private findUploadItemIndices(filterFunc: (item: ImageUpload) => boolean) {
    return this.uploadQueue.reduce((indices, item, index) => {
      return filterFunc(item) ? [...indices, index] : indices
    }, [] as number[])
  }

  openFileChooser() {
    this.fileInput.nativeElement.click()
  }

  openMetadataPicker(): void {
    this.imageMetadataPickerDialogService
      .open(this.multiImageView.multiRecordFormManager.getUpdatedRecords())
      .afterClosed().subscribe(result => result && this.multiImageView.multiRecordFormManager.updateWithValuesFrom(result.updatedValues))
  }

}
