import { Injectable } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { InscImage } from "../../models/image.model"
import { ImageViewerDialogComponent } from "./image-viewer-dialog.component"

@Injectable()
export class ImageViewerService {

  constructor(private dialog: MatDialog) {}

  open(title: string, images: Partial<InscImage>[]) {
    this.dialog.open(ImageViewerDialogComponent, {
      data: {
        title: title,
        images: images
      },
      width: "90vw",
      height: "90vh"
    })
  }
}
