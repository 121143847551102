<ng-container [formGroup]="formGroup">
  <insc-literature-entry-input formControlName="literature_entry"></insc-literature-entry-input>
  <mat-error inscError errorControlName="literature_entry"></mat-error>

  <insc-autocomplete-textfield formControlName="ref_type" label="Betreff" type="object" field="literature_references.ref_type"></insc-autocomplete-textfield>

  <mat-form-field>
    <input matInput type="text"  formControlName="reference">
    <mat-label>Stelle</mat-label>
    <mat-error inscError errorControlName="reference"></mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput type="text"  formControlName="urn">
    <mat-label>URN (Stelle)</mat-label>
    <mat-error inscError errorControlName="urn"></mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput type="text"  formControlName="url">
    <mat-label>URL (Stelle)</mat-label>
    <mat-error inscError errorControlName="url"></mat-error>
  </mat-form-field>
</ng-container>
