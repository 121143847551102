<ng-container [formGroup]="formGroup">
  <insc-location-input #locationComponent formControlName="location"></insc-location-input>

  @if (formGroup.value['location']) {
    <h4>Einzelheiten zum Standort</h4>
    <mat-form-field>
      <input matInput formControlName="specification" type="text"  />
      <mat-label>{{locationTypeString}}</mat-label>
      <mat-error inscError errorControlName="specification"></mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput formControlName="timeframe" type="text"  />
      <mat-label>Zeitraum</mat-label>
      <mat-error inscError errorControlName="timeframe"></mat-error>
    </mat-form-field>
  }
</ng-container>
