import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from "@angular/forms"
import { MatButtonModule } from "@angular/material/button"
import { MatCardModule } from "@angular/material/card"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatChipsModule } from "@angular/material/chips"
import { MatDialogModule } from "@angular/material/dialog"
import { MatDividerModule } from "@angular/material/divider"
import { MatExpansionModule } from "@angular/material/expansion"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatPaginatorModule } from "@angular/material/paginator"
import { MatRadioModule } from "@angular/material/radio"
import { MatSelectModule } from "@angular/material/select"
import { MatTooltipModule } from "@angular/material/tooltip"
import { RouterModule } from "@angular/router"
import { DateRangePickerModule } from "../components/./form-components/date-range-picker/date-range-picker.module"
import { DatingChooserComponent } from "../components/dating-chooser/dating-chooser.component"
import { CriteriaChipsComponent } from "./criteria-chips/criteria-chips.component"
import { PaginatorComponent } from "./paginator/paginator.component"
import { SearchControllerDirective } from "./search-controller.directive"
import { DatingSearchControlComponent } from "./search-controls/dating/dating-search-control.component"
import { DatingSearchDialogComponent } from "./search-controls/dating/dating-search-dialog/dating-search-dialog.component"
import { FacetListSearchControlComponent } from "./search-controls/facet-list/facet-list-search-control.component"
import { FacetComponent } from "./search-controls/facet-list/facet/facet.component"
import { FulltextSearchControlComponent } from "./search-controls/fulltext/fulltext-search-control.component"
import { RecordingDateSearchControlComponent } from "./search-controls/recording-date/recording-date-search-control.component"
import { SearchControlDirective } from "./search-controls/search-control.directive"
import { SearchToolbarComponent } from "./search-toolbar/search-toolbar.component"
import { SortControlComponent } from "./sort-control/sort-control.component"


@NgModule({
  declarations: [
    FacetComponent,
    FacetListSearchControlComponent,

    SearchToolbarComponent,
    SearchControlDirective,
    SearchControllerDirective,

    DatingSearchDialogComponent,

    DatingSearchControlComponent,
    FulltextSearchControlComponent,
    RecordingDateSearchControlComponent,

    PaginatorComponent,
    SortControlComponent,
    CriteriaChipsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatChipsModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatExpansionModule,
    MatRadioModule,
    MatIconModule,
    MatCheckboxModule,
    MatDividerModule,
    DateRangePickerModule,
    DatingChooserComponent
  ],
  exports: [
    FacetComponent,
    FacetListSearchControlComponent,

    SearchToolbarComponent,
    SearchControlDirective,
    SearchControllerDirective,

    DatingSearchDialogComponent,

    DatingSearchControlComponent,
    FulltextSearchControlComponent,
    RecordingDateSearchControlComponent,

    PaginatorComponent,
    SortControlComponent,
    CriteriaChipsComponent
  ]
})
export class SearchModule { }
