<ng-container [formGroup]="formGroup">
  <div class="flex-row" style="max-width: 600px;">
    <mat-form-field class="flex">
      <input matInput  type="text" formControlName="first_name">
      <mat-label>Vorname</mat-label>
      <mat-error inscError errorControlName="first_name"></mat-error>
    </mat-form-field>

    <mat-form-field class="flex">
      <input matInput  type="text" formControlName="last_name">
      <mat-label>Nachname</mat-label>
      <mat-error inscError errorControlName="last_name"></mat-error>
    </mat-form-field>
  </div>

  <div class="subform">
    <h4>Adresse</h4>
    <insc-address-form formGroupName="address"></insc-address-form>
  </div>
</ng-container>
