<insc-data-group>
  <div inscDataField>
    <div *inscFieldLabel>Thema / Motiv</div>
    <div *inscFieldValue>{{ iconographyDesc.iconography_topic }}</div>
  </div>
	<div inscDataField>
		<div *inscFieldLabel>Kommentar</div>
    <div *inscFieldValue>{{ iconographyDesc.comment }}</div>
	</div>
</insc-data-group>
