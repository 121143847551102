import { Pipe, PipeTransform } from "@angular/core"

import escapeStringRegexp from "escape-string-regexp"

export interface FilterParams {
  pattern: string
  flags?: string
  filterByProp: string | null
}

@Pipe({
  name: 'valueFilter',
  standalone: true
})
export class ValueFilterPipe implements PipeTransform {

  private readonly defaultParams: FilterParams = {pattern: "", flags: "ig", filterByProp: null}
  private currentParams: FilterParams = this.defaultParams
  private currentFilteredValues: null

  transform<T>(values: T[] | null, _args: FilterParams = this.currentParams): {original: T; highlighted: T}[] {
    const args = Object.assign(this.defaultParams, _args)

    if (values === null) {
      return []
    }

    if (args.pattern === "") {
      return values.map(value => ({original: value, highlighted: value}))
    }

    const patternChanged = args.pattern === this.currentParams.pattern
    const flagsChanged = args.flags === this.currentParams.flags
    const filterPropChanged = args.flags === this.currentParams.flags

    if ( !(patternChanged || flagsChanged || filterPropChanged) ) {
      return this.currentFilteredValues || values.map(value => ({original: value, highlighted: value}))
    }

    this.currentParams = args

    const filterRegExp = new RegExp(`(${escapeStringRegexp(args.pattern)})`, args.flags)

    return values.map(value => {
      const matchValue = args.filterByProp ? value[args.filterByProp] : value
      return {
        matches: matchValue && matchValue.match(filterRegExp),
        value: value
      }

    })
      .filter(result => result.matches)
      .sort((a, b) =>
        a.matches.length < b.matches.length
          ? 1
          : a.matches.length > b.matches.length ? -1 : 0
      )
      .map(result => {

        let highlighted

        if (typeof result.value === "string") {
          highlighted = this.highlightMatches(result.value, filterRegExp)
        } else {
          highlighted = {
            ...result.value,
            [args.filterByProp]: this.highlightMatches(result.value[args.filterByProp], filterRegExp)
          }
        }

        return {original: result.value, highlighted: highlighted}

      })
  }

  private highlightMatches(value: string, regExp: RegExp): string {
    return `<span>${value.replace(regExp, `<em class="filter-highlight">$1</em>`)}</span>`
  }

}




