import { Component, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"

import { Subject } from "rxjs"
import { filter, map, switchMap, take, takeUntil } from "rxjs/operators"
import { AuthService } from "../../../services/auth.service"
import { InscObjectService, InscriptionService, QueryParams } from "../../../services/data.service"
import { FormService } from "../../../services/form.service"
import { LinkingService } from "../../../services/linking.service"
import { ReusableDialogsService } from "../../../shared/dialogs/reusable-dialogs.service"

import { InscObject, Inscription } from "../../../shared/models"

import { SnackbarService } from "../../../shared/snackbars/snackbar.service"
import { RecordPageStateService, recordPageStateServiceFactory } from "../record-page-state.service"
import {
  GetConfigFromSelectionFunc,
  LinkChooserDialogConfig,
  LinkChooserDialogService
} from "../shared/dialogs/link-chooser-dialog/link-chooser-dialog.component"

enum LinkingStatus {
  New = "New",
  NewWithParent = "NewRecordWithParent",
  Unlinked = "Unlinked",
  LinkedWithObjectGroup = "LinkedWithObjectGroup",
  LinkedWithoutObjectGroup = "LinkedWithoutObjectGroup"
}

@Component({
  selector: 'insc-inscription-page',
  templateUrl: './inscription-page.component.html',
  styleUrls: ['./inscription-page.component.scss'],
  providers: [{
    provide: RecordPageStateService,
    useFactory: recordPageStateServiceFactory,
    deps: [InscriptionService, SnackbarService, FormService, Router, ActivatedRoute]
  }]
})
export class InscriptionPageComponent implements OnInit, OnDestroy {

  // make LinkingStatus enum availabale in template
  LinkingStatus = LinkingStatus

  private unsubscribe$ = new Subject<void>()
  linkingStatus: LinkingStatus

  hasEditPermission = this.auth.permission('update_records')

  get inscription(): Inscription { return this.recordPageState.record }
  get parentInscObject(): InscObject | null { return this.route.snapshot.data.insc_object as InscObject }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private inscriptionService: InscriptionService,
    private inscObjectService: InscObjectService,
    private snackbarService: SnackbarService,
    private linkChooser: LinkChooserDialogService,
    private auth: AuthService,
    private recordPageState: RecordPageStateService<Inscription>,
    private reusableDialogs: ReusableDialogsService,
    private linkingService: LinkingService
  ) {
  }

  ngOnInit(): void {
    this.recordPageState.recordChanges.pipe(
      map((inscription): LinkingStatus => {
        if (inscription?.id == null) {
          return this.parentInscObject ? LinkingStatus.NewWithParent : LinkingStatus.New
        } else if (inscription.insc_objects.length > 0 && inscription.object_group == null) {
          return LinkingStatus.LinkedWithoutObjectGroup
        } else {
          return LinkingStatus.LinkedWithObjectGroup
        }
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(linkingStatus => this.linkingStatus = linkingStatus)
  }

  delete(): void {
    this.reusableDialogs.openConfirmationDialog(
      () => void this.recordPageState.delete({commands: ["../.."], extras: { relativeTo: this.route }}),
      {
        message: `Inschrift ${this.inscription.name || this.inscription.id} wirklich löschen?`,
        yesAction: "INSCHRIFT LÖSCHEN",
        noAction: "INSCHRIFT BEHALTEN",
        icon: "delete"}
    )
  }

  save(): void {
    const saveAction = this.parentInscObject
      ? this.inscriptionService.saveForObject(this.recordPageState.formValue, this.parentInscObject.id)
      : this.inscriptionService.save(this.recordPageState.formValue)

    void this.recordPageState.processSaveResult(saveAction, () => {
      const inscriptionNavCommands = ["inscriptions", this.inscription.id]
      const navCommands = this.parentInscObject ? ["objects", this.parentInscObject.id, ...inscriptionNavCommands] : inscriptionNavCommands

      return this.router.navigate(navCommands)
    })
  }

  openObjectLinkChooser(): void {

    const staticConfig: Partial<LinkChooserDialogConfig<InscObject>> = {
      title: "Mit Inschriftenträgern verknüpfen",
    }

    const defaultQueryParams: Partial<QueryParams> = {
      sort_by: "updated_at"
    }

    const getConfigFromSelection: GetConfigFromSelectionFunc<InscObject> = selection => {
      const hasSelections = selection?.length > 0
      const baseConfig: Partial<LinkChooserDialogConfig<InscObject>> = {
        ...staticConfig,
        canSave: hasSelections,
        validationMessage: !hasSelections && "Eine Inschrift muss mit mindestens einem Inschriftenträger verknüpft sein."
      }
      const sel = selection?.[0]
      if (sel) {
        return this.inscObjectService.get(sel).pipe(
          take(1),
          map<InscObject, LinkChooserDialogConfig<InscObject>>(record => {
            const configResult: LinkChooserDialogConfig<InscObject> = record["has_object_group"] ? {
              infoMessage: `${selection.length > 1 ? 'Die gewählten' : 'Der gewählte'} Inschriftenträger ${selection.length > 1 ? 'gehören' : 'gehört'} zur Objektgruppe ${record.object_group.name}. Daher stehen nur weitere Inschriftenträger derselben Objektgruppe zur Auswähl.`,
              queryParams: {
                ...defaultQueryParams,
                filters: {
                  has_object_group: ["true"],
                  object_group_id:  [record.object_group?.id],
                }
              }
            } : {
              infoMessage: "Der gewählte Inschriftenträger gehört nicht zu einer Objektgruppe. Daher werden keine weiteren Auswahlmöglichkeiten angezeigt.",
              queryParams: {
                ...defaultQueryParams,
                filters: {
                  id: [record.id]
                },
              }
            }

            return {...baseConfig, ...configResult}
          })
        )
      } else {
        return {...baseConfig, queryParams: defaultQueryParams}
      }
    }

    const initialSelections = this.inscription.insc_objects.map(insc_object => insc_object.id)

    this.linkChooser.open({
      dataService: InscObjectService,
      getConfigFromSelection,
      select: initialSelections
    }).afterClosed().pipe(
      filter(result => result != null),
      switchMap(result => this.linkingService.move("object", result.selection, "inscription", this.inscription.id, "insc_objects")),
      switchMap(() => this.inscriptionService.get(this.inscription.id))
    ).subscribe(inscription => {
      this.recordPageState.record = inscription
      this.snackbarService.showSuccessWithMessage("Verknüpfungen aktualisiert")
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

}
