import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { ImageMetadataEditorsModule } from "../features/images/image-metadata-editors/image-metadata-editors.module"
import { ImageUploaderModule } from "../features/images/image-uploader/image-uploader.module"
import {
  ImageFileMetadataViewComponent
} from "../features/images/shared/components/image-file-metadata-view/image-file-metadata-view.component"
import { SnackbarsModule } from "../shared/snackbars/snackbars.module"
import { TestPageRoutingModule } from "./test-page-routing.module"
import { TestPageComponent } from "./test-page.component"


@NgModule({
  declarations: [
    TestPageComponent
  ],
  imports: [
    CommonModule,
    SnackbarsModule,
    TestPageRoutingModule,
    ImageMetadataEditorsModule,
    ImageUploaderModule,
    ImageFileMetadataViewComponent,
  ]
})
export class TestPageModule { }
