<insc-record-form-container>
  @if (recordPageState.form) {
    <form id="form" [formGroup]="recordPageState.form">
      <insc-form-section sectionTitle="Bezeichnung" [showTitle]="false">
        <mat-form-field appearance="outline">
          <mat-label>Bezeichnung</mat-label>
          <input matInput type="text" id="name" formControlName="name">
          <mat-error inscError errorControlName="name"></mat-error>
        </mat-form-field>
      </insc-form-section>
      <insc-form-section sectionTitle="Aktueller Standort">
        <insc-locationing-form formGroupName="current_locationing"></insc-locationing-form>
      </insc-form-section>
      <insc-form-section sectionTitle="Allgemein">
        <insc-multi-vocabulary-select label="Objekttyp" formControlName="object_types"
        vocabularyName="object_type" [hasEditorialState]="true"></insc-multi-vocabulary-select>
        <insc-multi-vocabulary-select label="Kunstgattung" formControlName="object_genres"
        vocabularyName="object_genre" [hasEditorialState]="true"></insc-multi-vocabulary-select>
        <insc-multi-vocabulary-select label="Material" formControlName="object_materials"
        vocabularyName="object_material" [hasEditorialState]="true"></insc-multi-vocabulary-select>
        <insc-multi-vocabulary-select label="Technik" formControlName="object_techniques"
        vocabularyName="object_technique" [hasEditorialState]="true"></insc-multi-vocabulary-select>
        <insc-single-vocabulary-select label="Zustand" formControlName="status"
        vocabularyName="status" [hasEditorialState]="true"></insc-single-vocabulary-select>
      </insc-form-section>
      <insc-form-section sectionTitle="Datierung" formArrayName="datings">
        @for (dating of recordPageState.itemsFor('datings'); track dating) {
          <insc-repeatable-form [formGroup]="dating">
            <insc-dating-form></insc-dating-form>
          </insc-repeatable-form>
        }
      </insc-form-section>
      <insc-form-section sectionTitle="Literatur" formArrayName="literature_references">
        @for (litRef of recordPageState.itemsFor('literature_references'); track litRef) {
          <insc-repeatable-form [formGroup]="litRef">
            <insc-literaturereference-form></insc-literaturereference-form>
          </insc-repeatable-form>
        }
      </insc-form-section>
      <insc-form-section sectionTitle="Provenienz" formArrayName="provenience_locationings">
        @for (locationing of recordPageState.itemsFor('provenience_locationings'); track locationing) {
          <insc-repeatable-form [formGroup]="locationing">
            <insc-locationing-form></insc-locationing-form>
          </insc-repeatable-form>
        }
      </insc-form-section>
      <insc-form-section sectionTitle="Personen / Organisationen" formArrayName="person_organizations">
        @for (persOrg of recordPageState.itemsFor('person_organizations'); track persOrg) {
          <insc-repeatable-form [formGroup]="persOrg">
            <insc-person-organization-form></insc-person-organization-form>
          </insc-repeatable-form>
        }
      </insc-form-section>
      <insc-form-section sectionTitle="Lokalisierung" formArrayName="origins">
        @for (origin of recordPageState.itemsFor('origins'); track origin) {
          <insc-repeatable-form [formGroup]="origin">
            <insc-origin-form></insc-origin-form>
          </insc-repeatable-form>
        }
      </insc-form-section>
      <insc-form-section sectionTitle="Wappen" formArrayName="emblems">
        @for (emblem of recordPageState.itemsFor('emblems'); track emblem) {
          <insc-repeatable-form [formGroup]="emblem">
            <insc-emblem-element-form></insc-emblem-element-form>
          </insc-repeatable-form>
        }
      </insc-form-section>
      <insc-form-section sectionTitle="Marken" formArrayName="marks">
        @for (mark of recordPageState.itemsFor('marks'); track mark) {
          <insc-repeatable-form [formGroup]="mark">
            <insc-mark-element-form></insc-mark-element-form>
          </insc-repeatable-form>
        }
      </insc-form-section>
      <insc-form-section sectionTitle="Zeichen" formArrayName="signs">
        @for (sign of recordPageState.itemsFor('signs'); track sign) {
          <insc-repeatable-form [formGroup]="sign">
            <insc-sign-element-form></insc-sign-element-form>
          </insc-repeatable-form>
        }
      </insc-form-section>
      <insc-form-section sectionTitle="Ikonographie" formArrayName="iconography_descs">
        @for (iconography_desc of recordPageState.itemsFor('iconography_descs'); track iconography_desc) {
          <insc-repeatable-form [formGroup]="iconography_desc">
            <insc-iconography-desc-form></insc-iconography-desc-form>
          </insc-repeatable-form>
        }
      </insc-form-section>
      <insc-form-section sectionTitle="Kommentare" formArrayName="comments">
        @for (comment of recordPageState.itemsFor('comments'); track comment) {
          <insc-repeatable-form [formGroup]="comment">
            <insc-comment-form></insc-comment-form>
          </insc-repeatable-form>
        }
      </insc-form-section>
    </form>
  }
</insc-record-form-container>
