<insc-data-group>
  <div inscDataField>
    <div *inscFieldLabel>Standort</div>
    <div *inscFieldValue>
      <span class="city">{{locationDisplayHelper.getLocationNameWithLostAnnotation(locationing.location?.city)}}</span>
      <span class="ancestors">{{locationDisplayHelper.getOwnAndAncestorNames(locationing.location)}}</span>
    </div>
  </div>
  <div inscDataField>
      <div *inscFieldLabel>{{ locationTypeString }}</div>
      <div *inscFieldValue>{{ locationing.specification }}</div>
  </div>
  <div inscDataField>
    <div *inscFieldLabel>Zeitraum</div>
    <div *inscFieldValue>{{ locationing.timeframe }}</div>
  </div>
</insc-data-group>
