<insc-image-tile [image]="result" class="highlight" [ngClass]="{'image-menu-open': imageMenuTrigger.menuOpen }">

  <div class="text-overlay">

    <div class="subtitle">
      @if (inscObjectOrInscriptionNames) {
        @for (name of inscObjectOrInscriptionNames; track name) {
          <div>
            {{ name }}
          </div>
        }
      } @else {
        <span class="unlinked">unverknüpft</span>
      }
      <ng-template #unlinked>
        <span class="unlinked">unverknüpft</span>
      </ng-template>
    </div>

    <div class="title" [innerHTML]="highlight.name || result.name"></div>

    <div class="subtitle">
      @if (highlight['insc_objects.current_locationing.location.name']) {
        <em>
          {{locationNames}}
        </em>
      } @else {
        {{locationNames}}
      }
    </div>
  </div>

  <div class="links-button-container"
    (click)="$event.stopPropagation()"
    >
    <button
      matTooltip="Verknüpfte Datensätze öffnen"
      [matMenuTriggerFor]="imageMenu"
      [matMenuTriggerData]="{$implicit: result}"
      #imageMenuTrigger="matMenuTrigger"
      mat-icon-button class="links-button"><mat-icon>more_vert</mat-icon></button>
  </div>
</insc-image-tile>


