import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router"
import { Observable } from 'rxjs'
import { GlobalConfirmNavigationService } from "./global-confirm-navigation.service"

@Injectable({
  providedIn: 'root'
})
export class GlobalCanActivateChildGuard  {

  constructor(private globalConfirmNavigationService: GlobalConfirmNavigationService) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.globalConfirmNavigationService.canNavigate()
  }
}
