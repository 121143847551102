import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { environment } from "../../environments/environment"
import { APIResult } from "./data.service"

export type LinkingIDs = string | string[]

// defines possible relationships for linking between images, inscriptions and objects
export type LinkingSource = "image" | "inscription" | "object" | "object_group"
export type LinkingTarget<S extends LinkingSource = LinkingSource> =
  S extends "object" ? "inscription" | "image" | "object_group" :
  S extends "inscription" ? "object" | "image" :
  S extends "image" ? "inscription" | "object" :
  S extends "object_group" ? "object"
                           : never
export type LinkingRelation<S extends LinkingSource = LinkingSource, T extends LinkingTarget = LinkingTarget> =
  S extends "object"          ? "insc_objects":
  S extends "inscription"     ? "inscriptions" :
  S extends "image"           ? T extends "object"
                                  ? "images" | "context_images"
                                  : "images"
  : S extends "object_group"  ? "object_group"
  : never

export type LinkingParams<S extends LinkingSource = LinkingSource> =
  { position?: S extends ("image" | "inscription") ? number : undefined }


@Injectable({
  providedIn: 'root'
})
export class LinkingService {

  protected readonly baseUrl = environment.apiUrl

  constructor(private http: HttpClient) { }

  link<S extends LinkingSource, T extends LinkingTarget<S>>(sourceType: S, sourceIds: LinkingIDs, targetType: T, targetId: string, targetRelation: LinkingRelation<S, T>, params: LinkingParams = {}) {

    // Array.concat will accept an array of ids or a single ids.
    // ["1", "2"] => "1,2"
    const sources = [].concat(sourceIds).join()
    console.log(sourceIds)
    return this.http.post<APIResult<S>>(`${this.baseUrl}/linking/link/${sourceType}/${sources}/to/${targetType}/${targetId}/${targetRelation}`, params)
  }

  move<S extends LinkingSource, T extends LinkingTarget<S>>(sourceType: S, sourceIds: LinkingIDs, targetType: T, targetId: string, targetRelation: LinkingRelation<S, T>, params: LinkingParams = {}) {
    const sources = [].concat(sourceIds).join()
    return this.http.post<APIResult<S>>(`${this.baseUrl}/linking/move/${sourceType}/${sources}/to/${targetType}/${targetId}/${targetRelation}`, params)
  }

  moveFrom<S extends LinkingSource, F extends LinkingTarget<S>, T extends LinkingTarget<S>>(sourceType: S, sourceIds: LinkingIDs, moveSourceType: F, moveSourceId: string, sourceRelation: LinkingRelation<S, F>, targetType: T, targetId: string, targetRelation: LinkingRelation<S, T>, params: LinkingParams = {}) {
    const sources = [].concat(sourceIds).join()
    return this.http.post<APIResult<S>>(`${this.baseUrl}/linking/move/${sourceType}/${sources}/from/${moveSourceType}/${moveSourceId}/${sourceRelation}/to/${targetType}/${targetId}/${targetRelation}`, params)
  }

  unlink<S extends LinkingSource, T extends LinkingTarget<S>>(sourceType: S, sourceIds: LinkingIDs, targetType: T, targetId: string, targetRelation: LinkingRelation<S, T>) {
    const sources = [].concat(sourceIds).join()
    return this.http.delete<APIResult<S>>(`${this.baseUrl}/linking/link/${sourceType}/${sources}/to/${targetType}/${targetId}/${targetRelation}`, {})
  }
}

