@if (vocabulary) {
  <div class="wrap">
    <mat-form-field class="search-field" appearance="outline" subscriptSizing="dynamic">
      <input matInput #filterInput placeholder="Filter..." [disabled]="currentlyEditing()">
      <mat-icon matIconPrefix>search</mat-icon>
      @if (filterInput.value) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="filterInput.value = ''">
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>

    <mat-nav-list class="list">
      @for (entry of (vocabulary.entries | valueFilter: {pattern: filterInput.value, filterByProp: 'name'}); track entry.original.id) {
        <mat-list-item
          class="list-item"
          [disabled]="buttonsDisabled(entry.original)"
          [disableRipple]="currentlyEditing(entry.original)"
          [activated]="selectedEntryId == entry.original.id"
          (click)="selectEntry(entry.original)"
        >
          <!-- VIEW MODE -->
          @if (!currentlyEditing(entry.original)) {
            <div class="entry">
              <div class="entry-name">
                <span [innerHtml]="entry.highlighted.name"></span>
                <span class="num-records">({{entry.original.assigned_record_count}})</span>
              </div>
              <div class="entry-buttons" matListItemMeta>
                @if (editable && entry.original.comment == null) {
                  <button
                    class="list-item-button" type="button"
                    mat-icon-button matTooltip="Kommentar hinzufügen"
                    (click)="addComment(entry.original)"
                    [disabled]="buttonsDisabled(entry.original)"
                    >
                    <mat-icon>add_comment</mat-icon>
                  </button>
                }
                @if (editable) {
                  <button class="list-item-button" type="button" mat-icon-button matTooltip="Eintrag bearbeiten"
                    (click)="startEdit(entry.original)  "
                    [disabled]="buttonsDisabled(entry.original)"
                    >
                    <mat-icon>edit</mat-icon>
                  </button>
                }
                @if (editable) {
                  <button class="list-item-button" type="button" mat-icon-button matTooltip="Eintrag entfernen"
                    (click)="removeEntry(vocabulary, entry.original)"
                    [disabled]="buttonsDisabled(entry.original)"
                    >
                    <mat-icon>delete</mat-icon>
                  </button>
                }
              </div>
            </div>
            @if (entry.original.comment) {
              <div class="comment-wrapper">
                <div class="comment"><mat-icon inline>comment</mat-icon>{{entry.original.comment}}</div>
              </div>
            }
          }
          @else {
            <!-- EDIT MODE -->

            <div class="edit-view">
              <!-- EDIT FORM -->
              <form class="edit-form">
                <mat-form-field subscriptSizing="dynamic">
                  <mat-label>Eintrag</mat-label>
                  <input matInput type="text" #newNameInput [value]="entry.original.name"
                    (keydown.enter)="$event.preventDefault(); updateEntry(vocabulary, entry.original, newNameInput.value, commentInput?.value)"
                    (keydown.escape)="cancelEdit(entry.original)"
                    >
                  @if (newNameInput?.focused) {
                    <mat-hint><em>ENTER</em> zum Speichern, <em>ESC</em> zum Abbrechen</mat-hint>
                  }
                </mat-form-field>
                @if (entry.original.comment != null) {
                  <mat-form-field subscriptSizing="dynamic">
                    <mat-label>Kommentar</mat-label>
                    <textarea matInput #commentInput (keydown.escape)="cancelEdit(entry.original)"
                    [value]="entry.original.comment"></textarea>
                    @if (commentInput?.focused) {
                      <mat-hint><em>ENTER</em> für neue Zeile, <em>ESC</em> zum Abbrechen</mat-hint>
                    }
                  </mat-form-field>
                }
              </form>
              <!-- BUTTONS -->
              <div class="list-item-buttons" matListItemMeta>
                <button class="list-item-button" mat-icon-button matTooltip="Speichern" type="button"
                  (click)="updateEntry(vocabulary, entry.original, newNameInput.value, commentInput?.value)"
                  >
                  <mat-icon>done</mat-icon>
                </button>
                <button class="list-item-button" type="button" mat-icon-button matTooltip="Änderungen verwerfen und abbrechen"
                  (click)="cancelEdit(entry.original)">
                  <mat-icon>cancel</mat-icon>
                </button>
                @if (entry.original.comment != null) {
                  <button
                    class="list-item-button"
                    type="button" mat-icon-button matTooltip="Kommentar entfernen"
                    (click)="removeComment(entry.original)"
                    [disabled]="buttonsDisabled(entry.original)"
                    >
                    <mat-icon>comments_disabled</mat-icon>
                  </button>
                }
                @if (entry.original.comment == null) {
                  <button
                    class="list-item-button"
                    type="button" mat-icon-button matTooltip="Kommentar hinzufügen"
                    (click)="addComment(entry.original)"
                    [disabled]="buttonsDisabled(entry.original)"
                    >
                    <mat-icon>add_comment</mat-icon>
                  </button>
                }
              </div>
            </div>
          }
        </mat-list-item>
      }
    </mat-nav-list>
    @if (editable) {
      <div>
        <mat-divider></mat-divider>
        <form class="add-form">
          <mat-form-field class="new-entry-input" subscriptSizing="dynamic">
            <mat-icon matIconPrefix>edit</mat-icon>
            <input matInput #newEntryInput type="text" [disabled]="currentlyEditing()"
              (keydown.enter)="$event.preventDefault(); addEntry(vocabulary, newEntryInput.value)"
              >
            <mat-label>Neuer Eintrag</mat-label>
          </mat-form-field>
          <button mat-icon-button matTooltip="Eintrag hinzufügen" type="button"
            (click)="addEntry(vocabulary, newEntryInput.value)" [disabled]="currentlyEditing() || !newEntryInput.value"
            >
            <mat-icon>playlist_add</mat-icon>
          </button>
        </form>
      </div>
    }
  </div>
}
