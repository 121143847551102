import { GermaniaSacraPersonRecord } from "./germania-sacra-person-api"

export const getTimeframeString = ({geburtsdatum, sterbedatum, belegdaten}: GermaniaSacraPersonRecord["person"]): string => {
  if (geburtsdatum && sterbedatum) {
    return `${geburtsdatum} - ${sterbedatum}`
  } else if (geburtsdatum) {
    return `geb. ${geburtsdatum}`
  } else if (sterbedatum) {
    return `gest. ${sterbedatum}`
  } else if (belegdaten) {
    return `belegt ${belegdaten}`
  }
}
