import { NgModule } from "@angular/core"
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, RouterModule, Routes } from "@angular/router"
import { ImageOverviewPageComponent } from "./image-overview-page/image-overview-page.component"
import { ObjectOverviewPageComponent } from "./object-overview-page/object-overview-page.component"

export class OverviewRouteReuseStategy implements RouteReuseStrategy {

  handlers: { [key: string]: DetachedRouteHandle } = {}

  shouldDetach(): boolean {
    return true
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    this.handlers[route.routeConfig.path] = handle
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.handlers[route.routeConfig.path]
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig) { return null }
    return this.handlers[route.routeConfig.path]
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig
  }

}

const routes: Routes = [
  { path: "overview", children: [
    {path: "", redirectTo: "objects", pathMatch: "full"},
    {path: "objects", component: ObjectOverviewPageComponent},
    {path: "images", component: ImageOverviewPageComponent}
  ]}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [{
    provide: RouteReuseStrategy,
    useClass: OverviewRouteReuseStategy,
  }]
})
export class OverviewPagesRoutingModule { }
