<mat-form-field class="input" appearance="outline" subscriptSizing="dynamic">
  <mat-icon matPrefix>search</mat-icon>
  <mat-label>Suchbegriff</mat-label>
  <input type="text" matInput [formControl]="queryCtrl" [matAutocomplete]="auto">
  @if (loading) {
    <mat-spinner matTextSuffix diameter="16"></mat-spinner>
  }
  @if (error) {
    <mat-icon matSuffix color="warn">error</mat-icon>
  }
  @if (error) {
    <mat-error>{{ getErrorString() }}</mat-error>
  }
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true" [displayWith]="autocompleteValueDisplayFunc">
  @if (lookupResults | async; as _lookupResults) {
    @for (result of _lookupResults; track result) {
      <mat-option
        [value]="result"
        [ngClass]="{'insc-mat-option-with-subtitle': !!result.description}">
        <div class="insc-mat-option-title">{{ result.title }}</div>
        @if (result.description) {
          <div class="insc-mat-option-subtitle"
          >{{ result.description }}</div>
        }
      </mat-option>
    }
    @if (queryCtrl.value.length > 0 && _lookupResults.length === 0) {
      <mat-option disabled
        >
        Keine Ergebnisse für {{queryCtrl.value}}.
      </mat-option>
    }
  }

</mat-autocomplete>
