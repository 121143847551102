import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { ActivitiesPageModule } from "./features/activities/activities-page/activities-page.module"
import { OverviewPagesModule } from "./features/overview/overview-pages.module"
import { RecordsModule } from "./features/records/records.module"
import { RegisterPageModule } from "./features/registers/register-page/register-page.module"
import { UserResolver } from "./features/user-management/user-management-page/resolvers/user-resolver.service"
import { UserManagementPageModule } from "./features/user-management/user-management-page/user-management-page.module"
import { VocabulariesPageModule } from "./features/vocabularies-page/vocabularies-page.module"
import { AuthGuard } from "./services/auth.guard"
import { CanDeactivateGuard } from "./services/can-deactivate-guard.service"
import { FormLeaveGuard } from "./services/form-leave-guard.service"
import { GlobalCanActivateChildGuard } from "./services/global-can-activate-child-guard.service"
import { TestPageModule } from "./test-page/test-page.module"

const routes: Routes = [
  { path: "test", loadChildren: () => TestPageModule},
  { path: "", canActivateChild: [AuthGuard, GlobalCanActivateChildGuard], children: [
    {path: "", redirectTo: "/overview/objects", pathMatch: "full"},
    {path: "registers", loadChildren: () => RegisterPageModule },
    {path: "", loadChildren: () => RecordsModule},
    {path: "", loadChildren: () => OverviewPagesModule},
    {path: "", loadChildren: () => ActivitiesPageModule},
    {path: "", loadChildren: () => UserManagementPageModule},
    {path: "vocabularies", loadChildren: () => VocabulariesPageModule},

    ]},
  { path: 'login', loadChildren: () => import('./features/login-page/login-page.module').then(m => m.LoginPageModule) }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule],
  providers: [FormLeaveGuard, CanDeactivateGuard, UserResolver]

})
export class AppRoutingModule { }
