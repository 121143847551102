import { Component, Input } from "@angular/core"
import { HistoricalPersonEntry } from "../../../../shared/models"

@Component({
  selector:    'insc-historical-person-entry-view',
  templateUrl: './historical-person-entry-view.component.html',
  styleUrls:   ['./historical-person-entry-view.component.scss']
})
export class HistoricalPersonEntryViewComponent {
  @Input() historicalPersonEntry: HistoricalPersonEntry
  @Input() disabled = false
}
