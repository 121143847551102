import { Injectable } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Observable } from "rxjs"
import { map, share, tap } from "rxjs/operators"
import { QueryParams } from "../../services/data.service"
import { SearchStateService } from "../../services/search-state.service"

@Injectable({
  providedIn: 'root'
})
export class OverviewPagesService {

  constructor(
    private searchStateService: SearchStateService,
    private router: Router
  ) { }

  searchRequestFromUrlQueryParams(route: ActivatedRoute): Observable<QueryParams> {
    return route.queryParamMap.pipe(
      // cache current search URL for "back to results"-Link
      tap(() => this.searchStateService.cacheSearchUrl(this.router.url)),

      map((queryParamMap) => (queryParamMap.has("q") ? JSON.parse(queryParamMap.get("q")) : {}) as QueryParams),
      share()
    )
  }

  navigateToSearch(queryParams: QueryParams): void {
    void this.router.navigate([], {
      queryParams: Object.keys(queryParams).length > 0 && {q: JSON.stringify(queryParams)}
    })
  }

}
