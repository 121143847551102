import { Component, Input, OnInit } from "@angular/core"

import { Address } from "../../../../shared/models"

@Component({
  selector:    'insc-address-view',
  templateUrl: './address-view.component.html',
  styleUrls:   ['./address-view.component.scss']
})
export class AddressViewComponent implements OnInit {

  @Input() address: Address

  constructor() { }

  ngOnInit() {
  }

}
