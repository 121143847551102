<h2 matDialogTitle>
  @if (data?.parent?.isContext) {
    <span>Kontextaufnahmen</span>
  }
  @if (!(data?.parent?.isContext)) {
    <span>Aufnahmen</span>
  }
  hochladen
  @if (data?.parent; as parent) {
    <span> – </span>
    @if (parent.type === 'insc_object') {
      <span>Inschriftenträger: </span>
    }
    @if (parent.type === 'inscription') {
      <span>Inschrift: </span>
    }
    <span class="linked-record-name">{{data.linkedRecordName}}</span>
  }
</h2>
<insc-image-uploader matDialogContent #uploader class="uploader" [parent]="data?.parent"></insc-image-uploader>
<mat-divider class="divider"></mat-divider>
<div matDialogActions align="end">
  <button mat-button color="primary" [disabled]="uploader.isBusy()" (click)="close()">
    <mat-icon>checkmark</mat-icon>
    <span>Fertig</span>
  </button>
</div>
