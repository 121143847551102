import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from "@angular/material/dialog"
import { ImageViewerDialogComponent } from "./image-viewer-dialog.component"
import { ImageViewerComponent } from "./image-viewer.component"
import { ImageViewerService } from "./image-viewer.service"



@NgModule({
  declarations: [
    ImageViewerComponent,
    ImageViewerDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  exports: [
    ImageViewerComponent,
    ImageViewerDialogComponent
  ],
  providers: [ImageViewerService]
})
export class ImageViewerModule { }
