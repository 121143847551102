import { Injectable } from "@angular/core"
import { ObjectLocation } from "../../../../../../shared/models/object-location.model"
import { ExternalNormDataMapperInterface } from "../../external-norm-data-mapper.interface"
import { GeonamesResponse } from "./geonames-norm-data-provider.service"

@Injectable({
  providedIn: "root"
})
export class GeonameToInscLocationMapper implements ExternalNormDataMapperInterface<GeonamesResponse, Partial<ObjectLocation>> {
  map(normDataRecord: GeonamesResponse): Partial<ObjectLocation> {
    return {
      name: normDataRecord.name,
      lat: normDataRecord.lat,
      lng: normDataRecord.lng,
      geonames_id: normDataRecord.geonameId,
      country_code: normDataRecord.countryCode
    }
  }
}
