import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { catchError, map, share } from "rxjs/operators"
import { AbstractExternalNormDataProviderService, ExternalNormDataLookupResult } from "../../abstract-external-norm-data-provider.service"
import { LobidGNDPersonRecord, LobidGNDQueryResponse } from "./gnd-api"
import { getTimeframeString } from "./gnd-formatting-helpers"

@Injectable({
  providedIn: 'root'
})
export class GNDNormDataProviderService extends AbstractExternalNormDataProviderService<LobidGNDPersonRecord> {

  constructor(private http: HttpClient) {
    super(http)
  }

  getExternalLink(id: string): string {
    return `https://d-nb.info/gnd/${id}`
  }

  getValidity(id: string): Observable<boolean> {
    return this.http.head(`https://lobid.org/gnd/${id}`, {observe: "response"}).pipe(
      catchError((err: unknown) => of(err)),
      map(res => {
        if (res instanceof HttpErrorResponse && res.status !== 404) {
          throw res
        }
        return res instanceof HttpResponse && Boolean(res?.ok)
      }),
      share()
    )
  }

  getEntryData<T>(id: string): Observable<T> {
    return this.http.get<T>(`https://lobid.org/gnd/${id}.json`)
  }

  get lookupAvailable(): boolean { return true }

  lookup(queryString: string, options: Record<string, string>): Observable<ExternalNormDataLookupResult[]> {
    if (!["person", "place"].includes(options?.type)) {
      throw new Error("GND Lookup Configuration error: lookupOptions must have a 'type' property with either 'person' or 'place' set.")
    }

    const type = {
      "person": "Person",
      "place": "Geografikum"
    }[options.type]

    return this.http.get<LobidGNDQueryResponse>(`https://lobid.org/gnd/search?q=${queryString}&format=json&filter=type:${type}`).pipe(
      map(response => response.member),
      catchError((err: unknown) => this.handleLookupError(err)),
      map(suggestionResults => suggestionResults.map(suggestionResult => ({
        id: suggestionResult.gndIdentifier,
        title: this.getResultTitle(suggestionResult),
        description: this.getResultDescription(suggestionResult)
      })))
    )
  }

  descriptionFromEntryData<T extends LobidGNDPersonRecord>(entryData: T): string {
    return entryData["preferredName"]
  }

  private getResultDescription(record: LobidGNDPersonRecord): string {
    if (record.biographicalOrHistoricalInformation?.length > 0 ) {
      return record.biographicalOrHistoricalInformation.join(" | ")
    } else {
      const occupations = record.professionOrOccupation?.map(entry => entry.label) ?? []
      const places = [
        ...(record.placeOfBirth ?? []),
        ...(record.placeOfActivity ?? []),
        ...(record.placeOfDeath ?? [])
      ].map(entry => entry.label)

      return [...occupations, ...places].join(" | ")
    }
  }

  private getResultTitle(record: LobidGNDPersonRecord): string {
    const timeframeString = getTimeframeString(record)
    if (timeframeString) {
      return `${record.preferredName} (${timeframeString})`
    } else if (record.periodOfActivity?.length > 0) {
      const periodString = record.periodOfActivity.map(date => `~${date}`).join(" / ")
      return `${record.preferredName} (${periodString})`
    }

    return record.preferredName
  }
}
