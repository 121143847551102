import { DataSource } from "@angular/cdk/collections"
import { FormArray, FormControl, FormGroup } from "@angular/forms"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort } from "@angular/material/sort"
import { BehaviorSubject, merge, Observable, Subject } from "rxjs"
import { map, takeUntil } from "rxjs/operators"
import { InscImage } from "../../../../shared/models/image.model"
import { IPTCDataset } from "../multi-iptc-editor/iptc-dataset.service"

export type IptcDatasetFormGroupType = {
  [Property in keyof IPTCDataset]: IPTCDataset[Property] extends (infer T)[]
                                    ? FormArray<FormControl<T>>
                                    : FormControl<IPTCDataset[Property]>
}
export interface TableIptcEditorImageRow {
  imageId: InscImage["id"]
  imageName: InscImage["name"]
  imagePreviewUrl: InscImage["preview_url"]
  iptcDatasetFormGroup: FormGroup<IptcDatasetFormGroupType>
}

/**
 * Data source for the TableIptcEditor view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class TableIptcEditorDataSource extends DataSource<TableIptcEditorImageRow> {
  paginator: MatPaginator | undefined;
  sort: MatSort | undefined;

  private data$ = new BehaviorSubject<TableIptcEditorImageRow[]>([])
  get data() { return this.data$.value }

  private unsubscribe$ = new Subject<void>()

  constructor() {
    super();
  }

  setData(data: TableIptcEditorImageRow[]) {
    this.data$.next(data)
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<TableIptcEditorImageRow[]> {
    if (this.paginator && this.sort) {
      // Combine everything that affects the rendered data into one update
      // stream for the data-table to consume.
      return merge(this.paginator.page, this.sort.sortChange, this.data$)
        .pipe(
          takeUntil(this.unsubscribe$),
          map(() => this.data$.value),
          map(data => this.getSortedData(data)),
          map(data => this.getPagedData(data))
        );
    } else {
      throw Error('Please set the paginator and sort on the data source before connecting.');
    }
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: TableIptcEditorImageRow[]): TableIptcEditorImageRow[] {
    if (this.paginator) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize
      const endIndex = startIndex + this.paginator.pageSize
      return data.slice(startIndex, endIndex)
    } else {
      return data
    }
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: TableIptcEditorImageRow[]): TableIptcEditorImageRow[] {
    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort?.direction === 'asc';
      switch (this.sort?.active) {
        case 'name': return compare(a.imageName, b.imageName, isAsc);
        case 'id': return compare(a.imageId, b.imageId, isAsc);
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
