import { Directive, EmbeddedViewRef, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"
import { AuthService } from "../../../services/auth.service"
import { User } from "../../models/user.model"

// TODO: improve performance - make meetsCondition stored variable, update using auth service observables

@Directive()
export class AbstractAuthDirective<T> implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>()

  requiredValue: T
  elseTemplateRef: TemplateRef<never>

  private thenViewRef: EmbeddedViewRef<any> = null
  private elseViewRef: EmbeddedViewRef<any> = null

  private _currentUser: User
  get currentUser(): User { return this._currentUser }


  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    protected auth: AuthService
  ) { }

  ngOnInit() {
    this.auth.currentUser.pipe(takeUntil(this.unsubscribe$)).subscribe(user => {
      this._currentUser = user
      this.updateView()
    })
  }

  meetsCondition(): boolean { return false }

  protected updateView(): void {

    if (this.meetsCondition()) {
      this.showThenTemplate()
    } else {
      this.showElseTemplateOrClear()
    }
  }

  private showThenTemplate() {

    if (this.elseViewRef) {
      this.viewContainer.clear()
      this.elseViewRef = null
    }

    if (!this.thenViewRef) {
      this.thenViewRef = this.viewContainer.createEmbeddedView(this.templateRef)
    }
  }

  private showElseTemplateOrClear() {
    if (this.thenViewRef) {
      this.viewContainer.clear()
      this.thenViewRef = null
    }

    if (this.elseTemplateRef && !this.elseViewRef) {
      this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef)
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

}
