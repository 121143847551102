<ng-container [formGroup]="recordingDateForm">
  <mat-radio-group class="type-chooser" formControlName="type">
    <mat-radio-button value="year">Zeitpunkt</mat-radio-button>
    <mat-radio-button value="range">Zeitraum</mat-radio-button>
  </mat-radio-group>
  <div class="pickers">
    <insc-date-range-picker
      [yearOnly]="true" formControlName="date_earliest"
    [placeholder]="recordingDateForm.value['type'] === 'year' ? 'Jahr' : 'frühestens'"></insc-date-range-picker>
    @if (recordingDateForm.value['type'] === 'range') {
      <insc-date-range-picker
      [yearOnly]="true" formControlName="date_latest" placeholder="spätestens"></insc-date-range-picker>
    }
  </div>
</ng-container>

@if (recordingDateForm?.errors?.invalid_range) {
  <mat-error>
    Jahr (frühestens) darf nicht höher sein als Jahr (spätestens).
  </mat-error>
}
