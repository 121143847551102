<h3 class="associated-records-title">Verknüpfte Datensätze</h3>
<div class="associated-records">
  <ng-template associatedRecordResult #associatedRecordLinkTemplate let-result>
    @if ({selectedImages: imageSidebarService.images$ | async}; as imageSidebarData) {
      @switch (result?.type) {
        @case ('Image') {
          <span
            class="associated-image"
            [ngClass]="{'associated-image-selected': isAssociatedImageSelected(imageSidebarData.selectedImages, result)}"
            (click)="associatedImageClick(imageSidebarData.selectedImages, result)">
            {{result.name}}
          </span>
        }
        @case ('InscObject') {
          <span>
            <a [routerLink]="['/objects', result.id]" target="_blank">{{result.name}}</a>
          </span>
        }
        @default {
          <span><em>Lade...</em></span>
        }
      }
    }
  </ng-template>

  @for (query of associatedQueries; track query) {
    <insc-associated-record-list
      [canCollapse]="associatedQueries.length > 1"
      [getSearchResults]="query.getSearchResults"
      [overviewRoute]="query.overviewRoute"
      [getQueryParams]="query.getQueryParams"
      [title]="query.title"
      [resultTemplate]="associatedRecordLinkTemplate"
      [entry]="selectedEntry"/>
  }
</div>
